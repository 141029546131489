import { all, takeEvery, put, fork, call } from 'redux-saga/effects'

import SliderApi from 'api/slider/sliderApi'

import { normalizeData } from 'services/jsonaDataFormatter'

import { fetchSlideLocationSuccess, fetchSlideLocationFail } from './actions'
import SLIDER_ACTIONS from './constants'

/**
 * Fetch slider
 */

function* fetchSlideLocation({ slideLocationId }) {
  try {
    const sliderData = yield call(SliderApi.fetchLocation, slideLocationId)

    if (sliderData.isSuccess()) {
      const slider = normalizeData(sliderData.success())

      yield put(fetchSlideLocationSuccess(slider))
    } else {
      throw sliderData.fail()
    }
  } catch (e) {
    yield put(fetchSlideLocationFail(e))
  }
}

/**
 * Saga action listeners
 */

export function* watchFetchSlideLocationRequest() {
  yield takeEvery(
    SLIDER_ACTIONS.FETCH_SLIDE_LOCATION_REQUEST,
    fetchSlideLocation,
  )
}

export default function* rootSaga() {
  yield all([fork(watchFetchSlideLocationRequest)])
}
