import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";

function CircularSteps({ isformSumitted, isDeliveryChoosen }) {
  const minValue = 0;
  const maxValue = 3;
  let value = 1;

  if (isformSumitted) {
    value = 2;
  }
  if (isDeliveryChoosen) {
    value = 3;
  }

  return (
    <div className="d-flex align-items-center mb-3 d-md-none">
      <CircularProgressbar
        minValue={minValue}
        maxValue={maxValue}
        value={value}
        text={`${value} / ${maxValue}`}
        className="circular-steps me-3"
      />
      <div>
        <h5 className="font-weight-semibold text-primary mb-1">
          {!isformSumitted
            ? "Billing Address"
            : !isDeliveryChoosen
            ? "Shipping Method"
            : "Payment Details"}
        </h5>
        {!isDeliveryChoosen && (
          <p className="mb-0 text-gray-600">
            Next{" "}
            <span>
              {!isformSumitted
                ? "Shipping Method"
                : !isDeliveryChoosen && "Payment Details"}
            </span>
          </p>
        )}
      </div>
    </div>
  );
}

export default CircularSteps;
