import React from "react";
import Pagination from "components/Pagination";
import { Translate } from "react-redux-i18n";

import DashboardOrdersSkeleton from "components/Skeleton/DashboardOrdersSkeleton";

import ReviewItem from "./ReviewItem";

const reviewList = [
  {
    id: 1,
    translationKey: "all",
    status: "all",
  },
  {
    id: 2,
    translationKey: "pendingReview",
    status: "pending",
  },
  {
    id: 3,
    translationKey: "approved",
    status: "approved",
  },
];

const ReviewList = ({
  reviews,
  handleEditReview,
  handleDeleteReview,
  page,
  pageMetaData,
  pageNumberHandler,
  isLoading,
}) => {
  if (isLoading) return <DashboardOrdersSkeleton repeat={5} />;

  return (
    <>
      {reviews.length !== 0 ? (
        reviews.map((review) => {
          return (
            <ReviewItem
              handleEditReview={handleEditReview}
              handleDeleteReview={handleDeleteReview}
              key={review.id}
              review={review}
            />
          );
        })
      ) : (
        <p className="text-muted">
          <Translate value="user.myReviews.noReviews" />
        </p>
      )}
      <Pagination
        page={page}
        pageMetaData={pageMetaData}
        pageNumberHandler={pageNumberHandler}
        className="justify-content-center mt-3"
      />
    </>
  );
};

const ReviewCard = ({
  title,
  page,
  pageMetaData,
  pageNumberHandler,
  handleEditReview,
  handleDeleteReview,
  handleFilterStatus,
  reviews,
  reviewFilterStatus,
  isLoading,
}) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-3 pb-2">
        <h2 className="h6 d-inline font-weight-bold mb-0">{title}</h2>
        <div>
          <button
            className="btn btn-secondary btn-sm text-muted dropdown-toggle p-0 border-0"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <Translate
              value={`user.myReviews.dropdown.${reviewFilterStatus.translationKey}`}
            />
          </button>
          <ul className="dropdown-menu border-0 shadow">
            {reviewList.map(({ translationKey, status, id }) => (
              <li key={id}>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => handleFilterStatus(status, translationKey)}
                >
                  <Translate
                    value={`user.myReviews.dropdown.${translationKey}`}
                  />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ReviewList
        reviews={reviews}
        handleEditReview={handleEditReview}
        handleDeleteReview={handleDeleteReview}
        page={page}
        pageMetaData={pageMetaData}
        pageNumberHandler={pageNumberHandler}
        isLoading={isLoading}
      />
    </>
  );
};

export default ReviewCard;
