import React from 'react'
import Wishlist from 'components/User/Wishlist'
import { Translate } from 'react-redux-i18n'

import extractImage from 'utils/extractImage'

const WishlistStatus = ({ status }) => {
  if (status === 'Available') {
    return (
      <span className="badge border rounded-pill text-success border-success">
        <Translate value="user.myWishList.available" />
      </span>
    )
  }

  return (
    <span className="badge border rounded-pill text-dark border-dark">
      <Translate value="user.myWishList.outOfStock" />
    </span>
  )
}

const WishlistItem = ({
  wishlistStatus,
  currentWishlistHash,
  moveWishlist,
  wishlists,
  onBuyNowClicked,
  onRemoveItem,
  productId,
  variant,
  price,
  quantity,
}) => {
  const { product, images, backorderable } = variant

  let availabilityStatus = product.in_stock ? 'In stock' : 'Out of stock'

  availabilityStatus = backorderable ? 'Pre Order' : availabilityStatus

  let bgColor

  if (wishlistStatus === 'Available') {
    bgColor = 'bg-success-light'
  } else {
    bgColor = 'bg-light'
  }

  return (
    <div className="card dashboard-card border-0 shadow-sm mb-3">
      <div className={`order-basic-info border-bottom p-3 ${bgColor}`}>
        {/* TODO: Date isnot implemeted yet. No date as response from server */}
        {/* <p className="text-muted mb-0">
          <Translate value="user.myWishList.placedOn" />:{' '}
          <span className="font-weight-semibold">27 AUG 2020</span>
        </p> */}
        <p className="text-muted mb-0">
          <Translate value="user.myWishList.availability" />:{' '}
          <WishlistStatus status={wishlistStatus} />
        </p>
      </div>
      <div className="p-3">
        <div>
          <Wishlist
            moveWishlist={moveWishlist}
            image={extractImage(images)}
            title={product?.name}
            productId={productId}
            currentWishlistHash={currentWishlistHash}
            variantId={variant.id}
            wishlists={wishlists}
            onRemoveItem={onRemoveItem}
            onBuyNowClicked={onBuyNowClicked}
            qty={quantity}
            price={price}
            stockStatus={
              availabilityStatus === 'Out of stock'
                ? 'Out of Stock'
                : 'Available'
            }
          />
        </div>
      </div>
    </div>
  )
}

export default WishlistItem
