import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import ProductAvailability from "./ProductAvailability";
import ProductOptionTypes from "../OptionTypes/ProductOptionTypes";
import CartQuantitySelector from "./CartQuantitySelector";
import CartButton from "./CartButton";
import WishlistButton from "./WishlistButton";

const CartForm = ({
  inStock,
  wishlists,
  backorderable,
  setCurrentOptionTypes,
  available,
  optionTypes,
  currentOptionTypes,
  addToWishlist,
  addToCart,
  vendor,
  variantId,
  changeDisplayVariant,
}) => {
  const [quantity, setQuantity] = useState(1);

  const { isProductToCartLoading } = useSelector(({ cart }) => cart);

  const handleAddToCart = () => {
    if (!quantity) return;
    addToCart({
      variantId,
      quantity,
    });
  };

  const onQuantityChange = (value) => {
    setQuantity(value);
  };

  const handleAddToWishlist = ({ variantId, quantity, wishlistId }) => {
    addToWishlist({ variantId, quantity, wishlistId });
  };

  const disabled = quantity <= 0 || backorderable || !available || !inStock;

  return (
    <>
      <ProductAvailability
        inStock={inStock}
        backorderable={backorderable}
        available={available}
        vendor={vendor}
      />
      {/* TODO @prakash we should be able to handle if property is defined but we don't have enough values */}
      {optionTypes && optionTypes[0] && optionTypes[0].option_values ? (
        <ProductOptionTypes
          setCurrentOptionTypes={setCurrentOptionTypes}
          optionTypes={optionTypes}
          currentOptionTypes={currentOptionTypes}
          changeDisplayVariant={changeDisplayVariant}
        />
      ) : null}
      <div className="row py-3">
        <div className="col-sm-12 col-md-4 pe-0">
          <CartQuantitySelector onQuantityChange={onQuantityChange} />
        </div>
        <div className="col-md-6 pe-0">
          <CartButton
            onClick={handleAddToCart}
            disabled={disabled}
            isLoading={isProductToCartLoading}
          />
        </div>
        <div className="col-md-2">
          <WishlistButton
            variantId={variantId}
            quantity={quantity}
            onClick={handleAddToWishlist}
            wishlists={wishlists}
          />
        </div>
      </div>
    </>
  );
};

CartForm.propTypes = {};

export default CartForm;
