import client from "services/spreeClient";

class ProductDetailApi {
  static fetchDetails(slug) {
    if (!slug) {
      console.log("No product slug provided");

      return;
    }

    // TODO handle vendor better
    const hasVendor = true;
    // const hasVendor = process.env.REACT_APP_API_URL.includes(
    //   "ecom-api.truemark"
    // );

    // include: `variants.product,option_types.option_values.name,product_properties,taxons,images,default_variant,videos,${
    //   hasVendor ? "vendor" : ""
    // }`,
    return client.products.show(slug, {
      include: `variants.product,option_types.option_values.name,product_properties,taxons,images,default_variant,${
        hasVendor ? "vendor" : ""
      }`,
    });
  }
}

export default ProductDetailApi;
