import React from "react";
import { useDispatch } from "react-redux";

import QuantityPicker from "components/QuantityPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { removeItem, setItemQuantity } from "appRedux/cart/actions";

function CartProductItem({ image, title, options_text, price, id, quantity }) {
  const dispatch = useDispatch();

  const removeItemHandler = () => {
    dispatch(removeItem(id));
  };

  const onQuantityChange = (value) => {
    dispatch(setItemQuantity(id, value));
  };

  return (
    <li className="cart-product-list d-flex align-items-center justify-content-between border-bottom py-3">
      <div className="d-flex align-items-center">
        <img
          src={image}
          alt={title}
          className="img-thumbnail product-img me-4"
        />
        <div className="product-details">
          <ul className="list-unstyled lh-lg">
            <li>
              <h3 className="h6 title text-dark mb-1 font-weight-bold">
                {title}
              </h3>
            </li>
            <li className="text-gray-600">
              Variants : <span>{options_text}</span>
            </li>
            <li className="price">{price}</li>
            <li className="mt-2">
              <QuantityPicker
                onChange={onQuantityChange}
                min={1}
                value={quantity}
                max={100}
              />
            </li>
          </ul>
        </div>
      </div>
      <button onClick={removeItemHandler} className="btn p-3">
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>
    </li>
  );
}

export default CartProductItem;
