export const CHECKOUT = {
  INDEX: "/checkout",
  ADDRESS: "/checkout/address",
  SELECT_ADDRESS: "/checkout/address/select",
  DELIVERY: "/checkout/delivery",
  PAYMENT: "/checkout/payment",
  REGISTRATION: "/checkout/registration",
};

export const CART = {
  INDEX: "/cart",
};

export const CATEGORY = {
  INDEX: "/t/:taxonId",
  addTaxonId: (url, taxonId) => {
    return url.replace(":taxonId", taxonId);
  },
};

export const HOME = {
  INDEX: "/",
};

export const ORDER = {
  INDEX: "/order/:orderNumber",
};

export const PRODUCTS = {
  INDEX: "/products",
  DETAIL: "/products/:slug",
};

export const USER = {
  INDEX: "/user",
  ADDRESS: {
    INDEX: "/user/address",
    ADD: "/user/address/add",
    EDIT: "/user/address/:addressId/edit",
  },
  WISHLIST: {
    INDEX: "/user/wishlist",
    SHOW: "/user/wishlist/:accessHash",
    ADD: "/user/wishlist/add",
    EDIT: "/user/wishlist/:accessHash/edit",
    addwishlistId: (url, accessHash) => {
      return url.replace(":accessHash", accessHash);
    },
  },
  DASHBOARD: "/user/dashboard",
  ORDER: "/user/order",
  REVIEW: {
    INDEX: "/user/review",
    EDIT: "/user/review/:reviewId/edit",
    addreviewId: (url, reviewId) => {
      return url.replace(":reviewId", reviewId);
    },
  },
  PROFILE: "/user/profile",
  QUESTION: {
    INDEX: "/user/question",
    EDIT: "/user/question/:questionId/edit",
    addQuestionId: (url, questionId) => {
      return url.replace(":questionId", questionId);
    },
  },
};

export const VENDOR = {
  INDEX: "/vendor",
  DETAIL: "/vendor/:slug",
  addSlug: (url, slug) => {
    return url.replace(":slug", slug);
  },
};

export const LOGIN = {
  INDEX: "/login",
};

export const SIGNUP = {
  INDEX: "/signup",
};

export const insertProductSlug = (slug) => {
  return PRODUCTS.DETAIL.replace(":slug", slug);
};

export default {
  CHECKOUT,
  CART,
  LOGIN,
  SIGNUP,
  CATEGORY,
  HOME,
  ORDER,
  PRODUCTS,
  VENDOR,
  USER,
};
