const VENDOR_ACTIONS = {
  FETCH_VENDOR_PRODUCTS_REQUEST: "FETCH_VENDOR_PRODUCTS_REQUEST",
  FETCH_VENDOR_PRODUCTS_FAILED: "FETCH_VENDOR_PRODUCTS_FAILED",
  FETCH_VENDOR_PRODUCTS_SUCCESS: "FETCH_VENDOR_PRODUCTS_SUCCESS",
  FETCH_PAGE_META_DATA: "FETCH_PAGE_META_DATA",
  CURRENT_PAGE: "CURRENT_PAGE",
  SET_SORTBY: "SET_SORTBY",
  FILTER_PRODUCTS_LIST: "FILTER_PRODUCTS_LIST",
  CLEAR_PRODUCT_FILTER: "CLEAR_PRODUCT_FILTER",
  RESET_PRODUCTS: "RESET_PRODUCTS",
  FETCH_VENDOR_DETAILS: "FETCH_VENDOR_DETAILS",
};

export default VENDOR_ACTIONS;
