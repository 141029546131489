import React from 'react';
import PropTypes from 'prop-types';

import ColorFilterItem from '../ColorFilterItem';

const ColorOptions = ({
  options,
  handleProductFilter,
  filterName,
  selectedFilterOptions,
}) => {
  const onChange = (value) => {
    const currentValues = selectedFilterOptions || [];

    if (currentValues.indexOf(value) > -1) {
      currentValues.splice(currentValues.indexOf(value), 1);
    } else {
      currentValues.push(value);
    }

    handleProductFilter(filterName, currentValues);
  };

  const colorOptions = options.map(({ id, name, presentation }) => {
    return (
      <ColorFilterItem
        key={id}
        colorBg={presentation}
        // colorName={name}
        onChange={onChange}
        colorId={`${name}-color`}
        selectedFilterOptions={selectedFilterOptions}
      />
    );
  });

  return <li className='d-flex flex-wrap'>{colorOptions}</li>;
};

ColorOptions.propTypes = {};

export default ColorOptions;
