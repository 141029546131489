import React, { useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import PropTypes from "prop-types";

import ReviewForm from "components/Product/ReviewForm";

import pluralizeWord from "utils/pluralizeWord";

import ProductReviews from "./ProductReviews";

const ProductAdditionalInfo = ({
  productProperties,
  description,
  productReviews,
}) => {
  const [toggleReviewForm, setToggleReviewForm] = useState(false);

  return (
    <>
      <ul className="nav nav-tabs mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className="nav-link active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            href="#pills-home"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            {I18n.t("productDetail.description.title")}
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            href="#pills-profile"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            {pluralizeWord(
              "productDetail.reviews.title",
              productReviews.length
            )}{" "}
            <span className="badge rounded-pill bg-primary">
              {productReviews.length}
            </span>
          </a>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <h5>{I18n.t("productDetail.description.specifications")}:</h5>
          <p className="product-description ps-0">{description}</p>
          <h5>{I18n.t("productDetail.description.details")}:</h5>
          <ul className="product-detail ps-0">
            {productProperties.map(({ id, description, value }) => (
              <li key={id}>
                <span className="font-weight-bold">{description}: </span>
                {value}
              </li>
            ))}
          </ul>
        </div>
        <div
          className="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          {toggleReviewForm ? (
            <ReviewForm setToggleReviewForm={setToggleReviewForm} />
          ) : (
            <ProductReviews
              productReviews={productReviews}
              setToggleReviewForm={setToggleReviewForm}
            />
          )}
        </div>
      </div>
    </>
  );
};

ProductAdditionalInfo.propTypes = {};

export default ProductAdditionalInfo;
