import {
  all,
  takeEvery,
  takeLatest,
  put,
  fork,
  call,
} from "redux-saga/effects";

import CartApi from "api/cart/cartApi";
import { normalizeData } from "services/jsonaDataFormatter";

import { resetCheckoutStep } from "appRedux/checkOut/actions";

import { cartOptions } from "constants/cartOptions";

import {
  fetchCartSuccess,
  openCartModal,
  handleCartButtonClick,
  refillPromoCode,
  addToCartSuccess,
} from "./actions";

import CART_ACTIONS from "./constants";

/**
 * Add items to cart
 * @param {Object} action - Action object
 * @param {Object|string} action.payload - Action payload
 */

function* addToCartRequest({ productInCart }) {
  try {
    const cartData = yield call(CartApi.addToCart, {
      variantId: productInCart.variantId,
      quantity: productInCart.quantity,
    });

    if (cartData.isSuccess()) {
      const cart = cartData.success();

      const normalizedCartData = normalizeData(cart);

      yield put(
        addToCartSuccess({
          productInCart: normalizedCartData,
        })
      );

      yield put(resetCheckoutStep());
      yield put({
        type: CART_ACTIONS.FETCH_CART_ITEMS_REQUEST,
        options: {
          include: cartOptions,
        },
      });
      yield put(handleCartButtonClick());
      yield put(openCartModal());
    } else {
      throw cartData.fail();
    }
  } catch (e) {
    yield put({ type: CART_ACTIONS.ADD_TO_CART_FAILED });
  }
}

/**
 * Fetch cart details
 */

function* fetchCart({ options }) {
  try {
    const cartData = yield call(CartApi.fetchCartDetails, options);

    if (cartData.isSuccess()) {
      const cart = cartData.success();

      const normalizedCartData = normalizeData(cart);

      yield put(
        fetchCartSuccess({
          cartData: normalizedCartData,
        })
      );

      if (normalizedCartData.promotions.length !== 0) {
        yield put(
          refillPromoCode({
            code: normalizedCartData.promotions[0].code,
            promoCodeName: normalizedCartData.promotions[0].name,
          })
        );
      }
    } else {
      throw cartData.fail();
    }
  } catch (e) {
    console.log(e);
  }
}

/**
 * set item quantity in cart
 */

function* setitemQuantity({ id, value }) {
  try {
    const res = yield call(CartApi.setitemQuantity, { id, value });

    if (res.isSuccess()) {
      yield put(resetCheckoutStep());
      yield put({
        type: CART_ACTIONS.FETCH_CART_ITEMS_REQUEST,
        options: {
          include: cartOptions,
        },
      });
    } else {
      throw res.fail();
    }
  } catch (e) {}
}

/**
 * remove from cart
 */

function* removeFromCart({ id }) {
  try {
    const res = yield call(CartApi.removeFromCart, id);

    if (res.isSuccess()) {
      yield put(resetCheckoutStep());
      yield put({ type: CART_ACTIONS.REMOVE_FROM_CART_SUCCESS });
      yield put({
        type: CART_ACTIONS.FETCH_CART_ITEMS_REQUEST,
        options: {
          include: cartOptions,
        },
      });
    } else {
      throw res.fail();
    }
  } catch (e) {
    yield put({ type: CART_ACTIONS.REMOVE_FROM_CART_FAILED });
  }
}

/**
 * empty the whole cart
 */

function* emptyCart() {
  try {
    const res = yield call(CartApi.emptyCart);

    if (res.isSuccess()) {
      yield put(resetCheckoutStep());
      yield put({ type: CART_ACTIONS.EMPTY_CART_SUCCESS });
      yield put({
        type: CART_ACTIONS.FETCH_CART_ITEMS_REQUEST,
        options: {
          include: cartOptions,
        },
      });
      localStorage.removeItem("orderToken");
    } else {
      throw res.fail();
    }
  } catch (e) {}
}

// promoCode
function* sendpromoCodeRequest(action) {
  try {
    const res = yield call(CartApi.promoCodeRequest, action.promoCode);

    if (res.isSuccess()) {
      yield put({
        type: CART_ACTIONS.PROMO_CODE_SUCCESS,
      });
      yield put({
        type: CART_ACTIONS.FETCH_CART_ITEMS_REQUEST,
        options: {
          include: cartOptions,
        },
      });
    } else {
      throw { ...res.fail() };
    }
  } catch (e) {
    yield put({
      type: CART_ACTIONS.PROMO_CODE_FAILED,
      error: e.summary || "Promo code not found",
    });
  }
}

function* removePromoCodeRequest(action) {
  try {
    const res = yield call(CartApi.removePromoCodeRequest, action.promoCode);

    if (res.isSuccess()) {
      yield put({
        type: CART_ACTIONS.FETCH_CART_ITEMS_REQUEST,
        options: {
          include: cartOptions,
        },
      });
      yield put({ type: CART_ACTIONS.REMOVE_PROMO_CODE_SUCCESS });
    } else {
      throw { ...res.fail() };
    }
  } catch (e) {
    yield put({
      type: CART_ACTIONS.REMOVE_PROMO_CODE_FAILED,
      error: e.summary,
    });
  }
}

/**
 * Saga action listeners
 */

export function* watchFetchCartRequest() {
  yield takeEvery(CART_ACTIONS.FETCH_CART_ITEMS_REQUEST, fetchCart);
}

export function* watchAddtoCartRequest() {
  yield takeEvery(CART_ACTIONS.ADD_TO_CART_REQUEST, addToCartRequest);
}

export function* watchsetItemQuantityRequest() {
  yield takeLatest(CART_ACTIONS.SET_ITEM_QUANTITY_REQUEST, setitemQuantity);
}

export function* watchRemoveFromCartRequest() {
  yield takeEvery(CART_ACTIONS.REMOVE_FROM_CART, removeFromCart);
}

export function* watchEmptyCartRequest() {
  yield takeLatest(CART_ACTIONS.EMPTY_CART_REQUEST, emptyCart);
}

export function* watchsendpromoCodeRequest() {
  yield takeLatest(CART_ACTIONS.PROMO_CODE_REQUEST, sendpromoCodeRequest);
}

export function* watchremovePromoCodeRequest() {
  yield takeLatest(
    CART_ACTIONS.REMOVE_PROMO_CODE_REQUEST,
    removePromoCodeRequest
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchAddtoCartRequest),
    fork(watchFetchCartRequest),
    fork(watchsetItemQuantityRequest),
    fork(watchRemoveFromCartRequest),
    fork(watchsendpromoCodeRequest),
    fork(watchremovePromoCodeRequest),
    fork(watchEmptyCartRequest),
  ]);
}
