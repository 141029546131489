import client from "services/spreeClient";

class VendorApi {
  static fetchProducts(slug) {
    if (!slug) {
      console.log("No product slug provided");

      return;
    }

    return client.vendor.productList(slug, {
      include:
        "products,image,products.option_types.option_values,products.images,products.default_variant,products.videos",
    });
  }
}

export default VendorApi;
