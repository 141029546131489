import React from "react";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactStars from "react-rating-stars-component";

import Modal from "components/Modal";
import UserIcon from "components/UserIcon";
import ReviewStatus from "components/User/ReviewStatus";

import { convertCDTToLocalTime } from "utils/DateFormatter";
import REACT_STARS from "constants/reactStarsConfig";
import { Translate } from "react-redux-i18n";

const Carousel = ({ images, index }) => {
  return (
    <div id="reviewCarousel" class="carousel slide carousel-fade">
      <div class="carousel-inner">
        {images.map((image, currentIndex) => (
          <div
            class={`carousel-item d-flex align-items-center bg-black rounded ${
              currentIndex == index ? "active" : ""
            }`}
          >
            <img
              src={image}
              class="d-block"
              alt={`product image ${currentIndex}`}
            />
          </div>
        ))}
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#reviewCarousel"
        data-bs-slide="prev"
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="text-primary"
          size="xl"
        />
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#reviewCarousel"
        data-bs-slide="next"
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          className="text-primary"
          size="xl"
        />
      </button>
    </div>
  );
};

const ReviewDetailCarousel = ({
  show,
  handleModelClose,
  index,
  images,
  rating,
  displayName,
  fullName,
  approved,
  createdAt,
  title,
  review,
}) => {
  if (!show) return null;

  return (
    <Modal
      className="review-modal modal-dialog-centered modal-dialog-scrollable"
      closeHandler={handleModelClose}
    >
      <div className="d-flex flex-column flex-sm-row gap-3 overflow-y-scroll">
        <Carousel images={images} index={index} />
        <div className="user-info w-100">
          <div className="d-flex flex-column gap-2 w-100 flex-wrap">
            <div className="d-flex gap-2 align-items-center">
              <UserIcon rating={rating} fullName={displayName} />
              <p className="mb-0 font-weight-bold fs-6">{fullName}</p>
              <ReviewStatus status={approved} disableAnswerStatus />
            </div>
            <p className="text-muted mb-0 review-added-date">
              <Translate
                value="productDetail.reviews.reviewedIn"
                date={convertCDTToLocalTime(createdAt)}
              />
            </p>
          </div>
          <ReactStars
            value={rating}
            count={REACT_STARS.COUNT}
            edit={false}
            size={REACT_STARS.USER_SIZE}
            activeColor={REACT_STARS.ACTIVE_COLOR}
          />
          <div className="question-box mt-3">
            <p className="font-weight-bold me-2 mb-2 fs-6">{title}</p>
            <p>{review}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReviewDetailCarousel;
