import React from "react";

import { ADDRESS_MODAL_TITLES } from "constants/modalTitles";

const AddressBookItem = ({
  address,
  modalTitle,
  btnText,
  onItemButtonClick,
  setAddress,
  selectedShippingAddressId,
  selectedBillingAddressId,
}) => {
  const {
    id,
    firstname,
    lastname,
    address1,
    address2,
    state_name: stateName,
    city,
    zipcode,
    phone,
    label,
  } = address;

  const activeAddress =
    (modalTitle === ADDRESS_MODAL_TITLES.BILLING_ADDRESS &&
      selectedBillingAddressId === id) ||
    (modalTitle === ADDRESS_MODAL_TITLES.SHIPPING_ADDRESS &&
      selectedShippingAddressId === id);

  return (
    <>
      <tbody>
        <tr>
          <td>{label ? label : "-"}</td>
          <td>
            {firstname} {lastname}
          </td>
          <td>
            {address1}, {address2}, {stateName}, {city}, {zipcode}
          </td>
          <td>{phone}</td>

          <td>
            {/* // TODO: default address */}
            {/* {i === 0 && (
              <>
                <span className='d-block'>
                  <Translate value='user.addressBook.defaultShippingAddress' />{' '}
                </span>
                <span className='d-block'>
                  <Translate value='user.addressBook.defaultBillingAddress' />{' '}
                </span>
              </>
            )} */}
          </td>
          <td>
            <button
              type="button"
              onClick={() => onItemButtonClick(id)}
              className="p-0 btn btn-link  text-uppercase bg-transparent border-0 text-primary"
            >
              {btnText}
            </button>
          </td>

          {modalTitle && (
            <td>
              <input
                className="form-check-input"
                type="radio"
                name={modalTitle}
                value={id}
                onChange={(e) => setAddress(e.target.value)}
                checked={activeAddress}
              />
            </td>
          )}
        </tr>
      </tbody>
    </>
  );
};

export default AddressBookItem;
