import React from 'react'
import PropTypes from 'prop-types'

import SEO from 'components/SEO'

import logo from 'assets/images/logo.png'

import SEO_DEFAULT from 'constants/seoDefaults'

const Layout = ({ children, title, description, metaImage, path }) => (
  <>
    <SEO
      title={title || SEO_DEFAULT.TITLE}
      description={description || SEO_DEFAULT.DESCRIPTION}
      metaImage={metaImage || logo}
      path={path || SEO_DEFAULT.PATH}
    />

    <div>{children}</div>
  </>
)

Layout.defaultProps = {
  title: null,
  description: null,
  metaImage: null,
  path: null,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
  metaImage: PropTypes.shape({
    src: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
}

export default Layout
