import React from 'react';

const DashboardCard = ({ title, button, children, clickHandler }) => {
  return (
    <div className='card dashboard-card shadow-sm border-0 mb-2 p-3'>
      <div className='d-flex align-items-center justify-content-between mb-1'>
        <h2 className='h6 d-inline font-weight-bold mb-0'>{title}</h2>
        <button
          onClick={clickHandler}
          className='btn p-0 text-primary w-auto font-weight-semibold'
        >
          {button}
        </button>
      </div>
      {children}
    </div>
  );
};

export default DashboardCard;
