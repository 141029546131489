import { faAccusoft } from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Modal = ({ closeHandler, children, modalTitle, className }) => {
  const preventModalClose = (e) => {
    if (!e) e = window.event;
    e.stopPropagation();
  };

  return (
    <div className="address-modal-backdrop modal" onClick={closeHandler}>
      <div
        onClick={(e) => preventModalClose(e)}
        className={`modal-dialog ${className ? className : ""} `}
      >
        <div className="modal-content">
          <div className="modal-header justify-content-between">
            <h5 className="modal-title" id="Address">
              {modalTitle}
            </h5>
            <FontAwesomeIcon
              type="button"
              className="close-icon"
              icon={faTimes}
              onClick={closeHandler}
            />
          </div>
          <div className="modal-body mt-0">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
