export const generatePages = (currentPage, totalPages) => {
  const lessThanfive = [];

  if (totalPages <= 5) {
    for (let index = 1; index <= totalPages; index++) {
      lessThanfive.push(index);
    }

    return lessThanfive;
  }

  if (currentPage === totalPages) {
    return [currentPage - 2, currentPage - 1, currentPage];
  }
  if (currentPage > totalPages - 3) {
    return [currentPage - 1, currentPage, totalPages];
  }
  if (currentPage <= 3) {
    return [1, 2, 3, 4, 5];
  }

  return [
    currentPage - 2,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    currentPage + 2,
  ];
};

export default generatePages;
