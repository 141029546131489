import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import ProductDetailApi from 'api/productDetail/productDetailApi';
import { normalizeData } from 'services/jsonaDataFormatter';

import { fetchProductDetailSuccess } from './actions';
import PRODUCT_ACTIONS from './constants';

/**
 * Fetch individual product
 */

function* fetchProductDetail({ slug }) {
  try {
    const productData = yield call(ProductDetailApi.fetchDetails, slug);

    if (productData.isSuccess()) {
      const product = normalizeData(productData.success());

      yield put(fetchProductDetailSuccess(product));
    } else {
      throw productData.fail();
    }
  } catch (e) {
    console.log(e);
  }
}

/**
 * Saga action listeners
 */

export function* watchProductDetailRequest() {
  yield takeEvery(
    PRODUCT_ACTIONS.FETCH_PRODUCT_DETAIL_REQUEST,
    fetchProductDetail
  );
}

export default function* rootSaga() {
  yield all([fork(watchProductDetailRequest)]);
}
