import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translate } from "react-redux-i18n";
import {
  faCog,
  faLock,
  faLockOpen,
  faPen,
  faPlus,
  faShare,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import ROUTES from "constants/routes";

import useMediaQuery from "utils/useMediaQuery";

import WishlistItem from "./WishlistItem";

const WishlistCard = ({
  wishlists,
  isPrivate,
  moveWishlist,
  handlePrivacyChange,
  fetchedWishlist,
  editWishlistLoading,
  handleWishlistChange,
  wishlistProducts,
  onRemoveItem,
  onBuyNowClicked,
}) => {
  const isSmallScreen = useMediaQuery("(min-width: 492px)");

  return (
    <>
      <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between py-3 gap-2">
        <div>
          <FontAwesomeIcon icon={faUsers} className="text-muted me-2" />
          <button
            className="btn btn-secondary btn-sm dropdown-toggle p-0"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <h2 className="h6 d-inline font-weight-bold mb-0">
              {fetchedWishlist.name}
            </h2>
          </button>
          {fetchedWishlist.is_default && (
            <span className="ms-2 badge border rounded-pill text-success border-success">
              <Translate value="user.myWishList.dropDown.default" />
            </span>
          )}
          <ul className="dropdown-menu border-0 shadow">
            {wishlists &&
              wishlists.map(({ id, name, token: hash }) => {
                return (
                  <li
                    key={id}
                    className={`${
                      fetchedWishlist.id === id ? "active" : ""
                    } dropdown-item`}
                    onClick={() => handleWishlistChange(hash)}
                  >
                    {name}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          {isSmallScreen && (
            <Link to={ROUTES.USER.WISHLIST.ADD} className="me-2">
              <FontAwesomeIcon icon={faPlus} />{" "}
              <Translate value="user.myWishList.dropDown.addNewList" />
            </Link>
          )}
          {fetchedWishlist.token && (
            <>
              <button
                className={`${
                  isSmallScreen ? "px-3" : "px-1"
                } btn py-2 btn-light `}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FontAwesomeIcon icon={faCog} />{" "}
                <Translate value="user.myWishList.dropDown.manageWishlist" />
              </button>
              <ul className="dropdown-menu border-0 shadow">
                {fetchedWishlist.token && (
                  <>
                    <li>
                      <Link
                        to={ROUTES.USER.WISHLIST.addwishlistId(
                          ROUTES.USER.WISHLIST.EDIT,
                          fetchedWishlist.token
                        )}
                        className="dropdown-item"
                      >
                        <FontAwesomeIcon icon={faPen} />{" "}
                        <Translate value="user.myWishList.dropDown.editThisList" />
                      </Link>
                    </li>

                    <li className="d-none">
                      <a className="dropdown-item" href="#">
                        <FontAwesomeIcon icon={faShare} />{" "}
                        <Translate value="user.myWishList.dropDown.shareWishlist" />
                      </a>
                    </li>
                    <li className="dropdown-item">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={() => {
                            handlePrivacyChange(!isPrivate);
                          }}
                          disabled={editWishlistLoading}
                          checked={isPrivate}
                          id="flexCheckChecked"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          <Translate value="user.myWishList.dropDown.makePrivate" />
                        </label>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </>
          )}
          {!isSmallScreen && (
            <Link to={ROUTES.USER.WISHLIST.ADD} className="ms-1">
              <FontAwesomeIcon icon={faPlus} />
            </Link>
          )}
        </div>
      </div>

      {wishlistProducts?.length !== 0 ? (
        wishlistProducts?.map(({ variant, quantity, display_total, id }) => {
          return (
            <WishlistItem
              moveWishlist={moveWishlist}
              currentWishlistHash={fetchedWishlist.token}
              key={id}
              productId={id}
              quantity={quantity}
              wishlists={wishlists}
              onRemoveItem={onRemoveItem}
              onBuyNowClicked={onBuyNowClicked}
              price={display_total}
              variant={variant}
              wishlistStatus="Available"
            />
          );
        })
      ) : (
        <p className="text-muted">
          <Translate value="user.myWishList.noProduct" />
        </p>
      )}
    </>
  );
};

export default WishlistCard;
