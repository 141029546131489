import React from "react";
import Skeleton from "react-loading-skeleton";

import CategoryCarouselSkeleton from "./CategoryCarouselSkeleton";
import useMediaQuery from "../../utils/useMediaQuery";

const ProductDetailSkeleton = () => {
  const isSmallScreen = useMediaQuery("(min-width: 540px)");

  return (
    <>
      <nav aria-label="breadcrumb" className="border-bottom bg-white">
        <ol className="breadcrumb mb-0 container px-3 py-2">
          <li className="breadcrumb-item">
            <a href="/">
              <Skeleton width="60px" />
            </a>
          </li>
          <li className="breadcrumb-item">
            <a href="/category?taxon_id=2">
              <Skeleton width="40px" />
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Skeleton width="150px" />
          </li>
        </ol>
      </nav>

      <section>
        <div className="container">
          <div className="row mt-3 mt-md-5">
            <div className="col-md-6 d-flex flex-column-reverse flex-md-row justify-content-end justify-content-md-between">
              <div className="col-md-3 mt-3 mt-md-0 row d-flex flex-row flex-md-column justify-content-between justify-content-md-start">
                <div className="col-4 col-md-12 mb-3">
                  <Skeleton height={isSmallScreen ? 100 : 150} />
                </div>
                <div className="col-4 col-md-12 mb-3">
                  <Skeleton height={100} />
                </div>
                <div className="col-4 col-md-12">
                  <Skeleton height={100} />
                </div>
              </div>

              <div className="col-12 col-md-9">
                <Skeleton height={isSmallScreen ? 500 : 300} />
                <div className="d-flex justify-content-center mt-3">
                  <Skeleton
                    circle={true}
                    height={15}
                    width={15}
                    className="me-2"
                  />
                  <Skeleton
                    circle={true}
                    height={15}
                    width={15}
                    className="me-2"
                  />
                  <Skeleton circle={true} height={15} width={15} />
                </div>
              </div>
            </div>
            <div className="col-md-5 offset-md-1 pe-md-0">
              <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
                <Skeleton width={250} height={46} className="mb-3" />
                <div className="mb-3">
                  <Skeleton
                    circle={true}
                    height={46}
                    width={46}
                    className="me-2"
                  />
                  <Skeleton
                    circle={true}
                    height={46}
                    width={46}
                    className="me-2"
                  />
                  <Skeleton circle={true} height={46} width={46} />
                </div>
              </div>

              <h4 className="price primary-text mt-2">
                <Skeleton height={28} width={100} />
              </h4>

              <div className="d-flex align-items-center pb-3 border-bottom mt-3">
                <Skeleton
                  circle={true}
                  height={18}
                  width={18}
                  className="me-2"
                />
                <Skeleton
                  circle={true}
                  height={18}
                  width={18}
                  className="me-2"
                />
                <Skeleton circle={true} height={18} width={18} />
              </div>

              <div className="border-bottom py-3">
                <Skeleton height={18} width={100} className="me-2" />
                <Skeleton height={18} width={80} />
              </div>

              <div className="border-bottom py-3">
                <Skeleton height={18} width={100} className="me-2" />
                <div className="mt-3">
                  <Skeleton
                    circle={true}
                    height={28}
                    width={28}
                    className="me-2"
                  />
                  <Skeleton
                    circle={true}
                    height={28}
                    width={28}
                    className="me-2"
                  />
                  <Skeleton circle={true} height={28} width={28} />
                </div>
              </div>

              <div className="border-bottom py-3">
                <Skeleton height={18} width={100} className="me-2" />
                <div className="mt-3">
                  <Skeleton height={40} width={60} className="me-2" />
                  <Skeleton height={40} width={60} className="me-2" />
                  <Skeleton height={40} width={60} />
                </div>
              </div>

              <div className="border-bottom py-3">
                <div className="row">
                  <span className="col-md-4 mb-2 pe-md-0">
                    <Skeleton height={40} width="100%" />
                  </span>
                  <span className="col-md-6 mb-2 pe-md-0">
                    <Skeleton height={40} width="100%" />
                  </span>{" "}
                  <span className="col-md-2">
                    <Skeleton height={40} width="100%" />
                  </span>
                </div>
              </div>

              <div className="py-3">
                <ul className="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a className="nav-link active">
                      <Skeleton width={60} />
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link">
                      <Skeleton height={15} width={60} />{" "}
                      <Skeleton circle={true} height={15} width={15} />
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <h5>
                      <Skeleton width={60} />
                    </h5>
                    <p className="product-description ps-0">
                      <Skeleton height={15} width="80%" />
                      <Skeleton height={15} width="90%" />
                      <Skeleton height={15} width="70%" />
                      <Skeleton height={15} width="50%" />
                    </p>
                    <h5>
                      {" "}
                      <Skeleton height={15} width={60} />
                    </h5>
                    <ul className="product-detail ps-0">
                      <li>
                        <Skeleton
                          circle={true}
                          height={15}
                          width={15}
                          className="me-2"
                        />
                        <Skeleton height={15} width="30%" />
                      </li>
                      <li>
                        <Skeleton
                          circle={true}
                          height={15}
                          width={15}
                          className="me-2"
                        />
                        <Skeleton height={15} width="40%" />
                      </li>
                      <li>
                        <Skeleton
                          circle={true}
                          height={15}
                          width={15}
                          className="me-2"
                        />
                        <Skeleton height={15} width="20%" />
                      </li>
                      <li>
                        <Skeleton
                          circle={true}
                          height={15}
                          width={15}
                          className="me-2"
                        />
                        <Skeleton height={15} width="50%" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <h3>
            <Skeleton width="250px" />
            <br />
            <Skeleton height={7} width={50} />
          </h3>
          <Skeleton height={200} width="100%" />
        </div>
      </section>

      <CategoryCarouselSkeleton className="bg-light" />
    </>
  );
};

export default ProductDetailSkeleton;
