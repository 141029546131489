import CHECKOUT_ACTIONS from './constants';

export const addAddressRequest = ({ formData, billId, shipId }) => ({
  type: CHECKOUT_ACTIONS.ADD_CHECKOUT_ADDRESS_REQUEST,
  formData,
  billId,
  shipId,
});

export const addStateAddressFailed = () => ({
  type: CHECKOUT_ACTIONS.ADD_STATE_ADDRESS_FAILED,
});

export const addAddressSuccess = () => {
  return {
    type: CHECKOUT_ACTIONS.ADD_CHECKOUT_ADDRESS_SUCCESS,
  };
};
export const addAddressFailed = (err) => {
  return {
    type: CHECKOUT_ACTIONS.ADD_CHECKOUT_ADDRESS_FAILED,
    err,
  };
};
export const updateAddressRequest = (formDataUpdates) => ({
  type: CHECKOUT_ACTIONS.UPDATE_CHECKOUT_ADDRESS_REQUEST,
  formDataUpdates,
});

export const updateAddressSuccess = ({ cartData }) => {
  return {
    type: CHECKOUT_ACTIONS.UPDATE_CHECKOUT_ADDRESS_SUCCESS,
    cartData,
  };
};
export const updateAddressFailed = (err) => {
  return {
    type: CHECKOUT_ACTIONS.UPDATE_CHECKOUT_ADDRESS_FAILED,
    err,
  };
};

// states
export const addStateAddressRequest = () => ({
  type: CHECKOUT_ACTIONS.ADD_STATE_ADDRESS_REQUEST,
});

export const addStateAddressSuccess = (statesData) => ({
  type: CHECKOUT_ACTIONS.ADD_STATE_ADDRESS_SUCCESS,
  statesData,
});

// delivery option
export const addShippingMethodRequest = () => ({
  type: CHECKOUT_ACTIONS.ADD_SHIPPING_METHOD_REQUEST,
});

export const sendShippingMethodRequest = ({
  deliveryOptionId,
  selectedDeliveryOption,
  special_instructions,
}) => ({
  type: CHECKOUT_ACTIONS.SEND_SHIPPING_METHOD_REQUEST,
  deliveryOptionId,
  selectedDeliveryOption,
  special_instructions,
});

export const adddShippingMethodSuccess = (deliveryOption) => ({
  type: CHECKOUT_ACTIONS.ADD_SHIPPING_METHOD_SUCCESS,
  deliveryOption,
});

// payment method
export const addPaymentMethodRequest = () => ({
  type: CHECKOUT_ACTIONS.ADD_PAYMENT_METHOD_REQUEST,
});

export const addPaymentMethodSuccess = (paymentMethod) => ({
  type: CHECKOUT_ACTIONS.ADD_PAYMENT_METHOD_SUCCESS,
  paymentMethod,
});

export const sendPaymentMethodRequest = (selectedPaymentMethod, paymentData) => ({
  type: CHECKOUT_ACTIONS.SEND_PAYMENT_METHOD_REQUEST,
  selectedPaymentMethod,
  paymentData
});

export const sendPaymentMethodSuccess = (orderNumber) => ({
  type: CHECKOUT_ACTIONS.SEND_PAYMENT_METHOD_SUCCESS,
  orderNumber,
});

// Add email on guest checkout
export const addEmailforCheckOut = (email) => ({
  type: CHECKOUT_ACTIONS.CHECKOUT_ADD_EMAIL,
  email,
});

// modal

export const openCheckoutAddressModal = (modalID) => {
  return {
    type: CHECKOUT_ACTIONS.CHECKOUT_ADDRESS_MODAL_OPEN,
    modalID,
  };
};

export const closeCheckoutAddressModal = () => {
  return {
    type: CHECKOUT_ACTIONS.CHECKOUT_ADDRESS_MODAL_CLOSE,
  };
};

// set special instruction

export const setSpecialInstruction = (setspecial_instructions) => {
  return {
    type: CHECKOUT_ACTIONS.SET_SPECIAL_INSTRUCTIONS,
    setspecial_instructions,
  };
};

export const resetCheckoutStep = () => ({
  type: CHECKOUT_ACTIONS.RESET_TO_INITIAL_CHECKOUT_STEP,
});

// order details

export const fetchOrderDetailRequest = (orderNumber) => {
  return {
    type: CHECKOUT_ACTIONS.FETCH_ORDER_DETAIL_REQUEST,
    orderNumber,
  };
};

export const fetchOrderDetailSuccess = (orderData) => ({
  type: CHECKOUT_ACTIONS.FETCH_ORDER_DETAIL_SUCCESS,
  orderData,
});

export const fetchOrderDetailFailed = (error) => ({
  type: CHECKOUT_ACTIONS.FETCH_ORDER_DETAIL_FAILED,
  error,
});
