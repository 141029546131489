import React from "react";
import { Translate } from "react-redux-i18n";

import AddressForm from "components/User/AddressForm";

const AddressCreate = () => {
  return (
    <AddressForm title={<Translate value="user.addressBook.addNewAddress" />} />
  );
};

export default AddressCreate;
