import React from 'react';
import { Link } from 'react-router-dom';

import logo from 'assets/images/logo.svg'

function Brand({ className }) {
  return (
    <div className={`brand w-auto ${className}`}>
      <Link to='/'>
        <img src={logo} alt='' />
      </Link>
    </div>
  );
}

export default Brand;
