import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const CollapseItem = ({
  children,
  title,
  itemID,
  activeStatus,
  wrapperClass,
  headingSize,
}) => {
  const activeClassName = activeStatus ? "show" : "";

  return (
    <div className={`card ${wrapperClass}`}>
      <div className="card-header py-3 bg-white" id={`${itemID}Label`}>
        <div
          className={`${!activeStatus && "collapsed"} cart-header text-start`}
          data-bs-toggle="collapse"
          data-bs-target={`#${itemID}Collapse`}
          aria-expanded="true"
          aria-controls={`${itemID}Collapse`}
        >
          <div>
            <h3 className={`font-weight-semibold mb-0 ${headingSize}`}>
              {title}
            </h3>
          </div>
          <span className="h2 toggle-icon" />
        </div>
      </div>

      <div
        id={`${itemID}Collapse`}
        className={`collapse ${activeClassName}`}
        aria-labelledby={`${itemID}Label`}
        data-parent="#accordionExample"
      >
        <div className="card-body">{children}</div>
      </div>
    </div>
  );
};

export default CollapseItem;
