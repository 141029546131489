import client from 'services/spreeClient'

class SliderApi {
  static fetchLocation(slideLocationId) {
    return client.slideLocations.list(slideLocationId, {
      include: 'slides',
    })
  }
}

export default SliderApi
