import React from "react";

function MobileMenuIcon({ className, onClick }) {
  return (
    <button
      className={`btn bd-search-docs-toggle d-lg-none p-0 ${className}`}
      type="button"
      data-bs-toggle="collapse"
      data-target="#bd-docs-nav"
      aria-controls="bd-docs-nav"
      aria-expanded="false"
      aria-label="Toggle docs navigation"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        role="img"
      >
        <title>Menu</title>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M4 7h22M4 15h22M4 23h22"
        />
      </svg>
    </button>
  );
}

export default MobileMenuIcon;
