import React from "react";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PRODUCTS_SORT from "constants/productsSort";

function SortBy({ className, handleSort, sortBy }) {
  return (
    <div className={`dropdown justify-content-end ${className}`}>
      <a
        className="dropdown-toggle text-secondary py-1 mb-3 mt-3"
        href="#"
        role="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <FontAwesomeIcon icon={faSort} className="primary-color me-2" />
        Sort By
      </a>

      <ul
        className="dropdown-menu position-absolute end-0 border-0 shadow"
        aria-labelledby="dropdownMenuLink"
      >
        <li>
          <a
            className={`dropdown-item  ${
              sortBy === PRODUCTS_SORT.DEFAULT ? "active" : ""
            }`}
            onClick={() => handleSort(PRODUCTS_SORT.DEFAULT)}
          >
            Default
          </a>
        </li>
        <li>
          <a
            className={`dropdown-item  ${
              sortBy === PRODUCTS_SORT.NEWEST_FIRST ? "active" : ""
            }`}
            onClick={() => handleSort(PRODUCTS_SORT.NEWEST_FIRST)}
          >
            Newest First
          </a>
        </li>
        <li>
          <a
            className={`dropdown-item  ${
              sortBy === PRODUCTS_SORT.PRICE_HIGH_TO_LOW ? "active" : ""
            }`}
            onClick={() => handleSort(PRODUCTS_SORT.PRICE_HIGH_TO_LOW)}
          >
            Price (High - Low)
          </a>
        </li>
        <li>
          <a
            className={`dropdown-item  ${
              sortBy === PRODUCTS_SORT.PRICE_LOW_TO_HIGH ? "active" : ""
            }`}
            onClick={() => handleSort(PRODUCTS_SORT.PRICE_LOW_TO_HIGH)}
          >
            Price (Low - High)
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SortBy;
