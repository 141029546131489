import { all } from "redux-saga/effects";

// import authSagas from 'appRedux/auth/saga';
import cartSagas from "appRedux/cart/saga";
import productsSagas from "appRedux/productDetail/saga";
import productQuestionSagas from "appRedux/productQuestion/saga";
import productReviewSagas from "appRedux/productReview/saga";
import checkOutSagas from "appRedux/checkOut/saga";
import authSagas from "appRedux/auth/saga";
import taxonSagas from "appRedux/taxon/saga";
import wishlistSagas from "appRedux/wishlist/saga";
import searchSagas from "appRedux/products/saga";
import userSagas from "appRedux/user/saga";
import sliderSagas from "appRedux/slider/saga";
import vendorSagas from "appRedux/vendor/saga";
import storeConfigSagas from "appRedux/store/saga";

export default function* rootSaga(getState) {
  yield all([
    cartSagas(),
    productsSagas(),
    productQuestionSagas(),
    productReviewSagas(),
    checkOutSagas(),
    authSagas(),
    wishlistSagas(),
    taxonSagas(),
    sliderSagas(),
    vendorSagas(),
    searchSagas(),
    userSagas(),
    storeConfigSagas(),
  ]);
}
