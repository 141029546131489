import React from "react";
import { Translate } from "react-redux-i18n";
import { Field } from "redux-form-normalize-on-blur";

import { isEnvironmentVariableEnabled } from "utils/isEnvironmentVariableEnabled";

const QuestionAnonymousCheckbox = () => {
  if (
    !isEnvironmentVariableEnabled(
      process.env.REACT_APP_ENABLE_PRODUCT_QA_ANONYMOUS
    )
  )
    return null;

  return (
    <div className=" mb-3 form-check text-start">
      <Field
        name="isAnonymous"
        type="checkbox"
        component="input"
        className="form-check-input"
        id="anonymous"
      />

      <label className="form-check-label" htmlFor="anonymous">
        <Translate value="form.fields.dontShowName" />
      </label>
    </div>
  );
};

export default QuestionAnonymousCheckbox;
