import React from "react";
import PropTypes from "prop-types";

import QuantityPicker from "components/QuantityPicker";

const CartQuantitySelector = ({ onQuantityChange }) => {
  return (
    <>
      <QuantityPicker onChange={onQuantityChange} min={1} max={4} />
    </>
  );
};

CartQuantitySelector.defaultProps = {
  onQuantityChange: () => {},
};

CartQuantitySelector.propTypes = {
  onQuantityChange: PropTypes.func,
};

export default CartQuantitySelector;
