import React from 'react'

import GuestForm from 'components/GuestForm/GuestForm'
import Footer from 'components/Footer/Footer'

import Login from '../Login'

const CheckoutLogin = () => {
  return (
    <>
      <div className="mb-5">
        <Login fromCheckout />

        <GuestForm />
      </div>
      <Footer />
    </>
  )
}

export default CheckoutLogin
