import React from 'react'
import PropTypes from 'prop-types'
import CheckboxFilterItem from '../CheckboxFilterItem'

const FilterOptions = ({
  options,
  filterName,
  handleProductFilter,
  selectedFilterOptions,
}) => {
  const onChange = (value) => {
    const currentValues = selectedFilterOptions || []

    if (currentValues.indexOf(value) > -1) {
      currentValues.splice(currentValues.indexOf(value), 1)
    } else {
      currentValues.push(value)
    }

    handleProductFilter(filterName, currentValues)
  }

  const filterOptions = options.map(({ id, name, presentation }) => {
    return (
      <CheckboxFilterItem
        key={id}
        filterName={filterName}
        checkboxID={name}
        label={presentation}
        onChange={onChange}
      />
    )
  })

  return filterOptions
}

FilterOptions.propTypes = {}

export default FilterOptions
