import React from "react";
import Skeleton from "react-loading-skeleton";

const ProductSkeleton = ({ className }) => (
  <div className={`card product-card border-0 ${className}`}>
    <a href="#">
      <div className="loader product">
        <Skeleton width="100%" height="100%" />
      </div>
      <div className="py-3">
        <small className="text-muted">
          <Skeleton width="20%" />
        </small>
        <h3 className="h6 title">
          <Skeleton />
          <Skeleton width="70%" />
        </h3>
        <h6 className="price mt-2">
          <Skeleton width="50%" />
        </h6>
      </div>
    </a>
  </div>
);

export default ProductSkeleton;
