import React from "react";

import ROUTES from "constants/routes";

const SecondaryNav = () => (
  <>
    <nav className="navbar navbar-expand-lg navbar-light bg-white py-1 d-none d-md-flex">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {/* <li className="nav-item">
              <a
                className="nav-link active"
                aria-current="page"
                // href={ROUTES.CATEGORY.INDEX}
                href="/category?taxon_id=20&page=1&sortBy=default"
              >
                Kitchenware
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                aria-current="page"
                href="/category?taxon_id=20&page=1&sortBy=default"
              >
                Fitness Gear
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                aria-current="page"
                href="/category?taxon_id=20&page=1&sortBy=default"
              >
                Machinery
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  </>
);

export default SecondaryNav;
