import { Http } from "@spree/storefront-api-v2-sdk";

import { Routes } from "lib/spreeSdk/routes";

export default class Vendor extends Http {
  async productList(slug, params) {
    return this.spreeResponse(
      "get",
      Routes.vendorProductListPath(slug),
      {}, // does not need token so pass empty object
      params
    );
  }
}
