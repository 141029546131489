import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { GoogleLogin } from "react-google-login";

import { Translate } from "react-redux-i18n";

const responseGoogle = (data) => console.log({ googleData: data });

export default ({ loginWithGoogle }) => {
  const onSuccess = (data) => {
    const { googleId, profileObj, tokenObj } = data;

    const payloadData = {
      provider: "google_oauth2",
      uid: googleId,
      info: {
        name: profileObj.name,
        email: profileObj.email,
        unverified_email: profileObj.email,
        email_verified: true,
        first_name: profileObj.familyName,
        last_name: profileObj.givenName,
        image: profileObj.imageUrl,
      },
      credentials: {
        token: tokenObj.access_token,
        expires_at: tokenObj.expires_at,
        expires: true,
      },
      extra: {
        id_token: tokenObj.id_token,
      },
    };

    loginWithGoogle(payloadData);
  };

  const onFailure = (err) => {
    console.log("Error", err);
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      onSuccess={onSuccess}
      onFailure={onFailure}
      style={{ opacity: 1 }}
      render={({ onClick, disabled }) => {
        return (
          <button
            disabled={disabled}
            onClick={onClick}
            type="button"
            className="btn google-btn rounded w-100 w-md-auto p-3 mt-3"
          >
            <span className="d-flex align-items-center justify-content-center">
              <FontAwesomeIcon className="me-1" icon={faGoogle} />
              <span>
                <Translate value="socials.google" />
              </span>
            </span>
          </button>
        );
      }}
    />
  );
};
