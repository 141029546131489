import client from 'services/spreeClient';
import { getUserToken } from 'services/getToken';

class CartApi {
  static createCart() {
    return client.cart.create({
      ...getUserToken(),
    });
  }

  static addToCart({ variantId, quantity }) {
    return client.cart.addItem(
      {
        ...getUserToken(),
      },
      {
        variant_id: variantId,
        quantity,
      }
    );
  }

  static fetchCartDetails(options) {
    return client.cart.show(
      {
        ...getUserToken(),
      },
      {
        ...options,
      }
    );
  }

  static setitemQuantity({ id, value }) {
    return client.cart.setQuantity(
      {
        ...getUserToken(),
      },
      {
        line_item_id: id,
        quantity: value,
      }
    );
  }

  static removeFromCart(id) {
    return client.cart.removeItem(
      {
        ...getUserToken(),
      },
      id
    );
  }

  static emptyCart() {
    return client.cart.emptyCart({
      ...getUserToken(),
    });
  }

  static promoCodeRequest(promoCode) {
    return client.cart.applyCouponCode(
      {
        ...getUserToken(),
      },
      {
        coupon_code: promoCode,
      }
    );
  }

  static removePromoCodeRequest(promoCode) {
    return client.cart.removeCouponCode(
      {
        ...getUserToken(),
      },
      promoCode
    );
  }
}

export default CartApi;
