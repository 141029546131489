import React from "react";
import Skeleton from "react-loading-skeleton";
import ProductSkeleton from "./ProductSkeleton";

const CategoryCarouselSkeleton = ({ className }) => (
  <section className={`py-5 ${className}`}>
    <div className="container">
      <div className="row">
        <div className="col-md-12 d-flex justify-content-between align-items-start mb-3">
          <div>
            <Skeleton height={40} width={150} className="mb-2" /> <br />
            <Skeleton height={5} width={100} />
          </div>
          <div>
            <Skeleton width={100} height={30} />
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-6 col-md-3 d-none d-md-block">
              <Skeleton height={30} className="w-75" />
              <Skeleton height={30} className="w-75" />
            </div>
            <ProductSkeleton className="col-6 col-md-3" />
            <ProductSkeleton className="d-none d-md-block col-md-3" />
            <ProductSkeleton className="d-none d-md-block col-md-3" />
            <div className="col-6 col-md-3 d-none d-md-block" />
            <ProductSkeleton className="col-6 col-md-3" />
            <ProductSkeleton className="col-6 col-md-3" />
            <ProductSkeleton className="col-6 col-md-3" />
          </div>
          <div className="d-none d-md-block text-end">
            <Skeleton width={300} height={30} />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default CategoryCarouselSkeleton;
