import client from "services/spreeClient";
import getToken from "services/getToken";

class WishlistApi {
  //wishlists
  static createWishlist({ name, isPrivate, isDefault }) {
    const wishlistParams = {
      wishlist: {
        name,
        is_default: isDefault,
        is_private: isPrivate,
      },
    };

    return client.wishlists.create({ ...getToken() }, wishlistParams);
  }

  static fetchDefaultWishlist() {
    return client.wishlists.default(
      { ...getToken() },
      {
        include: "wished_items.variant.product,wished_items.variant.images",
      }
    );
  }

  static fetchWishlistDetails(wishlistId) {
    return client.wishlists.show({ ...getToken() }, wishlistId, {
      include: "wished_items.variant.product,wished_items.variant.images",
    });
  }

  static editWishlist({ name, isPrivate, isDefault, wishlistId }) {
    const wishlistParams = {
      wishlist: {
        name,
        is_default: isDefault,
        is_private: isPrivate,
      },
    };

    return client.wishlists.update(
      { ...getToken() },
      wishlistId,
      wishlistParams
    );
  }

  static removeWishlist(wishlistId) {
    return client.wishlists.remove({ ...getToken() }, wishlistId);
  }

  static fetchAllWishlists({ variantId }) {
    const params = { ...getToken() };

    if (variantId) {
      params["is_variant_included"] = variantId;
    }

    return client.wishlists.list(params);
  }

  //wishlists-products
  static addToWishlist({ variantId, wishlistId, remark, quantity }) {
    const addToWishlistParams = {
      variant_id: variantId,
      remark,
      quantity,
    };

    return client.wishlists.addWishedItem(
      { ...getToken() },
      wishlistId,
      addToWishlistParams
    );
  }

  static editWishlistProduct({
    variantId,
    wishlistId,
    productId,
    remark,
    quantity,
  }) {
    const editToWishlistParams = {
      variant_id: variantId,
      remark,
      quantity,
    };

    return client.wishlists.updateWishedItem(
      { ...getToken() },
      wishlistId,
      productId,
      editToWishlistParams
    );
  }

  static removeWishlistProduct({ productId, wishlistId }) {
    return client.wishlists.removeWishedItem(
      { ...getToken() },
      wishlistId,
      productId
    );
  }
}

export default WishlistApi;
