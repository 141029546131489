import React, { useEffect } from "react";
import { useLocation, useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";

import OrderCard from "components/User/OrderCard";
import DashboardOrdersSkeleton from "components/Skeleton/DashboardOrdersSkeleton";

import { fetchOrderCurrentPage, orderListRequest } from "appRedux/user/actions";

import { getParams } from "utils/queryString";

const MyOrder = ({ history }) => {
  const { search, pathname } = useLocation();
  const { page } = getParams(search);
  const { replace } = useHistory();

  const dispatch = useDispatch();
  const {
    orderData: { orders, currentPage, pageMetaData, isLoading },
  } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(orderListRequest(currentPage));
  }, [currentPage]);

  const pageNumberHandler = (fetchPage) => {
    dispatch(fetchOrderCurrentPage(fetchPage));

    const search = `${fetchPage ? `page=${fetchPage}` : ""}`;
    const newPath = `${pathname}?${search}`;

    replace(newPath);
  };

  useEffect(() => {
    const nextPage = page || 1;

    pageNumberHandler(nextPage);
  }, [page]);

  if (isLoading) {
    return <DashboardOrdersSkeleton repeat={5} />;
  }

  return (
    <div className="col-md-9">
      <OrderCard
        pageMetaData={pageMetaData}
        page={page}
        pageNumberHandler={pageNumberHandler}
        orders={orders}
        title={I18n.t("user.myOrders.title")}
      />
    </div>
  );
};

export default withRouter(MyOrder);
