import React from "react";
import { Translate } from "react-redux-i18n";
import { Field } from "redux-form-normalize-on-blur";

import { isEnvironmentVariableEnabled } from "utils/isEnvironmentVariableEnabled";

const ReviewAnonymousCheckbox = () => {
  if (
    !isEnvironmentVariableEnabled(process.env.REACT_APP_ENABLE_REVIEW_ANONYMOUS)
  )
    return null;

  return (
    <div className="mb-3 form-check">
      <Field
        name="dontShowIdentifier"
        type="checkbox"
        component="input"
        className="form-check-input"
        id="exampleCheck1"
      />

      <label className="form-check-label" htmlFor="exampleCheck1">
        <Translate value="form.fields.dontShowName" />
      </label>
    </div>
  );
};

export default ReviewAnonymousCheckbox;
