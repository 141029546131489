import React, { useEffect } from "react";
import { I18n } from "react-redux-i18n";

import ProductList from "components/User/ProductList";
import OrderStatus from "components/User/OrderStatus";

import extractImage from "utils/extractImage";

import { STATUS, BUTTON_TEXT } from "constants/orderStatus";
import { convertCDTToLocalTime } from "utils/DateFormatter";
import { isEnvironmentVariableEnabled } from "utils/isEnvironmentVariableEnabled";

const OrderButton = ({ status }) => {
  const orderButtonText = () => {
    switch (status) {
      case STATUS.CONFIRMED:
      case STATUS.PLACED:
        return BUTTON_TEXT.CANCEL_ORDER;
      case STATUS.DELIVERED:
        return BUTTON_TEXT.RETURN_ITEM;
      case STATUS.PENDING:
        return BUTTON_TEXT.PAY_NOW;
      default:
        break;
    }
  };

  return (
    <button className="btn mt-1 py-2 btn-outline-primary font-weight-semibold w-100">
      {orderButtonText()}
    </button>
  );
};

const OrderCardItem = ({
  className,
  orderNumber,
  orderStatus,
  totalItems,
  totalPriceOverAll,
  state,
  placedOn,
  items,
}) => {
  return (
    <div className={`${className}`}>
      <div className="order-basic-info d-flex justify-content-between align-items-center border-bottom p-3">
        <div>
          <p className="mb-0 text-muted">
            {I18n.t("user.myOrders.order")}:{" "}
            <span className="text-dark font-weight-semibold">
              #{orderNumber}
            </span>
          </p>
          <p className="mb-0 text-muted">
            {I18n.t("user.myOrders.placedOn")}:{" "}
            <span className="text-dark">{convertCDTToLocalTime(placedOn)}</span>
          </p>
          <p className="mb-0 text-muted">
            {I18n.t("user.myOrders.status")}:{" "}
            <OrderStatus status={orderStatus} />
          </p>
        </div>
        <h3 className="h6 font-weight-bold text-dark mb-0">
          {totalPriceOverAll}
        </h3>
      </div>
      <div className="px-3 pb-3">
        <div>
          {items &&
            items.map((item) => {
              const { variant, quantity, name, display_total, id } = item;

              const imageUrl = variant.images.length
                ? variant.images
                : isEnvironmentVariableEnabled(
                    process.env.REACT_APP_ENABLE_FALLBACK_TO_PRODUCT_IMAGE
                  )
                ? variant.product.images
                : variant.images;

              const image = extractImage(imageUrl);

              return (
                <ProductList
                  key={id}
                  image={image}
                  title={name}
                  qty={quantity}
                  price={display_total}
                  status={orderStatus}
                />
              );
            })}
        </div>
        <OrderButton status={orderStatus} />
      </div>
    </div>
  );
};

export default OrderCardItem;
