import React from 'react';
import PropTypes from 'prop-types'

import KhaltiPayment from 'components/Payment/Khalti/KhaltiPayment';
import PaymentWrapper from 'components/Payment/Wrapper/PaymentWrapper';

const KHALTI_PAYMENT_PREFERENCES = [
  'KHALTI',
  'EBANKING',
  'MOBILE_BANKING',
  'CONNECT_IPS',
  'SCT',
];

const PaymentButton = ({
  method,
  onPay,
  productDetails,
  onClose,
  onKhaltiPaymentSuccess,
  onKhaltiPaymentError,
}) => {
  const isKhaltiPayment = method.type === 'Spree::Gateway::Khalti';

  if (isKhaltiPayment) {
    const handleKhaltiPayment = (response) => {
      onKhaltiPaymentSuccess(method.name, response);
    };

    return (
      <PaymentWrapper method={method}>
        <KhaltiPayment
          onSuccess={handleKhaltiPayment}
          publicKey={process.env.REACT_APP_KHALTI_PUBLIC_KEY}
          onError={onKhaltiPaymentError}
          onClose={onClose}
          productDetails={productDetails}
          paymentPreference={KHALTI_PAYMENT_PREFERENCES}
          buttonLabel="Pay with Khalti"
          buttonClassName="btn btn-primary w-100 w-md-auto"
        />
      </PaymentWrapper>
    );
  }

  return (
    <PaymentWrapper method={method}>
      <button
        className="btn btn-primary w-100 w-md-auto"
        onClick={(e) => {
          e.stopPropagation();
          onPay(method.name, method.type);
        }}
      >
        {`Pay with ${method.name}`}
      </button>
    </PaymentWrapper>
  );
}

PaymentButton.defaultProps = {
    onClose: () => {},
    onKhaltiPaymentSuccess: () => {},
  onKhaltiPaymentError: () => {},
}

PaymentButton.propTypes = {
  method: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
  }).isRequired,
  onPay: PropTypes.func.isRequired,
  productDetails: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      amount: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func,
  onKhaltiPaymentSuccess: PropTypes.func,
  onKhaltiPaymentError: PropTypes.func,
};

export default PaymentButton;
