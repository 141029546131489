import React, { useState } from "react";

import {
  faArrowRight,
  faEllipsisV,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translate } from "react-redux-i18n";

import Modal from "components/Modal";

const WishlistAddToCart = ({
  wishlistStatus,
  onBuyNowClicked,
  variantId,
  productId,
  qty,
}) => {
  if (wishlistStatus === "Available") {
    return (
      <button
        onClick={() => onBuyNowClicked({ variantId, productId, quantity: qty })}
        className="btn px-3 py-2 btn-primary font-weight-semibold"
      >
        <Translate value="productDetail.buttons.addToCart" />
      </button>
    );
  }

  return null;
};

const Wishlist = ({
  image,
  currentWishlistHash,
  variantId,
  productId,
  onBuyNowClicked,
  moveWishlist,
  wishlists,
  onRemoveItem,
  title,
  qty,
  price,
  stockStatus,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const wishlistsToMove = wishlists.filter((wishlist) => {
    return wishlist.token !== currentWishlistHash;
  });

  return (
    <div className="order-card-item py-3">
      <div className="d-flex flex-row align-items-start justify-content-between">
        <img src={image} alt={title} className="product-image me-3" />
        <div className="product-detail">
          <p className="mb-0 font-weight-semibold">{title}</p>
          <p className="mb-0 text-muted">
            <Translate value="user.myWishList.product.quantity" />:{" "}
            <span>{qty}</span>
          </p>
          <p className="mb-0 text-muted">
            <Translate value="user.myWishList.product.price" />: {price}
          </p>
        </div>
      </div>
      {openModal && (
        <Modal
          className="wishlist-modal p-3"
          closeHandler={() => setOpenModal(false)}
          modalTitle="Select the wishlist"
        >
          {wishlistsToMove.map(({ id, name, token }) => {
            return (
              <li
                key={id}
                onClick={() => {
                  setOpenModal(false);
                  moveWishlist({
                    from: currentWishlistHash,
                    to: token,
                    product: { variantId, productId, quantity: qty },
                  });
                }}
              >
                {name}
              </li>
            );
          })}
        </Modal>
      )}

      <div className="mt-4 mt-md-0">
        <WishlistAddToCart
          variantId={variantId}
          qty={qty}
          productId={productId}
          onBuyNowClicked={onBuyNowClicked}
          wishlistStatus={stockStatus}
        />
        <button
          className="btn px-3 py-2 btn-light ms-2"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </button>
        <ul className="dropdown-menu border-0 shadow">
          <li>
            <button
              className="dropdown-item"
              onClick={() => setOpenModal(true)}
            >
              <FontAwesomeIcon icon={faArrowRight} />{" "}
              <Translate value="user.myWishList.dropDown.moveItem" />
            </button>
          </li>
          <li>
            <button
              onClick={() => onRemoveItem(productId)}
              className="dropdown-item"
            >
              <FontAwesomeIcon icon={faTrashAlt} />{" "}
              <Translate value="user.myWishList.dropDown.removeItem" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Wishlist;
