import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import KhaltiCheckout from 'khalti-checkout-web';

const voidFunction = () => {};

const KhaltiPayment = React.memo(
  ({
    publicKey,
    onSuccess,
    onError,
    onClose,
    productDetails,
    paymentPreference,
    buttonLabel,
    buttonClassName,
    buttonStyle,
    renderComponent,
  }) => {
    const { id, name, amount, productUrl } = productDetails;
    const [checkoutInstance, setCheckoutInstance] = useState(null);
    const [initiatingPayment, setInitiating] = useState(false);

    const handleSuccess = useCallback(
      (response) => {
        setInitiating(false);
        onSuccess(response);
      },
      [onSuccess]
    );

    const handleError = useCallback(
      (response) => {
        setInitiating(false);
        onError(response);
      },
      [onError]
    );

    const handleClose = useCallback(
      (response) => {
        setInitiating(false);
        onClose(response);
      },
      [onClose]
    );

    const config = useMemo(
      () => ({
        publicKey,
        productIdentity: id,
        productName: name,
        productUrl,
        eventHandler: {
          onSuccess: handleSuccess,
          onError: handleError,
          onClose: handleClose,
        },
        paymentPreference,
      }),
      [id, name, productUrl]
    );

    useEffect(() => {
      const checkout = new KhaltiCheckout(config);

      setCheckoutInstance(checkout);
    }, []);

    const handlePayment = (e) => {
      e.stopPropagation();

      if (checkoutInstance) {
        setInitiating(true);
        checkoutInstance.show({ amount: amount });
      }
    };

    if (renderComponent) {
      return renderComponent({
        disabled: initiatingPayment,
        onClick: handlePayment,
      });
    }

    return (
      <button
        disabled={initiatingPayment}
        className={buttonClassName}
        style={{ ...buttonStyle }}
        onClick={handlePayment}
      >
        {buttonLabel || 'Pay with Khalti'}
      </button>
    );
  }
);

KhaltiPayment.defaultProps = {
  onSuccess: voidFunction,
  onError: voidFunction,
  onClose: voidFunction,
  paymentPreference: undefined,
  buttonLabel: null,
  buttonClassName: '',
  buttonStyle: {},
  renderComponent: null,
};

KhaltiPayment.propTypes = {
  publicKey: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onClose: PropTypes.func,
  productDetails: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    amount: PropTypes.number,
  }).isRequired,
  paymentPreference: PropTypes.arrayOf(PropTypes.string),
  buttonLabel: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonStyle: PropTypes.shape({}),
  renderComponent: PropTypes.node,
};

export default KhaltiPayment;
