import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translate } from "react-redux-i18n";
import {
  faMapMarker,
  faPhone,
  faBook,
  faInbox,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import CollapseItem from "components/CollapseItem";
import ShipmentInformationItem from "components/Shipment/ShipmentInformationItem";
import ListItem from "components/ListItem";

import { ADDRESS_MODAL_TITLES } from "constants/modalTitles";

const ShippingAndBilling = ({ isformSumitted, isDeliveryChoosen }) => {
  const formValues = useSelector((state) => state.checkout.checkoutAddress);

  const shippinginfo = {
    ...formValues.ship_address_attributes,
  };
  const billinginfo = {
    ...formValues.bill_address_attributes,
  };

  const shipAddress = {
    address1: shippinginfo.address1,
    address2: shippinginfo.address2,
    city: shippinginfo.city,
    region: shippinginfo.region,
    district: shippinginfo.district,
    state_name: shippinginfo.state_name,
    zipcode: shippinginfo.zipcode,
  };

  const billAddress = {
    address1: billinginfo.address1,
    address2: billinginfo.address2,
    city: billinginfo.city,
    region: billinginfo.region,
    district: billinginfo.district,
    state_name: billinginfo.state_name,
    zipcode: billinginfo.zipcode,
  };

  return (
    <CollapseItem
      title={<Translate value="orderSummary.shippingAndBilling.title" />}
      className="shipping-address"
      wrapperclassName="shadow-sm border-0 border-bottom"
      itemID="shipping"
      activeStatus={isformSumitted && !isDeliveryChoosen}
      headingSize="h5"
    >
      <ListItem className="shipping-address">
        <ShipmentInformationItem
          modalID="shippingAddress"
          modalTitle={ADDRESS_MODAL_TITLES.SHIPPING_ADDRESS}
          detail={`${shipAddress.address1}
                        ,${
                          shipAddress.address2 && ` ${shipAddress.address2}, `
                        } ${shipAddress.city}
                        , ${shipAddress.region}
                        , ${shipAddress.district}
                        , ${shipAddress.state_name}
                        , ${shipAddress.zipcode}`}
        >
          <FontAwesomeIcon
            icon={faMapMarker}
            className="text-primary mt-1 me-3"
          />
        </ShipmentInformationItem>
        <ShipmentInformationItem
          modalTitle={ADDRESS_MODAL_TITLES.PHONE}
          modalID="phone"
          detail={shippinginfo.phone}
        >
          <FontAwesomeIcon icon={faPhone} className="text-primary mt-1 me-3" />
        </ShipmentInformationItem>
        <ShipmentInformationItem
          modalTitle={ADDRESS_MODAL_TITLES.EMAIL}
          modalID="email"
          detail={formValues.email}
        >
          <FontAwesomeIcon icon={faInbox} className="text-primary mt-1 me-3" />
        </ShipmentInformationItem>
        <ShipmentInformationItem
          modalID="billingAddress"
          modalTitle={ADDRESS_MODAL_TITLES.BILLING_ADDRESS}
          detail={
            JSON.stringify(billAddress) !== JSON.stringify(shipAddress) ? (
              `${billAddress.address1} ,${
                billAddress.address2 && ` ${billAddress.address2}, `
              } ${billAddress.city}
                    , ${billAddress.region}, ${billAddress.district}, ${
                billAddress.state_name
              },
                    ${billAddress.zipcode}`
            ) : (
              <Translate value="orderSummary.shippingAndBilling.billToSameAddress" />
            )
          }
        >
          <FontAwesomeIcon icon={faBook} className="text-primary mt-1 me-3" />
        </ShipmentInformationItem>
      </ListItem>
    </CollapseItem>
  );
};

export default ShippingAndBilling;
