import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useParams } from "react-router-dom";
import swal from "sweetalert";

import WishlistCard from "components/User/WishlistCard";

import DashboardOrdersSkeleton from "components/Skeleton/DashboardOrdersSkeleton";

import {
  fetchWishlistRequest,
  fetchDefaultWishlistRequest,
  fetchWishlistDetailsRequest,
  editWishlistRequest,
  removeWishlistProductRequest,
} from "appRedux/wishlist/actions";
import { addToCart } from "appRedux/cart/actions";

import ROUTES from "constants/routes";

const Wishlist = ({ history }) => {
  const {
    wishlist: {
      wishlistData: { wishlists, isLoading: isWishlistLoading, metaData },
      wishlistDetails: { detail, isLoading: isWishlistProductLoading },
      editWishList: { isLoading: editWishlistLoading, error },
    },
    product: {
      productData: { wishlistProducts },
    },
  } = useSelector((state) => state.wishlist);

  const [isPrivate, setIsPrivate] = useState(null);

  const dispatch = useDispatch();

  const { accessHash } = useParams();

  useEffect(() => {
    dispatch(fetchWishlistRequest());
  }, []);

  useEffect(() => {
    if (!accessHash || accessHash == "undefined") {
      dispatch(fetchDefaultWishlistRequest());
    } else {
      dispatch(fetchWishlistDetailsRequest(accessHash));
    }
  }, [accessHash]);

  const handleWishlistChange = (hash) => {
    history.push(
      ROUTES.USER.WISHLIST.addwishlistId(ROUTES.USER.WISHLIST.SHOW, hash)
    );
  };

  useEffect(() => {
    if (detail.type) {
      setIsPrivate(detail.is_private);
    }
  }, [detail]);

  const handlePrivacyChange = (value) => {
    dispatch(
      editWishlistRequest(
        { name: detail.name, isPrivate: value, isDefault: detail.is_default },
        detail.token
      )
    );
  };

  const moveWishlist = ({ from, to, product }) => {
    dispatch(
      removeWishlistProductRequest({
        productId: product.productId,
        wishlistId: from,
        moveData: {
          variantId: product.variantId,
          wishlistId: to,
          remark: "",
          quantity: product.quantity,
        },
      })
    );
  };

  //added to cart and removed from wishlist
  const onBuyNowClicked = ({ variantId, productId, quantity }) => {
    dispatch(addToCart({ variantId, quantity }));
    dispatch(
      removeWishlistProductRequest({
        productId,
        wishlistId: detail.token,
        from: "buy",
      })
    );
  };

  const handleRemoveItem = (productId) => {
    swal({
      title: I18n.t("user.myWishList.alert.title"),
      text: I18n.t("user.myWishList.alert.text"),
      icon: "warning",
      buttons: [
        I18n.t("user.myWishList.alert.button.cancel"),
        I18n.t("user.myWishList.alert.button.ok"),
      ],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(
          removeWishlistProductRequest({
            productId,
            wishlistId: detail.token,
          })
        );
      }
    });
  };

  if (isWishlistLoading || isWishlistProductLoading) {
    return <DashboardOrdersSkeleton repeat={5} />;
  }

  return (
    <div className="col-md-9">
      <WishlistCard
        moveWishlist={moveWishlist}
        wishlists={wishlists}
        fetchedWishlist={detail}
        isPrivate={isPrivate}
        editWishlistLoading={editWishlistLoading}
        setIsPrivate={setIsPrivate}
        onBuyNowClicked={onBuyNowClicked}
        onRemoveItem={handleRemoveItem}
        handlePrivacyChange={handlePrivacyChange}
        handleWishlistChange={handleWishlistChange}
        wishlistProducts={wishlistProducts}
        title={I18n.t("user.myWishList.title")}
      />
    </div>
  );
};

export default Wishlist;
