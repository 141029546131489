import CART_ACTIONS from "./constants";

export const fetchCart = (options) => ({
  type: CART_ACTIONS.FETCH_CART_ITEMS_REQUEST,
  options,
});

export const fetchCartSuccess = ({ cartData }) => {
  return {
    type: CART_ACTIONS.FETCH_CART_ITEMS_SUCCESS,
    cartData,
  };
};

export const fetchCartFailed = (err) => {
  console.log(err);
};

export const addToCartRequest = ({ variantId, quantity }) => {
  return {
    type: CART_ACTIONS.ADD_TO_CART_REQUEST,
    productInCart: {
      variantId,
      quantity,
    },
  };
};

export const addToCartSuccess = ({ productInCart }) => {
  return {
    type: CART_ACTIONS.ADD_TO_CART_SUCCESS,
    productInCart,
  };
};

export const addToCart = ({ variantId, quantity }) => {
  return {
    type: CART_ACTIONS.ADD_TO_CART,
    payload: {
      variantId,
      quantity,
    },
  };
};

// remove item from cart
export const removeItem = (id) => {
  return {
    type: CART_ACTIONS.REMOVE_FROM_CART,
    id,
  };
};

// set item quanitity
export const setItemQuantity = (id, value) => {
  return {
    type: CART_ACTIONS.SET_ITEM_QUANTITY_REQUEST,
    id,
    value,
  };
};

// modal
export const openCartModal = () => {
  return {
    type: CART_ACTIONS.CART_MODAL_OPEN,
  };
};

export const closeCartModal = () => {
  return {
    type: CART_ACTIONS.CART_MODAL_CLOSE,
  };
};

export const handleCartButtonClick = () => {
  return {
    type: CART_ACTIONS.CART_BUTTON_CLICKED,
  };
};

export const handleResetCartButtonClick = () => {
  return {
    type: CART_ACTIONS.RESET_CART_BUTTON_CLICK,
  };
};

// empty cart
export const emptyCartRequest = () => {
  return {
    type: CART_ACTIONS.EMPTY_CART_REQUEST,
  };
};

// promoCode
export const sendpromoCodeRequest = (promoCode) => {
  return {
    type: CART_ACTIONS.PROMO_CODE_REQUEST,
    promoCode,
  };
};

export const removePromoCodeRequest = (promoCode) => {
  return {
    type: CART_ACTIONS.REMOVE_PROMO_CODE_REQUEST,
    promoCode,
  };
};

export const resetPromoCode = () => {
  return {
    type: CART_ACTIONS.RESET_PROMO_CODE,
  };
};

export const refillPromoCode = ({ code, promoCodeName }) => {
  return {
    type: CART_ACTIONS.REFILL_PROMO_CODE,
    code,
    promoCodeName,
  };
};
