import client from "services/spreeClient";

import PRODUCTS_SORT from "constants/productsSort";

class ProductsApi {
  static searchProduct({ perPage, filter, pageNumber, sortBy }) {
    const sort = () => {
      switch (sortBy) {
        case PRODUCTS_SORT.DEFAULT:
          break;
        case PRODUCTS_SORT.NEWEST_FIRST:
          return "updated_at";
        case PRODUCTS_SORT.PRICE_HIGH_TO_LOW:
          return "-price";
        case PRODUCTS_SORT.PRICE_LOW_TO_HIGH:
          return "price";
        default:
          break;
      }
    };

    return client.products.list({
      include: "option_types.option_values,images,default_variant",
      filter,
      sort: sort(),
      page: pageNumber,
      per_page: perPage,
    });
  }

  static searchSuggestions({ keywords }) {
    return client.products.list({
      include: "images",
      filter: {
        name: keywords,
      },
      page: 1,
      per_page: 5,
    });
  }
}

export default ProductsApi;
