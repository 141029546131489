const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const formatDate = (date) => {
  const getDate = new Date(date);
  const formatedDate = getDate.toLocaleDateString("en-US", options);

  return formatedDate;
};

function convertCDTToLocalTime(cdtTimestamp) {
  const cdtDate = new Date(cdtTimestamp);

  const utcTimestamp = cdtDate.getTime() + cdtDate.getTimezoneOffset() * 60000;

  const date = new Date(utcTimestamp);

  const month = date.toLocaleString("default", { month: "long" }).toUpperCase();
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedDate = month + " " + day + " " + year;

  return formattedDate;
}

export { formatDate, convertCDTToLocalTime };
