import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import VendorApi from "api/vendor/vendorApi";
import { normalizeData } from "services/jsonaDataFormatter";

import extractFilters from "utils/extractFilters";

import {
  fetchVendorProductsSuccess,
  fetchVendorProductsFail,
  fetchVendorDetails,
} from "./actions";
import VENDOR_ACTIONS from "./constants";

/**
 * Fetch individual product
 */

function* fetchVendorProducts({ slug }) {
  try {
    const vendorData = yield call(VendorApi.fetchProducts, slug);

    if (vendorData.isSuccess()) {
      const data = vendorData.success();

      const { products, ...vendorDetails } = normalizeData(
        vendorData.success()
      );

      const filters = extractFilters(data.included);

      yield put({
        type: VENDOR_ACTIONS.FETCH_PAGE_META_DATA,
        pageMetaData: data.meta,
        filters,
      });
      yield put(fetchVendorDetails(vendorDetails));
      yield put(fetchVendorProductsSuccess(products));
    } else {
      throw vendorData.fail();
    }
  } catch (e) {
    yield put(fetchVendorProductsFail(e));
  }
}

/**
 * Saga action listeners
 */

export function* watchfetchVendorProductsRequest() {
  yield takeEvery(
    VENDOR_ACTIONS.FETCH_VENDOR_PRODUCTS_REQUEST,
    fetchVendorProducts
  );
}

export default function* rootSaga() {
  yield all([fork(watchfetchVendorProductsRequest)]);
}
