import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { syncTranslationWithStore } from "react-redux-i18n";

import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];

const persistConfig = {
  key: "storefront",
  storage,
  whitelist: ["i18n", "cart", "checkout", "taxons", "user", "wishlist"],
};

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");

    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
};

const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(pReducer, bindMiddleware(middlewares));
const persistor = persistStore(store);

syncTranslationWithStore(store);

sagaMiddleware.run(rootSaga);
export { store, persistor };
