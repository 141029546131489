import React from "react";
import { I18n } from "react-redux-i18n";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialMediaItem = ({ icon, name, url }) => {
  if (!url) return null;

  return (
    <li className="mx-1">
      <a href={url}>
        <FontAwesomeIcon icon={icon} />
      </a>
    </li>
  );
};

const SocialMediaList = ({ facebook, twitter, instagram }) => {
  const hasSocialMediaLinks = facebook || twitter || instagram;

  if (!hasSocialMediaLinks) return null;

  return (
    <div>
      <h5 className="mb-3 text-gray-600">{I18n.t("footer.followUs.title")} </h5>
      <ul className="social-links ps-0 d-flex">
        <SocialMediaItem name="facebook" url={facebook} icon={faFacebookF} />
        <SocialMediaItem name="instagram" url={instagram} icon={faInstagram} />
        <SocialMediaItem name="twitter" url={twitter} icon={faTwitter} />
      </ul>
    </div>
  );
};

export default SocialMediaList;
