import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";

import ROUTES from "constants/routes";

const ShareButtons = ({ name, slug, productDescription }) => {
  const url = typeof window !== undefined ? window.location.href : "";

  return (
    <>
      <FacebookShareButton
        url={url}
        quote={name}
        // hashtag={`#${slug}`}
        description={productDescription}
        className="me-2"
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={name}
        // hashtags={[`${slug}`]}
        className="me-2"
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton
        url={url}
        summary={productDescription}
        source={url}
        className="me-2"
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </>
  );
};

export default ShareButtons;
