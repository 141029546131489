import fallbackImage from "assets/images/loader/placeholder-image.jpg";

import { isEnvironmentVariableEnabled } from "./isEnvironmentVariableEnabled";

function getFullName(information) {
  if (!information) return "";

  let fullName = "";

  if (information.firstname) {
    fullName += information.firstname;
  }

  if (information.lastname) {
    if (fullName) {
      fullName += " ";
    }
    fullName += information.lastname;
  }

  return fullName;
}

const getLastImageUrl = (images) => {
  if (images.length === 0) return fallbackImage;
  const styles = images[0].styles;

  return styles.length ? styles[styles.length - 1].url : fallbackImage;
};

function getCartImageUrl(cartItem) {
  if (cartItem.variant.images.length) {
    return getLastImageUrl(cartItem.variant.images);
  }

  if (
    isEnvironmentVariableEnabled(
      process.env.REACT_APP_ENABLE_FALLBACK_TO_PRODUCT_IMAGE
    )
  ) {
    return getLastImageUrl(cartItem.variant.product.images);
  }

  return fallbackImage;
}

export { getFullName, getCartImageUrl };
