import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-redux-i18n";

function Spinner({ show, className }) {
  if (!show) return null;

  return (
    <span className={`spinner-border ${className}`} role="status">
      <span className="visually-hidden">
        <Translate value="common.loading" />
        ...
      </span>
    </span>
  );
}

Spinner.defaultProps = {
  className: "text-white ms-3",
  show: false,
};

Spinner.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
};

export default Spinner;
