import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "containers/App";
import * as serviceWorker from "serviceWorker";
import "assets/scss/main.scss";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { store, persistor } from "appRedux/store";

const Loading = () => {
  return (
    <div>
      <p>Loading</p>
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={() => <Loading />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
