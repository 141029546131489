import SLIDER_ACTIONS from './constants'

const initialSliderState = {
  sliderData: {},
  isLoading: false,
  error: false,
}

export const sliderReducer = (state = initialSliderState, action) => {
  switch (action.type) {
    case SLIDER_ACTIONS.FETCH_SLIDE_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case SLIDER_ACTIONS.FETCH_SLIDE_LOCATION_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error || true,
      }

    case SLIDER_ACTIONS.FETCH_SLIDE_LOCATION_SUCCESS:
      return {
        ...state,
        sliderData: { ...action.sliderData },
        isLoading: false,
        error: null,
      }

    default:
      return state
  }
}

export default sliderReducer
