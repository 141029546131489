import client from "services/spreeClient";
import getToken from "services/getToken";

import { cartOptions } from "constants/cartOptions";

class UserApi {
  static userInfoRequest() {
    return client.account.accountInfo(
      { ...getToken() },
      {
        include: "default_billing_address,default_shipping_address",
      }
    );
  }

  static sendAddressId({ billAddressID, shipAddressID }) {
    return client.account.update(
      { ...getToken() },
      {
        user: {
          bill_address_id: billAddressID,
          ship_address_id: shipAddressID,
        },
      }
    );
  }

  static usercompletedOrdersList({ pageNumber, perPage }) {
    return client.account.completedOrdersList(
      { ...getToken() },
      {
        include: cartOptions,
        page: pageNumber,
        per_page: perPage,
      }
    );
  }

  static fetchAllAddresses() {
    return client.account.addressesList({ ...getToken() });
  }

  static updateAddress({ addressId, address }) {
    return client.account.updateAddress({ ...getToken() }, addressId, {
      address,
    });
  }

  static createAddress(address) {
    return client.account.createAddress({ ...getToken() }, { address });
  }

  static fetchUserReviews({ pageNumber, perPage, userId, status = "all" }) {
    return client.reviews.listByUser({ ...getToken() }, userId, {
      include: "product,user,product.images,images",
      page: pageNumber,
      per_page: perPage,
      filter: status,
    });
  }

  static fetchUserQuestions({ pageNumber, perPage, userId }) {
    return client.productQuestions.listByUser({ ...getToken() }, userId, {
      include: "product,product.images,product_answer,user",
      page: pageNumber,
      per_page: perPage,
    });
  }
}

export default UserApi;
