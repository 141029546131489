import React from "react";
import { Translate } from "react-redux-i18n";

import AddressBookHeadings from "components/User/AddressBookHeadings";
import AddressBookItem from "components/User/AddressBookItem";

const SelectAddressItem = ({ title, address, changeHandler, modalID }) => {
  return (
    <>
      <h3 className="mt-5 mb-3 h4">{title}</h3>
      <div className="table-responsive-sm">
        <table className="table table-striped shadow-sm table-responsive">
          <AddressBookHeadings />
          <AddressBookItem
            address={address}
            onItemButtonClick={changeHandler}
            btnText={<Translate value="user.addressBook.button.change" />}
            modalID={modalID}
          />
        </table>
      </div>
    </>
  );
};

export default SelectAddressItem;
