import client from 'services/spreeClient';

class AuthApi {
  static createAccount({ email, password, confirmPassword }) {
    return client.account.create({
      user: {
        email,
        password,
        password_confirmation: confirmPassword,
      },
    });
  }

  static confirmAccount(id) {
    return client.account.confirm(id);
  }

  static login({ email, password }) {
    return client.authentication.getToken({
      username: email,
      password,
    });
  }

  static socialLogin(data) {
    return client.auth.socialLogin(data.provider, { ...data });
  }
}

export default AuthApi;
