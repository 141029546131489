import Jsona from "jsona";

const dataFormatter = new Jsona();

function normalizeData(data) {
  return dataFormatter.deserialize(data);
}

function denormalizeData(data) {
  return dataFormatter.serialize(data);
}

export { normalizeData, denormalizeData };
