import React, { useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import { useLocation, useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import ReviewCard from "components/User/ReviewCard";

import {
  fetchReviewsRequest,
  fetchReviewsCurrentPage,
} from "appRedux/user/actions";
import { removeProductReviewRequest } from "appRedux/productReview/actions";

import { getParams } from "utils/queryString";
import ROUTES from "constants/routes";

const Review = ({ history }) => {
  const { search, pathname } = useLocation();
  const { page } = getParams(search);
  const { replace } = useHistory();
  const [reviewFilterStatus, setFilterStatus] = useState({
    status: "all",
    translationKey: "all",
  });

  const dispatch = useDispatch();
  const {
    reviewsData: { reviews, currentPage, pageMetaData, isLoading },
  } = useSelector((state) => state.user);
  const {
    userInfoData: {
      userInfo: { id },
    },
  } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(
      fetchReviewsRequest({
        currentPage,
        userId: id,
        status: reviewFilterStatus.status,
      })
    );
  }, [currentPage]);

  const pageNumberHandler = (fetchPage) => {
    dispatch(fetchReviewsCurrentPage(fetchPage));

    const newPath = `${pathname}?${fetchPage ? `page=${fetchPage}` : ""}`;

    replace(newPath);
  };

  const handleFilterStatus = (status, translationKey) => {
    setFilterStatus({ status, translationKey });
    dispatch(
      fetchReviewsRequest({
        currentPage,
        userId: id,
        status: status,
      })
    );
  };

  useEffect(() => {
    const nextPage = page || 1;

    pageNumberHandler(nextPage);
  }, [page]);

  const handleEditReview = (id) => {
    history.push(
      `${ROUTES.USER.REVIEW.addreviewId(ROUTES.USER.REVIEW.EDIT, id)}`
    );
  };

  const handleDeleteReview = ({ reviewId, productId }) => {
    swal({
      title: I18n.t("user.myWishList.alert.title"),
      text: I18n.t("user.myWishList.alert.text"),
      icon: "warning",
      buttons: [
        I18n.t("user.myWishList.alert.button.cancel"),
        I18n.t("user.myWishList.alert.button.ok"),
      ],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(
          removeProductReviewRequest({
            productId,
            reviewId,
            currentPage,
            userId: id,
          })
        );
      }
    });
  };

  return (
    <div className="col-md-9">
      <ReviewCard
        reviews={reviews}
        handleEditReview={handleEditReview}
        handleDeleteReview={handleDeleteReview}
        page={page}
        pageNumberHandler={pageNumberHandler}
        pageMetaData={pageMetaData}
        title={I18n.t("user.myReviews.title")}
        handleFilterStatus={handleFilterStatus}
        reviewFilterStatus={reviewFilterStatus}
        isLoading={isLoading}
      />
    </div>
  );
};

export default withRouter(Review);
