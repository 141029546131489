import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SliderSkeleton = ({ className }) => (
  <div className={`carousel-item position-relative ${className}`}>
    <div className="loader slider" />
    <div className="carousel-overlay d-flex align-items-center">
      <div className="caption container text-start">
        <div className="col-md-8">
          <h1>
            <SkeletonTheme color="#B4B4B4" highlightColor="#BBB">
              <Skeleton height={50} />
              <Skeleton height={50} width="80%" />
            </SkeletonTheme>
          </h1>
          <SkeletonTheme color="#B4B4B4" highlightColor="#BBB">
            <Skeleton height={50} width={100} />
          </SkeletonTheme>
        </div>
      </div>
    </div>
  </div>
);

export default SliderSkeleton;
