import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiSearchLine } from "react-icons/ri";
import { withRouter } from "react-router-dom";
import { I18n } from "react-redux-i18n";

import {
  searchSuggestionRequest,
  resetSearchSuggestion,
} from "appRedux/products/actions";

// import HeaderIcon from "./HeaderIcon";
// import CategoryFilter from "./CategoryFilter";

import extractImage from "utils/extractImage";

import ROUTES from "constants/routes";

function Search({ history, onToggle }) {
  const [keywords, setkeywords] = useState("");

  const dispatch = useDispatch();

  const {
    searchData: { suggestionList, isLoading, error },
  } = useSelector((state) => state.products);

  const procceedToSearchResults = (searchKeywords) => {
    onToggle();
    history.push({
      pathname: `${ROUTES.PRODUCTS.INDEX}`,
      search: `?keywords=${searchKeywords}`,
    });

    if (searchKeywords !== keywords) {
      setkeywords("");
    }

    dispatch(resetSearchSuggestion());
  };

  const submit = (e) => {
    e.preventDefault();
    setkeywords(e.target.keywords.value.trim());
    procceedToSearchResults(keywords);
  };

  useEffect(() => {
    if (keywords) {
      dispatch(searchSuggestionRequest(keywords));
    } else {
      dispatch(resetSearchSuggestion());
    }
  }, [keywords]);

  return (
    <>
      <div className="position-relative w-auto d-flex">
        <form onSubmit={submit} className="search-form shadow-sm w-100">
          <div className="select-search border-0 w-100">
            {/* <CategoryFilter /> */}
            <input
              placeholder={I18n.t("header.searchboxPlaceHolder")}
              name="keywords"
              value={keywords}
              onChange={(e) => setkeywords(e.target.value)}
              onBlur={() => dispatch(resetSearchSuggestion())}
              onFocus={() =>
                keywords && dispatch(searchSuggestionRequest(keywords))
              }
              type="text"
              aria-label="Search"
              className="search-box"
              autoComplete="off"
            />
            <div className="search-suggestion card border-0 shadow-sm w-100">
              {keywords &&
                suggestionList.map((suggestion) => {
                  let availabilityStatus = suggestion.inStock
                    ? "In stock"
                    : "Out of stock";

                  availabilityStatus = suggestion.backorderable
                    ? "Pre Order"
                    : availabilityStatus;
                  availabilityStatus = suggestion.available
                    ? availabilityStatus
                    : "Unavailable";

                  return (
                    <div
                      className="p-3 search-suggestion-item border-top"
                      onMouseDown={() =>
                        procceedToSearchResults(suggestion.name)
                      }
                    >
                      <div className="d-flex cart-item align-items-center">
                        <div className="position-relative">
                          <img
                            src={extractImage(suggestion.images)}
                            className="search-thumbnail img-thumbnail"
                          />
                          <span
                            className={`${
                              availabilityStatus ===
                              ("Out of stock" || "Unavailable")
                                ? "inactive"
                                : "active"
                            } availability-status`}
                          ></span>
                        </div>

                        <span className="ms-3 product-name">
                          {suggestion.name}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
            <button
              type="submit"
              disabled={keywords.trim().length === 0}
              className="search-submit"
            >
              <RiSearchLine />
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default withRouter(Search);
