const PRODUCT_REVIEW_ACTIONS = {
  FETCH_ALL_PRODUCT_REVIEW_REQUEST: 'FETCH_ALL_PRODUCT_REVIEW_REQUEST',
  FETCH_ALL_PRODUCT_REVIEW_SUCCESS: 'FETCH_ALL_PRODUCT_REVIEW_SUCCESS',
  FETCH_ALL_PRODUCT_REVIEW_FAILED: 'FETCH_ALL_PRODUCT_REVIEW_FAILED',

  CREATE_PRODUCT_REVIEW_REQUEST: 'CREATE_PRODUCT_REVIEW_REQUEST',
  CREATE_PRODUCT_REVIEW_FAILED: 'CREATE_PRODUCT_REVIEW_FAILED',
  CREATE_PRODUCT_REVIEW_SUCCESS: 'CREATE_PRODUCT_REVIEW_SUCCESS',

  EDIT_PRODUCT_REVIEW_REQUEST: 'EDIT_PRODUCT_REVIEW_REQUEST',
  EDIT_PRODUCT_REVIEW_FAILED: 'EDIT_PRODUCT_REVIEW_FAILED',
  EDIT_PRODUCT_REVIEW_SUCCESS: 'EDIT_PRODUCT_REVIEW_SUCCESS',

  REMOVE_PRODUCT_REVIEW_REQUEST: 'REMOVE_PRODUCT_REVIEW_REQUEST',
  REMOVE_PRODUCT_REVIEW_FAILED: 'REMOVE_PRODUCT_REVIEW_FAILED',
  REMOVE_PRODUCT_REVIEW_SUCCESS: 'REMOVE_PRODUCT_REVIEW_SUCCESS',

  RESET_REVIEW_FORM: 'RESET_REVIEW_FORM',
};

export default PRODUCT_REVIEW_ACTIONS;
