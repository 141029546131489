import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ColorFilterItem from "components/Filters/ColorFilterItem";

const ColorOption = ({
  optionName,
  value,
  currentOptionTypes,
  setCurrentOptionTypes,
}) => (
  <ColorFilterItem
    currentOptionTypes={currentOptionTypes}
    setCurrentOptionTypes={setCurrentOptionTypes}
    colorBg={value.presentation}
    colorId={value.id}
  />
);

const ProductOption = ({
  title,
  optionValues,
  setCurrentOptionTypes,
  optionName,
  currentOptionTypes,
  changeDisplayVariant,
}) => {
  if (optionName === ("color" || "transparent-color")) {
    return (
      <div className="d-flex">
        {optionValues.map((value) => (
          <div
            key={value.id}
            onClick={() => {
              changeDisplayVariant(value.id);
            }}
          >
            <ColorOption
              currentOptionTypes={currentOptionTypes}
              setCurrentOptionTypes={setCurrentOptionTypes}
              optionName={optionName}
              value={value}
            />
          </div>
        ))}
      </div>
    );
  }

  return optionValues.map((value, i) => (
    <div key={value.id} className="mb-2">
      <input
        type="radio"
        name={optionName}
        className="btn-check"
        id={value.name}
        value={value.name}
        autoComplete="off"
        onClick={() => {
          changeDisplayVariant(value.id);
        }}
      />
      <label className="btn btn-outline-primary size" htmlFor={value.name}>
        {value.presentation}
      </label>
    </div>
  ));
};

const ProductOptionTypes = ({
  optionTypes,
  changeDisplayVariant,
  currentOptionTypes,
  setCurrentOptionTypes,
}) =>
  optionTypes.map(({ id, name, presentation, option_values }) => (
    <div key={id} className="border-bottom py-3">
      <p className="mb-0">{presentation}</p>
      <form className="mt-2">
        <ProductOption
          title={presentation}
          setCurrentOptionTypes={setCurrentOptionTypes}
          currentOptionTypes={currentOptionTypes}
          optionName={name}
          optionValues={option_values}
          changeDisplayVariant={changeDisplayVariant}
        />
      </form>
    </div>
  ));

ProductOptionTypes.propTypes = {};

export default ProductOptionTypes;
