import React from "react";

import ROUTES from "constants/routes";

import agricultureCategory from "assets/images/category/gaming-chair.png";

const ProductBanner = ({ category, title, description, btnText }) => (
  <section className="bg-light product-banner ">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center text-center text-md-start">
        <div className="pe-3">
          <h5 className="product-category">{category}</h5>
          <h2 className="font-weight-bold">{title}</h2>
          <p className="description my-4 text-gray">{description}</p>
          <a
            href={`${ROUTES.CATEGORY.addTaxonId(ROUTES.CATEGORY.INDEX, 2)}`}
            className="btn btn-primary"
          >
            {btnText}
          </a>
        </div>
        <img
          src={agricultureCategory}
          alt="agricultural equipments"
          className="w-50 d-none d-sm-block"
        />
      </div>
    </div>
  </section>
);

export default ProductBanner;
