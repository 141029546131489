import AUTH_ACTIONS from './constants';

export const checkAuthorization = () => ({
  type: AUTH_ACTIONS.CHECK_AUTHORIZATION,
});

export const login = (email, password) => ({
  type: AUTH_ACTIONS.LOGIN_REQUEST,
  payload: { email, password },
});

export const loginWithFacebookRequest = ({
  provider,
  uid,
  info,
  credentials,
  extra,
}) => ({
  type: AUTH_ACTIONS.LOGIN_WITH_FACEBOOK_REQUEST,
  provider,
  uid,
  info,
  credentials,
  extra,
});

export const loginWithGoogleRequest = (data) => ({
  type: AUTH_ACTIONS.LOGIN_WITH_GOOGLE_REQUEST,
  ...data,
});

export const registration = (email, password, confirmPassword) => ({
  type: AUTH_ACTIONS.REGISTRATION_REQUEST,
  payload: { email, password, confirmPassword },
});

export const logout = () => ({
  type: AUTH_ACTIONS.LOGOUT,
});
