export const STATUS = {
  CONFIRMED: "Confirmed",
  PLACED: "Placed",
  DELIVERED: "Delivered",
  PENDING: "Pending",
};

export const BUTTON_TEXT = {
  CANCEL_ORDER: "Cancel order",
  RETURN_ITEM: "Return item",
  PAY_NOW: "Pay Now",
};

export default STATUS;
