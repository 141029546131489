import React from 'react';

const OrderStatus = ({ status }) => {
  let bgColor;

  if (status === 'Placed') {
    bgColor = 'text-info border-info';
  } else if (status === 'Confirmed') {
    bgColor = 'text-success border-success';
  } else if (status === 'Delivered') {
    bgColor = 'text-primary border-primary';
  } else if (status === 'Pending') {
    bgColor = 'text-warning border-warning';
  } else {
    bgColor = 'text-dark border-dark';
  }

  return (
    <span className={`badge border rounded-pill ${bgColor}`}>{status}</span>
  );
};

export default OrderStatus;
