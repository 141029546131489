import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form-normalize-on-blur';

import trimmer from 'utils/trimmer';
import { renderField, renderSelectField } from './FormFieldRender';

const nameOfField = (formType, name) => {
  if (name === 'email') {
    return 'email';
  }
  if (formType) {
    return `${formType}.${name}`;
  }
};

export const FormTextField = ({ formType, label, name }) => {
  return (
    <Field
      name={nameOfField(formType, name)}
      type='text'
      component={renderField}
      normalizeOnBlur={trimmer}
      label={label}
      helpText={name === 'email' && I18n.t('checkout.address.emailHelperText')}
    />
  );
};

export const FormSelectField = ({ formType, label, name, options }) => {
  return (
    <Field
      name={nameOfField(formType, name)}
      component={renderSelectField}
      label={label}
      options={options}
    />
  );
};

FormSelectField.defaultProps = {
  options: [],
};
