import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { loadTranslations, setLocale } from "react-redux-i18n";
import { useDispatch, useSelector } from "react-redux";

import Home from "pages/Home";
import ProductDetail from "pages/Products/ProductDetail";
import Category from "pages/Category";
import OrderDetails from "pages/OrderDetails";
import CheckoutLogin from "pages/Checkout/Registration";
import Address from "pages/Checkout/Address";
import CheckoutPayment from "pages/Checkout/Payment";
import DeliveryOption from "pages/Checkout/Delivery";
import Dashboard from "pages/User/Dashboard";
import Signup from "pages/Signup";
import MyOrder from "pages/User/MyOrder";
import QuestionIndex from "pages/User/Question/QuestionIndex";
import QuestionEdit from "pages/User/Question/QuestionEdit";
import AddressCreate from "pages/User/Address/AddressCreate";
import AddressEdit from "pages/User/Address/AddressEdit";
import ReviewIndex from "pages/User/Review/ReviewIndex";
import ReviewEdit from "pages/User/Review/ReviewEdit";
import WishlistIndex from "pages/User/Wishlist/WishlistIndex";
import WishlistCreate from "pages/User/Wishlist/WishlistCreate";
import WishlistEdit from "pages/User/Wishlist/WishlistEdit";
import UserAddress from "pages/User/Address/AddressIndex";
import Profile from "pages/User/Profile";
import Login from "pages/Login";
import Products from "pages/Products/ProductsIndex";
import SelectAddress from "pages/Checkout/SelectAddress";
import VendorDetails from "pages/Vendor/VendorDetails";

import Header from "components/Header/Header";
import ProductPreview from "components/Cart/ProductPreview";
import CheckoutWrapper from "components/Checkout/CheckoutWrapper";
import DashboardCard from "components/User/DashboardCard";
import CheckIfAuthenticated from "components/CheckIfAuthenticated";

import { addStateAddressRequest } from "appRedux/checkOut/actions";
import { fetchTaxonsRequest } from "appRedux/taxon/actions";

import translationsObject from "locales/root";

import ROUTES from "constants/routes";
import AccountLayout from "./layout/AccountLayout";

const CheckoutRoute = () => {
  const bearerToken = localStorage.getItem("bearerToken");

  const { continueAsGuest, isformSumitted, isDeliveryChoosen } = useSelector(
    (state) => state.checkout
  );
  const currentLocale = useSelector((state) => state.i18n.locale);

  useEffect(() => {}, [currentLocale]);

  // for state addresses request
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addStateAddressRequest());
  }, [dispatch]);

  const {
    addressBookData: { addressBook },
  } = useSelector((state) => state.user);

  return (
    <>
      <CheckoutWrapper
        isDeliveryChoosen={isDeliveryChoosen}
        isformSumitted={isformSumitted}
      >
        <Switch>
          <Route
            path={ROUTES.CHECKOUT.SELECT_ADDRESS}
            component={(props) => (
              <SelectAddress {...props} addressBook={addressBook} />
            )}
          />
          <Route path={ROUTES.CHECKOUT.ADDRESS} component={() => <Address />} />
          <Route
            path={ROUTES.CHECKOUT.DELIVERY}
            component={() => <DeliveryOption />}
          />
          <Route
            path={ROUTES.CHECKOUT.PAYMENT}
            component={() => <CheckoutPayment />}
          />
        </Switch>

        {!bearerToken && !continueAsGuest ? (
          <Route
            path={ROUTES.CHECKOUT.INDEX}
            component={() => <Redirect to={ROUTES.CHECKOUT.REGISTRATION} />}
          />
        ) : !isformSumitted ? (
          addressBook.length !== 0 ? (
            <Route
              path={ROUTES.CHECKOUT.INDEX}
              component={() => <Redirect to={ROUTES.CHECKOUT.SELECT_ADDRESS} />}
            />
          ) : (
            <Route
              path={ROUTES.CHECKOUT.INDEX}
              component={() => <Redirect to={ROUTES.CHECKOUT.ADDRESS} />}
            />
          )
        ) : !isDeliveryChoosen ? (
          <Route
            path={ROUTES.CHECKOUT.INDEX}
            component={() => <Redirect to={ROUTES.CHECKOUT.DELIVERY} />}
          />
        ) : (
          <Route
            path={ROUTES.CHECKOUT.INDEX}
            component={() => <Redirect to={ROUTES.CHECKOUT.PAYMENT} />}
          />
        )}
      </CheckoutWrapper>
    </>
  );
};

const UserRoute = () => {
  const bearerToken = localStorage.getItem("bearerToken");

  const currentLocale = useSelector((state) => state.i18n.locale);

  useEffect(() => {}, [currentLocale]);
  if (!bearerToken) {
    return <Redirect to={ROUTES.LOGIN.INDEX} />;
  }

  return (
    <Switch>
      <AccountLayout>
        <Route path={ROUTES.USER.ORDER} component={MyOrder} />
        <Route path={ROUTES.USER.PROFILE} component={Profile} />
        {/* ADDED TO AVOID CONFLICT BETWEEN ADD AND SHOW ROUTE */}
        <Switch>
          <Route path={ROUTES.USER.WISHLIST.ADD} component={WishlistCreate} />
          <Route
            path={ROUTES.USER.WISHLIST.SHOW}
            exact
            component={WishlistIndex}
          />
        </Switch>
        <Route path={ROUTES.USER.WISHLIST.EDIT} component={WishlistEdit} />
        <Route
          path={ROUTES.USER.WISHLIST.INDEX}
          exact
          component={WishlistIndex}
        />
        <Route path={ROUTES.USER.REVIEW.EDIT} component={ReviewEdit} />
        <Route path={ROUTES.USER.REVIEW.INDEX} exact component={ReviewIndex} />
        <Route path={ROUTES.USER.QUESTION.EDIT} component={QuestionEdit} />
        <Route
          path={ROUTES.USER.QUESTION.INDEX}
          exact
          component={QuestionIndex}
        />
        <Route path={ROUTES.USER.ADDRESS.ADD} component={AddressCreate} />
        <Route path={ROUTES.USER.ADDRESS.EDIT} component={AddressEdit} />
        <Route path={ROUTES.USER.ADDRESS.INDEX} exact component={UserAddress} />
      </AccountLayout>
      {/* TODO: we are currently hiding dashboard */}
      {/* <Route path={ROUTES.USER.DASHBOARD} component={Dashboard} /> */}
    </Switch>
  );
};

const ProductRoute = () => {
  return (
    <Switch>
      <Route path={`${ROUTES.PRODUCTS.DETAIL}`}>
        <ProductDetail />
      </Route>
      <Route path={ROUTES.PRODUCTS.INDEX}>
        <Products />
      </Route>
    </Switch>
  );
};

const VendorRoute = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.VENDOR.INDEX}
        render={() => <Redirect to={ROUTES.HOME.INDEX} />}
      />
      <Route path={ROUTES.VENDOR.DETAIL}>
        <VendorDetails />
      </Route>
    </Switch>
  );
};

function App() {
  const dispatch = useDispatch();
  const currentLocale = useSelector((state) => state.i18n.locale);
  const { cartData } = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(fetchTaxonsRequest());
  }, []);

  useEffect(() => {
    dispatch(loadTranslations(translationsObject));
    dispatch(setLocale(currentLocale || "en_US"));
  }, [currentLocale, dispatch]);

  const bearerToken = localStorage.getItem("bearerToken");

  const {
    checkOutSuccess,
    orderDetailData: { orderNumber },
  } = useSelector((state) => state.checkout);

  const ORDER_DETAIL_PATH = ROUTES.ORDER.INDEX.replace(
    ":orderNumber",
    orderNumber
  );

  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route
            path={ROUTES.CART.INDEX}
            component={(props) => <ProductPreview {...props} />}
          />

          <Route
            path={ROUTES.LOGIN.INDEX}
            component={CheckIfAuthenticated(Login)}
          />

          <Route
            path={ROUTES.SIGNUP.INDEX}
            component={CheckIfAuthenticated(Signup)}
          />

          <Route
            path={ROUTES.CHECKOUT.REGISTRATION}
            component={(props) => (
              <CheckoutLogin {...props} bearerToken={bearerToken} />
            )}
          />

          <Route path={ROUTES.CHECKOUT.INDEX}>
            {cartData.item_count === 0 ? (
              <Redirect to={ROUTES.CART.INDEX} />
            ) : checkOutSuccess ? (
              bearerToken ? (
                <Redirect to={ROUTES.USER.ORDER} />
              ) : (
                <Redirect to={ORDER_DETAIL_PATH} />
              )
            ) : (
              <CheckoutRoute />
            )}
          </Route>

          <Route path={ROUTES.USER.INDEX}>
            <UserRoute />
          </Route>

          <Route path={ROUTES.PRODUCTS.INDEX}>
            <ProductRoute />
          </Route>

          <Route path={ROUTES.VENDOR.INDEX}>
            <VendorRoute />
          </Route>

          <Route path={ROUTES.CATEGORY.INDEX}>
            <Category />
          </Route>
          <Route path={ROUTES.ORDER.INDEX}>
            <OrderDetails />
          </Route>
          <Route path={ROUTES.HOME.INDEX}>
            <Home />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
