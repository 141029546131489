import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { I18n, Translate } from "react-redux-i18n";
import { reduxForm } from "redux-form";
import { Field } from "redux-form-normalize-on-blur";
import { connect, useDispatch, useSelector } from "react-redux";

import {
  renderField,
  renderSelectField,
} from "components/Form/FormFieldRender";

import { addStateAddressRequest } from "appRedux/checkOut/actions";
import {
  updateAddressRequest,
  createAddressRequest,
} from "appRedux/user/actions";

import trimmer from "utils/trimmer";
import { required, phoneNumber, zipCode } from "utils/fieldValidation";

import USER_ACTIONS from "appRedux/user/constants";
import TIMEOUT_MILLISECONDS from "constants/timeoutMilliseconds";
import Button from "components/Button";

const AddressBookForm = ({ history, handleSubmit, title }) => {
  const { addressId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addStateAddressRequest());
  }, []);

  const stateAddress = useSelector((state) => state.checkout.stateAddress);

  const {
    addressBookData: { updateStatus, addStatus },
  } = useSelector((state) => state.user);

  const { updatesSubmitted } = updateStatus;
  const { newAddressSubmitted } = addStatus;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (addressId) {
        dispatch({ type: USER_ACTIONS.RESET_UPDATE_ADDRESS_STATUS });
      } else {
        dispatch({ type: USER_ACTIONS.RESET_CREATE_ADDRESS_STATUS });
      }
    }, TIMEOUT_MILLISECONDS.SUCCESS_MESSAGE);

    let goBack = null;

    if (updatesSubmitted || newAddressSubmitted) {
      goBack = setTimeout(() => {
        history.goBack();
      }, TIMEOUT_MILLISECONDS.SUCCESS_MESSAGE + 500);
    }

    return () => clearTimeout(timer) && clearTimeout(goBack);
  }, [updatesSubmitted, newAddressSubmitted]);

  const goBack = () => {
    history.goBack();
  };

  const submit = (values) => {
    const {
      firstname,
      lastname,
      phone,
      address1,
      address2,
      zipcode,
      city,
      state_name,
      label,
    } = values;
    const address = {
      firstname,
      lastname,
      phone,
      address1,
      address2,
      city,
      zipcode,
      state_name,
      country_iso: "NP",
      label,
    };

    if (addressId) {
      dispatch(updateAddressRequest({ addressId, address }));
    } else {
      dispatch(createAddressRequest(address));
    }
  };

  const isLoading = addressId ? updateStatus.isLoading : addStatus.isLoading;

  return (
    <div className="position-relative col-md-9 shadow-sm">
      <form onSubmit={handleSubmit(submit)}>
        <h3 className="mb-3">{title}</h3>
        <div className="row">
          <div className="col-md-6">
            <Field
              name="label"
              type="text"
              component={renderField}
              label={`${I18n.t("checkout.address.addressName")}`}
            />
            <Field
              name="firstname"
              type="text"
              component={renderField}
              normalizeOnBlur={trimmer}
              label={`${I18n.t("checkout.address.firstName")} *`}
              validate={[required]}
            />
            <Field
              name="lastname"
              type="text"
              component={renderField}
              normalizeOnBlur={trimmer}
              label={`${I18n.t("checkout.address.lastName")} *`}
              validate={[required]}
            />
            <Field
              name="phone"
              type="text"
              component={renderField}
              normalizeOnBlur={trimmer}
              label={`${I18n.t("checkout.address.phone")} *`}
              validate={[required, phoneNumber]}
            />
          </div>

          <div className="col-md-6">
            <Field
              name="address1"
              type="text"
              component={renderField}
              normalizeOnBlur={trimmer}
              label={`${I18n.t("checkout.address.address")} *`}
              validate={[required]}
            />
            <Field
              type="text"
              component={renderField}
              normalizeOnBlur={trimmer}
              label="Address (Contd)"
              name="address2"
              validate={[required]}
            />
            <Field
              type="text"
              component={renderField}
              normalizeOnBlur={trimmer}
              label={`${I18n.t("checkout.address.zipCode")}*`}
              name="zipcode"
              validate={[required, zipCode]}
            />
            <Field
              type="text"
              component={renderField}
              label={`${I18n.t("checkout.address.city")}*`}
              name="city"
              validate={[required]}
            />
            <Field
              label={`${I18n.t("checkout.address.stateProvince")}*`}
              name="state_name"
              component={renderSelectField}
              options={stateAddress}
              validate={[required]}
            />
          </div>
        </div>
        <div className="mb-4 d-flex justify-content-end">
          <Button
            btnText={<Translate value="user.addressBook.button.save" />}
            className="btn btn-primary py-2 px-3"
            type="submit"
            disabled={isLoading}
            isLoading={isLoading}
          />
          <button type="button" onClick={goBack} className="btn btn-secondary">
            <Translate value="user.addressBook.button.cancel" />
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const {
    addressBookData: { addressBook },
  } = state.user;

  const initalAddress = addressBook.find((address) => {
    return address.id === props.match.params.addressId;
  });

  if (!initalAddress) {
    return {};
  }

  return {
    initialValues: {
      firstname: initalAddress.firstname,
      lastname: initalAddress.lastname,
      phone: initalAddress.phone,
      state_name: initalAddress.state_name,
      zipcode: initalAddress.zipcode,
      address1: initalAddress.address1,
      address2: initalAddress.address2,
      city: initalAddress.city,
    },
  };
};

export default withRouter(
  connect(mapStateToProps)(reduxForm({ form: "addressForm" })(AddressBookForm))
);
