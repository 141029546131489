import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import AuthApi from 'api/auth/authApi';
import CartApi from 'api/cart/cartApi';

import { fetchCart, resetPromoCode, addToCart } from 'appRedux/cart/actions';

import { cartOptions } from 'constants/cartOptions';
import USER_ACTIONS from 'appRedux/user/constants';
import AUTH_ACTIONS from 'appRedux/auth/constants';
import CHECKOUT_ACTIONS from 'appRedux/checkOut/constants';

const getCartData = (state) => state.cart;

/**
 * Registration request
 */

function* registrationRequest({ payload }) {
  try {
    const response = yield call(AuthApi.createAccount, payload);

    if (response.isSuccess()) {
      yield put({ type: AUTH_ACTIONS.REGISTRATION_SUCCESS });
      yield put({ type: AUTH_ACTIONS.LOGIN_REQUEST, payload });
    } else {
      throw response.fail().errors;
    }
  } catch (e) {
    yield put({ type: AUTH_ACTIONS.REGISTRATION_FAILED, errors: e });
  }
}

/**
 * login request
 */

function* loginRequest({ payload }) {
  try {
    const response = yield call(AuthApi.login, payload);

    if (response.isSuccess()) {
      localStorage.removeItem('orderToken');
      localStorage.setItem('bearerToken', response.success().access_token);
      yield put({ type: CHECKOUT_ACTIONS.RESET_CHECKOUT });
      yield put({
        type: USER_ACTIONS.USER_INFO_REQUEST,
      });
      yield put({ type: USER_ACTIONS.FETCH_ALL_ADDRESSES_REQUEST });
      yield put(resetPromoCode());

      const { cartData } = yield select(getCartData);

      const { item_count, line_items } = cartData;

      if (item_count > 0) {
        const response = yield call(CartApi.createCart);

        if (response.isSuccess()) {
          for (const item of line_items) {
            const variantId = item.variant.id;
            const { quantity } = item;

            yield put(
              addToCart({
                variantId,
                quantity,
              })
            );
          }
        }
      } else {
        yield put(
          fetchCart({
            include: cartOptions,
          })
        );
      }
      yield put({
        type: AUTH_ACTIONS.LOGIN_SUCCESS,
        authData: response.success(),
      });
    } else {
      throw { ...response.fail() };
    }
  } catch (e) {
    yield put({ type: AUTH_ACTIONS.LOGIN_FAILED, error: e.serverResponse.data.error_description });
  }
}

/**
 * login with facebook request
 */

function* loginWithFacebookRequest({
  provider,
  uid,
  info,
  credentials,
  extra,
}) {
  try {
    const payload = { provider, uid, info, credentials, extra };
    const response = yield call(AuthApi.socialLogin, payload);

    if (response.isSuccess()) {
      localStorage.removeItem('orderToken');
      localStorage.setItem('bearerToken', response.success().access_token);
      yield put({ type: CHECKOUT_ACTIONS.RESET_CHECKOUT });
      yield put({
        type: USER_ACTIONS.USER_INFO_REQUEST,
      });
      yield put({ type: USER_ACTIONS.FETCH_ALL_ADDRESSES_REQUEST });
      yield put(resetPromoCode());

      const { cartData } = yield select(getCartData);

      const { item_count, line_items } = cartData;

      if (item_count > 0) {
        const response = yield call(CartApi.createCart);

        if (response.isSuccess()) {
          for (const item of line_items) {
            const variantId = item.variant.id;
            const { quantity } = item;

            yield put(
              addToCart({
                variantId,
                quantity,
              })
            );
          }
        }
      } else {
        yield put(
          fetchCart({
            include: cartOptions,
          })
        );
      }

      yield put({
        type: AUTH_ACTIONS.LOGIN_SUCCESS,
        authData: response.success(),
      });
    } else {
      throw { ...response.fail() };
    }
  } catch (e) {
    yield put({ type: AUTH_ACTIONS.LOGIN_FAILED, error: e.summary });
  }
}

/**
 * login with google request
 */

function* loginWithGoogleRequest({ provider, uid, info, credentials, extra }) {
  try {
    const payload = { provider, uid, info, credentials, extra };
    const response = yield call(AuthApi.socialLogin, payload);

    if (response.isSuccess()) {
      localStorage.removeItem('orderToken');
      localStorage.setItem('bearerToken', response.success().access_token);
      yield put({ type: CHECKOUT_ACTIONS.RESET_CHECKOUT });
      yield put({
        type: USER_ACTIONS.USER_INFO_REQUEST,
      });
      yield put({ type: USER_ACTIONS.FETCH_ALL_ADDRESSES_REQUEST });
      yield put(resetPromoCode());

      const { cartData } = yield select(getCartData);

      const { item_count, line_items } = cartData;

      if (item_count > 0) {
        const response = yield call(CartApi.createCart);

        if (response.isSuccess()) {
          for (const item of line_items) {
            const variantId = item.variant.id;
            const { quantity } = item;

            yield put(
              addToCart({
                variantId,
                quantity,
              })
            );
          }
        }
      } else {
        yield put(
          fetchCart({
            include: cartOptions,
          })
        );
      }

      yield put({
        type: AUTH_ACTIONS.LOGIN_SUCCESS,
        authData: response.success(),
      });
    } else {
      throw { ...response.fail() };
    }
  } catch (e) {
    yield put({ type: AUTH_ACTIONS.LOGIN_FAILED, error: e.summary });
  }
}

/**
 * Saga action listeners
 */

export function* watchRegistrationRequest() {
  yield takeEvery(AUTH_ACTIONS.REGISTRATION_REQUEST, registrationRequest);
}

export function* watchLogInRequest() {
  yield takeEvery(AUTH_ACTIONS.LOGIN_REQUEST, loginRequest);
}

export function* watchLogInWithFacebookRequest() {
  yield takeEvery(
    AUTH_ACTIONS.LOGIN_WITH_FACEBOOK_REQUEST,
    loginWithFacebookRequest
  );
}

export function* watchLogInWithGoogleRequest() {
  yield takeEvery(
    AUTH_ACTIONS.LOGIN_WITH_GOOGLE_REQUEST,
    loginWithFacebookRequest
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchRegistrationRequest),
    fork(watchLogInRequest),
    fork(watchLogInWithFacebookRequest),
    fork(watchLogInWithGoogleRequest),
  ]);
}
