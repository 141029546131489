import React, { useEffect } from "react";
import { I18n, Translate } from "react-redux-i18n";
import { useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";

import UserIcon from "components/UserIcon";
import Button from "components/Button";
import ReviewStatus from "components/User/ReviewStatus";

import { convertCDTToLocalTime } from "utils/DateFormatter";
import { getFullName } from "utils/utils";

import REACT_STARS from "constants/reactStarsConfig";
import ReviewCarousel from "./ReviewCarousel";
import extractImage, { extractImages } from "utils/extractImage";

const ProductReviews = ({ productReviews, setToggleReviewForm }) => {
  const toggleReviewFormHandler = () => {
    setToggleReviewForm((previous) => !previous);
  };
  const { reviewSubmitted } = useSelector((state) => state.productReview);

  useEffect(() => {
    if (reviewSubmitted) {
      setToggleReviewForm(false);
    }
  }, [reviewSubmitted]);

  return (
    <>
      <ul className="product-review list-style-none ps-0">
        {productReviews.length !== 0 &&
          productReviews.map((productReview) => {
            const {
              id,
              title,
              review,
              rating,
              show_identifier,
              created_at,
              user,
              name,
              approved,
              images,
            } = productReview;

            const fullName = getFullName(user?.default_billing_address) || name;

            const displayName = show_identifier ? fullName : "Anonymous";

            return (
              <li key={id} className="review border-bottom mb-3">
                <div className="d-flex align-items-start gap-3">
                  <UserIcon rating={rating} fullName={displayName} />
                  <div className="w-100">
                    <div className="user-info w-100">
                      <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between gap-2 w-100">
                        <div className="d-flex gap-2 align-items-center">
                          <p className="mb-0 font-weight-bold fs-5">
                            {fullName}
                          </p>
                          <ReviewStatus status={approved} disableAnswerStatus />
                        </div>
                        <p className="text-muted mb-0 review-added-date">
                          <Translate
                            value="productDetail.reviews.reviewedIn"
                            date={convertCDTToLocalTime(created_at)}
                          />
                        </p>
                      </div>
                      <ReactStars
                        value={rating}
                        count={REACT_STARS.COUNT}
                        edit={false}
                        size={REACT_STARS.USER_SIZE}
                        activeColor={REACT_STARS.ACTIVE_COLOR}
                      />
                    </div>
                    <div className="question-box mt-3">
                      <p className="font-weight-bold me-2 mb-2 fs-6">{title}</p>
                      <p>{review}</p>
                    </div>
                  </div>
                </div>
                <ReviewCarousel
                  images={extractImages(images)}
                  show={images.length}
                  rating={rating}
                  displayName={displayName}
                  fullName={fullName}
                  approved={approved}
                  createdAt={created_at}
                  title={title}
                  review={review}
                />
              </li>
            );
          })}
      </ul>
      <Button
        btnText={I18n.t("productDetail.reviews.buttontext")}
        className="w-100 bg-light primary-color"
        onClick={toggleReviewFormHandler}
      />
    </>
  );
};

ProductReviews.propTypes = {};

export default ProductReviews;
