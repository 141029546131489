import React from "react";
import { I18n } from "react-redux-i18n";

import { renderField } from "components/Form/FormFieldRender";
import { email, required } from "utils/fieldValidation";
import { Field } from "redux-form-normalize-on-blur";

const QuestionBasicInformationFields = ({ questionId }) => {
  const bearerToken = localStorage.getItem("bearerToken");

  return (
    <>
      {!questionId && !bearerToken && (
        <Field
          name="fullName"
          type="text"
          component={renderField}
          label={`${I18n.t("form.fields.fullName")} *`}
          validate={[required]}
        />
      )}

      {!bearerToken && (
        <Field
          name="email"
          type="email"
          component={renderField}
          label={`${I18n.t("form.fields.email")} *`}
          validate={[required, email]}
        />
      )}
    </>
  );
};

export default QuestionBasicInformationFields;
