export const getOrderToken = () => {
  return {
    orderToken: localStorage.getItem("orderToken"),
  };
};

export const getUserToken = () => {
  const bearerToken = localStorage.getItem("bearerToken");

  if (!bearerToken) {
    return {
      ...getOrderToken(),
    };
  }

  return { bearerToken };
};

const getToken = () => {
  const bearerToken = localStorage.getItem("bearerToken");

  if (!bearerToken) {
    return {};
  }

  return { bearerToken };
};

export default getToken;
