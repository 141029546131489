const CHECKOUT_ACTIONS = {
  ADD_STATE_ADDRESS_REQUEST: 'ADD_STATE_ADDRESS_REQUEST',
  ADD_STATE_ADDRESS_SUCCESS: 'ADD_STATE_ADDRESS_SUCCESS',
  ADD_STATE_ADDRESS_FAILED: 'ADD_STATE_ADDRESS_FAILED',

  ADD_CHECKOUT_ADDRESS_REQUEST: 'ADD_CHECKOUT_ADDRESS_REQUEST',
  ADD_CHECKOUT_ADDRESS_SUCCESS: 'ADD_CHECKOUT_ADDRESS_SUCCESS',
  ADD_CHECKOUT_ADDRESS_FAILED: 'ADD_CHECKOUT_ADDRESS_FAILED',
  UPDATE_CHECKOUT_ADDRESS_REQUEST: 'UPDATE_CHECKOUT_ADDRESS_REQUEST',
  UPDATE_CHECKOUT_ADDRESS_SUCCESS: 'UPDATE_CHECKOUT_ADDRESS_SUCCESS',
  UPDATE_CHECKOUT_ADDRESS_FAILED: 'UPDATE_CHECKOUT_ADDRESS_FAILED',

  ADD_SHIPPING_METHOD_REQUEST: 'ADD_SHIPPING_METHOD_REQUEST',
  ADD_SHIPPING_METHOD_SUCCESS: 'ADD_SHIPPING_METHOD_SUCCESS',
  ADD_SHIPPING_METHOD_FAILED: 'ADD_SHIPPING_METHOD_FAILED',
  SEND_SHIPPING_METHOD_REQUEST: 'SEND_SHIPPING_METHOD_REQUEST',
  SEND_SHIPPING_METHOD_SUCCESS: 'SEND_SHIPPING_METHOD_SUCCESS',

  ADD_PAYMENT_METHOD_REQUEST: 'ADD_PAYMENT_METHOD_REQUEST',
  ADD_PAYMENT_METHOD_SUCCESS: 'ADD_PAYMENT_METHOD_SUCCESS',
  SEND_PAYMENT_METHOD_REQUEST: 'SEND_PAYMENT_METHOD_REQUEST',
  SEND_PAYMENT_METHOD_SUCCESS: 'SEND_PAYMENT_METHOD_SUCCESS',

  CLEAR_CHECKOUT: 'CLEAR_CHECKOUT',

  CHECKOUT_ADD_EMAIL: 'CHECKOUT_ADD_EMAIL',

  SET_SPECIAL_INSTRUCTIONS: 'SET_SPECIAL_INSTRUCTIONS',

  CHECKOUT_ADDRESS_MODAL_OPEN: 'CHECKOUT_ADDRESS_MODAL_OPEN',
  CHECKOUT_ADDRESS_MODAL_CLOSE: 'CHECKOUT_ADDRESS_MODAL_CLOSE',

  CHECKOUT_CONTINUE_AS_GUEST: 'CHECKOUT_CONTINUE_AS_GUEST',
  CHECKOUT_IS_FORM_SUBMITTED: 'CHECKOUT_IS_FORM_SUBMITTED',
  CHECKOUT_IS_DELIVERY_CHOSEN: 'CHECKOUT_IS_DELIVERY_CHOSEN',

  FETCH_ORDER_DETAIL_REQUEST: 'FETCH_ORDER_DETAIL_REQUEST',
  FETCH_ORDER_DETAIL_SUCCESS: 'FETCH_ORDER_DETAIL_SUCCESS',
  FETCH_ORDER_DETAIL_FAILED: 'FETCH_ORDER_DETAIL_FAILED',
  CLEAR_ORDER_DETAILS: 'CLEAR_ORDER_DETAILS',

  RESET_TO_INITIAL_CHECKOUT_STEP: 'RESET_TO_INITIAL_CHECKOUT_STEP',

  RESET_CHECKOUT: 'RESET_CHECKOUT',
};

export default CHECKOUT_ACTIONS;
