export const storefrontPath = `api/v2/storefront`;

export const Routes = {
  productReviewPath: (id) => `${storefrontPath}/products/${id}/reviews`,
  reviewDetailPath: (productId, reviewId) =>
    `${storefrontPath}/products/${productId}/reviews/${reviewId}`,
  userReviewPath: (userId) => `${storefrontPath}/account/reviews`,
  reviewPath: (id) => `${storefrontPath}/reviews/${id}`,
  socialLoginPath: (provider) => `api/v1/spree_oauth/social_login/${provider}`,
  slideLocationsPath: (accessHash) =>
    `${storefrontPath}/slide_locations/${accessHash}`,
  userProductQuestionPath: (userId) =>
    `${storefrontPath}/account/product_questions_answers`,
  productQuestionPath: (id) =>
    `${storefrontPath}/products/${id}/product_questions`,
  productQuestionDetailPath: (productId, questionId) =>
    `${storefrontPath}/products/${productId}/product_questions/${questionId}`,
  paymentGatewayPath: (gateway) => `${storefrontPath}/${gateway}`,
  storeConfigPath: (storeId) => `${storefrontPath}/stores/${storeId}`,
  vendorProductListPath: (slug) => `${storefrontPath}/vendors/${slug}`,
};
