import React, { useState, useEffect } from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { Field } from "redux-form-normalize-on-blur";
import { Redirect, withRouter, Link } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  renderField,
  renderPasswordField,
} from "components/Form/FormFieldRender";
import Footer from "components/Footer/Footer";
import GoogleLoginButton from "components/Login/Google/GoogleLogin";
import FacebookLoginButton from "components/Login/Facebook/FacebookLogin";
import Layout from "components/Layout/Layout";

import {
  login,
  loginWithFacebookRequest,
  loginWithGoogleRequest,
} from "appRedux/auth/actions";
import { addEmailforCheckOut } from "appRedux/checkOut/actions";

import ROUTES from "constants/routes";
import AUTH_ACTIONS from "appRedux/auth/constants";
import trimmer from "utils/trimmer";
import { required } from "utils/fieldValidation";

const CheckoutLogin = ({ handleSubmit, email, submitting, fromCheckout }) => {
  const [showPassword, setShowPassword] = useState(false);
  const isAuthenticated = useSelector(
    (state) => state?.auth?.login?.isAuthenticated
  );
  const errors = useSelector((state) => state?.auth?.login?.errors);

  const isAuthenticating = useSelector(
    (state) => state?.auth?.login?.isAuthenticating
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({ type: AUTH_ACTIONS.LOGIN_FAILED, error: null });
    };
  }, []);

  if (isAuthenticated) {
    dispatch(addEmailforCheckOut(email));
    if (fromCheckout) {
      return <Redirect to={ROUTES.CHECKOUT.ADDRESS} />;
    }

    return <Redirect to={ROUTES.USER.PROFILE} />;
  }

  const submit = (values) => {
    dispatch(login(values.loginEmail, values.loginPassword));
  };

  const loginWithFacebook = (data) => {
    dispatch(loginWithFacebookRequest(data));
  };

  const loginWithGoogle = (data) => {
    dispatch(loginWithGoogleRequest(data));
  };

  return (
    <Layout
      title={fromCheckout ? "Registration | Checkout" : "Login"}
      description="Login to Trendline store"
      path={ROUTES.LOGIN.INDEX}
    >
      <div className={`${!fromCheckout ? "mb-5" : "mb-0"} container  mt-4`}>
        <form onSubmit={handleSubmit(submit)}>
          <div className="mb-md-4 row align-items-center">
            <h2 className="col-12 col-lg-6">
              <Translate value="login.title" />
            </h2>
          </div>
          <div className="position-relative p-sm-3 p-lg-5 login-card">
            <div className="row align-items-center">
              {isAuthenticating && (
                <div className="backdrop">
                  <div className="spinner">Loading...</div>
                </div>
              )}

              {errors && <p className="text-danger">{errors}</p>}
              <div className="col-12 col-lg-6">
                <div className="me-lg-4 mt-3 mt-sm-0">
                  <Field
                    name="loginEmail"
                    type="text"
                    placeholder={I18n.t("form.placeholder.email")}
                    component={renderField}
                    label={`${I18n.t("form.fields.email")}*`}
                    normalizeOnBlur={trimmer}
                    helpText={I18n.t("form.fields.emailHelperText")}
                    validate={[required]}
                  />

                  <Field
                    name="loginPassword"
                    placeholder={I18n.t("form.placeholder.password")}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    component={renderPasswordField}
                    label={`${I18n.t("form.fields.password")}*`}
                    normalizeOnBlur={trimmer}
                    validate={[required]}
                  />

                  <a href="#">
                    <p className="text-end">
                      <Translate value="login.forgetpassword" />
                    </p>
                  </a>

                  <button
                    disabled={submitting}
                    type="submit"
                    className="btn btn-primary rounded w-100 w-md-auto p-3 mb-3"
                  >
                    <Translate value="login.login" />
                  </button>
                </div>
              </div>
              <div className="col-12 col-lg-6 mb-3 text-center mt-sm-4">
                <p className="text-start text-muted">
                  <Translate value="login.orloginWith" />
                </p>
                <FacebookLoginButton loginWithFacebook={loginWithFacebook} />
                <GoogleLoginButton loginWithGoogle={loginWithGoogle} />
                <p className="text-start mt-4">
                  {" "}
                  <Translate value="login.newMember" />
                  <Link to={ROUTES.SIGNUP.INDEX}>
                    Register <Translate value="login.here" />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </form>
        <div />
      </div>
      {!fromCheckout && <Footer />}
    </Layout>
  );
};

const selector = formValueSelector("login");

export default withRouter(
  connect((state) => ({
    email: selector(state, "loginEmail"),
  }))(reduxForm({ form: "login" })(CheckoutLogin))
);
