import React, { useState } from 'react'
import { reduxForm, formValueSelector, reset } from 'redux-form'
import { useSelector, connect, useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Field } from 'redux-form-normalize-on-blur'

import Layout from 'components/Layout/Layout'

import {
  sendShippingMethodRequest,
  setSpecialInstruction,
} from 'appRedux/checkOut/actions'

import ROUTES from 'constants/routes'

const DeliveryOptionField = ({ value, id }) => {
  return (
    <>
      <Field
        className="form-check-input"
        type="radio"
        name="deliveryOption"
        id={id}
        value={value}
        component="input"
      />
      <label className="form-check-label" htmlFor={id}>
        {value}
      </label>
    </>
  )
}

const DeliveryOption = ({
  fromSide,
  deliveryOptioncurrentValue,
  special_instructionscurrentValue,
}) => {
  const [
    showAftereditDeliveryOption,
    setshowAftereditDeliveryOption,
  ] = useState(false)
  const deliveryOptionfromRedux = useSelector(
    (state) => state.checkout.deliveryOption,
  )
  const selectedDeliveryOption = useSelector(
    (state) => state.checkout.selectedDeliveryOption,
  )
  const special_instructions = useSelector(
    (state) => state.checkout.special_instructions,
  )

  let deliveryOptions = []

  if (deliveryOptionfromRedux) {
    deliveryOptionfromRedux.map((deliveryOption) => {
      deliveryOptions = [...deliveryOptions, ...deliveryOption.shipping_rates]
    })
  }
  const dispatch = useDispatch()
  const handleEditDeliveryOption = () => {
    const selectedDelveryOption = deliveryOptionfromRedux[0].shipping_rates.find(
      (deliveryOption) => {
        return deliveryOption.name === deliveryOptioncurrentValue
      },
    )

    setSpecialInstruction(special_instructionscurrentValue)
    dispatch(
      sendShippingMethodRequest({
        deliveryOptionId: deliveryOptionfromRedux[0].id,
        selectedDeliveryOption: { ...selectedDelveryOption },
        special_instructions: special_instructionscurrentValue,
      }),
    )
    setshowAftereditDeliveryOption(false)
  }

  const handleCancelDeliveryOption = () => {
    dispatch(reset('delivery'))
    setshowAftereditDeliveryOption(false)
  }

  return (
    <Layout title={'Delivery | Checkout'} path={ROUTES.CHECKOUT.DELIVERY}>
      {fromSide && !showAftereditDeliveryOption ? (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div>
              <p className="font-weight-bold">
                <Translate value="checkout.delivery.selectedDeliveryOption" /> :
              </p>
              <p>{selectedDeliveryOption && selectedDeliveryOption.name}</p>
            </div>
            <div>
              <p className="font-weight-bold">
                <Translate value="checkout.delivery.specialInstruction" /> :
              </p>
              <p>
                {special_instructions || (
                  <Translate value="checkout.delivery.noSpecialInstructionProvided" />
                )}
              </p>
            </div>
          </div>
          <button
            onClick={() => setshowAftereditDeliveryOption(true)}
            className="btn p-0 text-dark"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
        </div>
      ) : (
        <div className="p-3">
          <h5 className="font-weight-semibold mb-2">
            <Translate value="checkout.delivery.chooseDeliveryOptions" />
          </h5>
          <ul className="list-unstyled font-weight-normal mb-0">
            {deliveryOptions.map((option) => {
              return (
                <li key={option.id} className="form-check mb-1">
                  <DeliveryOptionField value={option.name} id={option.id} />
                </li>
              )
            })}
          </ul>
          <div className="mt-3">
            <h5 className="font-weight-semibold mb-2">
              {' '}
              <Translate value="checkout.delivery.specialInstruction" />
            </h5>

            <Field
              component="textarea"
              name="special_instructions"
              className="form-control"
            />
          </div>
          {fromSide && showAftereditDeliveryOption && (
            <div className="mt-2">
              <button
                onClick={handleEditDeliveryOption}
                className="btn btn-primary p-2"
              >
                <Translate value="checkout.delivery.button.saveChanges" />
              </button>
              <button
                onClick={handleCancelDeliveryOption}
                className="btn btn-secondary"
              >
                <Translate value="checkout.delivery.button.cancel" />
              </button>
            </div>
          )}
        </div>
      )}
    </Layout>
  )
}

const selector = formValueSelector('delivery')

const mapStateToProps = (state) => {
  const { deliveryOption, selectedDeliveryOption } = state.checkout

  let defaultDeliveryOption = ''

  if (deliveryOption.length !== 0) {
    const selectedDeliveryOptionFromApi =
      state.checkout.deliveryOption[0].shipping_rates.find((deliveryoption) => {
        return deliveryoption.selected === true
      }) || {}

    defaultDeliveryOption = selectedDeliveryOptionFromApi.name || ''
  }

  return {
    initialValues: {
      special_instructions: state.checkout.special_instructions,
      deliveryOption: selectedDeliveryOption.name || defaultDeliveryOption,
    },
    deliveryOptioncurrentValue: selector(state, 'deliveryOption'),
    special_instructionscurrentValue: selector(state, 'special_instructions'),
  }
}

export default connect(mapStateToProps)(
  reduxForm({ form: 'delivery', destroyOnUnmount: false })(DeliveryOption),
)
