import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faCheckCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { I18n } from "react-redux-i18n";
import FeatureStripItem from "./FeatureStrip/FeatureStripItem";

function FeatureStrip() {
  return (
    <aside className="py-5 bg-light">
      <div className="container">
        <div className="d-flex flex-column flex-md-row">
          <FeatureStripItem
            className="me-0 me-md-3 mb-3 mb-md-0"
            icon={<FontAwesomeIcon icon={faTruck} />}
            title={I18n.t("featureStrip.freeDelivery")}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing eld tempor incididunt ut labore et"
          />
          <FeatureStripItem
            className="me-0 me-md-3 mb-3 mb-md-0"
            icon={<FontAwesomeIcon icon={faCheckCircle} />}
            title={I18n.t("featureStrip.genuineProducts")}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor inc"
          />
          <FeatureStripItem
            icon={<FontAwesomeIcon icon={faUndo} />}
            title={I18n.t("featureStrip.easyReturn")}
            description="Lorem ipsum dolor sit adipiscing elit, sed do eiusmod tempor incididunt ut labore et"
          />
        </div>
      </div>
    </aside>
  );
}

export default FeatureStrip;
