import React from 'react';
import cx from 'classnames';
import { Translate } from 'react-redux-i18n';

import { useSelector } from 'react-redux';

function HorizontalSteps() {
  const { isformSumitted, isDeliveryChoosen } = useSelector(
    (state) => state.checkout
  );

  return (
    <div className='mb-3 shadow-sm d-none d-md-block'>
      <div className='md-stepper-horizontal'>
        <a className={cx('md-step  active ', { done: isformSumitted })}>
          <div className='md-step-circle'>
            <span>1</span>
          </div>
          <div className='md-step-title'>
            <Translate value='checkout.address.address' />
          </div>
          <div className='md-step-bar-left' />
          <div className='md-step-bar-right' />
        </a>
        <a
          className={cx('md-step', {
            active: isformSumitted,
            done: isDeliveryChoosen,
          })}
        >
          <div className='md-step-circle'>
            <span>2</span>
          </div>
          <div className='md-step-title'>
            <Translate value='checkout.delivery.title' />
          </div>
          <div className='md-step-bar-left' />
          <div className='md-step-bar-right' />
        </a>
        <a className={cx('md-step', { active: isDeliveryChoosen })}>
          <div className='md-step-circle'>
            <span>3</span>
          </div>
          <div className='md-step-title'>
            <Translate value='checkout.payment.title' />
          </div>
          <div className='md-step-bar-left' />
          <div className='md-step-bar-right' />
        </a>
      </div>
    </div>
  );
}

export default HorizontalSteps;
