import React from "react";
import { Link } from "react-router-dom";
import { I18n, Translate } from "react-redux-i18n";

import ROUTES from "constants/routes";
import { isEnvironmentVariableEnabled } from "utils/isEnvironmentVariableEnabled";

const AskQuestionButton = ({ handleClick }) => (
  <button
    onClick={handleClick}
    className="btn btn-primary w-auto py-3 px-4 shadow-sm mt-3"
  >
    {I18n.t("questions.askQuestionButton")}
  </button>
);

const CancelButton = ({ handleClick }) => (
  <button
    onClick={handleClick}
    className="btn btn-primary w-auto py-3 px-4 shadow-sm mt-3"
  >
    {I18n.t("questions.cancel")}
  </button>
);

const LoginToAddQuestion = ({ handleCancel }) => (
  <>
    <Link to={ROUTES.LOGIN.INDEX} className="btn btn-primary py-3">
      <Translate value="review.loginToAddProductQA" />
    </Link>
    <CancelButton handleClick={handleCancel} />
  </>
);

const ProductQuestionButton = ({
  toggleQuestionForm,
  handleToggleQuestionForm,
  setToggleQuestionForm,
}) => {
  const bearerToken = localStorage.getItem("bearerToken");

  const isFeatureEnabled =
    isEnvironmentVariableEnabled(
      process.env.REACT_APP_ENABLE_PRODUCT_QA_ANONYMOUS
    ) || bearerToken;

  if (isFeatureEnabled) {
    const translateText = !toggleQuestionForm
      ? I18n.t("questions.askQuestionButton")
      : I18n.t("questions.cancel");

    return (
      <button
        onClick={handleToggleQuestionForm}
        className="btn btn-primary w-auto py-3 px-4 shadow-sm mt-3"
      >
        {translateText}
      </button>
    );
  }

  if (!toggleQuestionForm) {
    return <AskQuestionButton handleClick={handleToggleQuestionForm} />;
  }

  return (
    <LoginToAddQuestion handleCancel={() => setToggleQuestionForm(false)} />
  );
};

export default ProductQuestionButton;
