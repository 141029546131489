import React from "react";
import { Translate } from "react-redux-i18n";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

const FACEBOOK_REQUEST_FIELDS = ["name", "email", "picture"];

export default ({ loginWihFacebook }) => {
  const responseFacebook = (response) => {
    if (response.status === "unknown") {
      return null;
    }

    const {
      graphDomain,
      accessToken,
      data_access_expiration_time,
      userId,
      email = null,
      name,
      picture,
    } = response;

    loginWihFacebook({
      provider: graphDomain,
      uid: userId,
      info: {
        email,
        name,
        image: picture.data.url,
      },
      credentials: {
        token: accessToken,
        expires_at: data_access_expiration_time,
        expires: true,
      },
      extra: {
        raw_info: {
          email,
          name,
          id: userId,
        },
      },
    });
  };

  return (
    <>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        callback={responseFacebook}
        fields={FACEBOOK_REQUEST_FIELDS.join(" ")}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            type="button"
            className="btn facebook-btn rounded w-100 w-md-auto p-3"
          >
            <span className="d-flex align-items-center justify-content-center">
              <FontAwesomeIcon className="me-1" icon={faFacebook} />
              <span>
                <Translate value="socials.facebook" />
              </span>
            </span>
          </button>
        )}
      />
    </>
  );
};
