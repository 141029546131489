import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, withRouter } from "react-router-dom";
import { Translate } from "react-redux-i18n";

import ProductItem from "components/Product/Item";
import Footer from "components/Footer/Footer";
import Breadcrumb from "components/Breadcrumb";
import CategoryList from "components/Category/CategoryList";
import Layout from "components/Layout/Layout";
import CategorySkeletion from "components/Skeleton/CategorySkeletion";

import {
  fetchProductsRequest,
  fetchcurrentPage,
  setSortBy,
  clearProductFilters,
  resetProducts,
} from "appRedux/products/actions";

import fallbackImage from "assets/images/loader/placeholder-image.jpg";

import { getParams } from "utils/queryString";
import { filterProducts } from "utils/products";

import ROUTES from "constants/routes";

const Product = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const { keywords, page } = getParams(search);
  const { replace } = useHistory();

  const {
    products,
    isLoading,
    error,
    pageMetaData,
    currentPage,
    sortBy,
    selectedFilters,
  } = useSelector((state) => state.products);

  const isFilterSelected =
    (selectedFilters.color && selectedFilters.color.length !== 0) ||
    (selectedFilters.size && selectedFilters.size.length !== 0) ||
    (selectedFilters["transparent-color"] &&
      selectedFilters["transparent-color"].length !== 0);

  useEffect(() => {
    return () => {
      dispatch(resetProducts());
    };
  }, []);

  useEffect(() => {
    dispatch(
      fetchProductsRequest({
        filter: { name: keywords ? decodeURI(keywords) : "" },
        pageNumber: currentPage,
        sortBy,
        perPage: 25,
      })
    );
  }, [keywords, currentPage, sortBy]);

  const pageNumberHandler = (fetchPage) => {
    dispatch(fetchcurrentPage(fetchPage));

    let search = `?keywords=${keywords}${
      fetchPage ? `&page=${fetchPage}` : ""
    }${`&sortBy=${sortBy}`}`;

    if (!keywords) {
      search = `${fetchPage ? `?page=${fetchPage}` : ""}`;
    }

    const newPath = `${pathname}${search}`;

    if (search) replace(newPath);
  };

  const handleSort = (value) => {
    dispatch(setSortBy(value));
  };

  useEffect(() => {
    dispatch(clearProductFilters());
  }, []);

  useEffect(() => {
    const nextPage = page || 1;

    pageNumberHandler(nextPage);
  }, [page, sortBy]);

  useEffect(() => {
    if (keywords || sortBy) {
      pageNumberHandler(1);
    }
  }, [keywords, sortBy]);

  useEffect(() => {
    return () => {
      dispatch(setSortBy("default"));
    };
  }, []);

  if (isLoading) {
    return <CategorySkeletion />;
  }

  let filteredProducts = [];

  if (products[keywords ? decodeURI(keywords) : "All Products"]) {
    filteredProducts = filterProducts(
      products[keywords ? decodeURI(keywords) : "All Products"].products,
      selectedFilters
    );
  }

  return (
    <Layout
      title={`${
        keywords ? decodeURI(keywords) : "All Products"
      } | Search Product`}
      description={`Search Result for ${
        keywords ? decodeURI(keywords) : "All Products"
      }`}
      path={ROUTES.PRODUCTS.INDEX}
    >
      <Breadcrumb
        secondStep={keywords ? decodeURI(keywords) : "All Products"}
      />
      <CategoryList
        sortBy={sortBy}
        handleSort={handleSort}
        pageMetaData={pageMetaData}
        page={currentPage}
        productLength={filteredProducts.length}
        pageNumberHandler={pageNumberHandler}
        category={`${keywords ? decodeURI(keywords) : "All Products"} (${
          filteredProducts.length
        })`}
      >
        {filteredProducts.length === 0 ? (
          <p className="mt-4">No product found</p>
        ) : (
          filteredProducts.map((product) => {
            const imageUrl = product.images
              ? product.images[0]?.styles[product.images[0].styles.length - 1]
                  .url
              : undefined;
            const image = imageUrl ? `${imageUrl}` : fallbackImage;

            return (
              <ProductItem
                key={product.id}
                className="col-6 col-md-4"
                image={image}
                title={product.name}
                categoryId={product.taxons[0].id}
                slug={product.slug}
                newPrice={product.display_price}
                link={`${ROUTES.PRODUCTS.INDEX}/${product.slug}`}
              />
            );
          })
        )}
      </CategoryList>

      <Footer />
    </Layout>
  );
};

export default withRouter(Product);
