import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { I18n, Translate } from "react-redux-i18n";
import SimpleBar from "simplebar-react";

import {
  closeCartModal,
  handleResetCartButtonClick,
} from "appRedux/cart/actions";

import ROUTES from "constants/routes";

import CartItem from "./CartItem";
import { getCartImageUrl } from "utils/utils";

function CartDropdown({ totalAmount, history, showCartDropdown }) {
  const { cartData } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const handleCheckout = () => {
    dispatch(closeCartModal());
    dispatch(handleResetCartButtonClick());

    if (cartData.item_count !== 0) {
      history.push(ROUTES.CHECKOUT.ADDRESS);
    }
  };

  const cartDropDownMenuClassName = showCartDropdown ? "show" : "";

  return (
    <div
      className={`dropdown-menu end-0 cart-menu mt-2 border-0 p-3 ${cartDropDownMenuClassName}`}
    >
      <ul className="ps-0">
        <SimpleBar style={{ maxHeight: 150 }}>
          {cartData.item_count === 0 ? (
            <p>
              <Translate value="cart.cartIsEmpty" />
            </p>
          ) : (
            cartData.line_items[0].name &&
            cartData.line_items.map((cartItem) => (
              <CartItem
                productUrl={`${ROUTES.PRODUCTS.INDEX}/${cartItem.slug}`}
                key={cartItem.id}
                id={cartItem.id}
                image={getCartImageUrl(cartItem)}
                altTag={cartItem.name}
                title={cartItem.name}
                price={cartItem.price}
                quantity={cartItem.quantity}
              />
            ))
          )}
        </SimpleBar>
      </ul>
      <div className="d-flex align-items-center justify-content-between">
        <p className="text-secondary mb-0">
          {I18n.t("cartDropdown.subTotal")} :
          <span className="price font-weight-bold"> {totalAmount}</span>
        </p>
        <Link to={ROUTES.CART.INDEX} className="cart-expand-link">
          <button
            onClick={() =>
              dispatch(closeCartModal(), dispatch(handleResetCartButtonClick()))
            }
            disabled={cartData.item_count === 0}
            className="btn btn-outline-light py-2 text-dark border"
          >
            {I18n.t("cartDropdown.expandCart")}
          </button>
        </Link>
      </div>

      <button
        onClick={handleCheckout}
        disabled={cartData.item_count === 0}
        className="btn btn-primary py-2 w-100 mt-3 shadow-sm"
      >
        <FontAwesomeIcon icon={faCreditCard} />{" "}
        {I18n.t("cartDropdown.checkOut")}
      </button>
    </div>
  );
}

export default withRouter(CartDropdown);
