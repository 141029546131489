import VENDOR_ACTIONS from "./constants";

const initialVendorState = {
  details: {},
  products: {
    list: [],
    pageMetaData: null,
    currentPage: 1,
    selectedFilters: {},
    filters: [],
    sortBy: "default",
  },
  error: false,
  isLoading: false,
};

export const vendorReducer = (state = initialVendorState, action) => {
  switch (action.type) {
    //vendor-prodcts
    case VENDOR_ACTIONS.FETCH_VENDOR_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case VENDOR_ACTIONS.FETCH_VENDOR_PRODUCTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case VENDOR_ACTIONS.FETCH_VENDOR_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          list: [...action.products],
        },
        isLoading: false,
        error: null,
      };

    case VENDOR_ACTIONS.FETCH_PAGE_META_DATA:
      return {
        ...state,
        products: {
          ...state.products,
          pageMetaData: { ...action.pageMetaData },
          filters: [...action.filters],
        },
      };

    case VENDOR_ACTIONS.CURRENT_PAGE:
      return {
        ...state,
        products: {
          ...state.products,
          currentPage: action.currentPage,
        },
      };

    case VENDOR_ACTIONS.SET_SORTBY:
      return {
        ...state,
        products: {
          ...state.products,
          sortBy: action.sortBy,
        },
      };

    case VENDOR_ACTIONS.FILTER_PRODUCTS_LIST:
      return {
        ...state,
        products: {
          ...state.products,
          selectedFilters: {
            ...state.selectedFilters,
            ...action.selectedFilters,
          },
        },
      };

    case VENDOR_ACTIONS.CLEAR_PRODUCT_FILTER:
      return {
        ...state,
        products: {
          ...state.products,
          selectedFilters: {},
        },
      };

    case VENDOR_ACTIONS.RESET_PRODUCTS:
      return {
        ...state,
        products: {
          ...initialVendorState.products,
        },
      };

    //vendor-details

    case VENDOR_ACTIONS.FETCH_VENDOR_DETAILS:
      return {
        ...state,
        details: { ...action.details },
      };

    default:
      return state;
  }
};

export default vendorReducer;
