import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, change, reset } from "redux-form";
import { Field } from "redux-form-normalize-on-blur";
import { I18n, Translate } from "react-redux-i18n";
import { withRouter } from "react-router-dom";

import { addAddressRequest } from "appRedux/checkOut/actions";

import { FormTextField, FormSelectField } from "components/Form/FormFields";
import Layout from "components/Layout/Layout";

import validate from "utils/fieldValidation";

import {
  getDefaultBillingAddress,
  getDefaultShippingAddress,
} from "appRedux/user/selectors";

import ROUTES from "constants/routes";

const getModalFormType = (modalType) => {
  if (modalType === "shippingAddress") return "ship_address_attributes";
  if (modalType === "billingAddress") return "bill_address_attributes";

  return modalType;
};

const BillingForm = ({ modalID, formType: renderFormType }) => {
  const stateAddress = useSelector((state) => state.checkout.stateAddress);
  const modalFormType = getModalFormType(modalID);
  const formType = renderFormType || modalFormType;

  switch (modalID) {
    case "email":
      return (
        <FormTextField
          name="email"
          label={`${I18n.t("checkout.address.email")}*`}
        />
      );

    case "phone":
      return (
        <FormTextField
          formType="ship_address_attributes"
          label={`${I18n.t("checkout.address.phone")}*`}
          name="phone"
        />
      );

    default:
      return (
        <>
          {!modalID && formType === "bill_address_attributes" && (
            <FormTextField
              name="email"
              label={`${I18n.t("checkout.address.email")}*`}
            />
          )}
          {!modalID && (
            <>
              <FormTextField
                formType={formType}
                label={`${I18n.t("checkout.address.firstName")}*`}
                name="firstname"
              />
              <FormTextField
                formType={formType}
                label={`${I18n.t("checkout.address.lastName")}*`}
                name="lastname"
              />
            </>
          )}
          <FormTextField
            formType={formType}
            label={`${I18n.t("checkout.address.address")}*`}
            name="address1"
          />
          <FormTextField
            formType={formType}
            label="Address (Contd)"
            name="address2"
          />
          <FormTextField
            formType={formType}
            label={`${I18n.t("checkout.address.city")}*`}
            name="city"
          />
          <FormSelectField
            formType={formType}
            label={`${I18n.t("checkout.address.stateProvince")}*`}
            name="state_name"
            options={stateAddress}
          />
          {/* TODO : district and region from api */}
          {/* <FormSelectField
            formType={formType}
            label={`${I18n.t('checkout.address.district')}*`}
            name='district'
            options={districts}
          />
          <FormSelectField
            formType={formType}
            label={`${I18n.t('checkout.address.region')}*`}
            name='region'
            options={regions}
          /> */}
          <FormTextField
            formType={formType}
            label={`${I18n.t("checkout.address.zipCode")}*`}
            name="zipcode"
          />
          {!modalID && (
            <FormTextField
              formType={formType}
              label={`${I18n.t("checkout.address.phone")}*`}
              name="phone"
            />
          )}
        </>
      );
  }
};

const CheckoutAddress = ({ handleSubmit, modalID, change, setismodalOpen }) => {
  const [showShippingForm, setShowShippingForm] = useState(false);

  const dispatch = useDispatch();

  if (modalID) {
    change("is_same_as_billing", false);
  }

  const submit = (values) => {
    if (values.is_same_as_billing) {
      values.ship_address_attributes = {
        ...values.bill_address_attributes,
      };
    }
    if (setismodalOpen) {
      setismodalOpen(false);
    }
    dispatch(addAddressRequest({ formData: values }));
  };

  return (
    <Layout title={"Address | Checkout"} path={ROUTES.CHECKOUT.ADDRESS}>
      <form
        onSubmit={handleSubmit(submit)}
        className="card border-0 shadow-sm p-3"
      >
        {modalID ? (
          <BillingForm modalID={modalID} />
        ) : (
          <>
            <h2 className="h4 font-weight-bold mb-3">
              <Translate value="orderDetails.billingAddress" />
            </h2>

            <BillingForm formType="bill_address_attributes" />

            <h2 className="h4 font-weight-bold mb-3">
              <Translate value="orderDetails.shippingAddress" />
            </h2>

            <div>
              <div className="mt-2 mb-3 form-check">
                <Field
                  name="is_same_as_billing"
                  type="checkbox"
                  component="input"
                  className="form-check-input"
                  id="exampleCheck1"
                  onChange={() => {
                    setShowShippingForm(!showShippingForm);
                  }}
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  <Translate value="orderSummary.shippingAndBilling.billToSameAddress" />
                </label>
              </div>

              {showShippingForm && (
                <BillingForm formType="ship_address_attributes" />
              )}
            </div>
          </>
        )}
        {modalID ? (
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setismodalOpen(false);
                dispatch(reset("checkoutForm"));
              }}
            >
              {I18n.t("checkout.address.close")}
            </button>
            <button type="submit" className="btn btn-primary">
              {I18n.t("checkout.address.saveChanges")}
            </button>
          </div>
        ) : (
          <button
            type="submit"
            className="btn btn-primary w-100 w-md-auto mb-3"
          >
            {I18n.t("checkout.address.submit")}
          </button>
        )}
      </form>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const defaultBillingAddress = getDefaultBillingAddress(state);
  const defaultShippingAddress = getDefaultShippingAddress(state);

  const prefill = Boolean(
    state.checkout.checkoutAddress.bill_address_attributes.firstname
  );
  const prefillAddress = prefill
    ? state.checkout.checkoutAddress
    : {
        bill_address_attributes: { ...defaultBillingAddress },
        ship_address_attributes: { ...defaultShippingAddress },
      };

  return {
    initialValues: {
      ...state.checkout.checkoutAddress, //  from redux store
      email:
        state.user.userInfoData.userInfo.email ||
        state.checkout.checkoutAddress.email,
      ship_address_attributes: {
        ...prefillAddress.ship_address_attributes,
        district: "Kathmandu",
        region: "Bagmati",
        country_iso: "NP",
      },
      bill_address_attributes: {
        ...prefillAddress.bill_address_attributes,
        district: "Kathmandu",
        region: "Bagmati",
        country_iso: "NP",
      },
    },
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: "checkoutForm",
      validate,
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(CheckoutAddress)
  )
);
