import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";

import ProfileCard from "components/User/ProfileCard";
import UserInfo from "components/User/UserInfo";
import ProfileSkeleton from "components/Skeleton/ProfileSkeleton";

import { userInfoRequest } from "appRedux/user/actions";

const Profile = () => {
  const dispatch = useDispatch();

  const {
    userInfoData: { userInfo, isLoading },
  } = useSelector((state) => state.user);

  const { email, default_billing_address, default_shipping_address } = userInfo;

  let userFirstName = "";
  let userLastname = "";

  useEffect(() => {
    dispatch(userInfoRequest());
  }, []);

  if (default_billing_address) {
    const { firstname, lastname } = default_billing_address;

    userFirstName = firstname;
    userLastname = lastname;
  }

  if (isLoading) {
    return <ProfileSkeleton repeat={5} />;
  }

  return (
    <div className="col-md-9">
      <UserInfo
        firstname={userFirstName}
        lastname={userLastname}
        email={email}
        className="bg-white mb-2"
      />
      <ProfileCard
        address={default_shipping_address}
        email={email}
        title={I18n.t("user.profile.contactInfo")}
        contentType={default_shipping_address ? "list" : "empty"}
        // button="EDIT" //todo: able to edit
      />
      <ProfileCard
        email={email}
        address={default_billing_address}
        title={I18n.t("user.profile.billingAddress")}
        contentType={default_shipping_address ? "list" : "empty"}
        // button="EDIT" //todo: able to edit
      />
      <ProfileCard
        email={email}
        address={default_shipping_address}
        title={I18n.t("user.profile.shippingAddress")}
        contentType={default_shipping_address ? "list" : "empty"}
        //  button="EDIT" //todo: able to edit
      />
    </div>
  );
};

export default Profile;
