export default {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',

  LOGIN_WITH_FACEBOOK_REQUEST: 'LOGIN_WITH_FACEBOOK_REQUEST',
  LOGIN_WITH_FACEBOOK_SUCCESS: 'LOGIN_WITH_FACEBOOK_SUCCESS',
  LOGIN_WITH_FACEBOOK_FAILED: 'LOGIN_WITH_FACEBOOK_FAILED',

  LOGIN_WITH_GOOGLE_REQUEST: 'LOGIN_WITH_GOOGLE_REQUEST',
  LOGIN_WITH_GOOGLE_SUCCESS: 'LOGIN_WITH_GOOGLE_SUCCESS',
  LOGIN_WITH_GOOGLE_FAILED: 'LOGIN_WITH_GOOGLE_FAILED',

  ORDER_TOKEN_REQUEST: 'ORDER_TOKEN_REQUEST',
  ORDER_TOKEN_SUCCESS: 'ORDER_TOKEN_SUCCESS',
  ORDER_TOKEN_FAILED: 'ORDER_TOKEN_FAILED',

  REGISTRATION_REQUEST: 'REGISTRATION_REQUEST',
  REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
  REGISTRATION_FAILED: 'REGISTRATION_FAILED',

  LOGOUT: 'LOGOUT',
};
