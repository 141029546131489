import React from "react";
import Skeleton from "react-loading-skeleton";

const DashboardOrdersSkeleton = ({ repeat }) => {
  const numberOfSkeleton = Array(repeat ?? 0).fill();

  return (
    <div className="col-md-9">
      <div className="d-flex justify-content-between align-items-center">
        <h2>
          <Skeleton height={20} width={100} />
        </h2>
        <div>
          <Skeleton height={20} width={100} className="me-3" />
          <Skeleton height={20} width={40} />
        </div>
      </div>
      <div className="card dashboard-card shadow-sm border-0 mb-2 py-3">
        {numberOfSkeleton.map(() => {
          return (
            <div className="border-bottom p-3 d-flex flex-column">
              <Skeleton width={100} />
              <Skeleton width={150} />
              <Skeleton width={200} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardOrdersSkeleton;
