import React from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form-normalize-on-blur";
import { I18n, Translate } from "react-redux-i18n";
import { withRouter, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import swal from "sweetalert";

import {
  createWishlistRequest,
  editWishlistRequest,
  removeWishlistRequest,
} from "appRedux/wishlist/actions";

import StickyFooter from "components/User/StickyFooter";
import Breadcrumb from "components/Breadcrumb";
import SideMenu from "components/User/SideMenu";
import { renderField } from "components/Form/FormFieldRender";

import { required } from "utils/fieldValidation";
import trimmer from "utils/trimmer";
import ROUTES from "constants/routes";

const WishlistForm = ({ handleSubmit, form, history }) => {
  const { accessHash } = useParams();

  const dispatch = useDispatch();

  const submit = (values) => {
    if (form === "edit") {
      dispatch(editWishlistRequest(values, accessHash, history));
    }
    if (form === "create") {
      dispatch(createWishlistRequest(values, history));
    }
  };

  const removeWishlistHandler = (accessHash) => {
    swal({
      title: I18n.t("user.myWishList.alert.title"),
      text: I18n.t("user.myWishList.alert.text"),
      icon: "warning",
      buttons: [
        I18n.t("user.myWishList.alert.button.cancel"),
        I18n.t("user.myWishList.alert.button.ok"),
      ],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(removeWishlistRequest({ wishlistId: accessHash }, history));
      }
    });
  };

  return (
    <div className="col-md-9 mt-4">
      <form onSubmit={handleSubmit(submit)}>
        <Field
          name="name"
          label={I18n.t("user.myWishList.form.name")}
          className="wishlist-field"
          type="text"
          component={renderField}
          normalizeOnBlur={trimmer}
          placeholder={`${I18n.t("user.myWishList.wishlist")} ${I18n.t(
            "user.myWishList.form.name"
          )}`}
          validate={[required]}
        />

        <div className="form-group">
          <Field
            type="checkbox"
            component="input"
            name="isPrivate"
            id="isPrivate"
          />
          &ensp;
          <label htmlFor="isPrivate">
            {" "}
            <Translate value="user.myWishList.form.isPrivate" />{" "}
          </label>
        </div>

        <div className="form-group">
          <Field
            type="checkbox"
            component="input"
            name="isDefault"
            id="isDefault"
          />
          &ensp;
          <label htmlFor="isDefault" className="mt-2">
            <Translate value="user.myWishList.form.isDefault" />
          </label>
        </div>

        <button
          type="submit"
          className="btn btn-primary mt-3 px-4 py-2 wishlist-button"
        >
          <Translate value="user.myWishList.form.save" />
        </button>
        {form === "edit" && (
          <button
            type="button"
            onClick={() => removeWishlistHandler(accessHash)}
            className="btn btn-primary mt-3 px-4 py-2 ms-2 wishlist-button delete-button"
          >
            <Translate value="user.myWishList.form.deleteWishlist" />
          </button>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const {
    wishlist: {
      wishlistDetails: { detail },
    },
  } = state.wishlist;

  let initialValues = {
    name: "",
    isPrivate: false,
    isDefault: false,
  };

  if (props.form === "edit") {
    initialValues = {
      name: detail?.name,
      isPrivate: detail?.is_private,
      isDefault: detail?.is_default,
    };
  }

  return {
    initialValues,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: "wishlistForm",
    })(WishlistForm)
  )
);
