import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

import { USER, LOGIN } from "constants/routes";

const WishlistButton = ({ wishlists, quantity, variantId, onClick }) => {
  const [isAddedInWishlist, setAddInWishlist] = useState(false);

  useEffect(() => {
    if (wishlists.length === 0) return;

    wishlists.forEach(({ variant_included }) => {
      if (variant_included) setAddInWishlist(true);

      return;
    });
  }, [wishlists]);

  const isAddedWishListClass = isAddedInWishlist ? "text-primary" : "";

  return (
    <div className="dropdown h-100">
      <button
        className="btn btn-light py-3 mt-3 mt-md-0 h-100 w-100 d-flex align-items-center justify-content-center"
        type="button"
        id="wishlistBottom"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <FontAwesomeIcon icon={faHeart} className={isAddedWishListClass} />
      </button>
      <ul className="dropdown-menu" aria-labelledby="wishlistBottom">
        {!localStorage.getItem("bearerToken") && (
          <li className="dropdown-item">
            <Link to={LOGIN.INDEX}>Login To Add Item To Wishlist</Link>
          </li>
        )}
        {wishlists.length ? (
          wishlists.map(({ id, name, token: wishlistId }) => {
            return (
              <li
                key={id}
                className="dropdown-item"
                onClick={() =>
                  onClick({
                    variantId,
                    quantity,
                    wishlistId,
                  })
                }
              >
                {name}
              </li>
            );
          })
        ) : (
          <li className="dropdown-item">
            <Link to={USER.WISHLIST.ADD}>
              <span className="text-muted"> No wishlist</span>
              <div>Create Wishlist</div>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

WishlistButton.propTypes = {};

export default WishlistButton;
