import PRODUCTS_ACTIONS from "./constants";

const initialState = {
  products: {},
  isLoading: true,
  error: false,
  pageMetaData: null,
  currentPage: 1,
  selectedFilters: {},
  filters: [],
  sortBy: "default",
  searchData: {
    isLoading: false,
    error: false,
    suggestionList: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_ACTIONS.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        error: false,
        isLoading: true,
      };
    case PRODUCTS_ACTIONS.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: { ...state.products, ...action.fetchedProducts },
        error: false,
        isLoading: false,
      };

    case PRODUCTS_ACTIONS.FETCH_PRODUCTS_FAILED:
      return {
        ...state,
        error: true,
        isLoading: false,
      };

    case PRODUCTS_ACTIONS.FETCH_PAGE_META_DATA:
      return {
        ...state,
        pageMetaData: { ...action.pageMetaData },
        filters: [...action.filters],
      };

    case PRODUCTS_ACTIONS.CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };

    case PRODUCTS_ACTIONS.SET_SORTBY:
      return {
        ...state,
        sortBy: action.sortBy,
      };

    case PRODUCTS_ACTIONS.FILTER_PRODUCTS_LIST:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ...action.selectedFilters,
        },
      };

    case PRODUCTS_ACTIONS.CLEAR_PRODUCT_FILTER:
      return {
        ...state,
        selectedFilters: {},
      };

    case PRODUCTS_ACTIONS.SEARCH_SUGGESTIONS_REQUEST:
      return {
        ...state,
        searchData: {
          ...state.searchData,
          isLoading: true,
          error: false,
        },
      };
    case PRODUCTS_ACTIONS.SEARCH_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        searchData: {
          isLoading: false,
          error: false,
          suggestionList: action.suggestionList,
        },
      };

    case PRODUCTS_ACTIONS.SEARCH_SUGGESTIONS_FAILED:
      return {
        ...state,
        searchData: {
          ...state.searchData,
          isLoading: false,
          error: action.error,
        },
      };

    case PRODUCTS_ACTIONS.RESET_SEARCH_SUGGESTIONS:
      return {
        ...state,
        searchData: {
          suggestionList: [],
          isLoading: false,
          error: false,
        },
      };

    case PRODUCTS_ACTIONS.RESET_PRODUCTS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
