import fallbackImage from "assets/images/loader/placeholder-image.jpg";

const extractImage = (images) => {
  let imageUrl;

  if (images?.length) {
    imageUrl = images[0]?.styles[images[0].styles.length - 1].url;
  }

  const image = imageUrl ? `${imageUrl}` : fallbackImage;

  return image;
};

export const extractImages = (images) => {
  if (!images || !images.length) return [];

  const imageUrls = [];

  images.map((image) => {
    imageUrls.push(image.styles[image.styles.length - 1].url);
  });

  return imageUrls;
};

export default extractImage;
