import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import WishlistApi from "api/wishlist/wishlistApi";
import { normalizeData } from "services/jsonaDataFormatter";

import {
  fetchWishlistSuccess,
  createWishlistSuccess,
  editWishlistSuccess,
  fetchDefaultWishlistSuccess,
  addToWishlistSuccess,
  editWishlistProductSuccess,
  removeWishlistProductSuccess,
  fetchWishlistDetailsSuccess,
  removeWishlistSuccess,
  fetchWishlistFail,
  createWishlistFail,
  editWishlistProductFail,
  editWishlistFail,
  removeWishlistProductFail,
  fetchWishlistDetailsFail,
  removeWishlistFail,
  addToWishlistFail,
  fetchDefaultWishlistFail,
} from "./actions";

import { USER } from "constants/routes";
import { WISHLIST_ACTIONS, WISHLIST_PRODUCT_ACTIONS } from "./constants";

/**
 * create a wishlist
 */
function* createWishlistRequest({ name, isPrivate, isDefault, history }) {
  try {
    const wishlistData = yield call(WishlistApi.createWishlist, {
      name,
      isPrivate,
      isDefault,
    });

    if (wishlistData.isSuccess()) {
      const wishlist = normalizeData(wishlistData.success());

      yield put(createWishlistSuccess());

      history.push(
        USER.WISHLIST.addwishlistId(USER.WISHLIST.SHOW, wishlist.token)
      );
    } else {
      throw wishlistData.fail();
    }
  } catch (e) {
    yield put(createWishlistFail(e));
  }
}

/**
 * edit a wishlist
 */
function* editWishlistRequest({
  name,
  isPrivate,
  isDefault,
  wishlistId,
  history,
}) {
  try {
    const wishlistData = yield call(WishlistApi.editWishlist, {
      name,
      isPrivate,
      isDefault,
      wishlistId,
    });

    if (wishlistData.isSuccess()) {
      yield put({
        type: WISHLIST_ACTIONS.FETCH_WISHLIST_DETAIL_REQUEST,
        wishlistId,
      });
      yield put(editWishlistSuccess());
      history.push(USER.WISHLIST.addwishlistId(USER.WISHLIST.SHOW, wishlistId));
    } else {
      throw wishlistData.fail();
    }
  } catch (e) {
    yield put(editWishlistFail(e));
  }
}

/**
 * remove a wishlist
 */
function* removeWishlistRequest({ wishlistId, history }) {
  try {
    const wishlistData = yield call(WishlistApi.removeWishlist, wishlistId);

    if (wishlistData.isSuccess()) {
      yield put({ type: WISHLIST_ACTIONS.RESET_WISHLIST });
      yield put(removeWishlistSuccess());
      history.push(USER.WISHLIST.INDEX);
    } else {
      throw wishlistData.fail();
    }
  } catch (e) {
    yield put(removeWishlistFail(e));
  }
}

/**
 * fetch wishlists
 */
function* fetchWishlistRequest(variantId) {
  try {
    const wishlistData = yield call(WishlistApi.fetchAllWishlists, variantId);

    if (wishlistData.isSuccess()) {
      const wishlists = normalizeData(wishlistData.success());
      const metaData = wishlistData.success().meta;

      yield put(fetchWishlistSuccess({ wishlists, metaData }));
    } else {
      throw wishlistData.fail();
    }
  } catch (e) {
    yield put(fetchWishlistFail(e));
  }
}

/**
 * fetch wishlist details
 */

function* fetchWishlistDetailRequest({ wishlistId }) {
  try {
    const wishlistData = yield call(
      WishlistApi.fetchWishlistDetails,
      wishlistId
    );

    if (wishlistData.isSuccess()) {
      const wishlistDetails = normalizeData(wishlistData.success());
      const wishedProduct = wishlistDetails.wished_items;

      yield put(
        fetchWishlistDetailsSuccess({
          wishlistDetails,
          wishlistProducts: wishedProduct,
        })
      );
    } else {
      throw wishlistData.fail();
    }
  } catch (e) {
    yield put(fetchWishlistDetailsFail(e));
  }
}
/**
 * fetch default wishlist details
 */

function* fetchDefaultWishlistRequest(variantId) {
  try {
    const wishlistData = yield call(
      WishlistApi.fetchDefaultWishlist,
      variantId
    );

    if (wishlistData.isSuccess()) {
      const wishlistDetails = normalizeData(wishlistData.success());

      const wishedProduct = wishlistDetails.wished_items;

      yield put(
        fetchDefaultWishlistSuccess({
          wishlistDetails,
          wishlistProducts: wishedProduct,
        })
      );
    } else {
      throw wishlistData.fail();
    }
  } catch (e) {
    yield put(fetchDefaultWishlistFail(e));
  }
}

/**
 * add products to wishlist
 */

function* addToWishlistRequest({
  variantId,
  wishlistId,
  remark,
  quantity,
  from,
  moveData,
}) {
  try {
    const wishlistData = yield call(
      WishlistApi.addToWishlist,
      from
        ? moveData
        : {
            variantId,
            wishlistId,
            remark,
            quantity,
          }
    );

    if (wishlistData.isSuccess()) {
      yield put(addToWishlistSuccess(from));
      if (from) {
        yield put({
          type: WISHLIST_ACTIONS.FETCH_WISHLIST_DETAIL_REQUEST,
          wishlistId: from,
        });
      }
    } else {
      throw wishlistData.fail();
    }
  } catch (e) {
    yield put(addToWishlistFail(e));
  }
}

/**
 * edit product of wishlist
 */

function* editWishlistProductRequest({
  variantId,
  wishlistId,
  productId,
  remark,
  quantity,
}) {
  try {
    const wishlistData = yield call(WishlistApi.editWishlistProduct, {
      variantId,
      wishlistId,
      productId,
      remark,
      quantity,
    });

    if (wishlistData.isSuccess()) {
      yield put(editWishlistProductSuccess());
    } else {
      throw wishlistData.fail();
    }
  } catch (e) {
    yield put(editWishlistProductFail(e));
  }
}

/**
 * remove product from wishlist
 */

function* removeWishlistProductRequest({
  productId,
  wishlistId,
  from,
  moveData,
}) {
  try {
    const wishlistData = yield call(WishlistApi.removeWishlistProduct, {
      productId,
      wishlistId,
    });

    if (wishlistData.isSuccess()) {
      yield put(removeWishlistProductSuccess(from));
      if (moveData) {
        return yield put({
          type: WISHLIST_PRODUCT_ACTIONS.ADD_TO_WISHLIST_REQUEST,
          moveData,
          from: wishlistId,
        });
      }

      yield put({
        type: WISHLIST_ACTIONS.FETCH_WISHLIST_DETAIL_REQUEST,
        wishlistId,
      });
    } else {
      throw wishlistData.fail();
    }
  } catch (e) {
    yield put(removeWishlistProductFail(e));
  }
}

/**
 * Saga action listeners
 */

//wishlists
export function* watchCreateWishlistRequest() {
  yield takeEvery(
    WISHLIST_ACTIONS.CREATE_WISHLIST_REQUEST,
    createWishlistRequest
  );
}

export function* watchEditWishlistRequest() {
  yield takeEvery(WISHLIST_ACTIONS.EDIT_WISHLIST_REQUEST, editWishlistRequest);
}

export function* watchFetchWishlistRequest() {
  yield takeEvery(
    WISHLIST_ACTIONS.FETCH_WISHLIST_REQUEST,
    fetchWishlistRequest
  );
}

export function* watchRemoveWishlistRequest() {
  yield takeEvery(
    WISHLIST_ACTIONS.REMOVE_WISHLIST_REQUEST,
    removeWishlistRequest
  );
}

export function* watchFetchWishlistDetailsRequest() {
  yield takeEvery(
    WISHLIST_ACTIONS.FETCH_WISHLIST_DETAIL_REQUEST,
    fetchWishlistDetailRequest
  );
}

export function* watchFetchDefaultWishlistRequest() {
  yield takeEvery(
    WISHLIST_ACTIONS.FETCH_DEFAULT_WISHLIST_REQUEST,
    fetchDefaultWishlistRequest
  );
}

//wishlist-products
export function* watchAddToWishlistRequest() {
  yield takeEvery(
    WISHLIST_PRODUCT_ACTIONS.ADD_TO_WISHLIST_REQUEST,
    addToWishlistRequest
  );
}

export function* watchEditWishlistProductRequest() {
  yield takeEvery(
    WISHLIST_PRODUCT_ACTIONS.EDIT_WISHLIST_PRODUCTS_REQUEST,
    editWishlistProductRequest
  );
}

export function* watchRemoveWishlistProductRequest() {
  yield takeEvery(
    WISHLIST_PRODUCT_ACTIONS.REMOVE_FROM_WISHLIST_REQUEST,
    removeWishlistProductRequest
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateWishlistRequest),
    fork(watchFetchWishlistRequest),
    fork(watchEditWishlistRequest),
    fork(watchRemoveWishlistRequest),
    fork(watchFetchWishlistDetailsRequest),
    fork(watchFetchDefaultWishlistRequest),
    fork(watchAddToWishlistRequest),
    fork(watchRemoveWishlistProductRequest),
    fork(watchEditWishlistProductRequest),
  ]);
}
