import SLIDER_ACTIONS from './constants'

export const fetchSlideLocationRequest = (slideLocationId) => ({
  type: SLIDER_ACTIONS.FETCH_SLIDE_LOCATION_REQUEST,
  slideLocationId,
})

export const fetchSlideLocationFail = (error) => ({
  type: SLIDER_ACTIONS.FETCH_SLIDE_LOCATION_FAILED,
  error,
})

export const fetchSlideLocationSuccess = (sliderData) => ({
  type: SLIDER_ACTIONS.FETCH_SLIDE_LOCATION_SUCCESS,
  sliderData,
})
