import React from "react";

const ListItem = ({ children, className }) => {
  return (
    <ul className={`list-unstyled shipping-address lh-lg ${className}`}>
      {children}
    </ul>
  );
};

export default ListItem;
