import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import TaxonApi from "api/taxon/taxonApi";

import { normalizeData } from "services/jsonaDataFormatter";

import { fetchTaxonsSuccess, fetchTaxonsFail } from "./actions";
import TAXON_ACTIONS from "./constants";

/**
 * Fetch all taxons
 */

function* fetchTaxons() {
  try {
    const fetchTaxons = yield call(TaxonApi.fetchTaxons);

    const data = normalizeData(fetchTaxons.success());

    yield put(fetchTaxonsSuccess(data));
  } catch (e) {
    yield put(fetchTaxonsFail(e));
  }
}

/**
 * Saga action listeners
 */

export function* watchFetchTaxonsRequest() {
  yield takeEvery(TAXON_ACTIONS.FETCH_TAXONS_REQUEST, fetchTaxons);
}

export default function* rootSaga() {
  yield all([fork(watchFetchTaxonsRequest)]);
}
