import { Http } from '@spree/storefront-api-v2-sdk';

import { Routes } from 'lib/spreeSdk/routes';

export default class PaymentGateway extends Http {
  async payWithKhalti(token, gateway, params) {
    const response = await this.spreeResponse(
      'post',
      Routes.socialLoginPath(gateway),
      token, // pass token for api call
      params
    );

    return response;
  }
}
