import React from 'react'
import cx from 'classnames'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const renderField = ({
  input,
  label,
  helpText,
  name,
  placeholder,
  className,
  type,
  meta: { touched, error },
}) => {
  let classNames = ['mb-3']

  classNames.push(className)

  return (
    <div className={classNames.join(' ')}>
      <label className="mb-1">{label}</label>
      <div>
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          id={name}
          className={cx('form-control', {
            'border border-danger shadow-none': touched && error,
          })}
        />
        <div id={`${name}Help`} className="form-text">
          {touched && error ? (
            <p className="text-danger">{error}</p>
          ) : (
            <p>{helpText}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export const renderTextAreaField = ({
  input,
  label,
  helpText,
  name,
  placeholder,
  meta: { touched, error },
}) => {
  return (
    <div className="mb-3">
      <label className="mb-1">{label}</label>
      <div>
        <textarea
          {...input}
          placeholder={placeholder}
          id={name}
          className={cx('form-control text-area', {
            'border border-danger shadow-none': touched && error,
          })}
        />
        <div id={`${name}Help`} className="form-text">
          {touched && error ? (
            <p className="text-danger">{error}</p>
          ) : (
            <p>{helpText}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export const renderSelectField = ({
  input,
  stateAddress,
  label,
  helpText,
  placeholder,
  className,
  meta: { touched, error },
  options,
}) => {
  let classNames = ['mb-3']

  classNames.push(className)

  return (
    <div className={classNames.join(' ')}>
      <label htmlFor={input.name} className="mb-1">
        {label}
      </label>
      <div>
        <select
          {...input}
          placeholder={placeholder}
          id={input.name}
          className={cx('form-control text-muted font-weight-light text-md', {
            'border border-danger shadow-none': touched && error,
          })}
        >
          <option value="">
            {input.name === 'bill_address_attributes.state_name' ||
            input.name === 'ship_address_attributes.state_name'
              ? `Please select a region, state or province`
              : `Please Select Option`}
          </option>
          {options.map((option) => {
            return (
              <option
                key={option.id ? option.id : option}
                value={option.name ? option.name : option}
              >
                {option.name ? option.name : option}
              </option>
            )
          })}
        </select>

        <div id={`${input.name}Help`} className="form-text">
          {touched && error ? (
            <p className="text-danger">{error}</p>
          ) : (
            <p>{helpText}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export const renderPasswordField = ({
  input,
  label,
  helpText,
  name,
  showPassword,
  setShowPassword,
  placeholder,
  meta: { touched, error },
}) => {
  const handleToggle = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="mb-3">
      <label className="mb-1">{label}</label>
      <div>
        <div className="form-group">
          <div>
            <input
              {...input}
              placeholder={placeholder}
              type={showPassword ? 'text' : 'password'}
              id={name}
              className={cx('form-control', {
                'border border-danger shadow-none': touched && error,
              })}
            />
            <FontAwesomeIcon
              onClick={handleToggle}
              className="field-icon"
              icon={showPassword ? faEye : faEyeSlash}
            />
          </div>
        </div>
        <div id={`${name}Help`} className="form-text">
          {touched && error ? (
            <p className="text-danger">{error}</p>
          ) : (
            <p>{helpText}</p>
          )}
        </div>
      </div>
    </div>
  )
}
