import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import ProductQuestionApi from "api/productQuestion/productQuestion";
import { normalizeData } from "services/jsonaDataFormatter";

import { USER } from "constants/routes";

import {
  fetchAllProductQuestions,
  fetchAllProductQuestionsSuccess,
  editProductQuestionSuccess,
  createProductQuestionSuccess,
  removeProductQuestionSuccess,
  createProductQuestionFail,
  editProductQuestionFail,
  removeProductQuestionFail,
} from "./actions";
import { fetchQuestionsRequest } from "appRedux/user/actions";

import PRODUCT_QUESTION_ACTIONS from "./constants";

/**
 * Fetch all product questions
 */

function* fetchAllProductQuestionsRequest({ productId }) {
  try {
    const productQuestionsData = yield call(
      ProductQuestionApi.fetchAllQuestion,
      productId
    );

    if (productQuestionsData.isSuccess()) {
      const productQuestions = normalizeData(productQuestionsData.success());

      yield put(fetchAllProductQuestionsSuccess(productQuestions));
    } else {
      throw productQuestionsData.fail();
    }
  } catch (e) {
    console.log(e);
  }
}

/**
 * Create product questions
 */

function* createProductQuestions({
  productId,
  content,
  userId,
  isAnonymous,
  fullName,
  email,
}) {
  try {
    const createProductQuestionsData = yield call(
      ProductQuestionApi.createQuestion,
      {
        productId,
        content,
        userId,
        isAnonymous,
        fullName,
        email,
      }
    );

    if (createProductQuestionsData.isSuccess()) {
      yield put(fetchAllProductQuestions(productId));
      yield put(createProductQuestionSuccess());
    } else {
      throw createProductQuestionsData.fail();
    }
  } catch (e) {
    yield put(createProductQuestionFail(e));
  }
}

/**
 * Edit product questions
 */

function* editProductQuestions({
  content,
  questionId,
  productId,
  isAnonymous,
  history,
  fullName,
  email,
}) {
  try {
    const editProductQuestionsData = yield call(
      ProductQuestionApi.editQuestion,
      {
        productId,
        content,
        questionId,
        isAnonymous,
        fullName,
        email,
      }
    );

    if (editProductQuestionsData.isSuccess()) {
      yield put(editProductQuestionSuccess());
      history.push(USER.QUESTION.INDEX);
    } else {
      throw editProductQuestionsData.fail();
    }
  } catch (e) {
    yield put(editProductQuestionFail(e));
  }
}

/**
 * Remove product questions
 */

function* removeProductQuestions({
  questionId,
  productId,
  currentPage,
  userId,
}) {
  try {
    const removeProductQuestionsData = yield call(
      ProductQuestionApi.removeQuestion,
      {
        productId,
        questionId,
      }
    );

    if (removeProductQuestionsData.isSuccess()) {
      yield put(removeProductQuestionSuccess());
      yield put(fetchQuestionsRequest({ currentPage, userId }));
    } else {
      throw removeProductQuestionsData.fail();
    }
  } catch (e) {
    yield put(removeProductQuestionFail(e));
  }
}

/**
 * Saga action listeners
 */

export function* watchFetchAllProductQuestionsRequest() {
  yield takeEvery(
    PRODUCT_QUESTION_ACTIONS.FETCH_ALL_PRODUCT_QUESTIONS_REQUEST,
    fetchAllProductQuestionsRequest
  );
}

export function* watchCreateProductQuestionsRequest() {
  yield takeEvery(
    PRODUCT_QUESTION_ACTIONS.CREATE_PRODUCT_QUESTION_REQUEST,
    createProductQuestions
  );
}

export function* watchEditProductQuestionsRequest() {
  yield takeEvery(
    PRODUCT_QUESTION_ACTIONS.EDIT_PRODUCT_QUESTION_REQUEST,
    editProductQuestions
  );
}

export function* watchRemoveProductQuestionsRequest() {
  yield takeEvery(
    PRODUCT_QUESTION_ACTIONS.REMOVE_PRODUCT_QUESTION_REQUEST,
    removeProductQuestions
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchAllProductQuestionsRequest),
    fork(watchCreateProductQuestionsRequest),
    fork(watchEditProductQuestionsRequest),
    fork(watchRemoveProductQuestionsRequest),
  ]);
}
