import React from "react";
import { I18n } from "react-redux-i18n";
import { Field } from "redux-form-normalize-on-blur";

import { renderField } from "components/Form/FormFieldRender";

import { required } from "utils/fieldValidation";

const ReviewNameField = ({ isFullNamePresent }) => {
  const bearerToken = localStorage.getItem("bearerToken");

  if (bearerToken && isFullNamePresent) return null;

  return (
    <Field
      name="name"
      type="text"
      component={renderField}
      label={`${I18n.t("form.fields.fullName")} *`}
      validate={[required]}
    />
  );
};

export default ReviewNameField;
