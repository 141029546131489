import swal from 'sweetalert';

export const responseInterceptor = () => { };

export const errorInterceptor = (error) => {
    if (error.response.status == 401) {
        localStorage.clear();
        swal('Session has expired. Please login to continue').then(() => {
            window.location.href = '/login';
        });
    }
};
