import React from "react";
import { I18n } from "react-redux-i18n";

import OrderSummary from "components/Cart/OrderSummary";
import CircularSteps from "components/ProgressSteps/CircularSteps";
import HorizontalSteps from "components/ProgressSteps/HorizontalSteps";
import Footer from "components/Footer/Footer";
import Breadcrumb from "components/Breadcrumb";

import ROUTES from "constants/routes";

const CheckoutPage = ({ children, isformSumitted, isDeliveryChoosen }) => {
  return (
    <div>
      <Breadcrumb
        secondStep="Checkout"
        thirdStep={
          !isformSumitted
            ? "Address"
            : !isDeliveryChoosen
            ? "Delivery"
            : "Payment"
        }
        url={ROUTES.CHECKOUT.INDEX}
      />
      <section className="py-4 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8 pe-md-5 mb-3 mb-md-0">
              <CircularSteps
                isformSumitted={isformSumitted}
                isDeliveryChoosen={isDeliveryChoosen}
              />
              <HorizontalSteps
                isformSumitted={isformSumitted}
                isDeliveryChoosen={isDeliveryChoosen}
              />
              {children}
            </div>
            <div className="col-md-4">
              <OrderSummary />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CheckoutPage;
