import PRODUCT_ACTIONS from './constants';

export const fetchProductDetail = (slug) => ({
  type: PRODUCT_ACTIONS.FETCH_PRODUCT_DETAIL_REQUEST,
  slug,
});

export const fetchProductDetailFail = () => ({
  type: PRODUCT_ACTIONS.FETCH_PRODUCT_DETAIL_FAILED,
});

export const fetchProductDetailSuccess = (product) => ({
  type: PRODUCT_ACTIONS.FETCH_PRODUCT_DETAIL_SUCCESS,
  product,
});
