import React from "react";
import { Translate } from "react-redux-i18n";

const OrderedProductDetails = ({
  name,
  image,
  displayTotal,
  quantity,
  itemPrice,
  variants,
}) => {
  return (
    <div className="row mt-4">
      <div className="col-lg-2 text-lg-start text-center">
        <img className="img-thumbnail" alt={name} src={image} />
      </div>
      <div className="col-lg-10 mt-4 mt-lg-0">
        <h2 className="">{name}</h2>
        <span className="text-break text-uppercase">
          <ul className="text-center text-lg-start list-unstyled mb-0">
            <li className="me-2">{variants}</li>
          </ul>
        </span>
        <div className="row mt-2 align-items-center justify-content-between ">
          <div className="col-md-3">
            <div className="d-lg-block d-inline-block">
              <span>
                <Translate value="orderDetails.product.price" />{" "}
              </span>
            </div>
            <span className="font-weight-bold"> {itemPrice}</span>
          </div>
          <div className="col-md-3">
            <div className="d-lg-block d-inline-block">
              <span>
                <Translate value="orderDetails.product.quantity" />
              </span>
            </div>
            <span className="font-weight-bold"> {quantity}</span>
          </div>
          <div className="col-md-3">
            <div className="d-lg-block d-inline-block">
              <span>
                <Translate value="orderDetails.product.total" />
              </span>
            </div>
            <span className="font-weight-bold "> {displayTotal}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderedProductDetails;
