const WISHLIST_ACTIONS = {
  FETCH_WISHLIST_REQUEST: 'FETCH_WISHLIST_REQUEST',
  FETCH_WISHLIST_FAILED: 'FETCH_WISHLIST_FAILED',
  FETCH_WISHLIST_SUCCESS: 'FETCH_WISHLIST_SUCCESS',

  FETCH_DEFAULT_WISHLIST_REQUEST: 'FETCH_DEFAULT_WISHLIST_REQUEST',
  FETCH_DEFAULT_WISHLIST_FAILED: 'FETCH_DEFAULT_WISHLIST_FAILED',
  FETCH_DEFAULT_WISHLIST_SUCCESS: 'FETCH_DEFAULT_WISHLIST_SUCCESS',

  FETCH_WISHLIST_DETAIL_REQUEST: 'FETCH_WISHLIST_DETAIL_REQUEST',
  FETCH_WISHLIST_DETAIL_FAILED: 'FETCH_WISHLIST_DETAIL_FAILED',
  FETCH_WISHLIST_DETAIL_SUCCESS: 'FETCH_WISHLIST_DETAIL_SUCCESS',

  CREATE_WISHLIST_REQUEST: 'CREATE_WISHLIST_REQUEST',
  CREATE_WISHLIST_FAILED: 'CREATE_WISHLIST_FAILED',
  CREATE_WISHLIST_SUCCESS: 'CREATE_WISHLIST_SUCCESS',

  EDIT_WISHLIST_REQUEST: 'EDIT_WISHLIST_REQUEST',
  EDIT_WISHLIST_FAILED: 'EDIT_WISHLIST_FAILED',
  EDIT_WISHLIST_SUCCESS: 'EDIT_WISHLIST_SUCCESS',

  REMOVE_WISHLIST_REQUEST: 'REMOVE_WISHLIST_REQUEST',
  REMOVE_WISHLIST_FAILED: 'REMOVE_WISHLIST_FAILED',
  REMOVE_WISHLIST_SUCCESS: 'REMOVE_WISHLIST_SUCCESS',

  RESET_WISHLIST: 'RESET_WISHLIST',
}

const WISHLIST_PRODUCT_ACTIONS = {
  FETCH_WISHLIST_PRODUCTS_REQUEST: 'FETCH_WISHLIST_PRODUCTS_REQUEST',
  FETCH_WISHLIST_PRODUCTS_FAILED: 'FETCH_WISHLIST_PRODUCTS_FAILED',
  FETCH_WISHLIST_PRODUCTS_SUCCESS: 'FETCH_WISHLIST_PRODUCTS_SUCCESS',

  ADD_TO_WISHLIST_REQUEST: 'ADD_TO_WISHLIST_REQUEST',
  ADD_TO_WISHLIST_FAILED: 'ADD_TO_WISHLIST_FAILED',
  ADD_TO_WISHLIST_SUCCESS: 'ADD_TO_WISHLIST_SUCCESS',

  EDIT_WISHLIST_PRODUCTS_REQUEST: 'EDIT_WISHLIST_PRODUCTS_REQUEST',
  EDIT_WISHLIST_PRODUCTS_FAILED: 'EDIT_WISHLIST_PRODUCTS_FAILED',
  EDIT_WISHLIST_PRODUCTS_SUCCESS: 'EDIT_WISHLIST_PRODUCTS_SUCCESS',

  REMOVE_FROM_WISHLIST_REQUEST: 'REMOVE_FROM_WISHLIST_REQUEST',
  REMOVE_FROM_WISHLIST_FAILED: 'REMOVE_FROM_WISHLIST_FAILED',
  REMOVE_FROM_WISHLIST_SUCCESS: 'REMOVE_FROM_WISHLIST_SUCCESS',
}

export { WISHLIST_ACTIONS, WISHLIST_PRODUCT_ACTIONS }
