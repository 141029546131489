import React from "react";
import { I18n } from "react-redux-i18n";

import { faCommentDots, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProductOption = ({ status, price }) => {
  if (status === "Placed" || status === "Confirmed" || status === "Pending")
    return (
      <div>
        <span>{price}</span>
      </div>
    );
  if (status === "Delivered") {
    return (
      <div>
        <button
          className="btn p-2"
          data-bs-toggle="tooltip"
          data-placement="left"
          title="Write a review"
        >
          <FontAwesomeIcon icon={faCommentDots} className="text-gray-600" />
        </button>
        <button
          className="btn p-2"
          data-bs-toggle="tooltip"
          data-placement="left"
          title="Return this item"
        >
          <FontAwesomeIcon icon={faUndo} className="text-gray-600" />
        </button>
      </div>
    );
  }

  return null;
};

const ProductList = ({ image, title, qty, price, status }) => {
  return (
    <div className="order-card-item d-flex flex-row justify-content-between align-items-center py-3">
      <div className="d-flex flex-row align-items-center justify-content-between">
        <img src={image} alt={title} className="product-image me-3" />
        <div className="product-detail">
          <p className="mb-0 font-weight-semibold">{title}</p>
          <p className="mb-0 text-muted">
            {I18n.t("user.myOrders.quantity")}: <span>{qty}</span>
          </p>
        </div>
      </div>
      <ProductOption status={status} price={price} />
    </div>
  );
};

export default ProductList;
