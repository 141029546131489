import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { formValueSelector } from 'redux-form';

import {
  sendShippingMethodRequest,
  setSpecialInstruction,
} from 'appRedux/checkOut/actions';

import OrderSummarySide from '../OrderSummarySide/OrderSummarySide';
import ShippingAndBilling from '../ShippingAndBilling/ShippingAndBilling';
import DeliveryOptionSide from '../DeliveryOptionSide/DeliveryOptionSide';

const OrderSummary = ({
  deliveryOptioncurrentValue,
  special_instructionscurrentValue,
}) => {
  const { isformSumitted, isDeliveryChoosen } = useSelector(
    (state) => state.checkout
  );

  const { cartData } = useSelector((state) => state.cart);

  const deliveryOptionfromRedux = useSelector(
    (state) => state.checkout.deliveryOption
  );
  const paymentMethodfromRedux = useSelector(
    (state) => state.checkout.paymentMethod
  );

  const dispatch = useDispatch();

  const handleProceedToPayment = () => {
    const selectedDelveryOption = deliveryOptionfromRedux[0].shipping_rates.find(
      (deliveryOption) => {
        return deliveryOption.name === deliveryOptioncurrentValue;
      }
    );

    setSpecialInstruction(special_instructionscurrentValue);
    dispatch(
      sendShippingMethodRequest({
        deliveryOptionId: deliveryOptionfromRedux[0].id,
        selectedDeliveryOption: { ...selectedDelveryOption },
        special_instructions: special_instructionscurrentValue,
      })
    );
  };

  return (
    <div className='accordion' id='accordionExample'>
      <OrderSummarySide
        isDeliveryChoosen={isDeliveryChoosen}
        isformSumitted={isformSumitted}
        cartData={cartData}
      />
      {isformSumitted && (
        <>
          <ShippingAndBilling
            isDeliveryChoosen={isDeliveryChoosen}
            isformSumitted={isformSumitted}
          />
          {isDeliveryChoosen ? (
            <DeliveryOptionSide
              isformSumitted={isformSumitted}
              isDeliveryChoosen={isDeliveryChoosen}
            />
          ) : (
            <Link
              to={
                paymentMethodfromRedux.length === 0 ? '#' : '/checkout/payment'
              }
            >
              <button
                disabled={deliveryOptionfromRedux.length === 0}
                onClick={handleProceedToPayment}
                className='btn btn-primary px-3 w-100'
                type='button'
                id='button-addon2'
              >
                {I18n.t('orderSummary.procceedToPayment')}
              </button>
            </Link>
          )}
        </>
      )}
    </div>
  );
};

const selector = formValueSelector('delivery');

const mapStateToProps = (state) => ({
  deliveryOptioncurrentValue: selector(state, 'deliveryOption'),
  special_instructionscurrentValue: selector(state, 'special_instructions'),
});

export default connect(mapStateToProps)(OrderSummary);
