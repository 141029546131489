import React from "react";
import { I18n } from "react-redux-i18n";

import SliderSkeleton from "../Skeleton/SliderSkeleton";
import CarouselItem from "./CarouselItem";

function Carousel({ sliderData, isSliderImageLoading }) {
  const slides = sliderData?.slides;

  return (
    <div
      className="carousel slide"
      data-bs-ride="carousel"
      id="carouselSlidesIndicators"
    >
      <ol className="carousel-indicators list-unstyled" id="indicator">
        {slides?.length !== 0 &&
          slides?.map((slide, i) => {
            return (
              <li
                key={slide.id}
                data-bs-target="#carouselSlidesIndicators"
                data-bs-slide-to={i}
                className={i === 0 ? "active" : ""}
              />
            );
          })}
      </ol>
      <div className="carousel-inner">
        {slides?.length !== 0 &&
          slides?.map(({ slide_name, slide_link, slide_image_url }, i) => {
            let link = slide_link;

            if (slide_link.includes("ecom-api.truemark"))
              link = slide_link.replace("https://ecom-api.truemark.com.np", "");
            if (slide_link.includes("ecom-store-demo"))
              link = slide_link.replace(
                "https://ecom-store-demo.vercel.app",
                ""
              );
            if (slide_link.includes("api.trendline"))
              link = slide_link.replace("https://api.trendlinestore.com", "");

            return (
              <CarouselItem
                className={i === 0 ? "active" : ""}
                image={`${process.env.REACT_APP_API_URL}${slide_image_url}`}
                title={slide_name}
                btnText={I18n.t("carousel.buttons.shopNow")}
                linkTo={link}
              />
            );
          })}
        {(slides?.length === 0 || isSliderImageLoading) && (
          <SliderSkeleton
            className={
              (slides?.length === 0 || isSliderImageLoading) && "active"
            }
          />
        )}
      </div>
    </div>
  );
}

export default Carousel;
