import React from 'react';
import { withRouter } from 'react-router';
import { reduxForm } from 'redux-form';
import { Field } from 'redux-form-normalize-on-blur';
import { I18n, Translate } from 'react-redux-i18n';
import { useDispatch } from 'react-redux';

import { addEmailforCheckOut } from 'appRedux/checkOut/actions';

import ROUTES from 'constants/routes';
import CHECKOUT from 'appRedux/checkOut/constants';
import trimmer from 'utils/trimmer';
import { email, required } from 'utils/fieldValidation';
import { renderField } from '../Form/FormFieldRender';

const GuestForm = ({ handleSubmit, history }) => {
  const dispatch = useDispatch();

  const submit = (values) => {
    dispatch({ type: CHECKOUT.CHECKOUT_CONTINUE_AS_GUEST });
    dispatch(addEmailforCheckOut(values.email));
    history.push(ROUTES.CHECKOUT.ADDRESS);
  };

  return (
    <div className='container mt-4'>
      <form onSubmit={handleSubmit(submit)}>
        <div className='mb-md-4 row align-items-center justify-content-center'>
          <h2 className='col-12 col-md-6 text-center'>
            <Translate value='guest.title' />
          </h2>
        </div>
        <div className='row align-items-center justify-content-center  login-card'>
          <div className='col-lg-6  col-12'>
            <div className='mb-sm-0 mt-3 mb-3 mt-sm-0 p-sm-3 p-lg-5 '>
              <Field
                name='email'
                type='text'
                placeholder={I18n.t('form.placeholder.email')}
                label={`${I18n.t('form.fields.email')}*`}
                component={renderField}
                normalizeOnBlur={trimmer}
                helpText={I18n.t('form.fields.emailHelperText')}
                validate={[required, email]}
              />

              <button
                type='submit'
                className='btn btn-primary rounded w-100 w-md-auto mt-3'
              >
                <Translate value='guest.title' />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withRouter(reduxForm({ form: 'guestForm' })(GuestForm));
