import React, { useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { useLocation, useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import QuestionCard from "components/User/QuestionCard";
import DashboardOrdersSkeleton from "components/Skeleton/DashboardOrdersSkeleton";

import {
  fetchQuestionsRequest,
  fetchQuestionsCurrentPage,
} from "appRedux/user/actions";
import { removeProductQuestionRequest } from "appRedux/productQuestion/actions";

import { getParams } from "utils/queryString";
import ROUTES from "constants/routes";

const Question = ({ history }) => {
  const { search, pathname } = useLocation();
  const { page } = getParams(search);
  const { replace } = useHistory();

  const dispatch = useDispatch();
  const {
    questionsData: { questions, currentPage, pageMetaData, isLoading },
  } = useSelector((state) => state.user);
  const {
    userInfoData: {
      userInfo: { id },
    },
  } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchQuestionsRequest({ currentPage, userId: id }));
  }, [currentPage]);

  const pageNumberHandler = (fetchPage) => {
    dispatch(fetchQuestionsCurrentPage(fetchPage));

    const newPath = `${pathname}?${fetchPage ? `page=${fetchPage}` : ""}`;

    replace(newPath);
  };

  useEffect(() => {
    const nextPage = page || 1;

    pageNumberHandler(nextPage);
  }, [page]);

  if (isLoading) {
    return <DashboardOrdersSkeleton repeat={5} />;
  }

  const handleEditQuestion = (id) => {
    history.push(
      `${ROUTES.USER.QUESTION.addQuestionId(ROUTES.USER.QUESTION.EDIT, id)}`
    );
  };

  const handleDeleteQuestion = ({ questionId, productId }) => {
    swal({
      title: I18n.t("user.myWishList.alert.title"),
      text: I18n.t("user.myWishList.alert.text"),
      icon: "warning",
      buttons: [
        I18n.t("user.myWishList.alert.button.cancel"),
        I18n.t("user.myWishList.alert.button.ok"),
      ],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(
          removeProductQuestionRequest({
            questionId,
            productId,
            currentPage,
            userId: id,
          })
        );
      }
    });
  };

  return (
    <div className="col-md-9">
      <QuestionCard
        questions={questions}
        handleEditQuestion={handleEditQuestion}
        handleDeleteQuestion={handleDeleteQuestion}
        page={page}
        pageNumberHandler={pageNumberHandler}
        pageMetaData={pageMetaData}
        title={I18n.t("user.myQuestions.title")}
      />
    </div>
  );
};

export default withRouter(Question);
