export const filterProducts = (products, filter) => {
  let hasFilter = false;

  Object.keys(filter).forEach((key) => {
    if (filter[key]?.length > 0) {
      hasFilter = true;
    }
  });

  if (!hasFilter) return products;

  const filteredProducts = products.filter(
    ({ default_variant = {}, variants = [] }) => {
      const productsVariants = [default_variant, ...variants];

      return productsVariants.find(({ option_values }) => {
        if (!option_values) {
          return;
        }
        let isIncluded = true;

        option_values.forEach(({ presentation, option_type }) => {
          if (option_type && option_type.name) {
            const currentFilterSelections = filter[option_type.name];

            isIncluded =
              isIncluded &&
              (!currentFilterSelections?.length ||
                currentFilterSelections?.includes(presentation));
          }
        });

        return isIncluded;
      });
    }
  );

  return filteredProducts;
};

export default filterProducts;
