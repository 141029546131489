import PRODUCTS_ACTIONS from './constants';

export const fetchProductsRequest = ({
  filter,
  pageNumber,
  sortBy,
  perPage,
}) => ({
  type: PRODUCTS_ACTIONS.FETCH_PRODUCTS_REQUEST,
  filter,
  pageNumber,
  sortBy,
  perPage,
});

export const setSortBy = (sortBy) => ({
  type: PRODUCTS_ACTIONS.SET_SORTBY,
  sortBy,
});

export const fetchProductSuccess = (fetchedProducts) => ({
  type: PRODUCTS_ACTIONS.FETCH_PRODUCTS_SUCCESS,
  fetchedProducts,
});

export const fetchcurrentPage = (currentPage) => ({
  type: PRODUCTS_ACTIONS.CURRENT_PAGE,
  currentPage,
});

export const filterProductList = (name, value) => {
  const filters = {};

  filters[name] = value;

  return {
    type: PRODUCTS_ACTIONS.FILTER_PRODUCTS_LIST,
    selectedFilters: filters,
  };
};

export const clearProductFilters = () => ({
  type: PRODUCTS_ACTIONS.CLEAR_PRODUCT_FILTER,
});

export const resetProducts = () => ({
  type: PRODUCTS_ACTIONS.RESET_PRODUCTS,
});

export const searchSuggestionRequest = (keywords) => ({
  type: PRODUCTS_ACTIONS.SEARCH_SUGGESTIONS_REQUEST,
  keywords,
});

export const searchSuggestionSuccess = (suggestionList) => ({
  type: PRODUCTS_ACTIONS.SEARCH_SUGGESTIONS_SUCCESS,
  suggestionList,
});

export const searchSuggestionFailed = (error) => ({
  type: PRODUCTS_ACTIONS.SEARCH_SUGGESTIONS_FAILED,
  error,
});

export const resetSearchSuggestion = () => ({
  type: PRODUCTS_ACTIONS.RESET_SEARCH_SUGGESTIONS,
});
