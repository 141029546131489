const PRODUCTS_ACTIONS = {
  FETCH_PRODUCTS_REQUEST: 'FETCH_PRODUCTS_REQUEST',
  FETCH_PRODUCTS_SUCCESS: 'FETCH_PRODUCTS_SUCCESS',
  FETCH_PRODUCTS_FAILED: 'FETCH_PRODUCTS_FAILED',

  FETCH_PAGE_META_DATA: 'FETCH_PAGE_META_DATA',

  CURRENT_PAGE: 'CURRENT_PAGE',
  SET_SORTBY: 'SET_SORTBY',

  FILTER_PRODUCTS_LIST: 'FILTER_PRODUCTS_LIST',
  CLEAR_PRODUCT_FILTER: 'CLEAR_PRODUCT_FILTER',

  SEARCH_SUGGESTIONS_REQUEST: 'SEARCH_SUGGESTIONS_REQUEST',
  SEARCH_SUGGESTIONS_SUCCESS: 'SEARCH_SUGGESTIONS_SUCCESS',
  SEARCH_SUGGESTIONS_FAILED: 'SEARCH_SUGGESTIONS_FAILED',
  RESET_SEARCH_SUGGESTIONS: 'RESET_SEARCH_SUGGESTIONS',

  RESET_PRODUCTS: 'RESET_PRODUCTS',
};

export default PRODUCTS_ACTIONS;
