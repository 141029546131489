import VENDOR_ACTIONS from "./constants";

export const fetchVendorProducts = (slug) => ({
  type: VENDOR_ACTIONS.FETCH_VENDOR_PRODUCTS_REQUEST,
  slug,
});

export const fetchVendorProductsFail = (error) => ({
  type: VENDOR_ACTIONS.FETCH_VENDOR_PRODUCTS_FAILED,
  error,
});

export const fetchVendorProductsSuccess = (products) => ({
  type: VENDOR_ACTIONS.FETCH_VENDOR_PRODUCTS_SUCCESS,
  products,
});

export const setSortBy = (sortBy) => ({
  type: VENDOR_ACTIONS.SET_SORTBY,
  sortBy,
});

export const fetchcurrentPage = (currentPage) => ({
  type: VENDOR_ACTIONS.CURRENT_PAGE,
  currentPage,
});

export const filterProductList = (name, value) => {
  const filters = {};

  filters[name] = value;

  return {
    type: VENDOR_ACTIONS.FILTER_PRODUCTS_LIST,
    selectedFilters: filters,
  };
};

export const clearProductFilters = () => ({
  type: VENDOR_ACTIONS.CLEAR_PRODUCT_FILTER,
});

export const resetProducts = () => ({
  type: VENDOR_ACTIONS.RESET_PRODUCTS,
});

export const fetchVendorDetails = (details) => ({
  type: VENDOR_ACTIONS.FETCH_VENDOR_DETAILS,
  details,
});
