const PRODUCT_QUESTION_ACTIONS = {
  FETCH_ALL_PRODUCT_QUESTIONS_REQUEST: 'FETCH_ALL_PRODUCT_QUESTIONS_REQUEST',
  FETCH_ALL_PRODUCT_QUESTIONS_SUCCESS: 'FETCH_ALL_PRODUCT_QUESTIONS_SUCCESS',
  FETCH_ALL_PRODUCT_QUESTIONS_FAILED: 'FETCH_ALL_PRODUCT_QUESTIONS_FAILED',

  CREATE_PRODUCT_QUESTION_REQUEST: 'CREATE_PRODUCT_QUESTION_REQUEST',
  CREATE_PRODUCT_QUESTION_FAILED: 'CREATE_PRODUCT_QUESTION_FAILED',
  CREATE_PRODUCT_QUESTION_SUCCESS: 'CREATE_PRODUCT_QUESTION_SUCCESS',

  EDIT_PRODUCT_QUESTION_REQUEST: 'EDIT_PRODUCT_QUESTION_REQUEST',
  EDIT_PRODUCT_QUESTION_FAILED: 'EDIT_PRODUCT_QUESTION_FAILED',
  EDIT_PRODUCT_QUESTION_SUCCESS: 'EDIT_PRODUCT_QUESTION_SUCCESS',

  REMOVE_PRODUCT_QUESTION_REQUEST: 'REMOVE_PRODUCT_QUESTION_REQUEST',
  REMOVE_PRODUCT_QUESTION_FAILED: 'REMOVE_PRODUCT_QUESTION_FAILED',
  REMOVE_PRODUCT_QUESTION_SUCCESS: 'REMOVE_PRODUCT_QUESTION_SUCCESS',

  RESET_QUESTION_FORM: 'RESET_QUESTION_FORM',
}

export default PRODUCT_QUESTION_ACTIONS
