import AUTH_ACTIONS from './constants';

const initialState = {
  login: {
    isAuthenticating: false,
    isAuthenticated: false,
    errors: null,
  },
  registration: {
    isAuthenticating: false,
    isAuthenticated: false,
    errors: null,
  },
  authData: {},
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    // log in
    case AUTH_ACTIONS.LOGIN_REQUEST:
      return {
        ...state,
        login: {
          isAuthenticated: false,
          isAuthenticating: true,
          errors: null,
        },
      };
    case AUTH_ACTIONS.LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          isAuthenticating: false,
          isAuthenticated: true,
          errors: null,
        },
        authData: action.authData,
      };
    case AUTH_ACTIONS.LOGIN_FAILED:
      return {
        ...state,
        login: {
          isAuthenticating: false,
          isAuthenticated: false,
          errors: action.error,
        },
      };

    // registration
    case AUTH_ACTIONS.REGISTRATION_REQUEST:
      return {
        ...state,
        registration: {
          isAuthenticated: false,
          isAuthenticating: true,
          errors: null,
        },
      };
    case AUTH_ACTIONS.REGISTRATION_SUCCESS:
      return {
        ...state,
        registration: {
          isAuthenticating: false,
          isAuthenticated: true,
          errors: null,
        },
      };
    case AUTH_ACTIONS.REGISTRATION_FAILED:
      return {
        ...state,
        registration: {
          isAuthenticating: false,
          isAuthenticated: false,
          errors: action.errors,
        },
      };

    // login/registration - facebook
    case AUTH_ACTIONS.LOGIN_WITH_FACEBOOK_REQUEST:
      return {
        ...state,
        registration: {
          ...state.registration,
          isAuthenticating: true,
        },
        login: {
          ...state.login,
          isAuthenticating: true,
        },
      };
    case AUTH_ACTIONS.LOGIN_WITH_FACEBOOK_SUCCESS:
      return {
        ...state,
        registration: {
          ...state.registration,
          isAuthenticating: false,
        },
        login: {
          ...state.login,
          isAuthenticating: false,
        },
      };
    case AUTH_ACTIONS.LOGIN_WITH_FACEBOOK_FAILED:
      return {
        ...state,
        registration: {
          ...state.registration,
          isAuthenticating: false,
        },
        login: {
          ...state.login,
          isAuthenticating: false,
        },
      };
    // log out
    case AUTH_ACTIONS.LOGOUT:
      return {
        login: {
          isAuthenticating: false,
          isAuthenticated: false,
          errors: null,
        },
        registration: {
          isAuthenticating: false,
          isAuthenticated: false,
          errors: null,
        },
        authData: {},
      };
    default:
      return state;
  }
}
