import React, { useRef, forwardRef, memo } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

import CategoryCarouselSkeleton from "components/Skeleton/CategoryCarouselSkeleton";
import ProductItem from "components/Product/Item";

import extractImage from "utils/extractImage";
import settings from "constants/slickSlider";
import ROUTES from "constants/routes";

const ProductList = memo(
  forwardRef(({ products, categoryId }, ref) => (
    <Slider ref={ref} {...settings}>
      {products.map((product, i) => {
        const {
          id,
          price,
          display_price,
          images,
          name,
          slug,
          taxons,
        } = product;
        const image = extractImage(images);

        return (
          <ProductItem
            key={id}
            categoryId={categoryId}
            image={image}
            title={name}
            newPrice={display_price}
            slug={slug}
            link={`${ROUTES.PRODUCTS.INDEX}/${slug}`}
          />
        );
      })}
    </Slider>
  ))
);

const CategoryCarousel = ({
  categoryId,
  title,
  className,
  products,
  isLoading,
}) => {
  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const sliderRef = useRef();

  if (isLoading || !products) {
    return <CategoryCarouselSkeleton />;
  }

  return (
    <section className={`py-5 ${className}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between align-items-center mb-4">
            <h3 className="section-header">{title}</h3>

            <div className="slider-control">
              <button
                type="button"
                className="btn primary-btn rounded-circle p-0"
                onClick={previous}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </button>
              <button
                type="button"
                className="btn primary-btn rounded-circle p-0 ms-2"
                onClick={next}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <ProductList
                categoryId={categoryId}
                ref={sliderRef}
                products={products}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryCarousel;
