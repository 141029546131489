import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import Pagination from "components/Pagination";
import SideNav from "components/SideNav";

import SortBy from "./SortBy";
import CategoryBanner from "./CategoryBanner";

function CategoryList({
  children,
  category,
  taxonImage,
  productLength,
  pageMetaData,
  taxonDescription,
  taxonName,
  pageNumberHandler,
  page,
  handleSort,
  sortBy,
  handleProductFilter,
}) {
  const [filterEnabled, setFilterEnabled] = useState(false);

  const toggleFilter = () => {
    setFilterEnabled(!filterEnabled);
  };

  let taxonImageUrl = "";

  if (taxonImage) {
    const bannerImage = taxonImage.styles.find((style) => {
      return style.height === "128";
    });

    taxonImageUrl = `${process.env.REACT_APP_API_URL}${bannerImage.url}`;
  }

  const hasProducts = productLength !== 0;

  return (
    <section>
      {/* <CategoryBanner /> */}

      <div className="container">
        <div className="row">
          {/* <div className="col-md-12 py-3">
            <div className="py-md-2 mb-0 text-uppercase font-weight-bold h2">
              {taxonName}
            </div>
            {taxonDescription && (
              <div className="py-3 py-md-2 taxon-description">
                {taxonDescription}
              </div>
            )}
            <CategoryBanner taxonImage={taxonImageUrl} />
          </div> */}
          <div className="d-flex justify-content-between border-bottom py-3 text-secondary d-md-none">
            <a href="#" onClick={toggleFilter}>
              <FontAwesomeIcon icon={faFilter} className="primary-color me-2" />
              Filter By
            </a>
            {hasProducts && (
              <div>
                <SortBy sortBy={sortBy} handleSort={handleSort} />
              </div>
            )}
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-md-3">
            <h3 className="mb-3 mt-3 h5 font-weight-bold text-dark">
              {category}
            </h3>
            <SideNav
              isOpen={filterEnabled}
              onToggle={toggleFilter}
              handleProductFilter={handleProductFilter}
              menuType="filter"
            />
          </div>

          <div className="col-md-9 ps-md-5">
            {hasProducts && (
              <SortBy
                sortBy={sortBy}
                handleSort={handleSort}
                className="d-none d-md-flex"
              />
            )}
            <div className="row">
              {children}
              {hasProducts && (
                <Pagination
                  page={page}
                  pageMetaData={pageMetaData}
                  pageNumberHandler={pageNumberHandler}
                  className="d-flex justify-content-center justify-content-md-end"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CategoryList;
