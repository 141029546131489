import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-redux-i18n";

import AddressTable from "components/OrderDetails/AddressTable";
import OrderSummaryItems from "components/OrderSummaryItems";
import OrderedProductDetails from "components/OrderDetails/OrderedProductDetails";
import Footer from "components/Footer/Footer";

import { fetchOrderDetailRequest } from "appRedux/checkOut/actions";

import extractImage from "utils/extractImage";
import { HOME, ORDER } from "constants/routes";
import Layout from "components/Layout/Layout";

const OrderDetails = () => {
  const dispatch = useDispatch();
  const {
    orderDetailData: {
      orderNumber,
      orderDetails: { orderData, isLoading, error, successStatus },
    },
  } = useSelector((state) => state.checkout);

  useEffect(() => {
    dispatch(fetchOrderDetailRequest(orderNumber));
  }, [orderNumber]);

  if (!orderNumber) {
    return <Redirect to={HOME.INDEX} />;
  }

  if (isLoading || !orderData) {
    return (
      <div className="backdrop d-flex align-items-center justify-content-center">
        <div className="spinner">Loading...</div>
      </div>
    );
  }

  let recentOrder = {};

  let selectedShippingMethod = "";

  if (orderData) {
    recentOrder = {
      ...orderData,
    };

    selectedShippingMethod = orderData.shipments[0].shipping_rates.find(
      (shippingRate) => shippingRate.selected === true
    );
  }

  const orderPlaceDate = new Date(recentOrder.created_at)
    .toDateString()
    .slice(4);

  return (
    <Layout title={`Order Success - ${orderNumber}`} path={ORDER.INDEX}>
      <div className="container">
        <div className="text-center">
          {successStatus && (
            <FontAwesomeIcon icon={faCheckCircle} className="mt-5 display-3" />
          )}
          <div className="mt-3 mb-5">
            {successStatus && (
              <div>
                <h3>
                  <Translate value="orderDetails.orderSuccess" />
                </h3>
                <p>
                  <Translate value="orderDetails.orderSentToEmail" />
                </p>
              </div>
            )}
            <h5 className=" font-weight-bold text-uppercase">
              <Translate value="orderDetails.order" /> {recentOrder.number} /{" "}
              {orderPlaceDate.toUpperCase()}
            </h5>
          </div>
          <div className="row justify-content-between mt-4">
            <AddressTable
              title={<Translate value="orderDetails.shippingAddress" />}
            >
              <p className="mb-0 text-muted">
                {recentOrder.shipping_address.address1}
              </p>
              <p className="mb-0 text-muted">
                {recentOrder.shipping_address.address2}
              </p>
              <p className="mb-0 text-muted">
                {recentOrder.shipping_address.city}{" "}
                {recentOrder.shipping_address.zipcode}
              </p>
              <p className="mb-0 text-muted">
                {recentOrder.shipping_address.state_name}
              </p>
              <p className="mb-0 text-muted">
                {recentOrder.shipping_address.country_name}
              </p>
            </AddressTable>
            <AddressTable
              title={<Translate value="orderDetails.billingAddress" />}
            >
              <p className="mb-0 text-muted">
                {recentOrder.billing_address.address1}
              </p>
              <p className="mb-0 text-muted">
                {recentOrder.billing_address.address2}
              </p>
              <p className="mb-0 text-muted">
                {recentOrder.billing_address.city}{" "}
                {recentOrder.billing_address.zipcode}
              </p>
              <p className="mb-0 text-muted">
                {recentOrder.billing_address.state_name}
              </p>
              <p className="mb-0 text-muted">
                {recentOrder.billing_address.country_name}
              </p>
            </AddressTable>
            <AddressTable title={<Translate value="orderDetails.shipping" />}>
              <p className="mb-0 text-muted">
                {selectedShippingMethod.name || selectedShippingMethod}
              </p>
              {recentOrder.special_instructions && (
                <div className="mt-2 address-table">
                  <dt className="text-uppercase text-lg-start">
                    <Translate value="orderDetails.specialInstruction" />
                  </dt>
                  <dd>
                    <div className="text-lg-start text-muted">
                      {recentOrder.special_instructions}
                    </div>
                  </dd>
                </div>
              )}
            </AddressTable>
            <AddressTable title={<Translate value="orderDetails.payment" />}>
              <p className="mb-0 text-muted">
                {recentOrder.payments[0].payment_method_name} (Rs.
                {recentOrder.payments[0].amount})
              </p>
            </AddressTable>
          </div>
        </div>
        <div className="row my-5 position-relative">
          <div className="col-lg-9 text-center text-md-start">
            {recentOrder.line_items &&
              recentOrder.line_items.map((item) => {
                const {
                  variant,
                  quantity,
                  name,
                  display_total,
                  id,
                  display_price,
                } = item;

                const { images } = variant;
                const image = extractImage(images);

                return (
                  <OrderedProductDetails
                    key={id}
                    name={name}
                    quantity={quantity}
                    image={image}
                    variants={variant.options_text}
                    itemPrice={display_price}
                    displayTotal={display_total}
                  />
                );
              })}
          </div>

          <div className="shadow-sm h-100 card-header py-3 bg-white col-lg-3 border-0 border-bottom mt-4 mt-lg-0">
            <h4 className="text-center">
              <Translate value="orderSummary.orderSummary.title" />
            </h4>
            <OrderSummaryItems
              itemCount={recentOrder.item_count}
              displayItemTotal={recentOrder.display_item_total}
              displayAdjustmentTotal={recentOrder.display_adjustment_total}
              displayShipTotal={recentOrder.display_ship_total}
              displayTaxTotal={recentOrder.display_tax_total}
            />
            <div className="total-price my-2 h5">
              <Translate value="orderSummary.orderSummary.total" />
              {" : "}
              <span className="text-primary font-weight-bold">
                {recentOrder.display_total}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default OrderDetails;
