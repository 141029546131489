import React from "react";
import ReactStars from "react-rating-stars-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translate } from "react-redux-i18n";
import {
  faEllipsisV,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import ReviewCarousel from "components/Product/AdditionalInfo/ReviewCarousel";
import { convertCDTToLocalTime } from "utils/DateFormatter";
import { getFullName } from "utils/utils";

import extractImage, { extractImages } from "utils/extractImage";

import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import REACT_STARS from "constants/reactStarsConfig";
import ReviewList from "./ReviewList";

const ReviewItem = ({ review, handleEditReview, handleDeleteReview }) => {
  const { images } = review.product;
  const image = extractImage(images);

  return (
    <div className="card dashboard-card shadow-sm border-0 mb-2 p-3">
      <div className="order-basic-info text-muted d-flex justify-content-between align-items-center">
        <p className="mb-0">
          <Translate
            value="user.myReviews.reviewedOn"
            date={convertCDTToLocalTime(review.created_at)}
          />
        </p>

        <div>
          <button
            className="btn p-1"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </button>
          <ul className="dropdown-menu border-0 text-dark shadow">
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => handleEditReview(review.id)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />{" "}
                <Translate value="user.myReviews.dropdown.editReview" />
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() =>
                  handleDeleteReview({
                    reviewId: review.id,
                    productId: review.product.id,
                  })
                }
              >
                <FontAwesomeIcon icon={faTrashAlt} />{" "}
                <Translate value="user.myReviews.dropdown.deleteReview" />
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <p className="mb-0 font-bold fs-6">
          {capitalizeFirstLetter(review.title)}
        </p>
        <ReviewList
          image={image}
          title={review.product.name}
          reviewStatus={review.approved}
        />
      </div>
      <ReactStars
        value={review.rating}
        edit={false}
        count={REACT_STARS.COUNT}
        size={REACT_STARS.SIZE}
        activeColor={REACT_STARS.ACTIVE_COLOR}
      />
      <p className="mb-3">{review.review}</p>
      <ReviewCarousel
        images={extractImages(review.images)}
        show={review?.images?.length}
        rating={review.rating}
        displayName={review.name}
        fullName={getFullName(review.default_billing_address) || review.name}
        approved={review.approved}
        createdAt={review.created_at}
        title={review.title}
        review={review.review}
      />
    </div>
  );
};

export default ReviewItem;
