import React from "react";
import Skeleton from "react-loading-skeleton";

const ProfileSkeleton = ({ repeat }) => {
  const numberOfSkeleton = Array(repeat ?? 0).fill();

  return (
    <div className="col-md-9">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="d-flex align-items-center justify-content-center">
          <Skeleton circle={true} height={60} width={60} />
          <div className="d-flex flex-column ms-3">
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
        </div>
        <div>
          <Skeleton height={20} width={40} />
        </div>
      </div>
      <div className="card dashboard-card shadow-sm border-0 mb-2 py-3">
        {numberOfSkeleton.map(() => {
          return (
            <div className="p-2">
              <div className="d-flex justify-content-between align-items-center">
                <Skeleton width={100} height={25} />
                <Skeleton width={50} />
              </div>

              <div className="border-bottom d-flex flex-column mt-2">
                <Skeleton width={100} />
                <Skeleton width={150} />
                <Skeleton width={200} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileSkeleton;
