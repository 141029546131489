import { Client, endpoints } from "@spree/storefront-api-v2-sdk";
import createAxiosFetcher from "@spree/axios-fetcher/dist/server/index";

import PaymentGateway from "lib/spreeSdk/endpoint/PaymentGateway";

import ProductQuestion from "lib/spreeSdk/endpoint/ProductQuestion";
import ProductReview from "lib/spreeSdk/endpoint/Review";
import SocialLogin from "lib/spreeSdk/endpoint/SocialLogin";
import SlideLocations from "lib/spreeSdk/endpoint/SlideLocation";
import Vendor from "lib/spreeSdk/endpoint/Vendor";
import SpreeStore from "lib/spreeSdk/endpoint/SpreeStore";

import {
  errorInterceptor,
  responseInterceptor,
} from "./interceptors/responseInterceptors";

function convertToCamelCase(str) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

class SpreeClient extends Client {
  static review;

  constructor(configs, endpoints) {
    super(configs);
    this.responseHandler = configs.responseHandler;
    this.errorHandler = configs.errorHandler;
    this.host = configs.host;
    this.updateEndPoints();

    // TODO: Fix axios by extending the endpoint. This throws misconfiguration error
    // this.addAxiosInterceptors()

    for (const endpoint in endpoints) {
      this[convertToCamelCase(endpoint)] = new endpoints[endpoint]({
        fetcher: this.fetcher,
      });
    }
  }

  updateEndPoints() {
    this.productQuestions = this.makeProductQuestions();
    this.reviews = this.makeReview();
    this.slideLocations = this.makeSlideLocations();
    this.auth = this.makeSocialLogin();
    this.paymentGateway = this.makePaymentGateway();
    this.vendor = this.makeVendor();
    this.storeConfig = this.makeStoreConfig();
  }

  addAxiosInterceptors() {
    this.account.axios.interceptors.response.use(
      this.responseHandler,
      this.errorHandler
    );
  }

  makeProductQuestions() {
    return new ProductQuestion({ fetcher: this.fetcher });
  }

  makeSocialLogin() {
    return new SocialLogin({ fetcher: this.fetcher });
  }

  makeSlideLocations() {
    return new SlideLocations({ fetcher: this.fetcher });
  }

  makeReview() {
    return new ProductReview({ fetcher: this.fetcher });
  }

  makePaymentGateway() {
    return new PaymentGateway({ fetcher: this.fetcher });
  }

  makeVendor() {
    return new Vendor({ fetcher: this.fetcher });
  }

  makeStoreConfig() {
    return new SpreeStore({ fetcher: this.fetcher, host: this.host });
  }
}

const makeSpreeClient = (config = {}) => new SpreeClient(config, endpoints);

const client = makeSpreeClient({
  host: process.env.REACT_APP_API_URL,
  responseHandler: responseInterceptor,
  errorHandler: errorInterceptor,
  createFetcher: createAxiosFetcher,
});

export default client;
