import React from 'react'
import PropTypes from 'prop-types'
import FilterRow from '../Row/FilterRow'

const FiltersList = ({ filters, handleProductFilter, selectedFilters }) => {
  const filterRows = filters.map((filter) => (
    <FilterRow
      handleProductFilter={handleProductFilter}
      key={filter.id}
      filter={filter}
      selectedFilters={selectedFilters}
    />
  ))

  return <ul className="list-unstyled mb-0">{filterRows}</ul>
}

FiltersList.propTypes = {}

export default FiltersList
