import React from "react";
import { Translate } from "react-redux-i18n";

import AddressForm from "components/User/AddressForm";

const AddressEdit = () => {
  return (
    <AddressForm title={<Translate value="user.addressBook.editMyAddress" />} />
  );
};

export default AddressEdit;
