import React from "react";
import PropTypes from "prop-types";

const ProductPrice = ({ displayPrice, comparePrice }) => {
  return (
    <div className="mt-3">
      <h4 className="price primary-text">
        {displayPrice}
        <small className="ms-2 text-decoration-line-through text-muted">
          {comparePrice}
        </small>
      </h4>
    </div>
  );
};

ProductPrice.defaultPrice = {
  displayPrice: "Rs 0.00",
  comparePrice: null,
};

ProductPrice.propTypes = {
  displayPrice: PropTypes.string,
  comparePrice: PropTypes.string,
};

export default ProductPrice;
