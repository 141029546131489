import React, { useMemo } from "react";

import Breadcrumb from "components/Breadcrumb";
import Layout from "components/Layout/Layout";
import SideMenu from "components/User/SideMenu";
import StickyFooter from "components/User/StickyFooter";

import ROUTES from "constants/routes";

const TITLE_LIST = {
  address: {
    step: "Address Book",
    title: {
      index: "User | Address",
      add: "Address | Create",
      edit: "Address | Edit",
    },
  },
  profile: {
    step: "Profile",
    title: {
      index: "User | Profile",
    },
  },
  order: {
    step: "My Orders",
    title: {
      index: "User | Orders",
    },
  },
  question: {
    step: "My Questions",
    title: {
      index: "User | Questions",
    },
  },
  review: {
    step: "My Reviews",
    title: {
      index: "User | Reviews",
      edit: "Reviews | Edit",
    },
  },
  wishlist: {
    step: "My Wishlists",
    title: {
      index: "User | Wishlists",
      edit: "Wishlist | Edit",
      add: "Wishlist | Create",
    },
  },
};

const AccountLayout = ({ children, location, ...rest }) => {
  function getRoutesTitleAndStep(location) {
    if (!location) return {};

    const { pathname } = location;

    const route = pathname.replace(`${ROUTES.USER.INDEX}/`, "");
    const [routeKey, action] = route.split("/");

    const titleList = TITLE_LIST[routeKey];

    if (!titleList) {
      return {};
    }

    const title = action ? titleList.title[action] : titleList.title.index;

    return { ...titleList, title: title || "" };
  }

  const getTittleAndStep = useMemo(() => getRoutesTitleAndStep(location));

  const { step, title } = getTittleAndStep;

  return (
    <Layout title={title} path={location.username}>
      <Breadcrumb
        secondStep="User"
        thirdStep={step}
        url={ROUTES.USER.PROFILE}
      />
      <div className={`container ${title} mt-3 mt-md-4 mb-4`}>
        <div className="row">
          <div className="col-md-3">
            <SideMenu active={step} className="bg-white d-none d-md-block" />
          </div>
          {children}
        </div>
      </div>
      <StickyFooter active={step} className="d-md-none" />
    </Layout>
  );
};

export default AccountLayout;
