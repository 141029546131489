import {
  all,
  takeEvery,
  put,
  fork,
  call,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { normalizeData } from 'services/jsonaDataFormatter';

import CheckOutApi from 'api/checkOut/checkOutApi';
import StateApi from 'api/state/stateApi';
import userApi from 'api/user/userApi';

import { cartOptions } from 'constants/cartOptions';
import {
  addStateAddressSuccess,
  adddShippingMethodSuccess,
  addPaymentMethodRequest,
  addPaymentMethodSuccess,
  sendPaymentMethodSuccess,
  fetchOrderDetailSuccess,
  fetchOrderDetailFailed,
} from './actions';

import CHECKOUT_ACTIONS from './constants';
import CART_ACTIONS from '../cart/constants';
import USER_ACTIONS from '../user/constants';

// accessing state from saga
const getCheckoutData = (state) => state.checkout;
const getUserData = (state) => state.user;
const bearerToken = localStorage.getItem('bearerToken');

// address
function* checkOutAddressRequest({ formData, billId, shipId }) {
  try {
    const {
      userInfoData: { userInfo },
    } = yield select(getUserData);
    const userId = userInfo.id;
    const response = yield call(CheckOutApi.sendCheckOutAddressRequest, {
      formData,
      userId,
      billId,
      shipId,
    });

    if (response.isSuccess()) {
      const res = normalizeData(response.success());
      const shipAddressID = billId || res.shipping_address.id;
      const billAddressID = shipId || res.billing_address.id;

      yield put({ type: CHECKOUT_ACTIONS.ADD_CHECKOUT_ADDRESS_SUCCESS });
      const { isformSumitted, deliveryOption } = yield select(getCheckoutData);

      if (!isformSumitted) {
        yield call(CheckOutApi.orderNext);

        if (bearerToken) {
          yield call(userApi.sendAddressId, {
            billAddressID,
            shipAddressID,
          });
        }
      }

      yield put({ type: CHECKOUT_ACTIONS.ADD_SHIPPING_METHOD_REQUEST });
    } else {
      throw response.fail();
    }
  } catch (e) {
    console.log(e);
  }
}

// stateAddress
function* stateAddressRequest() {
  try {
    const response = yield call(StateApi.sendstateAddressRequest);

    if (response.status === 200) {
      const { states } = normalizeData(response.data);

      yield put(addStateAddressSuccess(states));
    } else {
      throw new Error('Couldnt fetch states.');
    }
  } catch (e) {
    console.log(e);
  }
}
// shipping
function* shippingMethodRequest() {
  try {
    const response = yield call(CheckOutApi.sendshippingMethodRequest);

    if (response.isSuccess()) {
      const shipping = response.success();
      const deliveryOptions = normalizeData(shipping);

      yield put(adddShippingMethodSuccess(deliveryOptions));

      yield put({ type: CHECKOUT_ACTIONS.CHECKOUT_IS_FORM_SUBMITTED });
    }
  } catch (e) {
    console.log(e);
  }
}

function* sendshippingMethod({
  selectedDeliveryOption,
  deliveryOptionId,
  special_instructions,
}) {
  try {
    const { paymentMethod } = yield select(getCheckoutData);
    const selectedDeliveryOptionId = selectedDeliveryOption.id;
    const res = yield call(CheckOutApi.sendshippingMethod, {
      deliveryOptionId,
      selectedDeliveryOptionId,
      special_instructions,
    });

    if (res.isSuccess()) {
      yield put({ type: CHECKOUT_ACTIONS.SEND_SHIPPING_METHOD_SUCCESS });
      const { isDeliveryChoosen } = yield select(getCheckoutData);

      if (!isDeliveryChoosen) {
        yield call(CheckOutApi.orderNext);
      }

      yield put(addPaymentMethodRequest());
    } else {
      throw new Error('unsuccessful');
    }
  } catch (e) {
    console.log(e);
  }
}

// payment
function* paymentMethodRequest() {
  try {
    const res = yield call(CheckOutApi.sendpaymentMethodRequest);

    if (res.isSuccess()) {
      const payment = res.success();
      const paymentmethods = normalizeData(payment);

      yield put(addPaymentMethodSuccess(paymentmethods));
      yield put({ type: CHECKOUT_ACTIONS.CHECKOUT_IS_DELIVERY_CHOSEN });
    } else {
      throw new Error('unsuccessful');
    }
  } catch (e) {
    console.log(e);
  }
}

function* sendpaymentMethod(action) {
  const { paymentData } = action;

  try {
    let res = null;

    if(paymentData?.token) {
      res = yield call(
        CheckOutApi.payWithKhalti,
        paymentData.token,
        paymentData.amount
      )
    } else {
      res = yield call(
      CheckOutApi.sendpaymentMethod,
      action.selectedPaymentMethod
    );
    }

    if (res.isSuccess()) {
      const checkoutResponse = yield call(CheckOutApi.orderNext);

      if (normalizeData(checkoutResponse.success()).state !== 'complete') {
        const checkOutComplete = yield call(CheckOutApi.complete);

        if (checkOutComplete.isSuccess()) {
          const orderNumber = normalizeData(checkoutResponse.success()).number;

          yield put(sendPaymentMethodSuccess(orderNumber));
          yield put({ type: USER_ACTIONS.FETCH_ALL_ADDRESSES_REQUEST });
          yield put({ type: CHECKOUT_ACTIONS.CLEAR_CHECKOUT });
          yield put({ type: CART_ACTIONS.RESET_CART });
        }
      }

      if (checkoutResponse.isSuccess()) {
        const orderNumber = normalizeData(checkoutResponse.success()).number;

        yield put(sendPaymentMethodSuccess(orderNumber));
        yield put({ type: USER_ACTIONS.FETCH_ALL_ADDRESSES_REQUEST });
        yield put({ type: CHECKOUT_ACTIONS.CLEAR_CHECKOUT });
        yield put({ type: CART_ACTIONS.RESET_CART });
      }
    } else {
      throw new Error('unsuccessful');
    }
  } catch (e) {
    console.log(e);
  }
}

// order details
function* fetchOrderDetail({ orderNumber }) {
  try {
    const response = yield call(
      bearerToken
        ? CheckOutApi.userCompletedOrder
        : CheckOutApi.guestCompletedOrder,
      orderNumber
    );

    const orderData = normalizeData(response.success());

    if (response.isSuccess()) {
      yield put(fetchOrderDetailSuccess(orderData));
      localStorage.removeItem('orderToken');
    } else {
      throw { ...response.fail() };
    }
  } catch (e) {
    yield put(fetchOrderDetailFailed(e));
  }
}

export function* watchaddAddressRequest() {
  yield takeLatest(
    CHECKOUT_ACTIONS.ADD_CHECKOUT_ADDRESS_REQUEST,
    checkOutAddressRequest
  );
}

export function* watchstateAddressRequest() {
  yield takeEvery(
    CHECKOUT_ACTIONS.ADD_STATE_ADDRESS_REQUEST,
    stateAddressRequest
  );
}

export function* watchaddShippingMethodRequest() {
  yield takeLatest(
    CHECKOUT_ACTIONS.ADD_SHIPPING_METHOD_REQUEST,
    shippingMethodRequest
  );
}

export function* watchsendShippingMethodRequest() {
  yield takeLatest(
    CHECKOUT_ACTIONS.SEND_SHIPPING_METHOD_REQUEST,
    sendshippingMethod
  );
}

export function* watchaddPaymentMethodRequest() {
  yield takeLatest(
    CHECKOUT_ACTIONS.ADD_PAYMENT_METHOD_REQUEST,
    paymentMethodRequest
  );
}

export function* watchsendPaymentMethodRequest() {
  yield takeLatest(
    CHECKOUT_ACTIONS.SEND_PAYMENT_METHOD_REQUEST,
    sendpaymentMethod
  );
}

export function* watchFetchOrderDetailRequest() {
  yield takeLatest(
    CHECKOUT_ACTIONS.FETCH_ORDER_DETAIL_REQUEST,
    fetchOrderDetail
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchaddAddressRequest),
    fork(watchstateAddressRequest),
    fork(watchaddShippingMethodRequest),
    fork(watchsendShippingMethodRequest),
    fork(watchaddPaymentMethodRequest),
    fork(watchsendPaymentMethodRequest),
    fork(watchFetchOrderDetailRequest),
  ]);
}
