import React from "react";

function ColorFilterItem({
  colorBg,
  colorName,
  colorId,
  currentOptionTypes,
  onChange,
  setCurrentOptionTypes,
  selectedFilterOptions,
}) {
  const handleChange = (e) => {
    if (currentOptionTypes)
      setCurrentOptionTypes({ ...currentOptionTypes, color: e.target.value });
    onChange && onChange(e.target.value);
  };

  const isSelected = selectedFilterOptions?.includes(colorBg);

  return (
    <div
      className={`custom-control custom-option text-center mt-2 me-3 ${
        isSelected ? "is-selected" : ""
      }`}
    >
      <input
        onClick={handleChange}
        className="custom-control-input"
        type="radio"
        value={colorBg}
        name="colors"
        id={colorId}
        checked={currentOptionTypes ? currentOptionTypes.color === colorBg : ""}
      />

      <label className="custom-option-label rounded-circle" htmlFor={colorId}>
        <span
          className="custom-option-color rounded-circle"
          style={{ backgroundColor: colorBg }}
        />
      </label>
      <label
        className="d-block font-size-xs text-muted mt-n1"
        htmlFor={colorId}
      >
        {colorName}
      </label>
    </div>
  );
}

export default ColorFilterItem;
