const cartOptionsArray = [
  "line_items",
  "variants",
  "variants.images",
  "variants.product.images",
  "billing_address",
  "shipping_address",
  "user",
  "payments",
  "shipments",
  "shipments.shipping_rates",
  "promotions",
];

export const cartOptions = cartOptionsArray.join();
