import USER_ACTIONS from './constants';

const initialState = {
  userInfoData: {
    userInfo: {},
    isLoading: false,
    error: false,
  },
  orderData: {
    orders: [],
    pageMetaData: null,
    currentPage: 1,
    isLoading: false,
    error: false,
  },
  addressBookData: {
    addressBook: [],
    isLoading: false,
    error: false,
    updateStatus: {
      isLoading: false,
      error: false,
      updatesSubmitted: false,
    },
    addStatus: {
      isLoading: false,
      error: false,
      newAddressSubmitted: false,
    },
  },
  reviewsData: {
    reviews: [],
    pageMetaData: null,
    currentPage: 1,
    isLoading: false,
    error: false,
  },
  questionsData: {
    questions: [],
    pageMetaData: null,
    currentPage: 1,
    isLoading: false,
    error: false,
  },
  selectedAddress: {
    billingAddress: {},
    shippingAddress: {},
  },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    // user-info
    case USER_ACTIONS.USER_INFO_REQUEST:
      return {
        ...state,
        userInfoData: {
          ...state.userInfoData,
          isLoading: true,
          error: false,
        },
      };
    case USER_ACTIONS.USER_INFO_SUCCESS:
      return {
        ...state,
        userInfoData: {
          ...state.userInfoData,
          userInfo: { ...action.userInfo },
          isLoading: false,
          error: false,
        },
      };

    case USER_ACTIONS.USER_INFO_FAILED:
      return {
        ...state,
        userInfoData: {
          ...state.userInfoData,
          isLoading: false,
          error: action.error,
        },
      };
    // orders-list
    case USER_ACTIONS.ORDER_LIST_REQUEST:
      return {
        ...state,
        orderData: {
          ...state.orderData,
          isLoading: true,
          error: false,
        },
      };
    case USER_ACTIONS.ORDER_LIST_SUCCESS:
      return {
        ...state,
        orderData: {
          ...state.orderData,
          orders: [...action.orders],
          pageMetaData: { ...action.ordersPageMetaData },
          isLoading: false,
          error: false,
        },
      };

    case USER_ACTIONS.ORDER_LIST_FAILED:
      return {
        ...state,
        orderData: {
          ...state.orderData,
          isLoading: false,
          error: action.error,
        },
      };

    // address-book
    case USER_ACTIONS.FETCH_ALL_ADDRESSES_REQUEST:
      return {
        ...state,
        addressBookData: {
          ...state.addressBookData,
          isLoading: true,
          error: false,
        },
      };
    case USER_ACTIONS.FETCH_ALL_ADDRESSES_SUCCESS:
      return {
        ...state,
        addressBookData: {
          ...state.addressBookData,
          addressBook: action.addressBook,
          isLoading: false,
          error: false,
        },
      };

    case USER_ACTIONS.FETCH_ALL_ADDRESSES_FAILED:
      return {
        ...state,
        addressBookData: {
          ...state.addressBookData,
          isLoading: false,
          error: action.error,
        },
      };
    // update-address
    case USER_ACTIONS.UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        addressBookData: {
          ...state.addressBookData,
          updateStatus: {
            ...state.addressBookData.updateStatus,
            isLoading: true,
            error: false,
          },
        },
      };
    case USER_ACTIONS.UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        addressBookData: {
          ...state.addressBookData,
          updateStatus: {
            ...state.addressBookData.updateStatus,
            updatesSubmitted: true,
            isLoading: false,
            error: false,
          },
        },
      };
    case USER_ACTIONS.UPDATE_ADDRESS_FAILED:
      return {
        ...state,
        addressBookData: {
          ...state.addressBookData,
          updateStatus: {
            ...state.addressBookData.updateStatus,
            isLoading: false,
            error: action.error,
          },
        },
      };
    case USER_ACTIONS.RESET_UPDATE_ADDRESS_STATUS:
      return {
        ...state,
        addressBookData: {
          ...state.addressBookData,
          updateStatus: {
            isLoading: false,
            error: false,
            updatesSubmitted: false,
          },
        },
      };
    // add-address
    case USER_ACTIONS.CREATE_ADDRESS_REQUEST:
      return {
        ...state,
        addressBookData: {
          ...state.addressBookData,
          addStatus: {
            ...state.addressBookData.addStatus,
            isLoading: true,
            error: false,
          },
        },
      };
    case USER_ACTIONS.CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        addressBookData: {
          ...state.addressBookData,
          addStatus: {
            ...state.addressBookData.addStatus,
            newAddressSubmitted: true,
            isLoading: false,
            error: false,
          },
        },
      };
    case USER_ACTIONS.CREATE_ADDRESS_FAILED:
      return {
        ...state,
        addressBookData: {
          ...state.addressBookData,
          addStatus: {
            ...state.addressBookData.addStatus,
            isLoading: false,
            error: action.error,
          },
        },
      };
    case USER_ACTIONS.RESET_CREATE_ADDRESS_STATUS:
      return {
        ...state,
        addressBookData: {
          ...state.addressBookData,
          addStatus: {
            isLoading: false,
            error: false,
            newAddressSubmitted: false,
          },
        },
      };

    case USER_ACTIONS.ORDER_CURRENT_PAGE:
      return {
        ...state,
        orderData: {
          ...state.orderData,
          currentPage: action.ordersCurrentPage,
        },
      };
    // user-reviews
    case USER_ACTIONS.FETCH_USER_REVIEWS_REQUEST:
      return {
        ...state,
        reviewsData: {
          ...state.reviewsData,
          isLoading: true,
          error: false,
        },
      };
    case USER_ACTIONS.FETCH_USER_REVIEWS_SUCCESS:
      return {
        ...state,
        reviewsData: {
          ...state.reviewsData,
          reviews: [...action.reviews],
          pageMetaData: { ...action.reviewsPageMetaData },
          isLoading: false,
          error: false,
        },
      };
    case USER_ACTIONS.FETCH_USER_REVIEWS_FAILED:
      return {
        ...state,
        reviewsData: {
          ...state.reviewsData,
          isLoading: false,
          error: action.error,
        },
      };
    case USER_ACTIONS.REVIEWS_CURRENT_PAGE:
      return {
        ...state,
        reviewsData: {
          ...state.reviewsData,
          currentPage: action.reviewsCurrentPage,
        },
      };
    // user-questions
    case USER_ACTIONS.FETCH_USER_QUESTIONS_REQUEST:
      return {
        ...state,
        questionsData: {
          ...state.questionsData,
          isLoading: true,
          error: false,
        },
      };
    case USER_ACTIONS.FETCH_USER_QUESTIONS_SUCCESS:
      return {
        ...state,
        questionsData: {
          ...state.questionsData,
          questions: [...action.questions],
          pageMetaData: { ...action.questionsPageMetaData },
          isLoading: false,
          error: false,
        },
      };

    case USER_ACTIONS.FETCH_USER_QUESTIONS_FAILED:
      return {
        ...state,
        questionsData: {
          ...state.questionsData,
          isLoading: false,
          error: action.error,
        },
      };

    case USER_ACTIONS.QUESTIONS_CURRENT_PAGE:
      return {
        ...state,
        questionsData: {
          ...state.questionsData,
          currentPage: action.questionsCurrentPage,
        },
      };

    // selected address
    case USER_ACTIONS.USER_SELECTED_BILL_ADDRESS:
      return {
        ...state,
        selectedAddress: {
          ...state.selectedAddress,
          billingAddress: action.selectedBillAddress,
        },
      };
    case USER_ACTIONS.USER_SELECTED_SHIP_ADDRESS:
      return {
        ...state,
        selectedAddress: {
          ...state.selectedAddress,
          shippingAddress: action.selectedShipAddress,
        },
      };

    // logout
    case USER_ACTIONS.RESET_USER_DATA:
      return initialState;
    default:
      return state;
  }
}
