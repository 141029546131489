import React from "react";
import Skeleton from "react-loading-skeleton";

const AddressSkeleton = ({ repeat }) => {
  const numberOfSkeleton = Array(repeat ?? 0).fill();

  return (
    <div className="col-md-9">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <Skeleton height={20} width={100} />
        </div>
        <div>
          <Skeleton height={20} width={100} className="me-3" />
          <Skeleton height={20} width={40} />
        </div>
      </div>
      <div className="card dashboard-card shadow-sm border-0 mb-2 py-3">
        {numberOfSkeleton.map(() => {
          return (
            <div className="border-bottom p-2 d-lg-flex flex-wrap">
              <Skeleton width={190} className="me-4" />
              <Skeleton width={300} className="me-4" />
              <Skeleton width={200} className="me-4" />
              <Skeleton width={150} className="me-4" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddressSkeleton;
