import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import logo from "assets/images/logo.svg";
import android from "assets/images/playstore.png";
import ios from "assets/images/appstore.png";

import LangSelectDropDown from "components/LangSelectDropdown/LangSelectDropDown";

import { fetchStoreConfigRequest } from "appRedux/store/actions";

import ROUTES from "constants/routes";
import SocialMediaList from "./SocialMedia/SocialMediaList";

const MobileApps = () => (
  <div>
    <h5 className="mb-3">{I18n.t("footer.followUs.downlaodOurApp")} </h5>
    <div>
      <a href="#">
        <img src={android} alt="android" className="img-fluid col-5 me-2" />
      </a>
      <a href="#">
        <img src={ios} alt="ios" className="img-fluid col-5" />
      </a>
    </div>
  </div>
);

const Footer = () => {
  const dispatch = useDispatch();
  const storeConfig = useSelector(({ storeConfig }) => storeConfig);
  const { siteConfig, isLoading } = storeConfig;

  useEffect(() => {
    dispatch(fetchStoreConfigRequest());
  }, []);

  if (!siteConfig || isLoading) return null;

  const {
    name,
    url,
    facebook,
    twitter,
    instagram,
    customer_support_email: customerSupportEmail,
    description,
    address,
    contact_phone: contactPhone,
    contact_email: contactEmail,
  } = siteConfig;

  return (
    <footer className="footer bg-light pt-5 pb-2">
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-4">
            <img src={logo} alt="virtual bazar" className="mb-3 w-25" />
            <p>
              {" "}
              {I18n.t("footer.mainText", {
                storeName: name,
              })}
            </p>
          </div>
          <div className="col-md-4 ps-auto ps-md-5">
            <h5 className="mb-3 text-gray-600">
              {" "}
              {I18n.t("footer.contactUs.title")}
            </h5>
            <ul className="address ps-0">
              <li>{name}</li>
              <li>{address}</li>
              <li>
                {I18n.t("footer.contactUs.callUsNow")}: {contactPhone}
              </li>
              <li>
                {I18n.t("footer.contactUs.email")}: {customerSupportEmail}
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <SocialMediaList
              facebook={facebook}
              twitter={twitter}
              instagram={instagram}
            />
            {/* <MobileApps /> */}
          </div>
        </div>
      </div>
      <div className="py-4 border-top">
        <div className="container">
          <div className="row">
            {/* <div className='col-md-3'>
            <ul className='links ps-0 mb-0'>
              <li>
                <a href='#'>KITCHENWARE</a>
              </li>
            </ul>
          </div>
          <div className='col-md-3'>
            <ul className='links ps-0 mb-0'>
              <li>
                <a href='#'>FITNESS GEAR</a>
              </li>
            </ul>
          </div>
          <div className='col-md-3'>
            <ul className='links ps-0 mb-0'>
              <li>
                <a href='#'>MACHINERY</a>
              </li>
            </ul>
          </div> */}

            <div className="col-md-3 mt-4 mt-md-0">
              <h5 className="mb-3 text-gray-600">
                {I18n.t("footer.user.myAccount")}
              </h5>
              <ul className="links ps-0 mb-0">
                <li>
                  <Link to={ROUTES.USER.ORDER}>
                    {I18n.t("footer.user.myOrders")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="py-1 border-top">
        <div className="container">
          <div className="row justify-content-between mt-3">
            <p className="w-auto text-muted">
              © 2020 <a href="#">{name}</a>. All rights reserved.
            </p>
            <p className="w-auto text-muted">
              Designed and developed by
              <a href="https://www.truemark.dev"> Truemark</a>
            </p>
          </div>
          <div style={{ textAlign: "right" }}>
            <LangSelectDropDown />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
