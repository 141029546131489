import { Http } from '@spree/storefront-api-v2-sdk';

import { Routes } from 'lib/spreeSdk/routes';

export default class SpreeStore extends Http {
  async list(storeID, params) {
    const spreeStoreId = storeID || process.env.REACT_APP_STORE_ID;
    const response = await this.spreeResponse(
      'get',
      Routes.storeConfigPath(spreeStoreId),
      {}, // pass token for api call
      params
    );

    return response;
  }
}
