const extractFilters = (filterObj) => {
  const options = filterObj.filter(({ type }) => type === "option_type");
  const optionValues = filterObj.filter(({ type }) => type === "option_value");

  const filters = options.map(({ id, attributes, relationships }) => {
    const filter = { id, ...attributes };
    const filterValues = relationships.option_values.data.map(
      ({ id: optionValueId }) => {
        const { id, attributes } = optionValues.find(
          ({ id }) => id === optionValueId
        );

        return { id, ...attributes };
      }
    );

    return {
      ...filter,
      values: filterValues,
    };
  });

  return filters;
};

export default extractFilters;
