import React from "react";

import QuestionForm from "components/Product/QuestionForm";

const QuestionEdit = () => {
  return (
    <div className="col-md-9">
      <QuestionForm showCancelButton />
    </div>
  );
};

export default QuestionEdit;
