import STORE_ACTIONS from './constants';

const initialSliderState = {
  storeConfig: {},
  isLoading: false,
  error: false,
};

export const sliderReducer = (state = initialSliderState, action) => {
  switch (action.type) {
    case STORE_ACTIONS.FETCH_STORE_CONFIG_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case STORE_ACTIONS.FETCH_STORE_CONFIG_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error || true,
      };

    case STORE_ACTIONS.FETCH_STORE_CONFIG_SUCCESS:
      return {
        ...state,
        siteConfig: { ...action.storeConfig },
        isLoading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default sliderReducer;
