import { Http } from '@spree/storefront-api-v2-sdk';

import { Routes } from 'lib/spreeSdk/routes';

export default class SocialLogin extends Http {
  async socialLogin(provider, params) {
    const response = await this.spreeResponse(
      'post',
      Routes.socialLoginPath(provider),
      {}, // pass token for api call
      { omniauth_callback: params }
    );

    return response;
  }
}
