import React from "react";
import { Translate } from "react-redux-i18n";

const AddressBookHeadings = ({ modalTitle }) => {
  return (
    <thead>
      <tr>
        <th scope="col">
          <Translate value="user.addressBook.addressName" />{" "}
        </th>
        <th scope="col">
          <Translate value="user.addressBook.fullName" />{" "}
        </th>
        <th scope="col">
          <Translate value="user.addressBook.address" />{" "}
        </th>
        <th scope="col">
          <Translate value="user.addressBook.phoneNumber" />{" "}
        </th>
        <th></th>
        <th></th>
        {modalTitle && <th></th>}
      </tr>
    </thead>
  );
};

export default AddressBookHeadings;
