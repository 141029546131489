const CART_ACTIONS = {
  FETCH_CART_ITEMS_REQUEST: "FETCH_CART_ITEMS_REQUEST",
  FETCH_CART_ITEMS_SUCCESS: "FETCH_CART_ITEMS_SUCCESS",
  FETCH_CART_ITEMS_FAILED: "FETCH_CART_ITEMS_FAILED",

  FETCH_CART_ITEMS: "FETCH_CART_ITEMS",

  ADD_TO_CART: "ADD_TO_CART",
  ADD_TO_CART_REQUEST: "ADD_TO_CART_REQUEST",
  ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
  ADD_TO_CART_FAILED: "ADD_TO_CART_FAILED",

  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  REMOVE_FROM_CART_FAILED: "REMOVE_FROM_CART_FAILED",
  REMOVE_FROM_CART_SUCCESS: "REMOVE_FROM_CART_SUCCESS",

  SET_ITEM_QUANTITY_REQUEST: "SET_ITEM_QUANTITY_REQUEST",
  SET_ITEM_QUANTITY_SUCCESS: "SET_ITEM_QUANTITY_SUCCESS",
  SET_ITEM_QUANTITY_FAILED: "SET_ITEM_QUANTITY_FAILED",

  EMPTY_CART_REQUEST: "EMPTY_CART_REQUEST",
  EMPTY_CART_SUCCESS: "EMPTY_CART_SUCCESS",
  EMPTY_CART_FAILED: "EMPTY_CART_FAILED",

  CART_MODAL_OPEN: "CART_MODAL_OPEN",
  CART_MODAL_CLOSE: "CART_MODAL_CLOSE",

  CART_BUTTON_CLICKED: "CART_BUTTON_CLICKED",
  RESET_CART_BUTTON_CLICK: "RESET_CART_BUTTON_CLICK",

  PROMO_CODE_REQUEST: "PROMO_CODE_REQUEST",
  PROMO_CODE_SUCCESS: "PROMO_CODE_SUCCESS",
  PROMO_CODE_FAILED: "PROMO_CODE_FAILED",

  REMOVE_PROMO_CODE_REQUEST: "REMOVE_PROMO_CODE_REQUEST",
  REMOVE_PROMO_CODE_SUCCESS: "REMOVE_PROMO_CODE_SUCCESS",
  REMOVE_PROMO_CODE_FAILED: "REMOVE_PROMO_CODE_FAILED",

  RESET_PROMO_CODE: "RESET_PROMO_CODE",
  REFILL_PROMO_CODE: "REFILL_PROMO_CODE",
  RESET_CART: "RESET_CART",
};

export default CART_ACTIONS;
