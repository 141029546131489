import Pagination from "components/Pagination";
import React from "react";
import { Translate } from "react-redux-i18n";

import OrderCardItem from "./OrderCardItem";

const OrderCard = ({
  title,
  orders,
  pageNumberHandler,
  pageMetaData,
  page,
}) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-3 pb-2">
        <h2 className="h6 d-inline font-weight-bold mb-0">{title}</h2>
        {orders.length !== 0 && (
          <div>
            <button
              className="btn btn-secondary btn-sm text-muted dropdown-toggle p-0 me-3"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Last 5 orders
            </button>
            <ul className="dropdown-menu border-0 shadow">
              <li>
                <a className="dropdown-item" href="#">
                  Last 5 orders
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Last 15 days
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Last 30 days
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Last 6 months
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  All Orders
                </a>
              </li>
            </ul>
            <button
              className="btn btn-secondary btn-sm text-muted dropdown-toggle p-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              All
            </button>
            <ul className="dropdown-menu border-0 shadow">
              <li>
                <a className="dropdown-item" href="#">
                  All
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  To pay
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  To ship
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  To Receive
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
      {orders.length !== 0 ? (
        orders.map((order) => {
          const {
            number,
            item_count,
            state,
            created_at,
            line_items,
            display_total,
          } = order;

          return (
            <OrderCardItem
              key={number}
              items={line_items}
              placedOn={created_at}
              orderNumber={number}
              orderStatus="Confirmed" // "Placed" "Confirmed" "Delivered" "Pending"
              totalPriceOverAll={display_total}
              totalItems={item_count}
              state={state}
              className="card dashboard-card shadow-sm border-0 mb-2 p-3"
            />
          );
        })
      ) : (
        <p className="text-muted">
          <Translate value="user.myOrders.emptyOrderList" />
        </p>
      )}
      <Pagination
        page={page}
        pageMetaData={pageMetaData}
        pageNumberHandler={pageNumberHandler}
        className="justify-content-center mt-3"
      />
    </>
  );
};

export default OrderCard;
