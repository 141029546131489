import client from 'services/spreeClient';

class TaxonApi {
  static fetchTaxons() {
    return client.taxons.list({include: 'image'});
  }

  static fetchTaxonName(categoryId) {
    return client.taxons.show(categoryId);
  }
}

export default TaxonApi;
