import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { reset } from 'redux-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import Address from 'pages/Checkout/Address'

import ModalAddressBook from 'components/Checkout/ModalAddressBook'
import Modal from 'components/Modal'

import { addAddressRequest } from 'appRedux/checkOut/actions'

import { ADDRESS_MODAL_TITLES } from 'constants/modalTitles'

const ShipmentInformationItem = ({ children, detail, modalID, modalTitle }) => {
  const dispatch = useDispatch()

  const [ismodalOpen, setismodalOpen] = useState(false)

  const {
    userInfoData: {
      userInfo: { email },
    },
    selectedAddress: { billingAddress, shippingAddress },
    addressBookData: { addressBook },
  } = useSelector((state) => state.user)

  const showAddressList =
    billingAddress.firstname &&
    shippingAddress.firstname &&
    (modalTitle === ADDRESS_MODAL_TITLES.BILLING_ADDRESS ||
      modalTitle === ADDRESS_MODAL_TITLES.SHIPPING_ADDRESS)

  useEffect(() => {
    if (showAddressList) {
      const bill_address_attributes = {
        firstname: billingAddress.firstname,
        lastname: billingAddress.lastname,
        address1: billingAddress.address1,
        address2: billingAddress.address2,
        city: billingAddress.city,
        phone: billingAddress.phone,
        zipcode: billingAddress.zipcode,
        state_name: billingAddress.state_name,
        country_iso: billingAddress.country_iso,
        district: 'Kathmandu',
        region: 'Bagmati',
      }
      const ship_address_attributes = {
        firstname: shippingAddress.firstname,
        lastname: shippingAddress.lastname,
        address1: shippingAddress.address1,
        address2: shippingAddress.address2,
        city: shippingAddress.city,
        phone: shippingAddress.phone,
        zipcode: shippingAddress.zipcode,
        state_name: shippingAddress.state_name,
        country_iso: shippingAddress.country_iso,
        district: 'Kathmandu',
        region: 'Bagmati',
      }

      dispatch(
        addAddressRequest({
          formData: {
            email,
            bill_address_attributes,
            ship_address_attributes,
          },
          billId: billingAddress.id,
          shipId: shippingAddress.id,
        }),
      )
      setismodalOpen(false)
    }
  }, [billingAddress, shippingAddress, showAddressList, email])

  const modalCloseHandler = () => {
    setismodalOpen(false)
    dispatch(reset('checkoutForm'))
  }

  return (
    <li className="border-bottom py-3">
      <div className="d-flex align-items-start p-2">
        {children}
        <p className="lh-base mb-0">{detail}</p>
      </div>
      <button
        className="btn p-0 text-dark"
        onClick={() => setismodalOpen(true)}
      >
        <FontAwesomeIcon icon={faPencilAlt} />
      </button>
      {ismodalOpen && (
        <Modal
          modalTitle={modalTitle}
          className="address-modal modal-dialog-centered modal-dialog-scrollable"
          closeHandler={modalCloseHandler}
        >
          {showAddressList ? (
            <ModalAddressBook
              modalTitle={modalTitle}
              addressBook={addressBook}
            />
          ) : (
            <Address modalID={modalID} setismodalOpen={setismodalOpen} />
          )}
        </Modal>
      )}
    </li>
  )
}

export default ShipmentInformationItem
