import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddressBookCard from "components/User/AddressBookCard";
import AddressSkeleton from "components/Skeleton/AddressSkeleton";

import { fetchAllAddressesRequest } from "appRedux/user/actions";

const Address = () => {
  const {
    addressBookData: { addressBook, isLoading, error },
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllAddressesRequest());
  }, []);

  if (isLoading) {
    return <AddressSkeleton repeat={13} />;
  }

  return (
    <div className={`col-md-9 ${addressBook.length !== 0 && "shadow-sm"}`}>
      <AddressBookCard addressBook={addressBook} />
    </div>
  );
};

export default Address;
