import React from 'react';
import PropTypes from 'prop-types';

const ProductTitle = ({ title }) => {
  return <h2 className='font-weight-bold'>{title}</h2>;
};

ProductTitle.defaultProps = {
  title: '',
};

ProductTitle.propTypes = {
  title: PropTypes.string,
};

export default ProductTitle;
