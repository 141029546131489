import React from "react";
import { Redirect } from "react-router";

import { USER } from "constants/routes";

const CheckIfAuthenticated = (Component) => ({ ...props }) => {
  const bearerToken = localStorage.getItem("bearerToken");

  if (bearerToken) {
    return <Redirect to={USER.PROFILE} />;
  }

  return <Component {...props} />;
};

export default CheckIfAuthenticated;
