import React from "react";
import { set } from "lodash";

import ProductPrice from "components/Product/BasicInfo/ProductPrice";
import ProductReview from "components/Product/BasicInfo/ProductReview";
import ProductTitle from "components/Product/BasicInfo/ProductTitle";
import ShareButtons from "components/ShareButton/ShareButton";

const ProductBasicInfo = ({
  productDetail,
  slug,
  ratings,
  productReviews,
  comparePrice,
  productDescription,
}) => {
  const productReviewCount = productReviews.length;

  return (
    <>
      <div className="row  align-items-start">
        <div className="col-md-8 col-12">
          <ProductTitle title={productDetail.product.name} />
        </div>
        <div className="col-md-4 col-12  text-md-end">
          <ShareButtons
            productDescription={productDescription}
            slug={slug}
            name={productDetail.product.name}
          />
        </div>
      </div>

      <ProductPrice
        displayPrice={productDetail.display_price}
        comparePrice={comparePrice}
      />

      <ProductReview ratings={ratings} totalReviews={productReviewCount} />
    </>
  );
};

export default ProductBasicInfo;
