import STORE_ACTIONS from './constants';

export const fetchStoreConfigRequest = () => ({
  type: STORE_ACTIONS.FETCH_STORE_CONFIG_REQUEST,
});

export const fetchStoreConfigFail = () => ({
  type: STORE_ACTIONS.FETCH_STORE_CONFIG_FAILED,
});

export const fetchStoreConfigSuccess = (storeConfig) => ({
  type: STORE_ACTIONS.FETCH_STORE_CONFIG_SUCCESS,
  storeConfig,
});
