import React, { useEffect } from "react";
import {
  useLocation,
  useParams,
  withRouter,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";

import Footer from "components/Footer/Footer";
import Breadcrumb from "components/Breadcrumb";
import ProductItem from "components/Product/Item";
import CategoryList from "components/Category/CategoryList";
import Layout from "components/Layout/Layout";
import CategorySkeletion from "components/Skeleton/CategorySkeletion";

import {
  fetchVendorProducts,
  fetchcurrentPage,
  setSortBy,
  clearProductFilters,
  resetProducts,
} from "appRedux/vendor/actions";

import extractImage from "utils/extractImage";
import { getParams } from "utils/queryString";
import { filterProducts } from "utils/products";

import ROUTES from "constants/routes";

function VendorDetails({ history }) {
  const { search, pathname } = useLocation();
  const { page } = getParams(search);
  const { slug } = useParams();
  const { replace } = useHistory();

  const dispatch = useDispatch();
  const {
    details,
    products: { list, currentPage, pageMetaData, sortBy, selectedFilters },
    isLoading,
    error,
  } = useSelector((state) => state.vendor);

  useEffect(() => {
    return () => {
      dispatch(resetProducts());
    };
  }, []);

  useEffect(() => {
    if (slug) {
      dispatch(fetchVendorProducts(slug));
    }
  }, [slug, currentPage, sortBy]);

  const pageNumberHandler = (fetchPage) => {
    dispatch(fetchcurrentPage(fetchPage));

    const newPath = `${pathname}?${
      fetchPage ? `page=${fetchPage}` : ""
    }${`&sortBy=${sortBy}`}`;

    replace(newPath);

    if (!slug) {
      return history.push(`${ROUTES.HOME.INDEX}`);
    }
  };

  const handleSort = (value) => {
    dispatch(setSortBy(value));
  };

  useEffect(() => {
    dispatch(clearProductFilters());
  }, []);

  useEffect(() => {
    const nextPage = page || 1;

    pageNumberHandler(nextPage);
  }, [page, sortBy]);

  useEffect(() => {
    if (slug || sortBy) {
      pageNumberHandler(1);
    }
  }, [slug, sortBy]);

  useEffect(() => {
    return () => {
      dispatch(setSortBy("default"));
    };
  }, []);

  if (isLoading || error) {
    return <CategorySkeletion />;
  }

  let filteredProducts = [];

  if (list.length !== 0) {
    filteredProducts = filterProducts(list, selectedFilters);
  }

  return (
    <Layout
      title={`${details.name} | Vendor`}
      description={details.about_us}
      metaImage={details.image}
      path={ROUTES.VENDOR.DETAIL}
    >
      <Breadcrumb secondStep={details.name} />
      <CategoryList
        sortBy={sortBy}
        //taxonImage={getTaxon?.image}
        //taxonDescription={getTaxon?.description}
        taxonName={details.name}
        handleSort={handleSort}
        pageMetaData={pageMetaData}
        productLength={filteredProducts.length}
        page={currentPage}
        pageNumberHandler={pageNumberHandler}
        category={`${details.name} (${filteredProducts.length})`}
      >
        {filteredProducts.length === 0 ? (
          <p className="mt-4">No product found</p>
        ) : (
          filteredProducts.map((product) => {
            const {
              id,
              price,
              display_price,
              images,
              name,
              slug,
              taxons,
            } = product;
            const image = extractImage(images);

            return (
              <ProductItem
                key={id}
                className="col-6 col-md-4"
                categoryId={taxons[0].id}
                image={image}
                title={name}
                newPrice={display_price}
                slug={slug}
                link={`${ROUTES.PRODUCTS.INDEX}/${slug}`}
              />
            );
          })
        )}
      </CategoryList>

      <Footer />
    </Layout>
  );
}

export default withRouter(VendorDetails);
