import React from "react";

const AddressTable = ({ title, children }) => {
  return (
    <div className="col-md-3 address-table mb-lg-0 mb-3">
      <dt className="text-uppercase text-lg-start">{title}</dt>
      <dd>
        <div className="mt-2 text-lg-start">{children}</div>
      </dd>
    </div>
  );
};

export default AddressTable;
