import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { filterProductList } from "appRedux/products/actions";

import FiltersList from "./Filters/List/FiltersList";
import Brand from "./Header/Brand";
import Search from "./Header/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const MobileNav = ({ isOpen, onToggle, menuType, children }) => {
  if (!isOpen) return null;

  if (menuType == "filter") {
    return (
      <nav className="side-nav mobile d-md-none" style={{ zIndex: 1048 }}>
        <div className="border-bottom w-100 d-flex justify-content-between align-items-center p-3">
          <p className="mb-0 font-weight-normal">Filter By:</p>
          <FontAwesomeIcon
            icon={faTimes}
            className="close text-danger"
            onClick={onToggle}
            type="button"
            aria-label="close"
          />
        </div>
        {children}
        {/* Display hidden for reusing design changes */}
        {/* <ul className='d-none'>
        <li className='border-bottom px-3 py-1'>
          <a
            className='d-inline-flex align-items-center collapsed w-100 justify-content-between my-2 font-weight-semibold'
            data-toggle='collapse'
            href='#JB100'
            role='button'
            aria-expanded='false'
          >
            JB 100 <span className='toggle-icon' />
          </a>
          <ul className='list-unstyled font-weight-normal collapse' id='JB100'>
            <li>
              <a href='#' className='d-inline-flex align-items-center'>
                Introduction
              </a>
            </li>
            <li>
              <a href='#' className='d-inline-flex align-items-center'>
                Download
              </a>
            </li>
          </ul>
        </li>
        <li className='border-bottom px-3 py-1'>
          <a
            className='d-inline-flex align-items-center collapsed w-100 justify-content-between my-2 font-weight-semibold'
            data-toggle='collapse'
            href='#Capacity'
            role='button'
            aria-expanded='false'
          >
            Capacity <span className='toggle-icon' />
          </a>
          <ul
            className='list-unstyled font-weight-normal collapse'
            id='Capacity'
          >
            <li>
              <a href='#' className='d-inline-flex align-items-center'>
                Introduction
              </a>
            </li>
            <li>
              <a href='#' className='d-inline-flex align-items-center'>
                Download
              </a>
            </li>
          </ul>
        </li>
        <li className='border-bottom px-3 py-1'>
          <a
            className='d-inline-flex align-items-center collapsed w-100 justify-content-between my-2 font-weight-semibold'
            data-toggle='collapse'
            href='#Motor'
            role='button'
            aria-expanded='false'
          >
            Motor <span className='toggle-icon' />
          </a>
          <ul className='list-unstyled font-weight-normal collapse' id='Motor'>
            <li className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                name='exampleRadios'
                id='exampleRadios1'
                value='option1'
                checked
              />
              <label className='form-check-label' htmlFor='exampleRadios1'>
                With Motor
              </label>
            </li>
            <li className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                name='exampleRadios'
                id='exampleRadios2'
                value='option2'
              />
              <label className='form-check-label' htmlFor='exampleRadios2'>
                Without Motor
              </label>
            </li>
          </ul>
        </li>
        <li className='border-bottom px-3 py-1'>
          <a
            className='d-inline-flex align-items-center collapsed w-100 justify-content-between my-2 font-weight-semibold'
            data-toggle='collapse'
            href='#Price'
            role='button'
            aria-expanded='false'
          >
            Price <span className='toggle-icon' />
          </a>
          <ul className='list-unstyled font-weight-normal collapse' id='Price'>
            <li>
              <a href='#' className='d-inline-flex align-items-center'>
                Rs.100 - Rs.1000
              </a>
            </li>
            <li>
              <a href='#' className='d-inline-flex align-items-center'>
                Rs.1000 - Rs.2000
              </a>
            </li>
          </ul>
        </li>
      </ul> */}
      </nav>
    );
  }

  return (
    <>
      <nav className="side-nav mobile d-lg-none" style={{ zIndex: 1048 }}>
        <div className="border-bottom w-100 d-flex justify-content-between align-items-center px-3 py-2">
          <Brand />
          <FontAwesomeIcon
            icon={faTimes}
            className="close text-danger"
            onClick={onToggle}
            type="button"
            aria-label="close"
          />
        </div>
        <Search onToggle={onToggle} />
      </nav>
    </>
  );
};

MobileNav.defaultProps = {
  isOpen: false,
  onToggle: () => {},
};

MobileNav.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

function SideNav({ isOpen, onToggle, menuType }) {
  const { filters, selectedFilters } = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const handleProductFilter = (name, value) => {
    dispatch(filterProductList(name, value));
  };

  return (
    <>
      <MobileNav isOpen={isOpen} onToggle={onToggle} menuType={menuType}>
        <FiltersList
          handleProductFilter={handleProductFilter}
          filters={filters}
          selectedFilters={selectedFilters}
        />
      </MobileNav>
      <nav className="side-nav desktop d-none d-lg-block sticky-top">
        <FiltersList
          handleProductFilter={handleProductFilter}
          selectedFilters={selectedFilters}
          filters={filters}
        />
      </nav>
    </>
  );
}

SideNav.defaultProps = {
  isOpen: false,
  onToggle: () => {},
};

SideNav.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default SideNav;
