import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import ProductReviewApi from "api/productReview/productReview";
import { normalizeData } from "services/jsonaDataFormatter";

import { USER } from "constants/routes";

import { fetchReviewsRequest } from "appRedux/user/actions";
import {
  fetchAllProductReview,
  fetchAllProductReviewSuccess,
  createProductReviewFail,
  editProductReviewFail,
  removeProductReviewFail,
  editProductReviewSuccess,
  createProductReviewSuccess,
  removeProductReviewSuccess,
} from "./actions";
import PRODUCT_REVIEW_ACTIONS from "./constants";

/**
 * Fetch all product review
 */

function* fetchAllProductReviews({ id }) {
  try {
    const productReviewsData = yield call(ProductReviewApi.fetchAllReviews, id);

    if (productReviewsData.isSuccess()) {
      const productReviews = normalizeData(productReviewsData.success());

      yield put(fetchAllProductReviewSuccess(productReviews));
    } else {
      throw productReviewsData.fail();
    }
  } catch (e) {
    console.log(e);
  }
}

/**
 * Add product review
 */

function* createProductReviewRequest({ formData, productId }) {
  try {
    const productReviewsData = yield call(
      ProductReviewApi.createProductReview,
      { formData, productId }
    );

    if (productReviewsData.isSuccess()) {
      yield put(fetchAllProductReview(productId));
      yield put(createProductReviewSuccess());
    } else {
      throw productReviewsData.fail();
    }
  } catch (e) {
    yield put(createProductReviewFail(e));
  }
}

/**
 * Edit product review
 */

function* editProductReviewRequest({ formData, productId, reviewId, history }) {
  try {
    const productReviewsData = yield call(ProductReviewApi.editProductReview, {
      formData,
      productId,
      reviewId,
    });

    if (productReviewsData.isSuccess()) {
      yield put(editProductReviewSuccess());
      history.push(USER.REVIEW.INDEX);
    } else {
      throw productReviewsData.fail();
    }
  } catch (e) {
    yield put(editProductReviewFail(e));
  }
}

/**
 * Delete product review
 */

function* removeProductReviewRequest({
  productId,
  reviewId,
  currentPage,
  userId,
}) {
  try {
    const productReviewsData = yield call(
      ProductReviewApi.removeProductReview,
      {
        productId,
        reviewId,
      }
    );

    if (productReviewsData.isSuccess()) {
      yield put(removeProductReviewSuccess());
      yield put(fetchReviewsRequest({ currentPage, userId }));
    } else {
      throw productReviewsData.fail();
    }
  } catch (e) {
    yield put(removeProductReviewFail(e));
  }
}

/**
 * Saga action listeners
 */

export function* watchFetchAllProductReviewsRequest() {
  yield takeEvery(
    PRODUCT_REVIEW_ACTIONS.FETCH_ALL_PRODUCT_REVIEW_REQUEST,
    fetchAllProductReviews
  );
}

export function* watchCreateProductReviewRequestRequest() {
  yield takeEvery(
    PRODUCT_REVIEW_ACTIONS.CREATE_PRODUCT_REVIEW_REQUEST,
    createProductReviewRequest
  );
}

export function* watchEditProductReviewRequestRequest() {
  yield takeEvery(
    PRODUCT_REVIEW_ACTIONS.EDIT_PRODUCT_REVIEW_REQUEST,
    editProductReviewRequest
  );
}

export function* watchDeleteProductReviewRequestRequest() {
  yield takeEvery(
    PRODUCT_REVIEW_ACTIONS.REMOVE_PRODUCT_REVIEW_REQUEST,
    removeProductReviewRequest
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchAllProductReviewsRequest),
    fork(watchCreateProductReviewRequestRequest),
    fork(watchEditProductReviewRequestRequest),
    fork(watchDeleteProductReviewRequestRequest),
  ]);
}
