import { Http } from "@spree/storefront-api-v2-sdk";

import { Routes } from "lib/spreeSdk/routes";

export default class ProductReview extends Http {
  static isContentTypeMultiForm = false;

  async list(token = {}, productId, params) {
    return this.spreeResponse(
      "get",
      Routes.productReviewPath(productId),
      token, // does not need token so pass empty object
      params
    );
  }

  async listByUser(token, userId, params) {
    return this.spreeResponse(
      "get",
      Routes.userReviewPath(userId),
      token, // pass token for api call
      params
    );
  }

  async createReview(token, productId, params) {
    this.isContentTypeMultiForm = true;

    const response = await this.spreeResponse(
      "post",
      Routes.productReviewPath(productId),
      token, // pass token for api call
      params
    );

    this.isContentTypeMultiForm = false;

    return response;
  }

  async updateReview(token, productId, reviewId, params) {
    this.isContentTypeMultiForm = true;

    const response = await this.spreeResponse(
      "patch",
      Routes.reviewDetailPath(productId, reviewId),
      token, // pass token for api call
      params
    );

    this.isContentTypeMultiForm = false;

    return response;
  }

  async removeReview(token, productId, reviewId) {
    const response = await this.spreeResponse(
      "delete",
      Routes.reviewDetailPath(productId, reviewId),
      token // pass token for api call
    );

    return response;
  }

  spreeOrderHeaders(tokens) {
    const header = {};

    if (tokens.orderToken) {
      header["X-Spree-Order-Token"] = tokens.orderToken;
    }

    if (tokens.bearerToken) {
      header["Authorization"] = `Bearer ${tokens.bearerToken}`;
    }

    if (this.isContentTypeMultiForm) {
      header["Content-Type"] = "multipart/form-data";
    }

    return header;
  }
}
