import trendlineLogo from "assets/images/logo.png";
import productPlaceholder from "assets/images/loader/placeholder-image.jpg"

const SITE_DATA = {
  title: "Store front", // Navigation and site title
  titleAlt: "Store front", // Title for schema.org JSONLD
  description: "Ecommerce site.",
  url: "https://www.trendlinestore.com", // Domain of site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  image: {
    src: trendlineLogo,
    width: 384,
    height: 384,
  },
  keywords: [],
  ogLanguage: "en_US", // Facebook Language
  // googleAnalyticsID: '',

  // JSONLD / Manifest
  favicon: "/favicon.ico", // Used for manifest favicon generation
  shortName: "Store Front", // shortname for manifest.
  author: {
    // Author for schema.org JSONLD
    name: "Truemark Technology",
    url: "https://truemark.dev",
  },
  themeColor: "#ffffff",
  backgroundColor: "#111111",

  twitter: "", // Twitter username
  twitterUrl: "",
  twitterCardType: "summary_large_image",
  facebook: "", // Facebook site name
  // githubUrl: '',
  // instagramUrl: 'https://www.instagram.com/id/',
  // feedUrl: '/atom.xml',
};

export const productDefaultMetaImage = productPlaceholder;

export default SITE_DATA;
