import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLocale } from 'react-redux-i18n';

const LangSelectDropDown = () => {
  const currentLocale = useSelector((state) => state.i18n.locale);
  const dispatch = useDispatch();
  const [lang, setlang] = useState(currentLocale);

  useEffect(() => {
    dispatch(setLocale(lang));
  }, [lang]);

  const handleChange = (e) => {
    setlang(e.target.value);
  };

  return (
    <div>
      <select onChange={handleChange} value={lang}>
        <option value='en_US'>English</option>
        <option value='np_NP'>Nepali</option>
      </select>
    </div>
  );
};

export default LangSelectDropDown;
