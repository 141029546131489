import React from "react";
import PropTypes from "prop-types";
import ReactStars from "react-rating-stars-component";

import REACT_STARS from "constants/reactStarsConfig";
import pluralizeWord from "utils/pluralizeWord";

const ProductReview = ({ ratings, totalReviews }) => {
  return (
    <div className="d-flex align-items-center pb-3 border-bottom">
      <ReactStars
        value={ratings}
        count={REACT_STARS.COUNT}
        size={REACT_STARS.SIZE}
        edit={false}
        activeColor={REACT_STARS.ACTIVE_COLOR}
      />
      <span className="ms-3 mt-2 text-secondary">
        {totalReviews}{" "}
        {pluralizeWord("productDetail.reviews.title", totalReviews)}
      </span>
    </div>
  );
};

ProductReview.defaultProps = {
  ratings: 0,
  totalReviews: 0,
};

ProductReview.propTypes = {
  ratings: PropTypes.number,
  totalReviews: PropTypes.number,
};

export default ProductReview;
