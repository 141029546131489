import React, { useEffect } from "react";
import { useLocation, useParams, withRouter } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import Footer from "components/Footer/Footer";
import Breadcrumb from "components/Breadcrumb";

import ProductShow from "containers/Product/Show";

import { fetchProductsRequest } from "appRedux/products/actions";

import { getParams } from "utils/queryString";

import ROUTES from "constants/routes";

function ProductDetail({ history }) {
  const { search } = useLocation();
  const { taxon_id } = getParams(search);
  const { slug } = useParams();
  const { products, isLoading: isTaxonProductsLoading } = useSelector(
    (state) => state.products
  );
  const { product, isLoading } = useSelector((state) => state.productDetail);
  const { taxons } = useSelector((state) => state.taxons);
  const { isLoading: isProductQuestionsLoading } = useSelector(
    ({ productQuestion }) => productQuestion
  );
  const {
    wishlist: {
      wishlistData: { isLoading: isWishlistLoading },
    },
  } = useSelector((state) => state.wishlist);

  const dispatch = useDispatch();

  let productName = "";
  let taxonName = "";
  let alternateTaxonID = null;

  useEffect(() => {
    dispatch(
      fetchProductsRequest({
        filter: { taxons: taxon_id || alternateTaxonID },
        perPage: 25,
      })
    );
  }, [taxon_id, alternateTaxonID]);

  useEffect(() => {
    if (alternateTaxonID) {
      const search = `?taxon_id=${alternateTaxonID}`;

      history.push({
        pathname: `${ROUTES.PRODUCTS.INDEX}/${slug}`,
        search,
      });
    }
  }, [alternateTaxonID]);

  const taxonID = Object.keys(products)[0];

  if (!taxon_id) {
    if (product.taxons) {
      alternateTaxonID = product.taxons[0].id;
      taxonName = product.taxons[0].name;
      productName = product.name;
    }
  } else if (products[taxonID]) {
    productName = product.name;
    taxonName = products[taxonID].name;
  }

  const taxonRelatedProducts = products[taxonID]
    ? products[taxonID].products
    : [];

  const getTaxon = taxons?.find((taxon) => taxon.name === taxonName);

  const taxonId = getTaxon?.id;

  return (
    <>
      {!isLoading &&
        !isWishlistLoading &&
        !isTaxonProductsLoading &&
        !isProductQuestionsLoading && (
          <Breadcrumb
            url={`${ROUTES.CATEGORY.addTaxonId(
              ROUTES.CATEGORY.INDEX,
              taxonId
            )}`}
            secondStep={taxonName}
            thirdStep={productName}
          />
        )}
      <ProductShow
        isTaxonProductsLoading={isTaxonProductsLoading}
        taxonRelatedProducts={taxonRelatedProducts}
        categoryId={taxon_id || alternateTaxonID}
      />
      <Footer />
    </>
  );
}

export default withRouter(ProductDetail);
