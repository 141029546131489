import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import StoreApi from 'api/store/storeApi';

import { normalizeData } from 'services/jsonaDataFormatter';

import { fetchStoreConfigSuccess, fetchStoreConfigFail } from './actions';
import SLIDER_ACTIONS from './constants';

/**
 * Fetch slider
 */

function* fetchSiteConfig({ storeID }) {
  try {
    const storeData = yield call(StoreApi.fetchConfig, storeID);

    if (storeData.isSuccess()) {
      const slider = normalizeData(storeData.success());

      yield put(fetchStoreConfigSuccess(slider));
    } else {
      throw storeData.fail();
    }
  } catch (e) {
    console.log(e);
    yield put(fetchStoreConfigFail(e));
  }
}

/**
 * Saga action listeners
 */

export function* watchFetchStoreConfigRequest() {
  yield takeEvery(SLIDER_ACTIONS.FETCH_STORE_CONFIG_REQUEST, fetchSiteConfig);
}

export default function* rootSaga() {
  yield all([fork(watchFetchStoreConfigRequest)]);
}
