import React, { useState } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { productReviewSetting } from "constants/reactSlickSettings";
import ReviewDetailCarousel from "./ReviewDetailCarousel";

const PrevArrow = ({ className, onClick }) => (
  <button onClick={onClick} className={`arrow shadow-sm border ${className}`}>
    <FontAwesomeIcon icon={faChevronLeft} />
  </button>
);

const NextArrow = ({ className, onClick }) => (
  <button onClick={onClick} className={`arrow shadow-sm border ${className}`}>
    <FontAwesomeIcon icon={faChevronRight} />
  </button>
);

const settings = {
  ...productReviewSetting,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const ReviewCarousel = ({
  images,
  show,
  rating,
  displayName,
  fullName,
  approved,
  createdAt,
  title,
  review,
}) => {
  const [openModel, setOpenModel] = useState({ showModel: false, index: 0 });

  const handleClick = (index = 0) => {
    setOpenModel((previous) => ({ showModel: !previous.showModel, index }));
  };

  if (!show) return null;

  return (
    <div className="slick-review-container mb-3">
      <Slider {...settings}>
        {images.map((image, index) => (
          <img
            src={image}
            alt={image}
            key={index}
            className="slide-image rounded"
            onClick={() => handleClick(index)}
          />
        ))}
      </Slider>
      <ReviewDetailCarousel
        show={openModel.showModel}
        images={images}
        handleModelClose={() => handleClick()}
        index={openModel.index}
        rating={rating}
        displayName={displayName}
        fullName={fullName}
        approved={approved}
        createdAt={createdAt}
        title={title}
        review={review}
      />
    </div>
  );
};

export default ReviewCarousel;
