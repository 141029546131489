import React from 'react'

function CheckboxFilterItem({ checkboxID, label, filterName, onChange }) {
  const handleChange = (e) => {
    onChange(e.target.value)
  }

  return (
    <li className="form-check">
      <form>
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="checkbox"
            name={filterName}
            value={label}
            onClick={handleChange}
          />
          {label}
        </label>
      </form>
    </li>
  )
}

export default CheckboxFilterItem
