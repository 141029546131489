import React from "react";
import { Translate } from "react-redux-i18n";

const OrderSummaryItems = ({
  itemCount,
  displayItemTotal,
  displayAdjustmentTotal,
  displayShipTotal,
  displayTaxTotal,
}) => {
  return (
    <ul className="list-unstyled cart-overview lh-lg border-bottom pb-3">
      <li>
        <Translate value="orderSummary.orderSummary.totalItems" /> :
        <span className="text-muted">{itemCount}</span>
      </li>
      <li>
        <Translate value="orderSummary.orderSummary.subTotal" /> :
        <span className="text-muted">{displayItemTotal}</span>
      </li>

      <li>
        <Translate value="orderSummary.orderSummary.discount" /> :
        <span className="text-muted">{displayAdjustmentTotal}</span>
      </li>

      <li>
        <Translate value="orderSummary.orderSummary.shipping" /> :
        <span className="text-muted">{displayShipTotal}</span>
      </li>
      <li>
        <Translate value="orderSummary.orderSummary.tax" /> :
        <span className="text-muted">{displayTaxTotal}</span>
      </li>
    </ul>
  );
};

export default OrderSummaryItems;
