import React from "react";
import PropTypes from "prop-types";

import ROUTES from "constants/routes";

const ProductItem = ({
  categoryId,
  image,
  title,
  oldPrice,
  newPrice,
  className,
  category,
  slug,
}) => {
  return (
    <div className={`${className}`}>
      <div className={`card product-card border-0 shadow-sm`}>
        <a href={`${ROUTES.PRODUCTS.INDEX}/${slug}?taxon_id=${categoryId}`}>
          <img src={image} className="img-fluid w-100 product-img" alt="" />
          <div className="p-md-3 py-3">
            <small className="text-muted">{category}</small>
            <h3 className="h6 title">{title}</h3>
            <h6 className="price mt-2">
              {newPrice}
              <span className="ms-2 text-decoration-line-through text-muted">
                {oldPrice}
              </span>
            </h6>
          </div>
        </a>
      </div>
    </div>
  );
};

ProductItem.defaultProps = {};

ProductItem.propTypes = {};

export default ProductItem;
