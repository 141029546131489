import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import { I18n, Translate } from "react-redux-i18n";
import { withRouter, useParams } from "react-router-dom";
import { reduxForm } from "redux-form";
import { Field } from "redux-form-normalize-on-blur";
import { useDispatch, useSelector, connect } from "react-redux";

import {
  renderField,
  renderTextAreaField,
} from "components/Form/FormFieldRender";

import {
  createProductReviewRequest,
  editProductReviewRequest,
} from "appRedux/productReview/actions";

import { required } from "utils/fieldValidation";
import REACT_STARS from "constants/reactStarsConfig";
import ROUTES from "constants/routes";

import ReviewNameField from "./ReviewNameField";
import { getFullName } from "utils/utils";
import ReviewAnonymousCheckbox from "./ReviewAnonymousCheckbox";

function ReviewForm({ handleSubmit, setToggleReviewForm, history }) {
  const { reviewId } = useParams();

  const {
    userReview: { isLoading, error },
  } = useSelector((state) => state.productReview);
  const {
    reviewsData: { reviews },
    userInfoData: { userInfo },
  } = useSelector((state) => state.user);
  const {
    product: { id },
  } = useSelector(({ productDetail }) => productDetail);

  const review = reviews.find((review) => {
    return review.id === reviewId;
  });

  const [starsValue, setStarsValue] = useState(
    reviewId ? review.rating : REACT_STARS.INITIAL_VALUE
  );

  const [selectedFiles, setSelectedFiles] = useState([]);

  const onFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const dispatch = useDispatch();

  const submit = (values) => {
    const formData = new FormData();

    formData.append("review[title]", values.title);
    formData.append("review[review]", values.review);
    formData.append(
      "review[name]",
      values.name || getFullName(userInfo.default_billing_address)
    );
    formData.append("review[rating]", starsValue.toString());
    formData.append(
      "review[show_identifier]",
      Boolean(values.dontShowIdentifier)
    );

    selectedFiles.forEach((file, index) => {
      formData.append(`review[images_attributes][${index}][attachment]`, file);
    });

    if (reviewId) {
      dispatch(
        editProductReviewRequest({
          productId: review.product.id,
          reviewId,
          history,
          formData,
        })
      );
    } else {
      dispatch(
        createProductReviewRequest({
          productId: id,
          formData,
        })
      );
    }

    setToggleReviewForm(false);
  };

  const bearerToken = localStorage.getItem("bearerToken");

  if (!bearerToken) {
    return (
      <>
        <Link to={ROUTES.LOGIN.INDEX} className="btn btn-primary py-3">
          <Translate value="review.loginToAddReview" />
        </Link>
        <button
          type="button"
          onClick={() => setToggleReviewForm(false)}
          className="btn btn-secondary"
        >
          <Translate value="review.cancelButton" />
        </button>
      </>
    );
  }
  const handleCancel = () => {
    if (reviewId) {
      return history.push(ROUTES.USER.REVIEW.INDEX);
    }
    setToggleReviewForm(false);
  };

  // TODO: @offmausam :add skeleton ui
  if (isLoading) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="mb-3 mt-3">
        <label htmlFor="exampleInputEmail1" className="form-label">
          <Translate value="review.title" />
        </label>
        <ReactStars
          value={starsValue}
          onChange={(newRating) => setStarsValue(newRating)}
          count={REACT_STARS.COUNT}
          size={REACT_STARS.SIZE}
          activeColor={REACT_STARS.ACTIVE_COLOR}
        />
      </div>

      <ReviewNameField
        isFullNamePresent={Boolean(
          getFullName(userInfo.default_billing_address)
        )}
      />
      <Field
        name="title"
        type="text"
        component={renderField}
        label={`${I18n.t("form.fields.title")} *`}
        validate={[required]}
      />
      <Field
        name="review"
        component={renderTextAreaField}
        label={`${I18n.t("form.fields.yourReview")} *`}
        validate={[required]}
      />
      <div className="my-3">
        <input
          type="file"
          id="reviewImages"
          onChange={onFileChange}
          className="review-images-field form-control"
          multiple
        />
      </div>

      <ReviewAnonymousCheckbox />

      <button type="submit" className="btn btn-primary py-2 px-3">
        <Translate value="review.button" />
      </button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn btn-secondary"
      >
        <Translate value="review.cancelButton" />
      </button>
    </form>
  );
}

const mapStateToProps = (state, props) => {
  const { reviews } = state.user.reviewsData;

  const review = reviews.find((review) => {
    return review.id === props.match.params.reviewId;
  });

  if (review) {
    return {
      initialValues: {
        name: review.name,
        title: review.title,
        review: review.review,
        dontShowIdentifier: review?.show_identifier,
      },
    };
  }
};

export default withRouter(
  connect(mapStateToProps)(reduxForm({ form: "review" })(ReviewForm))
);
