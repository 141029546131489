import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBorderAll,
  faCartPlus,
  faHeart,
  faQuestion,
  faStarHalfAlt,
  faUser,
  faBook,
} from "@fortawesome/free-solid-svg-icons";

import ROUTES from "constants/routes";

const StickyFooter = ({ className, active }) => {
  return (
    <div className={`sticky-footer bottom  py-3 shadow ${className}`}>
      <div className="container">
        <ul className="icon-menu ps-0 mb-0 text-white">
          {/* TODO: we are currently hiding dashboard */}
          {/* <li>
            <Link
              to={ROUTES.USER.DASHBOARD}
              className={cx('menu-item', {
                active: active === 'Dashboard',
              })}
            >
              <FontAwesomeIcon icon={faBorderAll} />
            </Link>
          </li> */}
          <li>
            <Link
              to={ROUTES.USER.ORDER}
              className={cx("menu-item", {
                active: active === "My Orders",
              })}
            >
              <FontAwesomeIcon icon={faCartPlus} />
            </Link>
          </li>
          <li>
            <Link
              to={ROUTES.USER.REVIEW.INDEX}
              className={cx("menu-item", {
                active: active === "My Reviews",
              })}
            >
              <FontAwesomeIcon icon={faStarHalfAlt} />
            </Link>
          </li>
          <li>
            <Link
              to={ROUTES.USER.QUESTION.INDEX}
              className={cx("menu-item", {
                active: active === "My Questions",
              })}
            >
              <FontAwesomeIcon icon={faQuestion} />
            </Link>
          </li>
          <li>
            <Link
              to={ROUTES.USER.WISHLIST.INDEX}
              className={cx("menu-item", {
                active: active === "My Wishlists",
              })}
            >
              <FontAwesomeIcon icon={faHeart} />
            </Link>
          </li>
          <li>
            <Link
              to={ROUTES.USER.ADDRESS.INDEX}
              className={cx("menu-item", {
                active: active === "Address Book",
              })}
            >
              <FontAwesomeIcon icon={faBook} />
            </Link>
          </li>
          <li>
            <Link
              to={ROUTES.USER.PROFILE}
              className={cx("menu-item", {
                active: active === "Profile",
              })}
            >
              <FontAwesomeIcon icon={faUser} />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StickyFooter;
