import React from "react";
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBorderAll,
  faCartPlus,
  faHeart,
  faStarHalfAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import ROUTES from "constants/routes";

const SideMenu = ({ className, active }) => {
  return (
    <div className={`side-menu py-2 shadow-sm sticky-top ${className}`}>
      <div className="container">
        <ul className="icon-menu ps-0 mb-0 text-white font-weight-semibold">
          {/* TODO: we are currently hiding dashboard */}
          {/* <li className="py-2">
            <Link
              to={ROUTES.USER.DASHBOARD}
              className={cx('menu-item', {
                active: active === 'Dashboard',
              })}
            >
              {I18n.t('user.dashboard.title')}
            </Link>
          </li> */}
          <li className="py-2">
            <Link
              to={ROUTES.USER.ORDER}
              className={cx("menu-item", {
                active: active === "My Orders",
              })}
            >
              {I18n.t("user.myOrders.title")}
            </Link>
          </li>
          <li className="py-2">
            <Link
              to={ROUTES.USER.REVIEW.INDEX}
              className={cx("menu-item", {
                active: active === "My Reviews",
              })}
            >
              {I18n.t("user.myReviews.title")}
            </Link>
          </li>
          <li className="py-2">
            <Link
              to={ROUTES.USER.QUESTION.INDEX}
              className={cx("menu-item", {
                active: active === "My Questions",
              })}
            >
              {I18n.t("user.myQuestions.title")}
            </Link>
          </li>
          <li className="py-2">
            <Link
              to={ROUTES.USER.WISHLIST.INDEX}
              className={cx("menu-item", {
                active: active === "My Wishlists",
              })}
            >
              {I18n.t("user.myWishList.title")}
            </Link>
          </li>
          <li className="py-2">
            <Link
              to={ROUTES.USER.ADDRESS.INDEX}
              className={cx("menu-item", {
                active: active === "Address Book",
              })}
            >
              {I18n.t("user.addressBook.title")}
            </Link>
          </li>
          <li className="py-2">
            <Link
              to={ROUTES.USER.PROFILE}
              className={cx("menu-item", {
                active: active === "Profile",
              })}
            >
              {I18n.t("user.profile.title")}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
