import React from 'react'
import { I18n } from 'react-redux-i18n'

import kitchenware from 'assets/images/category/kitchenware.jpg'
import travelKit from 'assets/images/category/travel-kit.jpg'
import sewingKit from 'assets/images/category/sewing-kit.jpg'

import ROUTES from 'constants/routes'

function FeaturedCategory() {
  return (
    <section className="py-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-6 mb-4 mb-lg-0">
            <div className="featured-category shadow-sm">
              <img className="img-fluid" src={travelKit} alt="travel kit" />
              <div className="overlay">
                <div className="category-info text-dark">
                  <h3 className="title">Eco Friendly Travel Kit</h3>
                  <a
                    href={`${ROUTES.CATEGORY.addTaxonId(
                      ROUTES.CATEGORY.INDEX,
                      3
                    )}`}
                    className="btn px-3 py-2 text-white bg-dark mt-2"
                  >
                    {I18n.t('featuredCategory.buttons.viewProducts')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6">
            <div className="featured-category shadow-sm">
              <img className="img-fluid" src={sewingKit} alt="kitchen ware" />
              <div className="overlay">
                <div className="category-info text-dark">
                  <h3 className="title">Portable Sewing Kit</h3>
                  <a
                    href={`${ROUTES.CATEGORY.addTaxonId(
                      ROUTES.CATEGORY.INDEX,
                      7
                    )}`}
                    className="btn px-3 py-2 text-white bg-dark mt-2"
                  >
                    {I18n.t('featuredCategory.buttons.viewProducts')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedCategory
