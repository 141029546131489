import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const UserInfo = ({ className, email, lastname, firstname }) => {
  return (
    <div
      className={`d-flex flex-row justify-content-between shadow-sm border-0 p-3 ${className}`}
    >
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="rounded-circle user-image-container me-3 d-flex justify-content-center align-items-center">
          <FontAwesomeIcon icon={faUser} className="user-image" />
        </div>
        <div className="user-detail">
          <p className="mb-0 font-weight-semibold">{`${firstname} ${lastname}`}</p>
          <p className="mb-0 text-muted">{email}</p>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
