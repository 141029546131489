import client from 'services/spreeClient';
import { getUserToken, getOrderToken } from 'services/getToken';

import { cartOptions } from 'constants/cartOptions';

class CheckOutApi {
  static promoCodeRequest(promoCode) {
    return client.cart.applyCouponCode(
      {
        ...getUserToken(),
      },
      {
        coupon_code: promoCode,
      }
    );
  }

  static sendCheckOutAddressRequest({ formData, userId, billId, shipId }) {
    return client.checkout.orderUpdate(
      {
        ...getUserToken(),
      },
      {
        order: {
          email: formData.email,
          bill_address_attributes: {
            ...formData.bill_address_attributes,
            user_id: userId || '',
            id: billId,
          },
          ship_address_attributes: {
            ...formData.ship_address_attributes,
            user_id: userId || '',
            id: shipId,
          },
        },
      }
    );
  }

  static sendshippingMethodRequest() {
    return client.checkout.shippingMethods({
      ...getUserToken(),
    });
  }

  static sendpaymentMethodRequest() {
    return client.checkout.paymentMethods({
      ...getUserToken(),
    });
  }

  static sendshippingMethod({
    deliveryOptionId,
    selectedDeliveryOptionId,
    special_instructions,
  }) {
    return client.checkout.orderUpdate(
      {
        ...getUserToken(),
      },
      {
        order: {
          special_instructions,
          shipments_attributes: [
            {
              id: deliveryOptionId,
              selected_shipping_rate_id: selectedDeliveryOptionId,
            },
          ],
        },
      }
    );
  }

  static sendpaymentMethod(selectedPaymentMethod) {
    return client.checkout.orderUpdate(
      {
        ...getUserToken(),
      },
      {
        order: {
          payments_attributes: [
            {
              payment_method_id: selectedPaymentMethod.id,
            },
          ],
        },
      }
    );
  }

  static orderNext() {
    return client.checkout.orderNext({
      ...getUserToken(),
    });
  }

  static complete() {
    return client.checkout.complete({
      ...getUserToken(),
    });
  }

  static userCompletedOrder(orderNumber) {
    return client.account.completedOrder(
      {
        ...getUserToken(),
      },
      orderNumber,
      {
        include: cartOptions,
      }
    );
  }

  static guestCompletedOrder(orderNumber) {
    return client.order.status(
      {
        ...getOrderToken(),
      },
      orderNumber,
      {
        include: cartOptions,
      }
    );
  }

  static payWithKhalti(khaltiToken, amount){
    return client.paymentGateway.payWithKhalti(
      {
        ...getOrderToken(),
      },
      'khalti',
      {
        payment_method: {
          token: khaltiToken,
          amount
       }
      }
    )
  }
}

export default CheckOutApi;
