import { I18n } from "react-redux-i18n";

const pluralizeWord = (translationAttribute, quantity) => {
  if (quantity === 1) {
    return I18n.t(`${translationAttribute}`);
  }

  return I18n.t(`${translationAttribute}_plural`);
};

export default pluralizeWord;
