import React from "react";

import ReviewForm from "components/Product/ReviewForm";

const ReviewEdit = () => {
  return (
    <div className="col-md-9">
      <ReviewForm />
    </div>
  );
};

export default ReviewEdit;
