import React from "react";
import { I18n } from "react-redux-i18n";

import DeliveryOption from "pages/Checkout/Delivery";
import CollapseItem from "../CollapseItem";

const DeliveryOptionSide = ({ isDeliveryChoosen, isformSumitted }) => {
  return (
    <CollapseItem
      title={I18n.t("orderSummary.deliveryOptions")}
      className="delivery-option"
      wrapperClass="shadow-sm border-0 border-bottom"
      itemID="delivery"
      activeStatus={isformSumitted && isDeliveryChoosen}
      headingSize="h5"
    >
      <DeliveryOption fromSide />
    </CollapseItem>
  );
};

export default DeliveryOptionSide;
