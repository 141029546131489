import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import WishlistForm from "components/User/WishlistForm";

import { fetchWishlistDetailsRequest } from "appRedux/wishlist/actions";

const WishlistEdit = () => {
  const { accessHash } = useParams();

  const {
    wishlist: {
      wishlistDetails: { detail, isLoading },
    },
  } = useSelector((state) => state.wishlist);

  const dispatch = useDispatch();

  useEffect(() => {
    if (accessHash && !detail.name) {
      dispatch(fetchWishlistDetailsRequest(accessHash));
    }
  }, [accessHash]);

  if (isLoading) {
    return (
      <div className="backdrop d-flex align-items-center justify-content-center">
        <div className="spinner">Loading...</div>
      </div>
    );
  }

  return <WishlistForm form="edit" />;
};

export default WishlistEdit;
