import { I18n } from "react-redux-i18n";

// field-level-validators
export const required = (value) =>
  value ? undefined : I18n.t("form.errors.required");

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? I18n.t("form.errors.invalidEmail")
    : undefined;

export const password = (value) =>
  value &&
  !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/i.test(value)
    ? I18n.t("form.errors.passwordConfirmation")
    : undefined;

export const passwordsMustMatch = (value, allValues) =>
  value !== allValues.password
    ? I18n.t("form.errors.passwordDontMatch")
    : undefined;

export const phoneNumber = (value) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? I18n.t("form.errors.phoneNumber")
    : undefined;

export const zipCode = (value) =>
  value && !/^\d{5}(?:[-\s]\d{4})?$/i.test(value)
    ? I18n.t("form.errors.zipcode")
    : undefined;

// validators
const validate = (values) => {
  const errors = {
    ship_address_attributes: {},
    bill_address_attributes: {},
  };

  const ship_address_attributes = values.ship_address_attributes || {};
  const bill_address_attributes = values.bill_address_attributes || {};

  if (!values.email) {
    errors.email = "This Field is Required.";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!ship_address_attributes.phone) {
    errors.ship_address_attributes.phone = "This Field is Required.";
  } else if (
    !/^(0|[1-9][0-9]{9})$/i.test(values.ship_address_attributes.phone)
  ) {
    errors.ship_address_attributes.phone =
      "Invalid phone number, must be 10 digits";
  }
  if (!bill_address_attributes.phone) {
    errors.bill_address_attributes.phone = "This Field is Required.";
  } else if (
    !/^(0|[1-9][0-9]{9})$/i.test(values.bill_address_attributes.phone)
  ) {
    errors.bill_address_attributes.phone =
      "Invalid phone number, must be 10 digits";
  }

  if (!bill_address_attributes.zipcode) {
    errors.bill_address_attributes.zipcode = "This Field is Required.";
  } else if (
    !/^\d{5}(?:[-\s]\d{4})?$/i.test(values.bill_address_attributes.zipcode)
  ) {
    errors.bill_address_attributes.zipcode =
      "Invalid zipcode, must be 5 postive digits";
  }
  if (!ship_address_attributes.zipcode) {
    errors.ship_address_attributes.zipcode = "This Field is Required.";
  } else if (
    !/^\d{5}(?:[-\s]\d{4})?$/i.test(values.ship_address_attributes.zipcode)
  ) {
    errors.ship_address_attributes.zipcode =
      "Invalid zipcode, must be 5 postive digits";
  }

  if (!bill_address_attributes.firstname) {
    errors.bill_address_attributes.firstname = "This Field is Required.";
  }
  if (!ship_address_attributes.firstname) {
    errors.ship_address_attributes.firstname = "This Field is Required.";
  }

  if (!bill_address_attributes.lastname) {
    errors.bill_address_attributes.lastname = "This Field is Required.";
  }
  if (!ship_address_attributes.lastname) {
    errors.ship_address_attributes.lastname = "This Field is Required.";
  }

  if (!bill_address_attributes.address1) {
    errors.bill_address_attributes.address1 = "This Field is Required.";
  }
  if (!ship_address_attributes.address1) {
    errors.ship_address_attributes.address1 = "This Field is Required.";
  }

  if (!bill_address_attributes.state_name) {
    errors.bill_address_attributes.state_name = "This Field is Required.";
  }
  if (!ship_address_attributes.state_name) {
    errors.ship_address_attributes.state_name = "This Field is Required.";
  }

  if (!bill_address_attributes.district) {
    errors.bill_address_attributes.district = "This Field is Required.";
  }
  if (!ship_address_attributes.district) {
    errors.ship_address_attributes.district = "This Field is Required.";
  }

  if (!bill_address_attributes.region) {
    errors.bill_address_attributes.region = "This Field is Required.";
  }
  if (!ship_address_attributes.region) {
    errors.ship_address_attributes.region = "This Field is Required.";
  }

  if (!bill_address_attributes.city) {
    errors.bill_address_attributes.city = "This Field is Required.";
  }
  if (!ship_address_attributes.city) {
    errors.ship_address_attributes.city = "This Field is Required.";
  }

  return errors;
};

export default validate;
