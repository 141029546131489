import React from "react";

function UserIcon({ fullName }) {
  let initials = fullName.match(/\b\w/g) || [];

  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();

  return (
    <div className="user mt-1">
      <div className="user-icon">{initials}</div>
    </div>
  );
}

export default UserIcon;
