import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";

import SelectAddressItem from "components/Checkout/SelectAddressItem";
import ModalAddressBook from "components/Checkout/ModalAddressBook";
import Layout from "components/Layout/Layout";
import Modal from "components/Modal";

import {
  setUserSelectedBillAddress,
  setUserSelectedShipAddress,
} from "appRedux/user/actions";
import { addAddressRequest } from "appRedux/checkOut/actions";

import { ADDRESS_MODAL_TITLES } from "constants/modalTitles";
import ROUTES from "constants/routes";

const SelectAddress = ({ addressBook }) => {
  const {
    userInfoData: {
      userInfo: { email },
    },
    selectedAddress: { shippingAddress, billingAddress },
  } = useSelector((state) => state.user);

  const [modalStatus, setModalStatus] = useState({ status: false, title: "" });

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      addressBook.length !== 0 &&
      !shippingAddress.firstname &&
      !billingAddress.firstname
    ) {
      dispatch(setUserSelectedBillAddress(addressBook[0]));
      dispatch(setUserSelectedShipAddress(addressBook[0]));
    }
  }, []);

  const selectAddressHandler = () => {
    const bill_address_attributes = {
      firstname: billingAddress.firstname,
      lastname: billingAddress.lastname,
      address1: billingAddress.address1,
      address2: billingAddress.address2,
      city: billingAddress.city,
      phone: billingAddress.phone,
      zipcode: billingAddress.zipcode,
      state_name: billingAddress.state_name,
      country_iso: billingAddress.country_iso,
      district: "Kathmandu",
      region: "Bagmati",
    };
    const ship_address_attributes = {
      firstname: shippingAddress.firstname,
      lastname: shippingAddress.lastname,
      address1: shippingAddress.address1,
      address2: shippingAddress.address2,
      city: shippingAddress.city,
      phone: shippingAddress.phone,
      zipcode: shippingAddress.zipcode,
      state_name: shippingAddress.state_name,
      country_iso: shippingAddress.country_iso,
      district: "Kathmandu",
      region: "Bagmati",
    };

    dispatch(
      addAddressRequest({
        formData: {
          email,
          bill_address_attributes,
          ship_address_attributes,
        },
        billId: billingAddress.id,
        shipId: shippingAddress.id,
      })
    );
  };

  const changeHandler = (title) => {
    setModalStatus({
      status: true,
      title,
    });
  };

  return (
    <Layout
      title={"Select Address | Checkout"}
      path={ROUTES.CHECKOUT.SELECT_ADDRESS}
    >
      {modalStatus.status && (
        <Modal
          className="address-modal modal-dialog-centered modal-dialog-scrollable"
          modalTitle={modalStatus.title}
          closeHandler={() => setModalStatus({ ...modalStatus, status: false })}
        >
          <ModalAddressBook
            modalTitle={modalStatus.title}
            addressBook={addressBook}
          />
        </Modal>
      )}
      <SelectAddressItem
        title={<Translate value="orderDetails.billingAddress" />}
        address={billingAddress}
        changeHandler={() =>
          changeHandler(ADDRESS_MODAL_TITLES.BILLING_ADDRESS)
        }
      />
      <SelectAddressItem
        title={<Translate value="orderDetails.shippingAddress" />}
        address={shippingAddress}
        changeHandler={() =>
          changeHandler(ADDRESS_MODAL_TITLES.SHIPPING_ADDRESS)
        }
      />
      <div className="text-end mt-4">
        <button
          className="btn btn-primary py-2 px-3"
          onClick={selectAddressHandler}
        >
          <Translate value="checkout.address.submit" />
        </button>
      </div>
    </Layout>
  );
};

export default SelectAddress;
