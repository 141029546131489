import PRODUCT_REVIEW_ACTIONS from "./constants";

const initialState = {
  productReviews: [],
  isLoading: false,
  error: false,
  userReview: {
    isLoading: false,
    error: false,
  },
};

export default function productReviewsReducer(state = initialState, action) {
  switch (action.type) {
    //fetch-all-product-review
    case PRODUCT_REVIEW_ACTIONS.FETCH_ALL_PRODUCT_REVIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case PRODUCT_REVIEW_ACTIONS.FETCH_ALL_PRODUCT_REVIEW_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case PRODUCT_REVIEW_ACTIONS.FETCH_ALL_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,
        productReviews: action.reviews,
        isLoading: false,
        error: null,
      };

    //create-product-review
    case PRODUCT_REVIEW_ACTIONS.CREATE_PRODUCT_REVIEW_REQUEST:
      return {
        ...state,
        userReview: {
          isLoading: true,
          error: false,
        },
      };

    case PRODUCT_REVIEW_ACTIONS.CREATE_PRODUCT_REVIEW_FAILED:
      return {
        ...state,
        userReview: {
          ...state.userReview,
          isLoading: false,
          error: action.error,
        },
      };

    case PRODUCT_REVIEW_ACTIONS.CREATE_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,

        userReview: {
          ...state.userReview,
          isLoading: false,
          error: false,
        },
      };

    //update-product-review
    case PRODUCT_REVIEW_ACTIONS.EDIT_PRODUCT_REVIEW_REQUEST:
      return {
        ...state,
        userReview: {
          isLoading: true,
          error: false,
        },
      };

    case PRODUCT_REVIEW_ACTIONS.EDIT_PRODUCT_REVIEW_FAILED:
      return {
        ...state,
        userReview: {
          ...state.userReview,
          isLoading: false,
          error: action.error,
        },
      };

    case PRODUCT_REVIEW_ACTIONS.EDIT_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,
        userReview: {
          ...state.userReview,
          isLoading: false,
          error: false,
        },
      };

    //remove-product-review
    case PRODUCT_REVIEW_ACTIONS.REMOVE_PRODUCT_REVIEW_REQUEST:
      return {
        ...state,
        userReview: {
          isLoading: true,
          error: false,
        },
      };

    case PRODUCT_REVIEW_ACTIONS.REMOVE_PRODUCT_REVIEW_FAILED:
      return {
        ...state,
        userReview: {
          ...state.userReview,
          isLoading: false,
          error: action.error,
        },
      };

    case PRODUCT_REVIEW_ACTIONS.REMOVE_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,
        userReview: {
          ...state.userReview,
          isLoading: false,
          error: false,
        },
      };

    case PRODUCT_REVIEW_ACTIONS.RESET_REVIEW_FORM:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
