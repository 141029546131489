import React from "react";
import { Translate } from "react-redux-i18n";
import PropTypes from "prop-types";

import MessageStrip from "components/MessageStrip";

import { formatDate } from "utils/DateFormatter";
import QuestionStatus from "components/User/QuestionStatus";
import { getFullName } from "utils/utils";

const ProductCustomerQA = ({ questions }) => {
  const questionList = questions.map(
    ({
      id,
      content,
      product_answer,
      is_anonymous,
      created_at,
      user,
      full_name: fullName,
    }) => {
      const name = getFullName(user?.default_billing_address);

      const displayName = is_anonymous
        ? "Anonymous"
        : Boolean(name)
        ? name
        : fullName && !fullName?.includes("null")
        ? fullName
        : "Anonymous";

      return (
        <div key={id} className="product-question border-bottom py-3">
          <div className="question-box d-flex">
            <h5 className="question me-3">{content}</h5>
            <QuestionStatus status={product_answer} disableAnswerStatus />
          </div>
          <div className="answer">
            <p>{product_answer && product_answer.content}</p>
          </div>
          <span className="text-secondary">
            <Translate
              value="questions.questionedIn"
              date={formatDate(created_at)}
              displayName={displayName}
            />
          </span>
        </div>
      );
    }
  );

  return (
    <section className="py-5">
      <div className="container">
        {questions.length === 0 ? null : (
          <>
            <h3 className="mb-4 section-header">
              <Translate value="questions.customerQuestionsAndAnswers" />
            </h3>
            <div className="row">
              <div className="col-md-8 mb-3">
                {questionList}
                {/* <Button
              btnText="See more questions"
              className="w-100 bg-light primary-color"
            /> */}
              </div>
            </div>
          </>
        )}
        <MessageStrip />
      </div>
    </section>
  );
};

ProductCustomerQA.defaultProps = {
  questions: [],
};

ProductCustomerQA.propTypes = {
  question: PropTypes.array.isRequired,
};

export default ProductCustomerQA;
