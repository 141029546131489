import { Http } from '@spree/storefront-api-v2-sdk'

import { Routes } from 'lib/spreeSdk/routes'

export default class SlideLocations extends Http {
  async list(accessHash, params) {
    return this.spreeResponse(
      'get',
      Routes.slideLocationsPath(accessHash),
      {}, // does not need token so pass empty object
      params,
    )
  }
}
