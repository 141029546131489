import React from "react";
import { faInbox, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ListInfo = ({ contentType, email, address, title }) => {
  if (contentType === "empty" || !address) {
    return (
      <p className="text-muted">
        You have not set the default {title.toLowerCase()}.
      </p>
    );
  }

  const {
    address1,
    address2,
    city,
    firstname,
    country_name,
    lastname,
    phone,
    state_name,
    zipcode,
  } = address;

  return (
    <ul className="list-info list-style-none mb-0 ps-0">
      <li className="d-flex align-items-center py-2">
        <FontAwesomeIcon icon={faUser} className="me-3 text-muted" />

        <p className="mb-0">{`${firstname} ${lastname}`}</p>
      </li>
      <li className="d-flex align-items-center py-2">
        <FontAwesomeIcon icon={faInbox} className="me-3 text-muted" />

        <p className="mb-0">{email}</p>
      </li>
      <li className="d-flex align-items-center py-2">
        <FontAwesomeIcon icon={faPhone} className="me-3 text-muted" />

        <p className="mb-0">{phone}</p>
      </li>
    </ul>
  );
};

const ProfileCard = ({ title, contentType, button, email, address }) => {
  return (
    <div className="card dashboard-card shadow-sm border-0 mb-2 p-3">
      <div className="d-flex align-items-center justify-content-between mb-1">
        <h2 className="h6 d-inline font-weight-bold mb-0">{title}</h2>
        <button className="btn p-0 text-primary w-auto font-weight-semibold">
          {button}
        </button>
      </div>
      <ListInfo
        contentType={contentType}
        address={address}
        email={email}
        title={title}
      />
    </div>
  );
};

export default ProfileCard;
