import React from "react";
import { Link } from "react-router-dom";

function CarouselItem({ image, title, btnText, className, linkTo }) {
  return (
    <div className={`carousel-item position-relative ${className}`}>
      <img src={image} className="d-block w-100 slider-img" alt="..." />
      <div className="carousel-overlay d-flex align-items-center">
        <div className="caption container text-start">
          <div className="col-md-8">
            <h1>{title}</h1>
            <Link to={linkTo} className="btn btn-primary mt-3">
              {btnText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarouselItem;
