import client from "services/spreeClient";
import getToken from "services/getToken";

class ProductReviewApi {
  static fetchAllReviews(productId) {
    return client.reviews.list({ ...getToken() }, productId, {
      filter: "all",
      include: "user,images",
    });
  }

  static createProductReview({ formData, productId }) {
    return client.reviews.createReview({ ...getToken() }, productId, formData);
  }

  static editProductReview({ formData, productId, reviewId }) {
    return client.reviews.updateReview(
      { ...getToken() },
      productId,
      reviewId,
      formData
    );
  }

  static removeProductReview({ productId, reviewId }) {
    return client.reviews.removeReview({ ...getToken() }, productId, reviewId);
  }
}

export default ProductReviewApi;
