import React from "react";
import PropTypes from "prop-types";
import FilterOptions from "../Options/FilterOptions";
import ColorOptions from "../Options/ColorOptions";
import useMediaQuery from "utils/useMediaQuery";

const FilterRowContainer = ({ id, name, presentation, children }) => {
  const isSmallScreen = useMediaQuery("(min-width: 540px)");

  return (
    <li className={`border-bottom py-1 ${isSmallScreen ? "px-0" : "px-3"}`}>
      <a
        className="d-inline-flex align-items-center collapsed w-100 justify-content-between my-2 font-weight-semibold"
        data-bs-toggle="collapse"
        href={`#${name}`}
        role="button"
        aria-expanded="false"
      >
        {presentation} <span className="toggle-icon" />
      </a>
      <ul className="list-unstyled font-weight-normal collapse" id={name}>
        {children}
      </ul>
    </li>
  );
};

const FilterRow = ({ filter, handleProductFilter, selectedFilters }) => {
  const { values, name, presentation } = filter;

  const selectedFilterOptions = selectedFilters[name];

  return (
    <FilterRowContainer name={name} presentation={presentation}>
      {presentation === "Color" ? (
        <ColorOptions
          handleProductFilter={handleProductFilter}
          options={values}
          selectedFilterOptions={selectedFilterOptions}
          filterName={name}
        />
      ) : (
        <FilterOptions
          handleProductFilter={handleProductFilter}
          filterName={name}
          options={values}
          selectedFilterOptions={selectedFilterOptions}
        />
      )}
    </FilterRowContainer>
  );
};

FilterRow.propTypes = {};

export default FilterRow;
