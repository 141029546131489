import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Translate } from "react-redux-i18n";

import { VENDOR } from "constants/routes";

const ProductAvailability = ({ inStock, backorderable, available, vendor }) => {
  let availabilityStatus = inStock ? "In stock" : "Out of stock";

  availabilityStatus = backorderable ? "Pre Order" : availabilityStatus;
  availabilityStatus = available ? availabilityStatus : "Unavailable";

  return (
    <div className="border-bottom py-2">
      <Translate value="productDetail.availability" />:{" "}
      <span className="text-secondary">{availabilityStatus}</span>
      {vendor && (
        <span className="text-secondary ms-2">
          {" "}
          |{" "}
          <Link to={VENDOR.addSlug(VENDOR.DETAIL, vendor.slug)}>
            <Translate value="productDetail.soldBy" name={vendor.name} />
          </Link>
        </span>
      )}
    </div>
  );
};

ProductAvailability.propTypes = {};

export default ProductAvailability;
