import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";

import DashboardCard from "components/User/DashboardCard";
import UserInfo from "components/User/UserInfo";
import OrderCardItem from "components/User/OrderCardItem";
import ProfileCard from "components/User/ProfileCard";

import ProfileSkeleton from "components/Skeleton/ProfileSkeleton";

import { fetchOrderDetailRequest } from "appRedux/checkOut/actions";
import { userInfoRequest } from "appRedux/user/actions";

import ROUTES from "constants/routes";

const Dashboard = ({ history }) => {
  const dispatch = useDispatch();
  const { userInfoData } = useSelector((state) => state.user);
  const {
    email,
    default_billing_address,
    default_shipping_address,
  } = userInfoData.userInfo;
  const {
    orderDetailData: { orderNumber, orderDetails },
  } = useSelector((state) => state.checkout);

  useEffect(() => {
    dispatch(userInfoRequest());
  }, []);

  useEffect(() => {
    if (orderNumber) {
      dispatch(fetchOrderDetailRequest(orderNumber));
    }
  }, [orderNumber]);

  let userFirstName = "";
  let userLastname = "";

  if (default_billing_address) {
    const { firstname, lastname } = default_billing_address;

    userFirstName = firstname;
    userLastname = lastname;
  }

  const isLoading = userInfoData.isLoading || orderDetails.isLoading;

  let recentOrder = {};

  if (orderDetails.orderData) {
    recentOrder = {
      ...orderDetails.orderData,
    };
  }

  const handleOrdersButtonClick = () => {
    history.push(ROUTES.USER.ORDER);
  };

  if (isLoading) {
    return <ProfileSkeleton repeat={5} />;
  }

  return (
    <div className="col-md-9">
      <UserInfo
        firstname={userFirstName}
        lastname={userLastname}
        email={email}
        className="bg-white mb-2"
      />
      <ProfileCard
        address={default_shipping_address}
        email={email}
        title={I18n.t("user.profile.contactInfo")}
        contentType="list"
        button="EDIT"
      />
      {orderNumber && (
        <DashboardCard
          title="Recent Orders"
          clickHandler={handleOrdersButtonClick}
          button="VIEW ALL"
        >
          <OrderCardItem
            items={recentOrder.line_items}
            placedOn={recentOrder.created_at}
            orderNumber={recentOrder.number}
            orderStatus="Confirmed" // "Placed" "Confirmed" "Delivered" "Pending"
            totalPriceOverAll={recentOrder.display_total}
            totalItems={recentOrder.item_count}
            state={recentOrder.state}
            className="card dashboard-card mb-2 p-3"
          />
        </DashboardCard>
      )}

      <ProfileCard
        email={email}
        address={default_billing_address}
        title={I18n.t("user.profile.billingAddress")}
        contentType="list"
        button="EDIT"
      />
      <ProfileCard
        email={email}
        address={default_shipping_address}
        title={I18n.t("user.profile.shippingAddress")}
        contentType="list"
        button="EDIT"
      />
    </div>
  );
};

export default withRouter(Dashboard);
