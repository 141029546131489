import TAXON_ACTIONS from './constants';

export const fetchTaxonsRequest = () => ({
  type: TAXON_ACTIONS.FETCH_TAXONS_REQUEST,
});

export const fetchTaxonsFail = (error) => ({
  type: TAXON_ACTIONS.FETCH_TAXONS_FAILED,
  error,
});

export const fetchTaxonsSuccess = (taxons) => ({
  type: TAXON_ACTIONS.FETCH_TAXONS_SUCCESS,
  taxons,
});
