import React from "react";
import { withRouter } from "react-router";
import { Translate } from "react-redux-i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import AddressBookItem from "components/User/AddressBookItem";
import AddressBookHeadings from "components/User/AddressBookHeadings";

import ROUTES from "constants/routes";

const AddressBookCard = ({ addressBook, history }) => {
  const editAddressHandler = (id) => {
    const editRoute = ROUTES.USER.ADDRESS.EDIT.replace(":addressId", id);

    history.push(editRoute);
  };

  const addAddressHandler = () => {
    history.push(ROUTES.USER.ADDRESS.ADD);
  };

  return (
    <>
      <div className="table-responsive-sm">
        <table className="table table-striped shadow-sm table-responsive">
          {addressBook.length !== 0 && <AddressBookHeadings />}
          {addressBook &&
            addressBook.map((address) => {
              return (
                <AddressBookItem
                  key={address.id}
                  address={address}
                  btnText={<Translate value="user.addressBook.button.edit" />}
                  onItemButtonClick={editAddressHandler}
                />
              );
            })}
        </table>
      </div>
      {addressBook.length === 0 && (
        <p className="text-muted">
          <Translate value="user.addressBook.noAddress" />
        </p>
      )}
      <div className="my-4 text-md-end text-start">
        <button
          onClick={addAddressHandler}
          type="button"
          className="btn btn-primary"
        >
          <FontAwesomeIcon icon={faPlus} />{" "}
          <Translate value="user.addressBook.button.addNewAddress" />
        </button>
      </div>
    </>
  );
};

export default withRouter(AddressBookCard);
