import CART_ACTIONS from "./constants";

const initialState = {
  items: [],
  cartData: {
    item_count: 0,
  },
  promoCodeData: {
    promoCode: "",
    promoCodeAdd: {
      success: "",
      isLoading: false,
      error: false,
    },
    promoCodeRemove: {
      success: false,
      isLoading: false,
      error: false,
    },
  },
  modal: false,
  isCartButtonClicked: false,
  isProductToCartLoading: false,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case CART_ACTIONS.ADD_TO_CART_REQUEST:
      return {
        ...state,
        isProductToCartLoading: true,
      };

    case CART_ACTIONS.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        isProductToCartLoading: false,
        items: [...state.items, action.productInCart],
      };

    case CART_ACTIONS.ADD_TO_CART_FAILED:
      return {
        ...state,
        isProductToCartLoading: false,
      };

    // fetch cart item
    case CART_ACTIONS.FETCH_CART_ITEMS_SUCCESS:
      return {
        ...state,
        cartData: action.cartData,
      };

    // remove item from cart requst
    case CART_ACTIONS.REMOVE_FROM_CART:
      return {
        ...state,
      };

    // set cart item
    case CART_ACTIONS.SET_ITEM_QUANTITY_REQUEST:
      return {
        ...state,
      };
    case CART_ACTIONS.SET_ITEM_QUANTITY_SUCCESS:
      return {
        ...state,
      };

    // reset cart after checkout complepted
    case CART_ACTIONS.RESET_CART:
      return {
        ...initialState,
      };

    // empty-cart
    case CART_ACTIONS.EMPTY_CART_REQUEST:
      return {
        ...state,
      };
    case CART_ACTIONS.EMPTY_CART_SUCCESS:
      return {
        ...state,
        promoCodeData: {
          ...initialState.promoCodeData,
        },
      };
    case CART_ACTIONS.EMPTY_CART_FAILED:
      return {
        ...state,
      };

    // add promo code
    case CART_ACTIONS.PROMO_CODE_REQUEST:
      return {
        ...state,
        promoCodeData: {
          ...state.promoCodeData,
          promoCode: action.promoCode,
          promoCodeAdd: {
            success: "",
            isLoading: true,
            error: false,
          },
        },
      };

    case CART_ACTIONS.PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoCodeData: {
          ...state.promoCodeData,
          promoCodeAdd: {
            success: "Promo Code Added",
            isLoading: false,
            error: false,
          },
          promoCodeRemove: {
            success: false,
            isLoading: false,
            error: false,
          },
        },
      };

    case CART_ACTIONS.PROMO_CODE_FAILED:
      return {
        ...state,
        promoCodeData: {
          ...state.promoCodeData,
          promoCodeAdd: {
            success: "",
            isLoading: false,
            error: action.error,
          },
        },
      };

    // remove promo code
    case CART_ACTIONS.REMOVE_PROMO_CODE_REQUEST:
      return {
        ...state,
        promoCodeData: {
          ...state.promoCodeData,
          promoCode: action.promoCode,
          promoCodeRemove: {
            success: false,
            isLoading: true,
            error: false,
          },
        },
      };

    case CART_ACTIONS.REMOVE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoCodeData: {
          ...state.promoCodeData,
          promoCode: "",
          promoCodeAdd: {
            success: "",
            isLoading: false,
            error: false,
          },
          promoCodeRemove: {
            success: true,
            isLoading: false,
            error: false,
          },
        },
      };

    case CART_ACTIONS.REMOVE_PROMO_CODE_FAILED:
      return {
        ...state,
        promoCodeData: {
          ...state.promoCodeData,
          promoCodeRemove: {
            success: false,
            isLoading: false,
            error: action.error,
          },
        },
      };

    // reset promocode
    case CART_ACTIONS.RESET_PROMO_CODE:
      return {
        ...state,
        promoCodeData: {
          ...initialState.promoCodeData,
        },
      };

    // refill promoCode
    case CART_ACTIONS.REFILL_PROMO_CODE:
      return {
        ...state,
        promoCodeData: {
          promoCode: action.code,
          promoCodeAdd: {
            success: action.promoCodeName,
            isLoading: false,
            error: false,
          },
          promoCodeRemove: {
            success: false,
            isLoading: false,
            error: false,
          },
        },
      };
    // modal
    case CART_ACTIONS.CART_MODAL_OPEN:
      return {
        ...state,
        modal: true,
      };
    case CART_ACTIONS.CART_MODAL_CLOSE:
      return {
        ...state,
        modal: false,
      };
    case CART_ACTIONS.CART_BUTTON_CLICKED:
      return {
        ...state,
        isCartButtonClicked: true,
      };
    case CART_ACTIONS.RESET_CART_BUTTON_CLICK:
      return {
        ...state,
        isCartButtonClicked: false,
      };
    default:
      return state;
  }
}
