import { WISHLIST_ACTIONS, WISHLIST_PRODUCT_ACTIONS } from './constants'

const initialState = {
  wishlist: {
    wishlistData: {
      isLoading: false,
      error: false,
      wishlists: [],
      metaData: null,
    },
    wishlistDetails: {
      isLoading: false,
      error: false,
      detail: {},
    },
    ceateWishList: {
      isLoading: false,
      error: false,
    },
    editWishList: {
      isLoading: false,
      error: false,
    },
    removeWishList: {
      isLoading: false,
      error: false,
    },
  },
  product: {
    productData: {
      wishlistProducts: [],
    },
    addProduct: {
      isLoading: false,
      error: false,
    },
    editProduct: {
      isLoading: false,
      error: false,
    },
    removeProduct: {
      isLoading: false,
      error: false,
    },
  },
}

export default function wishListReducer(state = initialState, action) {
  switch (action.type) {
    //*WISHLIST
    //fetch-wishlist
    case WISHLIST_ACTIONS.FETCH_WISHLIST_REQUEST:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          wishlistData: {
            ...state.wishlist.wishlistData,
            isLoading: true,
            error: false,
          },
        },
      }

    case WISHLIST_ACTIONS.FETCH_WISHLIST_FAILED:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          wishlistData: {
            ...state.wishlist.wishlistData,
            isLoading: false,
            error: action.error,
          },
        },
      }

    case WISHLIST_ACTIONS.FETCH_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          wishlistData: {
            isLoading: false,
            error: false,
            wishlists: [...action.wishlists],
            metaData: action.metaData,
          },
        },
      }

    //create-wishlist
    case WISHLIST_ACTIONS.CREATE_WISHLIST_REQUEST:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          ceateWishList: {
            ...state.wishlist.ceateWishList,
            isLoading: true,
            error: false,
          },
        },
      }

    case WISHLIST_ACTIONS.CREATE_WISHLIST_FAILED:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          ceateWishList: {
            ...state.wishlist.ceateWishList,
            isLoading: false,
            error: action.error,
          },
        },
      }

    case WISHLIST_ACTIONS.CREATE_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          ceateWishList: {
            ...state.wishlist.ceateWishList,
            isLoading: false,
            error: false,
          },
        },
      }

    //edit-wishlist
    case WISHLIST_ACTIONS.EDIT_WISHLIST_REQUEST:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          editWishList: {
            ...state.wishlist.editWishList,
            isLoading: true,
            error: false,
          },
        },
      }

    case WISHLIST_ACTIONS.EDIT_WISHLIST_FAILED:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          editWishList: {
            ...state.wishlist.editWishList,
            isLoading: false,
            error: action.error,
          },
        },
      }

    case WISHLIST_ACTIONS.EDIT_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          editWishList: {
            ...state.wishlist.editWishList,
            isLoading: false,
            error: false,
          },
        },
      }

    //fetch-wishlist-detail
    case WISHLIST_ACTIONS.FETCH_WISHLIST_DETAIL_REQUEST:
    case WISHLIST_ACTIONS.FETCH_DEFAULT_WISHLIST_REQUEST:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          wishlistDetails: {
            ...state.wishlist.wishlistDetails,
            isLoading: true,
            error: false,
          },
        },
      }

    case WISHLIST_ACTIONS.FETCH_WISHLIST_DETAIL_FAILED:
    case WISHLIST_ACTIONS.FETCH_DEFAULT_WISHLIST_FAILED:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          wishlistDetails: {
            ...state.wishlist.wishlistDetails,
            isLoading: false,
            error: action.error,
          },
        },
      }

    case WISHLIST_ACTIONS.FETCH_WISHLIST_DETAIL_SUCCESS:
    case WISHLIST_ACTIONS.FETCH_DEFAULT_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          wishlistDetails: {
            ...state.wishlist.wishlistDetails,
            detail: action.wishlistDetails,
            isLoading: false,
            error: false,
          },
        },
        product: {
          ...state.product,
          productData: {
            ...state.product.productData,
            wishlistProducts: action.wishlistProducts,
          },
        },
      }

    //remove-wishlist
    case WISHLIST_ACTIONS.REMOVE_WISHLIST_REQUEST:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          removeWishList: {
            ...state.wishlist.removeWishList,
            isLoading: true,
            error: false,
          },
        },
      }

    case WISHLIST_ACTIONS.REMOVE_WISHLIST_FAILED:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          removeWishList: {
            ...state.wishlist.removeWishList,
            isLoading: false,
            error: action.error,
          },
        },
      }

    case WISHLIST_ACTIONS.REMOVE_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          removeWishList: {
            ...state.wishlist.removeWishList,
            isLoading: false,
            error: false,
          },
        },
      }

    //*WISHLIST PRODUCTS
    //add Product to wishlist
    case WISHLIST_PRODUCT_ACTIONS.ADD_TO_WISHLIST_REQUEST:
      return {
        ...state,
        product: {
          ...state.product,
          addProduct: {
            ...state.product.addProduct,
            isLoading: true,
            error: false,
          },
        },
      }

    case WISHLIST_PRODUCT_ACTIONS.ADD_TO_WISHLIST_FAILED:
      return {
        ...state,
        product: {
          ...state.product,
          addProduct: {
            ...state.product.addProduct,
            isLoading: true,
            error: action.error,
          },
        },
      }

    case WISHLIST_PRODUCT_ACTIONS.ADD_TO_WISHLIST_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          addProduct: {
            ...state.product.addProduct,
            isLoading: false,
            error: false,
          },
        },
      }

    //remove Product from wishlist
    case WISHLIST_PRODUCT_ACTIONS.REMOVE_FROM_WISHLIST_REQUEST:
      return {
        ...state,
        product: {
          ...state.product,
          removeProduct: {
            ...state.product.removeProduct,
            isLoading: true,
            error: false,
          },
        },
      }

    case WISHLIST_PRODUCT_ACTIONS.REMOVE_FROM_WISHLIST_FAILED:
      return {
        ...state,
        product: {
          ...state.product,
          removeProduct: {
            ...state.product.removeProduct,
            isLoading: true,
            error: action.error,
          },
        },
      }

    case WISHLIST_PRODUCT_ACTIONS.REMOVE_FROM_WISHLIST_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          removeProduct: {
            ...state.product.removeProduct,
            isLoading: false,
            error: false,
          },
        },
      }

    //edit Product from wishlist
    case WISHLIST_PRODUCT_ACTIONS.EDIT_WISHLIST_PRODUCTS_REQUEST:
      return {
        ...state,
        product: {
          ...state.product,
          editProduct: {
            ...state.product.editProduct,
            isLoading: true,
            error: false,
          },
        },
      }

    case WISHLIST_PRODUCT_ACTIONS.EDIT_WISHLIST_PRODUCTS_REQUEST:
      return {
        ...state,
        product: {
          ...state.product,
          editProduct: {
            ...state.product.editProduct,
            isLoading: true,
            error: action.error,
          },
        },
      }

    case WISHLIST_PRODUCT_ACTIONS.EDIT_WISHLIST_PRODUCTS_REQUEST:
      return {
        ...state,
        product: {
          ...state.product,
          editProduct: {
            ...state.product.editProduct,
            isLoading: false,
            error: false,
          },
        },
      }

    // logout
    case WISHLIST_ACTIONS.RESET_WISHLIST:
      return initialState

    default:
      return state
  }
}
