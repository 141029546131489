import TAXON_ACTIONS from './constants';

const taxonState = {
  taxons: [],
  isLoading: false,
  error: false,
};

export default function taxonReducer(state = taxonState, action) {
  switch (action.type) {
    case TAXON_ACTIONS.FETCH_TAXONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case TAXON_ACTIONS.FETCH_TAXONS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case TAXON_ACTIONS.FETCH_TAXONS_SUCCESS:
      return {
        ...state,
        taxons: action.taxons,
        isLoading: false,
        error: null,
      };

    default:
      return state;
  }
}
