const USER_ACTIONS = {
  USER_INFO_REQUEST: 'USER_INFO_REQUEST',
  USER_INFO_SUCCESS: 'USER_INFO_SUCCESS',
  USER_INFO_FAILED: 'USER_INFO_FAILED',

  ORDER_LIST_REQUEST: 'ORDER_LIST_REQUEST',
  ORDER_LIST_SUCCESS: 'ORDER_LIST_SUCCESS',
  ORDER_LIST_FAILED: 'ORDER_LIST_FAILED',
  ORDER_CURRENT_PAGE: 'ORDER_CURRENT_PAGE',

  FETCH_ALL_ADDRESSES_REQUEST: 'FETCH_ALL_ADDRESSES_REQUEST',
  FETCH_ALL_ADDRESSES_SUCCESS: 'FETCH_ALL_ADDRESSES_SUCCESS',
  FETCH_ALL_ADDRESSES_FAILED: 'FETCH_ALL_ADDRESSES_FAILED',

  UPDATE_ADDRESS_REQUEST: 'UPDATE_ADDRESS_REQUEST',
  UPDATE_ADDRESS_SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
  UPDATE_ADDRESS_FAILED: 'UPDATE_ADDRESS_FAILED',
  RESET_UPDATE_ADDRESS_STATUS: 'RESET_UPDATE_ADDRESS_STATUS',

  CREATE_ADDRESS_REQUEST: 'CREATE_ADDRESS_REQUEST',
  CREATE_ADDRESS_SUCCESS: 'CREATE_ADDRESS_SUCCESS',
  CREATE_ADDRESS_FAILED: 'CREATE_ADDRESS_FAILED',
  RESET_CREATE_ADDRESS_STATUS: 'RESET_CREATE_ADDRESS_STATUS',

  FETCH_USER_REVIEWS_REQUEST: 'FETCH_USER_REVIEWS_REQUEST',
  FETCH_USER_REVIEWS_SUCCESS: 'FETCH_USER_REVIEWS_SUCCESS',
  FETCH_USER_REVIEWS_FAILED: 'FETCH_USER_REVIEWS_FAILED',
  REVIEWS_CURRENT_PAGE: 'REVIEWS_CURRENT_PAGE',

  FETCH_USER_QUESTIONS_REQUEST: 'FETCH_USER_QUESTIONS_REQUEST',
  FETCH_USER_QUESTIONS_SUCCESS: 'FETCH_USER_QUESTIONS_SUCCESS',
  FETCH_USER_QUESTIONS_FAILED: 'FETCH_USER_QUESTIONS_FAILED',
  QUESTIONS_CURRENT_PAGE: 'QUESTIONS_CURRENT_PAGE',

  RESET_USER_DATA: 'RESET_USER_DATA',

  USER_SELECTED_BILL_ADDRESS: 'USER_SELECTED_BILL_ADDRESS',
  USER_SELECTED_SHIP_ADDRESS: 'USER_SELECTED_SHIP_ADDRESS',
};

export default USER_ACTIONS;
