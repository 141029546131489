import PRODUCT_ACTIONS from './constants';

const initialProductState = {
  product: {},
  isLoading: false,
  error: false,
};

export const productDetailReducer = (state = initialProductState, action) => {
  switch (action.type) {
    case PRODUCT_ACTIONS.FETCH_PRODUCT_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case PRODUCT_ACTIONS.FETCH_PRODUCT_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        product: {},
        error: action.error,
      };
    case PRODUCT_ACTIONS.FETCH_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        product: action.product,
        isLoading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default productDetailReducer;
