import React from "react";
import { I18n } from "react-redux-i18n";
import PropTypes from "prop-types";
import Button from "components/Button";

const CartButton = ({ onClick, disabled, isLoading }) => {
  return (
    <Button
      btnText={I18n.t("productDetail.buttons.addToCart")}
      className="btn-primary py-3 mt-3 mt-md-0 w-100"
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}
    />
  );
};

CartButton.defaultProps = {
  addCartItems: () => {},
  disabled: false,
  isLoading: false,
};

CartButton.propTypes = {
  addCartItems: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default CartButton;
