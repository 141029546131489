import { WISHLIST_ACTIONS, WISHLIST_PRODUCT_ACTIONS } from "./constants";
import swal from "sweetalert";

//*WISHLISTS
//fetch-wishlists
export const fetchWishlistRequest = (variantId = null) => ({
  type: WISHLIST_ACTIONS.FETCH_WISHLIST_REQUEST,
  variantId,
});

export const fetchWishlistFail = (error) => ({
  type: WISHLIST_ACTIONS.FETCH_WISHLIST_FAILED,
  error,
});

export const fetchWishlistSuccess = ({ wishlists, metaData }) => ({
  type: WISHLIST_ACTIONS.FETCH_WISHLIST_SUCCESS,
  wishlists,
  metaData,
});

//fetch-default-wishlist
export const fetchDefaultWishlistRequest = () => ({
  type: WISHLIST_ACTIONS.FETCH_DEFAULT_WISHLIST_REQUEST,
});

export const fetchDefaultWishlistFail = (error) => ({
  type: WISHLIST_ACTIONS.FETCH_DEFAULT_WISHLIST_FAILED,
  error,
});

export const fetchDefaultWishlistSuccess = ({
  wishlistDetails,
  wishlistProducts,
}) => ({
  type: WISHLIST_ACTIONS.FETCH_DEFAULT_WISHLIST_SUCCESS,
  wishlistDetails,
  wishlistProducts,
});

//create-wishlists
export const createWishlistRequest = (
  { name, isPrivate, isDefault },
  history
) => ({
  type: WISHLIST_ACTIONS.CREATE_WISHLIST_REQUEST,
  name,
  isPrivate,
  isDefault,
  history,
});

export const createWishlistFail = (error) => ({
  type: WISHLIST_ACTIONS.CREATE_WISHLIST_FAILED,
  error,
});

export const createWishlistSuccess = () => {
  swal("Wishlist Created!", "Your wishlist has been created", "success");

  return {
    type: WISHLIST_ACTIONS.CREATE_WISHLIST_SUCCESS,
  };
};

//edit-wishlists
export const editWishlistRequest = (
  { name, isPrivate, isDefault },
  wishlistId,
  history
) => ({
  type: WISHLIST_ACTIONS.EDIT_WISHLIST_REQUEST,
  name,
  isPrivate,
  isDefault,
  wishlistId,
  history,
});

export const editWishlistFail = (error) => ({
  type: WISHLIST_ACTIONS.EDIT_WISHLIST_FAILED,
  error,
});

export const editWishlistSuccess = () => {
  swal("Wishlist Edited!", "Your wishlist has been edited", "info");

  return {
    type: WISHLIST_ACTIONS.EDIT_WISHLIST_SUCCESS,
  };
};

//remove-wishlist
export const removeWishlistRequest = ({ wishlistId }, history) => ({
  type: WISHLIST_ACTIONS.REMOVE_WISHLIST_REQUEST,
  wishlistId,
  history,
});

export const removeWishlistFail = (error) => ({
  type: WISHLIST_ACTIONS.REMOVE_WISHLIST_FAILED,
  error,
});

export const removeWishlistSuccess = () => {
  swal("Wishlist Removed!", "Your wishlist has been removed", "info");

  return {
    type: WISHLIST_ACTIONS.REMOVE_WISHLIST_SUCCESS,
  };
};

//wishlist-detail
export const fetchWishlistDetailsRequest = (wishlistId) => ({
  type: WISHLIST_ACTIONS.FETCH_WISHLIST_DETAIL_REQUEST,
  wishlistId,
});

export const fetchWishlistDetailsFail = (error) => ({
  type: WISHLIST_ACTIONS.FETCH_WISHLIST_DETAIL_FAILED,
  error,
});

export const fetchWishlistDetailsSuccess = ({
  wishlistDetails,
  wishlistProducts,
}) => ({
  type: WISHLIST_ACTIONS.FETCH_WISHLIST_DETAIL_SUCCESS,
  wishlistDetails,
  wishlistProducts,
});

//*WISHLISTS PRODUCTS
//add product to wishlist
export const addToWishlistRequest = ({
  variantId,
  wishlistId,
  remark,
  quantity,
  from,
  moveData,
}) => ({
  type: WISHLIST_PRODUCT_ACTIONS.ADD_TO_WISHLIST_REQUEST,
  variantId,
  wishlistId,
  remark,
  quantity,
  from,
  moveData,
});

export const addToWishlistFail = (error) => ({
  type: WISHLIST_PRODUCT_ACTIONS.ADD_TO_WISHLIST_FAILED,
  error,
});

export const addToWishlistSuccess = (from) => {
  if (from) {
    swal("Product Moved!", "Product has been moved from the wishlist", "info");
  } else {
    swal("Product Addded!", "Product added to the wishlist", "success");
  }

  return {
    type: WISHLIST_PRODUCT_ACTIONS.ADD_TO_WISHLIST_SUCCESS,
  };
};

//edit product to wishlist
export const editWishlistProductRequest = ({
  variantId,
  wishlistId,
  productId,
  remark,
  quantity,
}) => ({
  type: WISHLIST_PRODUCT_ACTIONS.EDIT_WISHLIST_PRODUCTS_REQUEST,
  variantId,
  wishlistId,
  productId,
  remark,
  quantity,
});

export const editWishlistProductFail = (error) => ({
  type: WISHLIST_PRODUCT_ACTIONS.EDIT_WISHLIST_PRODUCTS_FAILED,
  error,
});

export const editWishlistProductSuccess = () => {
  swal("Product Edited!", "Your product has been edited", "info");

  return {
    type: WISHLIST_PRODUCT_ACTIONS.EDIT_WISHLIST_PRODUCTS_SUCCESS,
  };
};

//remove product to wishlist
export const removeWishlistProductRequest = ({
  productId,
  wishlistId,
  from,
  moveData,
}) => ({
  type: WISHLIST_PRODUCT_ACTIONS.REMOVE_FROM_WISHLIST_REQUEST,
  productId,
  wishlistId,
  from,
  moveData,
});

export const removeWishlistProductFail = (error) => ({
  type: WISHLIST_PRODUCT_ACTIONS.REMOVE_FROM_WISHLIST_FAILED,
  error,
});

export const removeWishlistProductSuccess = (from) => {
  if (from !== "buy") {
    swal("Product Removed!", "Product has been removed from wishlist", "info");
  }

  return {
    type: WISHLIST_PRODUCT_ACTIONS.REMOVE_FROM_WISHLIST_SUCCESS,
  };
};
