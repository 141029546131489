import client from "services/spreeClient";
import getToken from "services/getToken";

class ProductQuestionApi {
  static fetchAllQuestion(productId) {
    return client.productQuestions.list({ ...getToken() }, productId, {
      include: "product_answer,user.default_billing_address,product",
    });
  }

  static createQuestion({
    productId,
    content,
    userId,
    isAnonymous,
    fullName,
    email,
  }) {
    const questionParams = {
      product_id: productId,
      content,
      user_id: userId,
      is_anonymous: isAnonymous,
      full_name: fullName,
      email,
    };

    return client.productQuestions.createQuestion(
      { ...getToken() },
      productId,
      { product_question: questionParams }
    );
  }

  static editQuestion({
    productId,
    content,
    questionId,
    isAnonymous,
    fullName,
    email,
  }) {
    const questionParams = {
      content,
      full_name: fullName,
      email,
      is_anonymous: isAnonymous,
    };

    return client.productQuestions.updateQuestion(
      { ...getToken() },
      productId,
      questionId,
      { product_question: questionParams }
    );
  }

  static removeQuestion({ productId, questionId }) {
    return client.productQuestions.removeQuestion(
      { ...getToken() },
      productId,
      questionId
    );
  }
}

export default ProductQuestionApi;
