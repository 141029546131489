import CHECKOUT_ACTIONS from './constants';

const initialState = {
  stateAddress: [],
  deliveryOption: [],
  selectedDeliveryOption: {},
  paymentMethod: [],
  checkOutSuccess: false,
  selectedPaymentMethod: {},
  modal: {
    modalID: '',
    isModalOpen: false,
  },
  continueAsGuest: false,
  isformSumitted: false,
  isDeliveryChoosen: false,
  special_instructions: '',
  checkoutAddress: {
    email: '',
    bill_address_attributes: {
      firstname: '',
      lastname: '',
      address1: '',
      address2: '',
      district: '',
      region: '',
      city: '',
      phone: '',
      zipcode: '',
      state_name: '',
      country_iso: 'NP',
    },
    ship_address_attributes: {
      firstname: '',
      lastname: '',
      address1: '',
      address2: '',
      district: '',
      region: '',
      city: '',
      phone: '',
      zipcode: '',
      state_name: '',
      country_iso: 'NP',
    },
    is_same_as_billing: true,
  },
  orderDetailData: {
    orderDetails: {
      orderData: null,
      isLoading: false,
      error: false,
      successStatus: false,
    },
    orderNumber: '',
  },
};

export default function checkOutReducer(state = initialState, action) {
  switch (action.type) {
    // states
    case CHECKOUT_ACTIONS.ADD_STATE_ADDRESS_REQUEST:
      return {
        ...state,
      };
    case CHECKOUT_ACTIONS.ADD_STATE_ADDRESS_SUCCESS:
      return {
        ...state,
        stateAddress: [...action.statesData],
      };
    case CHECKOUT_ACTIONS.ADD_STATE_ADDRESS_FAILED:
      return {
        ...state,
      };

    // shippingMethod
    case CHECKOUT_ACTIONS.ADD_SHIPPING_METHOD_REQUEST:
      return {
        ...state,
      };
    case CHECKOUT_ACTIONS.ADD_SHIPPING_METHOD_SUCCESS:
      return {
        ...state,
        deliveryOption: action.deliveryOption,
      };
    case CHECKOUT_ACTIONS.ADD_SHIPPING_METHOD_FAILED:
      return {
        ...state,
      };
    case CHECKOUT_ACTIONS.SEND_SHIPPING_METHOD_REQUEST:
      return {
        ...state,
        special_instructions: action.special_instructions,
        selectedDeliveryOption: action.selectedDeliveryOption,
      };
    case CHECKOUT_ACTIONS.SEND_SHIPPING_METHOD_SUCCESS:
      return {
        ...state,
      };

    // set sepical instruction
    case CHECKOUT_ACTIONS.SET_SPECIAL_INSTRUCTIONS:
      return {
        ...state,
        special_instructions: action.setspecial_instructions,
      };

    // address
    case CHECKOUT_ACTIONS.ADD_CHECKOUT_ADDRESS_REQUEST:
      return {
        ...state,
        checkoutAddress: action.formData,
      };
    case CHECKOUT_ACTIONS.ADD_CHECKOUT_ADDRESS_SUCCESS:
      return {
        ...state,
      };
    case CHECKOUT_ACTIONS.ADD_CHECKOUT_ADDRESS_FAILED:
      return {
        ...state,
      };
    case CHECKOUT_ACTIONS.UPDATE_CHECKOUT_ADDRESS_REQUEST:
      return {
        ...state,
        checkoutAddress: action.formDataUpdates,
      };
    case CHECKOUT_ACTIONS.UPDATE_CHECKOUT_ADDRESS_SUCCESS:
      return {
        ...state,
      };
    case CHECKOUT_ACTIONS.UPDATE_CHECKOUT_ADDRESS_FAILED:
      return {
        ...state,
      };

    // payment
    case CHECKOUT_ACTIONS.ADD_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
      };
    case CHECKOUT_ACTIONS.ADD_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethod: [...action.paymentMethod],
      };
    case CHECKOUT_ACTIONS.SEND_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        selectedPaymentMethod: action.selectedPaymentMethod,
      };
    case CHECKOUT_ACTIONS.SEND_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        orderDetailData: {
          ...state.orderDetailData,
          orderNumber: action.orderNumber,
        },
        checkOutSuccess: true,
      };
    case CHECKOUT_ACTIONS.CLEAR_CHECKOUT:
      return {
        ...state,
        stateAddress: [],
        checkoutAddress: {
          ...state.checkoutAddress,
          is_same_as_billing: true,
        },
        continueAsGuest: false,
        isformSumitted: false,
        isDeliveryChoosen: false,
        deliveryOption: [],
        selectedDeliveryOption: {},
        paymentMethod: [],
        checkOutSuccess: false,
        selectedPaymentMethod: {},
      };

    // ADD_EMAIL
    case CHECKOUT_ACTIONS.CHECKOUT_ADD_EMAIL:
      return {
        ...state,
        checkoutAddress: {
          ...state.checkoutAddress,
          email: action.email,
        },
      };
    // modal
    case CHECKOUT_ACTIONS.CHECKOUT_ADDRESS_MODAL_OPEN:
      return {
        ...state,
        modal: {
          modalID: action.modalID,
          isModalOpen: true,
        },
      };
    case CHECKOUT_ACTIONS.CHECKOUT_ADDRESS_MODAL_CLOSE:
      return {
        ...state,
        modal: {
          modalID: null,
          isModalOpen: false,
        },
      };

    // checkout-flow
    case CHECKOUT_ACTIONS.CHECKOUT_CONTINUE_AS_GUEST:
      return {
        ...state,
        continueAsGuest: true,
      };
    case CHECKOUT_ACTIONS.CHECKOUT_IS_FORM_SUBMITTED:
      return {
        ...state,
        isformSumitted: true,
      };
    case CHECKOUT_ACTIONS.CHECKOUT_IS_DELIVERY_CHOSEN:
      return {
        ...state,
        isDeliveryChoosen: true,
      };

    // order details
    case CHECKOUT_ACTIONS.FETCH_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        orderDetailData: {
          ...state.orderDetailData,
          orderDetails: {
            ...state.orderDetailData.orderDetails,
            isLoading: true,
            error: false,
          },
        },
      };

    case CHECKOUT_ACTIONS.FETCH_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderDetailData: {
          ...state.orderDetailData,
          orderDetails: {
            orderData: action.orderData,
            successStatus: true,
            isLoading: false,
            error: false,
          },
        },
      };

    case CHECKOUT_ACTIONS.FETCH_ORDER_DETAIL_FAILED:
      return {
        ...state,
        orderDetailData: {
          ...state.orderDetailData,
          orderDetails: {
            ...state.orderDetailData.orderDetails,
            successStatus: false,
            isLoading: false,
            error: action.error,
          },
        },
      };

    case CHECKOUT_ACTIONS.CLEAR_ORDER_DETAILS:
      return {
        ...state,
        orderDetailData: {
          orderDetails: {
            orderData: null,
            isLoading: false,
            error: false,
          },
          orderNumber: '',
        },
      };

    case CHECKOUT_ACTIONS.RESET_TO_INITIAL_CHECKOUT_STEP:
      return {
        ...state,
        isformSumitted: false,
        isDeliveryChoosen: false,
      };

    // logout
    case CHECKOUT_ACTIONS.RESET_CHECKOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
