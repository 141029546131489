import React, { useEffect, useState } from "react";
import generatePages from "utils/pageGenerator";

const Pagination = ({ className, pageNumberHandler, pageMetaData, page }) => {
  const currentPage = page && parseInt(page, 10);

  const totalPages = pageMetaData?.total_pages;

  const [pages, setPages] = useState(generatePages(currentPage, totalPages));

  const previousClickHandler = () => {
    pageNumberHandler(currentPage - 1);
  };

  const nextClickHandler = () => {
    pageNumberHandler(currentPage + 1);
  };

  const pageNumberClickHandler = (pageNum) => {
    pageNumberHandler(pageNum);
  };

  const firstClickHandler = () => {
    pageNumberHandler(1);
  };
  const lastClickHandler = () => {
    pageNumberHandler(pageMetaData.total_pages);
  };

  useEffect(() => {
    setPages(generatePages(currentPage, totalPages));
  }, [currentPage]);

  if (
    pageMetaData &&
    (Object.keys(pageMetaData).length === 0 || pageMetaData.total_count === 0)
  )
    return null;

  return (
    <nav aria-label="Page navigation example">
      <ul className={`pagination ${className}`}>
        <li className={`${currentPage === 1 ? "disabled" : ""} page-item`}>
          <button
            type="button"
            onClick={firstClickHandler}
            className="page-link"
          >
            First
          </button>
        </li>
        <li className={`${currentPage === 1 ? "disabled" : ""} page-item`}>
          <button
            type="button"
            onClick={previousClickHandler}
            className="page-link"
          >
            Previous
          </button>
        </li>
        {pageMetaData?.total_pages !== 0 &&
          pages.map((v) => {
            const activeClass = currentPage === v ? "active" : "";

            return (
              <li className="page-item pointer" key={v}>
                <button
                  type="button"
                  className={`page-link ${activeClass}`}
                  onClick={() => pageNumberClickHandler(v)}
                >
                  {v}
                </button>
              </li>
            );
          })}
        <li
          className={`${
            pageMetaData?.total_pages === 0 ||
            (pageMetaData && currentPage === pageMetaData.total_pages)
              ? "disabled"
              : ""
          } page-item`}
        >
          <button
            type="button"
            className="page-link"
            onClick={nextClickHandler}
          >
            Next
          </button>
        </li>
        <li
          className={`${
            pageMetaData?.total_pages === 0 ||
            (pageMetaData && currentPage === pageMetaData.total_pages)
              ? "disabled"
              : ""
          } page-item`}
        >
          <button
            type="button"
            className="page-link"
            onClick={lastClickHandler}
          >
            Last
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
