import React, { useState } from "react";
import { I18n, Translate } from "react-redux-i18n";

import QuestionForm from "components/Product/QuestionForm";

import ProductQuestionButton from "./ProductQuestionsButton";

function MessageStrip() {
  const [toggleQuestionForm, setToggleQuestionForm] = useState(false);

  const handleToggleQuestionForm = () => {
    setToggleQuestionForm(!toggleQuestionForm);
  };

  return (
    <section className="py-5 text-center bg-light text-white">
      <div className="container">
        <h2 className="font-weight-bold text-dark">
          <Translate value="questions.title" />
        </h2>
        <ProductQuestionButton
          toggleQuestionForm={toggleQuestionForm}
          handleToggleQuestionForm={handleToggleQuestionForm}
          setToggleQuestionForm={setToggleQuestionForm}
        />

        <div className="mt-4 row justify-content-center">
          <div className="col-md-6 text-dark">
            {toggleQuestionForm && <QuestionForm showCancelButton={false} />}
          </div>
        </div>
      </div>
    </section>
  );
}

export default MessageStrip;
