import { combineReducers } from "redux";
import { i18nReducer as i18n } from "react-redux-i18n";
import { reducer as formReducer } from "redux-form";

import auth from "appRedux/auth/reducer";
import modal from "appRedux/modal/reducer";
import cart from "appRedux/cart/reducer";
import productDetail from "appRedux/productDetail/reducer";
import productQuestion from "appRedux/productQuestion/reducer";
import productReview from "appRedux/productReview/reducer";
import checkOutReducer from "appRedux/checkOut/reducer";
import taxons from "appRedux/taxon/reducer";
import wishlist from "appRedux/wishlist/reducer";
import user from "appRedux/user/reducer";
import products from "appRedux/products/reducer";
import slider from "appRedux/slider/reducer";
import vendor from "appRedux/vendor/reducer";
import storeConfig from "appRedux/store/reducer";

export default combineReducers({
  auth,
  modal,
  cart,
  storeConfig,
  productQuestion,
  productReview,
  taxons,
  user,
  wishlist,
  slider,
  vendor,
  products,
  form: formReducer,
  checkout: checkOutReducer,
  productDetail,
  i18n,
});
