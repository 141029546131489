import React from "react";
import PropTypes from "prop-types";

import Spinner from "./Spinner";

function Button({ btnText, className, onClick, disabled, isLoading, type }) {
  return (
    <button
      disabled={disabled || isLoading}
      type={type}
      onClick={onClick}
      className={`btn ${className} d-flex justify-content-center align-items-center`}
    >
      <span>{btnText}</span>
      <Spinner show={isLoading} />
    </button>
  );
}

Button.defaultProps = {
  btnText: "",
  className: "",
  onClick: () => {},
  disabled: false,
  isLoading: false,
  type: "button",
};

Button.propTypes = {
  btnText: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
};

export default Button;
