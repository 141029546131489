import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import ProductImageSlider from "./Image/ProductImageSlider";
import ProductDescription from "./BasicInfo/ProductBasicInfo";
import ProductBasicInfo from "./BasicInfo/ProductBasicInfo";
import CartForm from "./Cart/CartForm";
import ProductAdditionalInfo from "./AdditionalInfo/ProductAdditionalInfo";

function getAvailableOptionValues(product) {
  const { variants, option_types } = product;
  const options = {};

  variants.forEach(({ option_values }) => {
    option_values.forEach(({ id, name, option_type, presentation }) => {
      // TODO: @anil its crashing sometimes when option_type is undefined
      if (!option_type) return;

      if (!(option_type.name in options)) options[option_type.name] = [];
      const existingValue = options[option_type.name].find(
        (opt) => opt.name === name
      );

      if (!existingValue)
        options[option_type.name].push({ id, name, presentation });
    });
  });

  return option_types.map((v) => ({ ...v, option_values: options[v.name] }));
}

function ProductDisplay({
  product,
  addToCart,
  addToWishlist,
  slug,
  wishlists,
}) {
  const { variants, default_variant, videos } = product;
  const [displayVariantId, setDisplayVariantId] = useState(default_variant.id);
  const [currentOptionTypes, setCurrentOptionTypes] = useState({});

  useEffect(() => {
    const filteredValues = productDetail.option_values.filter(
      (optionValues) => {
        return optionValues.presentation !== undefined;
      }
    );

    filteredValues.map(({ option_type, presentation }) => {
      if (
        option_type.name === "color" ||
        option_type.name === "transparent-color"
      ) {
        setCurrentOptionTypes({ ...currentOptionTypes, color: presentation });
      } else {
        setCurrentOptionTypes((prevVaules) => ({
          ...prevVaules,
          [option_type.name]: presentation,
        }));
      }
    });
  }, [displayVariantId]);

  const displayVariant = [default_variant, ...variants].find(
    ({ id }) => id === displayVariantId
  );

  const { productReviews } = useSelector((state) => state.productReview);

  const changeDisplayVariant = (optionValueId) => {
    const selectedVariant = variants.find(({ option_values }) =>
      option_values.find(({ id }) => id === optionValueId)
    );

    if (selectedVariant) {
      setDisplayVariantId(selectedVariant.id);
    }
  };

  const { images, ...productDetail } = displayVariant || default_variant;

  const optionTypes = getAvailableOptionValues(product);

  return (
    <section className="py-5">
      <div className="container">
        <div className="row">
          <ProductImageSlider images={images} videos={videos} />
          <div className="col-md-6 offset-md-1">
            <ProductBasicInfo
              slug={slug}
              productReviews={productReviews || []}
              productDescription={product.description}
              ratings={product.stars}
              productDetail={productDetail}
              optionTypes={optionTypes}
              comparePrice={product.display_compare_at_price}
            />
            <CartForm
              optionTypes={optionTypes}
              vendor={product.vendor}
              addToCart={addToCart}
              setCurrentOptionTypes={setCurrentOptionTypes}
              addToWishlist={addToWishlist}
              currentOptionTypes={currentOptionTypes}
              wishlists={wishlists}
              variantId={displayVariant.id}
              inStock={productDetail.in_stock}
              backorderable={productDetail.backorderable}
              available={productDetail.product.available}
              changeDisplayVariant={changeDisplayVariant}
            />
            <div className="py-3">
              <ProductAdditionalInfo
                productReviews={productReviews || []}
                productProperties={productDetail.product.product_properties}
                description={productDetail.product.description}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ProductDisplay.defaultProps = {
  addToCart: () => {},
};

ProductDisplay.propTypes = {
  product: PropTypes.shape({}).isRequired,
  addToCart: PropTypes.func,
};

export default ProductDisplay;
