import React from "react";
import { withRouter } from "react-router";
import { Translate } from "react-redux-i18n";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import AddressBookItem from "components/User/AddressBookItem";
import AddressBookHeadings from "components/User/AddressBookHeadings";

import {
  setUserSelectedBillAddress,
  setUserSelectedShipAddress,
} from "appRedux/user/actions";

import ROUTES from "constants/routes";
import { ADDRESS_MODAL_TITLES } from "constants/modalTitles";

const AddressBookCard = ({ addressBook, history, modalTitle }) => {
  const {
    selectedAddress: { billingAddress, shippingAddress },
  } = useSelector((state) => state.user);

  const selectedBillingAddressId = billingAddress.id;
  const selectedShippingAddressId = shippingAddress.id;

  const dispatch = useDispatch();
  const editAddressHandler = (id) => {
    const editRoute = ROUTES.USER.ADDRESS.EDIT.replace(":addressId", id);

    history.push(editRoute);
  };

  const addAddressHandler = () => {
    history.push(ROUTES.USER.ADDRESS.ADD);
  };

  if (modalTitle) {
    if (modalTitle === ADDRESS_MODAL_TITLES.BILLING_ADDRESS) {
      addressBook.sort((x, y) =>
        x.id === selectedBillingAddressId
          ? -1
          : y.id === selectedBillingAddressId
          ? 1
          : 0
      );
    } else if (modalTitle === ADDRESS_MODAL_TITLES.SHIPPING_ADDRESS) {
      addressBook.sort((x, y) =>
        x.id === selectedShippingAddressId
          ? -1
          : y.id === selectedShippingAddressId
          ? 1
          : 0
      );
    }
  }

  const setAddressHandler = (id) => {
    const address = addressBook.find((address) => address.id === id);

    if (modalTitle) {
      if (modalTitle === ADDRESS_MODAL_TITLES.BILLING_ADDRESS) {
        dispatch(setUserSelectedBillAddress(address));
      } else if (modalTitle === ADDRESS_MODAL_TITLES.SHIPPING_ADDRESS) {
        dispatch(setUserSelectedShipAddress(address));
      }
    }
  };

  return (
    <div className="table-responsive-sm">
      <table className="table table-striped table-responsive">
        <AddressBookHeadings modalTitle={modalTitle} />
        {addressBook &&
          addressBook.map((address) => {
            return (
              <AddressBookItem
                selectedBillingAddressId={selectedBillingAddressId}
                selectedShippingAddressId={selectedShippingAddressId}
                key={address.id}
                address={address}
                modalTitle={modalTitle}
                btnText={<Translate value="user.addressBook.button.edit" />}
                onItemButtonClick={editAddressHandler}
                setAddress={setAddressHandler}
              />
            );
          })}
        <div className="mt-4 text-end">
          <button
            onClick={addAddressHandler}
            type="button"
            className="btn btn-primary"
          >
            <FontAwesomeIcon icon={faPlus} />{" "}
            <Translate value="user.addressBook.button.addNewAddress" />
          </button>
        </div>
      </table>
    </div>
  );
};

export default withRouter(AddressBookCard);
