import React from "react";

import ROUTES from "constants/routes";
import trimText from "utils/trimmer";

const Breadcrumb = ({ secondStep, thirdStep, url }) => {
  return (
    <nav aria-label="breadcrumb" className="border-bottom bg-white">
      <ol className="breadcrumb mb-0 container px-3 py-2">
        <li className="breadcrumb-item">
          <a href={ROUTES.HOME.INDEX}>Home</a>
        </li>
        {secondStep && thirdStep ? (
          <li className="breadcrumb-item">
            <a href={url}>{secondStep}</a>
          </li>
        ) : (
          <li className="breadcrumb-item active" aria-current="page">
            {secondStep}
          </li>
        )}
        {thirdStep && (
          <li className="breadcrumb-item active" aria-current="page">
            {trimText(thirdStep)}
          </li>
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
