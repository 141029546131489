import React from 'react'
import KhaltiCheckout from 'khalti-checkout-web'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { withRouter } from 'react-router'

import { sendPaymentMethodRequest } from 'appRedux/checkOut/actions'

import PaymentButton from 'components/Payment/Button/PaymentButton'
import Layout from 'components/Layout/Layout'

import ROUTES from 'constants/routes'

const CheckoutPayment = () => {
  const dispatch = useDispatch()

  const paymentMethod = useSelector((state) => state.checkout.paymentMethod)

  const { cartData } = useSelector(({ cart }) => cart)

  const onPay = (methodName) => {
    const selectedMethod = paymentMethod.filter(
      (method) => method.name === methodName,
    )

    dispatch(sendPaymentMethodRequest(selectedMethod[0]))
  }

  const responseOut = (response) => {
    console.log(response)
  }

  const onKhaltiPaymentSuccess = (methodName, { token, amount }) => {
    const paymentData = { token, amount }
    const selectedMethod = paymentMethod.filter(
      (method) => method.name === methodName,
    )

    dispatch(sendPaymentMethodRequest(selectedMethod[0], paymentData))
  }

  const onKhaltiPaymentError = (response) => {
    responseOut(response)
  }

  const productDetails = {
    id: cartData.number,
    name: cartData.number,
    amount: cartData.total * 100,
    productUrl: window?.location.origin,
  }

  return (
    <Layout title={'Payment | Checkout'} path={ROUTES.CHECKOUT.PAYMENT}>
      <div className="card order-summary border-0 shadow-sm p-3">
        <h2 className="h4 font-weight-bold mb-3">
          <Translate value="checkout.payment.choosePaymentMethod" />
        </h2>
        <div className="accordion" id="accordionExample">
          {paymentMethod.map((method) => {
            return (
              <PaymentButton
                method={method}
                key={method.id}
                responseOut={responseOut}
                onPay={onPay}
                productDetails={productDetails}
                onKhaltiPaymentSuccess={onKhaltiPaymentSuccess}
                onKhaltiPaymentError={onKhaltiPaymentError}
                onClose={() => {}}
              />
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default withRouter(CheckoutPayment)
