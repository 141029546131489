import { Http } from "@spree/storefront-api-v2-sdk";

import { Routes } from "lib/spreeSdk/routes";

export default class ProductQuestion extends Http {
  async list(token = {}, productId, params) {
    return this.spreeResponse(
      "get",
      Routes.productQuestionPath(productId),
      token,
      params
    );
  }

  async listByUser(token, userId, params) {
    return this.spreeResponse(
      "get",
      Routes.userProductQuestionPath(userId),
      token, // pass token for api call
      params
    );
  }

  async createQuestion(token, productId, params) {
    const response = await this.spreeResponse(
      "post",
      Routes.productQuestionPath(productId),
      token, // pass token for api call
      params
    );

    return response;
  }

  async updateQuestion(token, productId, questionId, params) {
    const response = await this.spreeResponse(
      "patch",
      Routes.productQuestionDetailPath(productId, questionId),
      token, // pass token for api call
      params
    );

    return response;
  }

  async removeQuestion(token, productId, questionId) {
    const response = await this.spreeResponse(
      "delete",
      Routes.productQuestionDetailPath(productId, questionId),
      token // pass token for api call
    );

    return response;
  }
}
