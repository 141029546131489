import React, { useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { useDispatch, useSelector } from "react-redux";

import Carousel from "components/Carousel/Carousel";
import CategoryCarousel from "components/Carousel/CategoryCarousel";
import FeatureStrip from "components/FeatureStrip";
import FeaturedCategory from "components/Home/FeaturedCategory";
import ProductBanner from "components/Home/ProductBanner";
import Footer from "components/Footer/Footer";
import Layout from "components/Layout/Layout";

import { fetchProductsRequest, resetProducts } from "appRedux/products/actions";
import { fetchSlideLocationRequest } from "appRedux/slider/actions";

import TAXONS from "constants/taxons";
import ROUTES from "constants/routes";

function Home() {
  const dispatch = useDispatch();

  const { taxons } = useSelector((state) => state.taxons);
  const { sliderData, isLoading: isSliderImageLoading } = useSelector(
    (state) => state.slider
  );
  const { products, isLoading, error } = useSelector((state) => state.products);

  let trendingId = "";
  let bestSellerId = "";

  if (taxons && taxons.length !== 0) {
    const trending =
      taxons.find((taxon) => {
        return taxon.name === TAXONS.TRENDING;
      }) || {};

    trendingId = trending.id || taxons[0].id;

    const bestSeller =
      taxons.find((taxon) => {
        return taxon.name === TAXONS.BEST_SELLERS;
      }) || {};

    bestSellerId = bestSeller?.id || taxons[taxons.length - 1].id;
  }

  useEffect(() => {
    dispatch(fetchSlideLocationRequest(process.env.REACT_APP_SLIDER_ID));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetProducts());
    };
  }, []);

  useEffect(() => {
    if (taxons) {
      if (trendingId) {
        dispatch(
          fetchProductsRequest({
            filter: { taxons: trendingId },
            perPage: 15,
          })
        );
      }
      if (bestSellerId) {
        dispatch(
          fetchProductsRequest({
            filter: { taxons: bestSellerId },
            perPage: 15,
          })
        );
      }
    }
  }, [trendingId, bestSellerId]);

  const bestSellerProduct = products[bestSellerId]
    ? products[bestSellerId].products
    : [];
  const trendingProduct = products[trendingId]
    ? products[trendingId].products
    : [];

  return (
    <Layout path={ROUTES.HOME.INDEX}>
      <Carousel
        sliderData={sliderData}
        isSliderImageLoading={isSliderImageLoading}
      />
      <FeaturedCategory />

      <CategoryCarousel
        isLoading={isLoading}
        products={bestSellerProduct}
        categoryId={bestSellerId}
        title={I18n.t("categoryCarousel.titles.bestSeller")}
      />
      <ProductBanner
        category={I18n.t("productBanner.category")}
        title={I18n.t("productBanner.title")}
        description="This Ergonomic design gaming racing style home office chairs are of high quality standard and are reliable for long hours of Gaming and Sitting."
        btnText={I18n.t("productBanner.buttons.readMore")}
      />
      <CategoryCarousel
        isLoading={isLoading}
        products={trendingProduct}
        categoryId={trendingId}
        title={I18n.t("categoryCarousel.titles.trending")}
      />

      {/* <FeatureStrip /> */}
      <Footer />
    </Layout>
  );
}

export default Home;
