import React from "react";

import CollapseItem from "components/CollapseItem";

const PaymentWrapper = ({ children, method }) => {
  return (
    <CollapseItem
      title={`Pay with ${method.name}`}
      className="order-summary"
      wrapperclassName="mb-3 border"
      itemID={`payment-${method.id}`}
      headingSize="h5"
    >
      {children}
    </CollapseItem>
  );
};

export default PaymentWrapper;
