import React, { useEffect, useState } from "react";
import { I18n, Translate } from "react-redux-i18n";
import { connect, useDispatch, useSelector } from "react-redux";
import { reduxForm, reset } from "redux-form";
import { Field } from "redux-form-normalize-on-blur";
import { withRouter } from "react-router";

import OrderSummaryItems from "components/OrderSummaryItems";
import CollapseItem from "components/CollapseItem";

import {
  sendpromoCodeRequest,
  removePromoCodeRequest,
} from "appRedux/cart/actions";

import trimmer from "utils/trimmer";
import { required } from "utils/fieldValidation";

import ROUTES from "constants/routes";
import TIMEOUT_MILLISECONDS from "constants/timeoutMilliseconds";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";

const OrderSummarySide = ({
  cartData,
  isformSumitted,
  isDeliveryChoosen,
  procceedToCheckOutButton,
  history,
  handleSubmit,
}) => {
  const dispatch = useDispatch();

  const [addSuccessMessage, setAddSuccessMessage] = useState(null);
  const [removeSuccessMessage, setRemoveSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    promoCodeData: { promoCodeAdd, promoCodeRemove, promoCode },
  } = useSelector((state) => state.cart);

  const handleProccedToCheckOut = () => {
    history.push(ROUTES.CHECKOUT.ADDRESS);
  };

  if (promoCodeAdd.success) {
    dispatch(reset("promoCode"));
  }

  const isLoading = promoCodeAdd.isLoading || promoCodeRemove.isLoading;
  const error = promoCodeAdd.error || promoCodeRemove.error;

  const setTimer = (setMessage) =>
    setTimeout(() => {
      setMessage(false);
    }, TIMEOUT_MILLISECONDS.SUCCESS_MESSAGE);

  useEffect(() => {
    const timer = setTimer(setAddSuccessMessage);

    if (promoCodeAdd.success) {
      setAddSuccessMessage(true);
    }

    return () => clearTimeout(timer);
  }, [promoCodeAdd.success]);

  useEffect(() => {
    const timer = setTimer(setErrorMessage);

    if (error) {
      setErrorMessage(true);
    }

    return () => clearTimeout(timer);
  }, [error]);

  useEffect(() => {
    const timer = setTimer(setRemoveSuccessMessage);

    if (promoCodeRemove.success) {
      setRemoveSuccessMessage(true);
    }

    return () => clearTimeout(timer);
  }, [promoCodeRemove.success]);

  const submit = ({ typedPromoCode }) => {
    if (promoCodeAdd.success) {
      dispatch(removePromoCodeRequest(promoCode));
    } else if (typedPromoCode !== "") {
      dispatch(sendpromoCodeRequest(typedPromoCode));
    }
  };

  return (
    <CollapseItem
      title={I18n.t("orderSummary.orderSummary.title")}
      className="order-summary"
      wrapperclassName="shadow-sm border-0 border-bottom"
      itemID="order"
      activeStatus={!isformSumitted && !isDeliveryChoosen}
      headingSize="h5"
    >
      <OrderSummaryItems
        itemCount={cartData.item_count}
        displayItemTotal={cartData.display_item_total}
        displayAdjustmentTotal={cartData.display_adjustment_total}
        displayShipTotal={cartData.display_ship_total}
        displayTaxTotal={cartData.display_tax_total}
      />
      {!isformSumitted && (
        <form onSubmit={handleSubmit(submit)}>
          <div className="d-flex">
            <div className="flex-grow-1">
              <Field
                name="typedPromoCode"
                type="text"
                component={PromoCodeFieldrenderer}
                normalizeOnBlur={trimmer}
                label="Promo Code"
                validate={[required]}
              />
            </div>
            <button
              disabled={
                cartData.item_count === 0 ||
                isLoading ||
                removeSuccessMessage ||
                addSuccessMessage
              }
              className="btn btn-primary px-2 col-5 promo-btn"
              type="submit"
              id="button-addon2"
            >
              {promoCodeAdd.success ? (
                <Translate value="orderSummary.orderSummary.removeCode" />
              ) : (
                <Translate value="orderSummary.orderSummary.applyCode" />
              )}
            </button>
          </div>
          <div id="Help" className="form-text">
            {errorMessage ? (
              <p className="text-danger">{error}</p>
            ) : isLoading ? (
              <p className=" text-primary">
                <Translate value="common.loading" />
              </p>
            ) : addSuccessMessage ? (
              <p className=" text-primary">
                <Translate value="promoCode.promoCodeApplied" />
              </p>
            ) : (
              removeSuccessMessage && (
                <p className=" text-primary">
                  <Translate value="promoCode.promoCodeRemoved" />
                </p>
              )
            )}
          </div>
        </form>
      )}
      <div className="total-price mt-4 h5">
        {`${I18n.t("orderSummary.orderSummary.total")}: `}
        <span className="price font-weight-bold float-end">
          {cartData.display_total}
        </span>
        {procceedToCheckOutButton && (
          <button
            disabled={cartData.item_count === 0}
            onClick={handleProccedToCheckOut}
            className="btn btn-primary px-3 w-100 mt-3"
            type="button"
            id="button-addon2"
          >
            <Translate value="orderSummary.orderSummary.proceedToCheckout" />
          </button>
        )}
      </div>
    </CollapseItem>
  );
};

const PromoCodeFieldrenderer = ({ input, name }) => {
  const {
    promoCodeData: { promoCodeAdd },
  } = useSelector((state) => state.cart);

  return (
    <input
      {...input}
      placeholder={
        promoCodeAdd.success
          ? capitalizeFirstLetter(promoCodeAdd.success)
          : I18n.t("promoCode.title")
      }
      type="text"
      id={name}
      disabled={promoCodeAdd.success}
      aria-label="Recipient's promocode"
      className="form-control promo-input"
      aria-describedby="button-addon2"
    />
  );
};

const mapStateToProps = (state) => {
  const { promoCodeAdd, promoCodeRemove, promoCode } = state.cart.promoCodeData;

  return {
    initialValues: {
      typedPromoCode:
        promoCodeAdd.success || promoCodeRemove.success ? "" : promoCode,
    },
  };
};

export default withRouter(
  connect(mapStateToProps)(reduxForm({ form: "promoCode" })(OrderSummarySide))
);
