import React from "react";
import { Translate } from "react-redux-i18n";

const QuestionStatus = ({ status, disableAnswerStatus }) => {
  if (!status) {
    return (
      <span className="badge border rounded-pill text-warning border-warning h-50">
        <Translate value="user.myQuestions.pendingReview" />
      </span>
    );
  }

  if (disableAnswerStatus) return null;

  return (
    <span className="badge border rounded-pill text-success border-success h-50">
      <Translate value="user.myQuestions.answered" />
    </span>
  );
};

export default QuestionStatus;
