import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { removeItem } from "appRedux/cart/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function CartItem({ image, altTag, title, price, quantity, id, productUrl }) {
  const dispatch = useDispatch();
  const removeCartItemHandler = () => {
    dispatch(removeItem(id));
  };

  return (
    <li className="cart-product border-bottom pb-2">
      <div className="d-flex cart-item align-items-center">
        <img src={image} alt={altTag} className="img-thumbnail" />
        <div className="details ms-3">
          <Link to={productUrl}>
            <h3 className="h6 title text-dark mb-1">{title}</h3>
          </Link>
          <h6 className="price text-secondary">
            {price}
            <span className="ms-3 text-muted">x{quantity}</span>
          </h6>
        </div>
      </div>

      <FontAwesomeIcon
        icon={faTimes}
        className="close text-danger"
        onClick={removeCartItemHandler}
        type="button"
        aria-label="Remove"
      />
    </li>
  );
}

export default CartItem;
