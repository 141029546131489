import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import SideNav from 'components/SideNav'
import Search from './Search'
import Brand from './Brand'
import HeaderIcon from './HeaderIcon'
import SecondaryNav from './SecondaryNav'
import MobileMenuIcon from './MobileMenuIcon'

function Header(handleProductFilter) {
  const { cartData } = useSelector(({ cart }) => cart)

  const [filterEnabled, setFilterEnabled] = useState(false)

  const toggleFilter = () => {
    setFilterEnabled(!filterEnabled)
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top border-bottom d-none d-lg-flex">
        <div className="container">
          <Brand />
          <Search onToggle={toggleFilter} />
          <SecondaryNav />
          <HeaderIcon
            itemCount={cartData.item_count}
            displayTotal={cartData.display_total}
          />
        </div>
      </nav>

      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top border-bottom d-flex d-lg-none">
        <div className="container">
          <MobileMenuIcon className="order-1" onClick={toggleFilter} />
          <Brand className="order-2" />
          <HeaderIcon
            className="order-3"
            itemCount={cartData.item_count}
            displayTotal={cartData.display_total}
          />
        </div>

        <SideNav
          isOpen={filterEnabled}
          onToggle={toggleFilter}
          handleProductFilter={handleProductFilter}
        />
      </nav>
    </>
  )
}

export default Header
