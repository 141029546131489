import swal from "sweetalert";
import { I18n } from "react-redux-i18n";

import PRODUCT_QUESTION_ACTIONS from "./constants";

export const fetchAllProductQuestions = (productId) => ({
  type: PRODUCT_QUESTION_ACTIONS.FETCH_ALL_PRODUCT_QUESTIONS_REQUEST,
  productId,
});

export const fetchAllProductQuestionsFail = () => ({
  type: PRODUCT_QUESTION_ACTIONS.FETCH_ALL_PRODUCT_QUESTIONS_FAILED,
});

export const fetchAllProductQuestionsSuccess = (questions) => ({
  type: PRODUCT_QUESTION_ACTIONS.FETCH_ALL_PRODUCT_QUESTIONS_SUCCESS,
  questions,
});

export const createProductQuestionRequest = ({
  content,
  email,
  userId,
  productId,
  isAnonymous,
  fullName,
}) => ({
  type: PRODUCT_QUESTION_ACTIONS.CREATE_PRODUCT_QUESTION_REQUEST,
  content,
  userId,
  isAnonymous,
  email,
  productId,
  fullName,
});

export const createProductQuestionFail = () => ({
  type: PRODUCT_QUESTION_ACTIONS.CREATE_PRODUCT_QUESTION_FAILED,
});

export const createProductQuestionSuccess = () => {
  swal(
    I18n.t("questions.questionSubmitted"),
    "Your question has been submitted",
    "success"
  );

  return {
    type: PRODUCT_QUESTION_ACTIONS.CREATE_PRODUCT_QUESTION_SUCCESS,
  };
};

export const editProductQuestionRequest = ({
  content,
  questionId,
  productId,
  isAnonymous,
  history,
  fullName,
}) => ({
  type: PRODUCT_QUESTION_ACTIONS.EDIT_PRODUCT_QUESTION_REQUEST,
  content,
  questionId,
  isAnonymous,
  productId,
  history,
  fullName,
});

export const editProductQuestionFail = () => ({
  type: PRODUCT_QUESTION_ACTIONS.EDIT_PRODUCT_QUESTION_FAILED,
});

export const editProductQuestionSuccess = () => {
  swal("Question Edited!", "Your question has been edited", "info");

  return {
    type: PRODUCT_QUESTION_ACTIONS.EDIT_PRODUCT_QUESTION_SUCCESS,
  };
};

export const removeProductQuestionRequest = ({
  questionId,
  productId,
  currentPage,
  userId,
}) => ({
  type: PRODUCT_QUESTION_ACTIONS.REMOVE_PRODUCT_QUESTION_REQUEST,
  questionId,
  productId,
  currentPage,
  userId,
});

export const removeProductQuestionFail = () => ({
  type: PRODUCT_QUESTION_ACTIONS.REMOVE_PRODUCT_QUESTION_FAILED,
});

export const removeProductQuestionSuccess = () => {
  swal("Question Removed!", "Your question has been removed", "info");

  return {
    type: PRODUCT_QUESTION_ACTIONS.REMOVE_PRODUCT_QUESTION_SUCCESS,
  };
};
