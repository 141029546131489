import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import { normalizeData } from "services/jsonaDataFormatter";
import ProductsApi from "api/products/productsApi";
import TaxonApi from "api/taxon/taxonApi";

import extractFilters from "utils/extractFilters";

import {
  fetchProductSuccess,
  searchSuggestionSuccess,
  searchSuggestionFailed,
} from "./actions";

import PRODUCTS_ACTIONS from "./constants";

function* productsRequest({ filter, pageNumber, sortBy, perPage }) {
  try {
    const filterGroup = filter.taxons
      ? filter.taxons
      : filter.name
      ? filter.name
      : "All Products";

    const res = yield call(ProductsApi.searchProduct, {
      filter,
      pageNumber,
      sortBy,
      perPage,
    });

    if (res.isSuccess()) {
      const data = res.success();
      const products = normalizeData(data);

      let name = filterGroup;

      if (filter.taxons) {
        const taxonName = yield call(TaxonApi.fetchTaxonName, filter.taxons);

        name = normalizeData(taxonName.success()).name;
      }

      const filters = extractFilters(data.included);

      yield put({
        type: PRODUCTS_ACTIONS.FETCH_PAGE_META_DATA,
        pageMetaData: data.meta,
        filters,
      });
      yield put(
        fetchProductSuccess({
          [filterGroup]: {
            products: [...products],
            name,
          },
        })
      );
    } else {
      throw new Error("unsuccessful");
    }
  } catch (e) {
    console.log(e);
  }
}

function* productSuggestionRequest({ keywords }) {
  try {
    const res = yield call(ProductsApi.searchSuggestions, { keywords });

    if (res.isSuccess()) {
      const data = res.success();
      const suggestions = normalizeData(data);

      yield put(searchSuggestionSuccess(suggestions));
    } else {
      throw new Error("unsuccessful");
    }
  } catch (e) {
    yield put(searchSuggestionFailed(e));
  }
}

export function* watchProductsRequest() {
  yield takeEvery(PRODUCTS_ACTIONS.FETCH_PRODUCTS_REQUEST, productsRequest);
}

export function* watchProductSuggestionRequest() {
  yield takeEvery(
    PRODUCTS_ACTIONS.SEARCH_SUGGESTIONS_REQUEST,
    productSuggestionRequest
  );
}

export default function* rootSaga() {
  yield all([fork(watchProductsRequest), fork(watchProductSuggestionRequest)]);
}
