import PRODUCT_QUESTION_ACTIONS from './constants'

const initialState = {
  productQuestions: [],
  isLoading: false,
  error: false,
  userQuestion: {
    isLoading: false,
    error: false,
  },
}

export default function productQuestionsReducer(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_QUESTION_ACTIONS.FETCH_ALL_PRODUCT_QUESTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case PRODUCT_QUESTION_ACTIONS.FETCH_ALL_PRODUCT_QUESTIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    case PRODUCT_QUESTION_ACTIONS.FETCH_ALL_PRODUCT_QUESTIONS_SUCCESS:
      return {
        ...state,
        productQuestions: action.questions,
        isLoading: false,
        error: null,
      }

    case PRODUCT_QUESTION_ACTIONS.CREATE_PRODUCT_QUESTION_REQUEST:
      return {
        ...state,
        userQuestion: {
          isLoading: true,
          error: false,
        },
      }

    case PRODUCT_QUESTION_ACTIONS.CREATE_PRODUCT_QUESTION_FAILED:
      return {
        ...state,
        userQuestion: {
          ...state.userQuestion,
          isLoading: false,
          error: action.error || true,
        },
      }

    case PRODUCT_QUESTION_ACTIONS.CREATE_PRODUCT_QUESTION_SUCCESS:
      return {
        ...state,
        userQuestion: {
          ...state.userQuestion,
          isLoading: false,
          error: false,
        },
      }

    case PRODUCT_QUESTION_ACTIONS.EDIT_PRODUCT_QUESTION_REQUEST:
      return {
        ...state,
        userQuestion: {
          isLoading: true,
          error: false,
        },
      }

    case PRODUCT_QUESTION_ACTIONS.EDIT_PRODUCT_QUESTION_FAILED:
      return {
        ...state,
        userQuestion: {
          ...state.userQuestion,
          isLoading: false,
          error: action.error || true,
        },
      }

    case PRODUCT_QUESTION_ACTIONS.EDIT_PRODUCT_QUESTION_SUCCESS:
      return {
        ...state,
        userQuestion: {
          ...state.userQuestion,
          isLoading: false,
          error: false,
        },
      }

    case PRODUCT_QUESTION_ACTIONS.REMOVE_PRODUCT_QUESTION_REQUEST:
      return {
        ...state,
        userQuestion: {
          isLoading: true,
          error: false,
        },
      }

    case PRODUCT_QUESTION_ACTIONS.REMOVE_PRODUCT_QUESTION_FAILED:
      return {
        ...state,
        userQuestion: {
          ...state.userQuestion,
          isLoading: false,
          error: action.error || true,
        },
      }

    case PRODUCT_QUESTION_ACTIONS.REMOVE_PRODUCT_QUESTION_SUCCESS:
      return {
        ...state,
        userQuestion: {
          ...state.userQuestion,
          isLoading: false,
          error: false,
        },
      }

    case PRODUCT_QUESTION_ACTIONS.RESET_QUESTION_FORM:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
